// ** MUI Imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Magnify from "mdi-material-ui/Magnify";
import IconButton from "@mui/material/IconButton";
import Close from "mdi-material-ui/Close";

import BenefitItem from "./BenefitItem";
import { NewBenefitType, UpdateBenefitCategoryType } from "../../../../types/apps/attributeTypes";
import { DictionaryListType } from "../../../../service";

import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors, DragEndEvent } from "@dnd-kit/core";

import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { useEffect, useState } from "react";
import { RootState, useAppDispatch } from "../../../../store";
import { getAllBenefits, postUpdateBenefitCategory, postUpdateBenefitOrder } from "../../../../store/apps/attributesSlice";

import classes from "./Benefit.module.sass";
import { useSelector } from "react-redux";
import { companyBenefitParent } from "../../../../types/apps/companyTypes";

type propType = { tableData?: DictionaryListType[]; loading: boolean };

const Benefit = ({ loading }: propType) => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<companyBenefitParent[] | []>([]);
  const stateData = useSelector((state: RootState) => state.attributes.Benefits);
  const [searchVal, setSearchVal] = useState<string>("");
  const [mainDropDown, setmainDropDown] = useState<string>("");

  useEffect(() => {
    initData();
  }, []);

  const initData = async () => {
    await dispatch(getAllBenefits());
  };

  useEffect(() => {
    if (Array.isArray(stateData)) setData(stateData);
  }, [stateData]);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over!.id) {
      const oldIndex = data.findIndex((item) => item.id === active.id);
      const newIndex = data.findIndex((item) => item.id === over!.id);
      const newOrderedList = [...arrayMove(data, oldIndex, newIndex)];

      const updatedArrayList = [];

      for (let i = 0; i < newOrderedList.length; i++) {
        updatedArrayList.push({ ...newOrderedList[i], orderIndex: i });
      }
      setData(updatedArrayList);

      const idList = updatedArrayList.map((el) => el.id);
      const dragObject = { benefitIds: idList, categoryId: null };

      await dispatch(postUpdateBenefitOrder(dragObject));
    }
  };

  const addBenefit = () => {
    let createNewBenefit = {
      categoryId: null,
      name: "",
      nameGE: "",
    } as UpdateBenefitCategoryType;
    dispatch(postUpdateBenefitCategory(createNewBenefit))
      .unwrap()
      .then((res) => {
        setData((prevData) => {
          const newObject = { id: res.data, categoryId: "", benefits: [], companyBenefits: [], name: "", nameGE: "", orderIndex: null } as companyBenefitParent;
          const updatedArray = [newObject, ...prevData].map((el, idx) => ({ ...el, orderIndex: idx }));
          return updatedArray;
        });
      });
  };

  return (
    <Card>
      <CardHeader title={"Benefit"} />
      <Box className={classes.benefitTable}>
        <Box className={classes.headerWrapper}>
          <Button onClick={addBenefit}>Add Benefit</Button>
          <TextField
            variant="standard"
            value={searchVal}
            onChange={(e) => setSearchVal(e.target.value)}
            placeholder="Search…"
            InputProps={{
              startAdornment: <Magnify fontSize="small" />,
              endAdornment: (
                <IconButton size="small" title="Clear" aria-label="Clear" onClick={() => setSearchVal("")}>
                  <Close fontSize="small" />
                </IconButton>
              ),
            }}
            sx={{
              width: {
                xs: 1,
                sm: "auto",
              },
              m: (theme) => theme.spacing(1, 0.5, 1.5),
              "& .MuiSvgIcon-root": {
                mr: 0.5,
              },
              "& .MuiInput-underline:before": {
                borderBottom: 1,
                borderColor: "divider",
              },
            }}
          />
        </Box>
        <Box className={classes.contentWrapper}>
          <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd} onDragStart={() => setmainDropDown("")}>
            <SortableContext items={data.map((items) => items.id)} strategy={verticalListSortingStrategy}>
              {data
                .filter((el) => el.name.toLowerCase().includes(searchVal.toLowerCase()))
                .map((el: companyBenefitParent) => (
                  <Box key={el.id}>
                    <BenefitItem
                      open={mainDropDown}
                      setOpen={setmainDropDown}
                      id={el.id}
                      parentName={el.name}
                      parentNameGE={el.nameGE}
                      hide={el.hide || false}
                      benefits={el.benefits}
                      refetchData={initData}
                    />
                  </Box>
                ))}
            </SortableContext>
          </DndContext>
        </Box>
      </Box>
    </Card>
  );
};

export default Benefit;
