// ** MUI Imports
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Select from "@mui/material/Select";
import CardContent from "@mui/material/CardContent";

// ** Styles
import DatePickerWrapper from "../../../../../components/@core/styles/libs/react-datepicker";
import { SingleCompanyType, benefitType } from "../../../../../service";
import { format } from "date-fns/esm";
import { useSelector } from "react-redux";
import { RootState } from "../../../../../store";
import FormControl from "@mui/material/FormControl";
import { CompanyValues, companyBenefitChild, companyBenefitParent } from "../../../../../types/apps/companyTypes";
import { DictionaryTypeEnum } from "../../../../../types/enums";
import CompanyBenefits from "./CompanyBenefits";

interface Props {
  values: CompanyValues;
  setFieldValue: (field: string, value: any) => void;
  data: SingleCompanyType;
}

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

const MenuProps = {
  PaperProps: {
    style: {
      width: 250,
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const EditCard = ({ values, setFieldValue, data }: Props) => {
  const { companyBenefits } = values;
  // ** States
  const { dictionaries } = useSelector((state: RootState) => state.companies);

  const validateNumbers = (field: string, value: any) => {
    const re = /^\d*(\.\d+)?$/;
    if (!value) {
      setFieldValue(field, 0);
    } else if (value.match(re)) {
      if (!value.match(/^(?:[1-9]\d*|0)$/)) {
        if (value > 0) {
          value = value.replace(/^0+/, "");
        } else {
          value = 0;
        }
      }
      setFieldValue(field, value);
    } else {
      setFieldValue(field, 0);
    }
  };

  const updateBenefit = (id: string, newObj: companyBenefitParent) => {
    const mutatedIndex = values.companyBenefits.findIndex((el) => el.id === id);
    const benefitsCopy = [...values.companyBenefits];
    if (!benefitsCopy || mutatedIndex === -1) return;
    benefitsCopy[mutatedIndex] = newObj;

    setFieldValue("companyBenefits", benefitsCopy);
  };

  if (data) {
    return (
      <>
        <Card>
          <CardContent>
            <Grid container>
              <Grid item xl={12} xs={12} sx={{ mb: { xl: 4, xs: 4 } }}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Box sx={{ mb: 6, display: "flex", alignItems: "center" }}>
                    <img
                      src={data.profileImageUrl}
                      alt="Company"
                      style={{
                        height: "50px",
                        width: "50px",
                        borderRadius: "50%",
                      }}
                    />
                    <TextField
                      autoFocus
                      fullWidth
                      id="company_name"
                      label="Name"
                      sx={{ mb: 0, ml: 3, width: "100%" }}
                      onChange={(e) => setFieldValue("name", e.target.value)}
                      value={values.name || ""}
                    />
                  </Box>
                  <Box sx={{ mb: 6 }}>
                    <TextField
                      fullWidth
                      id="tagLine"
                      label="Tagline"
                      name="tagLine"
                      sx={{ mb: 0, width: "100%" }}
                      onChange={(e) => {
                        setFieldValue("tagLine", e.target.value);
                      }}
                      value={values.tagLine || ""}
                    />
                  </Box>
                  <Box>
                    <TextField
                      fullWidth
                      id="notificationEmail"
                      label="Notification Email"
                      name="notificationEmail"
                      sx={{ mb: 0, width: "100%" }}
                      onChange={(e) => {
                        setFieldValue("notificationEmail", e.target.value);
                      }}
                      value={values.notificationEmail || ""}
                    />
                  </Box>
                </Box>
              </Grid>
              <Grid item xl={12} xs={12}>
                <DatePickerWrapper sx={{ "& .react-datepicker-wrapper": { width: "auto" } }}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: { xl: "flex-start", xs: "flex-start" },
                    }}
                  >
                    <Box sx={{ mb: 4, display: "flex", alignItems: "center" }}>
                      <Typography
                        variant="h6"
                        sx={{
                          mr: 1,
                          width: "200px",
                          color: values.isVerified ? "#666CFF" : "#FF4D49",
                        }}
                      >
                        {values.isVerified ? "Verified" : "Not Verified"}
                      </Typography>
                    </Box>

                    <Box
                      sx={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2, 1fr)",
                        gap: "10px",
                      }}
                    >
                      <Typography variant="body2" sx={{ mr: 2, width: "100px" }}>
                        Create Date:
                      </Typography>
                      <Typography variant="body2" sx={{ mr: 2, width: "100px" }}>
                        {format(new Date(data.createDate), "dd MMM yyyy")}
                      </Typography>
                      <Typography variant="body2" sx={{ mr: 2, width: "100px" }}>
                        Email:
                      </Typography>
                      <Typography variant="body2" sx={{ mr: 2, width: "100px" }}>
                        {data.email}
                      </Typography>
                      <Typography variant="body2" sx={{ mr: 2, width: "150px" }}>
                        Active vacancies:
                      </Typography>
                      <Typography variant="body2" sx={{ mr: 2, width: "100px" }}>
                        {data.activeVacancyCount}
                      </Typography>
                    </Box>
                  </Box>
                </DatePickerWrapper>
              </Grid>
            </Grid>
          </CardContent>

          <Divider sx={{ mt: 1 }} />

          <CardContent>
            <Grid
              container
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(1, 1fr)",
                gap: "20px",
              }}
            >
              <TextField
                multiline
                value={values.about || ""}
                label="About company (EN)"
                onChange={(e) => {
                  setFieldValue("about", e.target.value);
                }}
                sx={{ width: "100%" }}
              />
              <TextField
                multiline
                value={values.aboutGe || ""}
                label="About company (GE)"
                onChange={(e) => {
                  setFieldValue("aboutGe", e.target.value);
                }}
                sx={{ width: "100%" }}
              />
            </Grid>
          </CardContent>

          <Divider sx={{ mb: 1.25 }} />

          <CardContent>
            <Typography variant="h6" sx={{ mb: 6, ml: 1 }}>
              Keyfacts
            </Typography>
            <Grid
              container
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "20px",
              }}
            >
              <TextField
                multiline
                value={values.keyFacts.founded || ""}
                label="Founded"
                onChange={(e) => {
                  validateNumbers("keyFacts.founded", e.target.value);
                }}
                sx={{ width: "100%" }}
              />
              <TextField
                multiline
                value={values.keyFacts.employeesLocally || ""}
                label="Company size local"
                onChange={(e) => {
                  setFieldValue("keyFacts.employeesLocally", e.target.value);
                }}
                sx={{ width: "100%" }}
              />
              <TextField
                multiline
                value={values.keyFacts.employeesGlobally || ""}
                label="Company size global"
                onChange={(e) => {
                  setFieldValue("keyFacts.employeesGlobally", e.target.value);
                }}
                sx={{ width: "100%" }}
              />
            </Grid>
          </CardContent>

          <Divider />

          <CardContent>
            <Typography variant="h6" sx={{ mb: 6, ml: 1 }}>
              Contact info
            </Typography>
            <Grid
              container
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "20px",
              }}
            >
              <TextField
                multiline
                value={values.keyFacts.phoneNumber || ""}
                label="Phone number"
                onChange={(e) => {
                  setFieldValue("keyFacts.phoneNumber", e.target.value);
                }}
                sx={{ width: "100%" }}
              />
              <TextField
                multiline
                value={values.keyFacts.email || ""}
                label="Email"
                onChange={(e) => {
                  setFieldValue("keyFacts.email", e.target.value);
                }}
                sx={{ width: "100%" }}
              />
              <TextField
                multiline
                value={values.keyFacts.website || ""}
                label="Website"
                onChange={(e) => {
                  setFieldValue("keyFacts.website", e.target.value);
                }}
                sx={{ width: "100%" }}
              />
              <TextField
                multiline
                value={values.keyFacts.georgiaOffice || ""}
                label="Georgian office address"
                onChange={(e) => {
                  setFieldValue("keyFacts.georgiaOffice", e.target.value);
                }}
                sx={{ width: "100%" }}
              />
              <TextField
                multiline
                value={values.keyFacts.googleMapUrl || ""}
                label="Georgian office Google map URL"
                onChange={(e) => {
                  setFieldValue("keyFacts.googleMapUrl", e.target.value);
                }}
                sx={{ width: "100%" }}
              />
              <TextField
                multiline
                value={values.keyFacts.headQuarter || ""}
                label="Headquarters address"
                onChange={(e) => {
                  setFieldValue("keyFacts.headQuarter", e.target.value);
                }}
                sx={{ width: "100%" }}
              />
              <TextField
                multiline
                value={values.keyFacts.headQuarterGoogleMapUrl || ""}
                label="Headquarters Google map URL"
                onChange={(e) => {
                  setFieldValue("keyFacts.headQuarterGoogleMapUrl", e.target.value);
                }}
                sx={{ width: "100%" }}
              />
            </Grid>
          </CardContent>

          <Divider />

          <CardContent>
            <Typography variant="h6" sx={{ mb: 6, ml: 1 }}>
              Social Network
            </Typography>
            <Grid
              container
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "20px",
              }}
            >
              <TextField
                multiline
                value={values.keyFacts.facebookUrl || ""}
                label="Facebook"
                onChange={(e) => {
                  setFieldValue("keyFacts.facebookUrl", e.target.value);
                }}
                sx={{ width: "100%" }}
              />
              <TextField
                multiline
                value={values.keyFacts.linkedInUrl || ""}
                label="LinkedIn"
                onChange={(e) => {
                  setFieldValue("keyFacts.linkedInUrl", e.target.value);
                }}
                sx={{ width: "100%" }}
              />
              <TextField
                multiline
                value={values.keyFacts.twitterUrl || ""}
                label="Twitter"
                onChange={(e) => {
                  setFieldValue("keyFacts.twitterUrl", e.target.value);
                }}
                sx={{ width: "100%" }}
              />
              <TextField
                multiline
                value={values.keyFacts.youTubeUrl || ""}
                label="YouTube"
                onChange={(e) => {
                  setFieldValue("keyFacts.youTubeUrl", e.target.value);
                }}
                sx={{ width: "100%" }}
              />
              <TextField
                multiline
                value={values.keyFacts.tikTokUrl || ""}
                label="TikTok"
                onChange={(e) => {
                  setFieldValue("keyFacts.tikTokUrl", e.target.value);
                }}
                sx={{ width: "100%" }}
              />
            </Grid>
          </CardContent>

          <Divider />

          <CardContent>
            <Typography variant="h6" sx={{ mb: 6, ml: 1 }}>
              Benefits
            </Typography>
            <Typography variant="body2" sx={{ mb: 6, ml: 1 }}></Typography>

            <Grid
              container
              sx={{
                display: "grid",
                gridTemplateColumns: "repeat(2, 1fr)",
                gap: "20px",
              }}
            >
              {companyBenefits?.map((el) => {
                return <CompanyBenefits key={el.id} data={el} updateBenefit={(newObj: companyBenefitParent) => updateBenefit(el.id, newObj)} />;
              })}
            </Grid>
          </CardContent>

          <Divider />

          <CardContent>
            <Grid container>
              <FormControl fullWidth>
                <InputLabel id="technology_label">Technologies</InputLabel>

                {dictionaries && (
                  <Select
                    multiple
                    label="Technologies"
                    labelId="technology_label"
                    size="small"
                    // @ts-ignore: Unreachable code error
                    value={values.technologies}
                    onChange={(e) => setFieldValue("technologies", e.target.value)}
                    sx={{ mb: 4, width: "100%", color: "text.secondary" }}
                  >
                    {dictionaries
                      .find((dictionary) => dictionary.dictionaryName === DictionaryTypeEnum.Technology)
                      ?.dictionaryList.map((el) => (
                        <MenuItem key={el.id} value={el.id}>
                          {el.name}
                        </MenuItem>
                      ))}
                  </Select>
                )}
              </FormControl>
            </Grid>
          </CardContent>
        </Card>
      </>
    );
  } else {
    return null;
  }
};

export default EditCard;
