import { Dispatch, MouseEvent, SetStateAction, useEffect, useState } from "react";
import { useAppDispatch } from "../../../../store";

// ** service
import { postDeleteBenefitCategory, postDeleteBenefits, postUpdateBenefitCategory, postUpdateBenefitOrder } from "../../../../store/apps/attributesSlice";

// ** Material
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";

// ** components
import BenefitSubItem from "./BenefitSubItem";
import { Modal as DeleteModal } from "../../../../utils/views/modal/modal";

// ** dnd
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors, DragEndEvent } from "@dnd-kit/core";
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

// ** icons
import DragHandleIcon from "@mui/icons-material/DragHandle";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

// ** styles
import classes from "./BenfitItem.module.sass";

// ** types
import { UpdateBenefitCategoryType } from "../../../../types/apps/attributeTypes";
import { toast } from "react-hot-toast";
import { companyBenefitChild } from "../../../../types/apps/companyTypes";
import InputLabel from "@mui/material/InputLabel";
import Switch from "@mui/material/Switch";
import { FormControlLabel, Grid } from "@mui/material";

type propType = {
  id: string;
  parentName: string;
  parentNameGE: string;
  benefits: companyBenefitChild[];
  hide: boolean;
  open: string;
  setOpen: Dispatch<SetStateAction<string>>;
  refetchData: () => void;
};

const BenefitItem = ({ id, parentName, parentNameGE, benefits, open, setOpen, hide, refetchData }: propType) => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<companyBenefitChild[]>([]);
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id });
  const [name, setName] = useState(parentName);
  const [nameGE, setNameGE] = useState(parentNameGE);
  const [hideBenefit, setHideBenefit] = useState<boolean>(hide);
  const [deleteBenefit, setDeleteBenefit] = useState<{ name: string; id: string; parent: boolean } | null>(null);
  const dropDownOpen = open === id;

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  useEffect(() => {
    if (benefits) setData(benefits);
  }, [benefits]);

  const openDropDown = (e: MouseEvent<HTMLDivElement>) => {
    if (open === id) setOpen("");
    else setOpen(id);
  };

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over!.id) {
      const oldIndex = data.findIndex((item) => item.id === active.id);
      const newIndex = data.findIndex((item) => item.id === over!.id);
      const newOrderedList = [...arrayMove(data, oldIndex, newIndex)];

      const updatedArrayList = [];

      for (let i = 0; i < newOrderedList.length; i++) {
        updatedArrayList.push({ ...newOrderedList[i], orderIndex: i });
      }
      setData(updatedArrayList);

      const idList = updatedArrayList.map((el) => el.id);
      const dragObject = { benefitIds: idList, categoryId: id };

      await dispatch(postUpdateBenefitOrder(dragObject));
    }
  };

  const addSubBenefit = () => {
    if (data.some((el) => el.id === "")) return toast.error("Please add only one sub benefit at once");

    setData((prevData) => {
      const newObject = {
        id: "",
        name: "",
        nameGE: "",
        description: "",
        descriptionGE: "",
        iconUrl: "",
        canNotBeDeletedByTheCompany: false,
        benefitType: 0,
        orderIndex: null,
        hide: false,
      };
      return [newObject, ...prevData];
    });
  };

  const updateChildData = (data: companyBenefitChild) => {
    setData((prevData) => {
      const idx = prevData.findIndex((el) => el.id === "");
      prevData[idx].id = data.id;
      prevData[idx].name = data.name;
      prevData[idx].nameGE = data.nameGE;
      prevData[idx].description = data.description;
      prevData[idx].descriptionGE = data.descriptionGE;
      prevData[idx].orderIndex = data.orderIndex;
      return prevData;
    });
  };

  const saveBenefit = () => {
    let newObject = {
      categoryId: id,
      name,
      nameGE,
      hide: hideBenefit,
    } as UpdateBenefitCategoryType;
    dispatch(postUpdateBenefitCategory(newObject));
  };

  const handleDeleteBenefit = async (benefitId: string | undefined) => {
    if (!benefitId) return;
    try {
      if (deleteBenefit?.parent) {
        await dispatch(postDeleteBenefitCategory(benefitId)).unwrap();
      } else {
        await dispatch(postDeleteBenefits(benefitId)).unwrap();
      }
      toast.success(`${deleteBenefit?.name} deleted sucessfully`);
      refetchData();
      setDeleteBenefit(null);
    } catch (err) {
      toast.error(typeof err === "string" ? err : "Something went wrong!");
    }
  };

  return (
    <>
      <DeleteModal show={!!deleteBenefit} onClose={() => setDeleteBenefit(null)} submitAction={() => handleDeleteBenefit(deleteBenefit?.id)} submitBtnColor="error" submitText="Delete">
        <Typography>
          Are you sure you want to delete <b>{deleteBenefit?.name}</b>?
        </Typography>
      </DeleteModal>
      <Box className={`${classes.BenefitItem} ${dropDownOpen && classes.activeMenu}`} ref={setNodeRef} style={style}>
        <Box className={classes.header} onClick={openDropDown}>
          <DragHandleIcon {...listeners} {...attributes} />
          <Box>
            <Typography variant="h6">{name}</Typography>
            {!dropDownOpen ? <AddIcon /> : <RemoveIcon />}
          </Box>
        </Box>
        <Box className={`${classes.body} ${dropDownOpen && classes.activeMenu}`}>
          <TextField
            autoFocus
            fullWidth
            id="tagLine"
            label="Section Name"
            name="tagLine"
            sx={{ mb: 0, width: "100%" }}
            onChange={(e) => {
              setName(e.target.value);
            }}
            value={name}
          />
          <TextField
            fullWidth
            id="tagLine"
            label="Section Name (GE)"
            name="tagLine"
            sx={{ mb: 0, width: "100%" }}
            onChange={(e) => {
              setNameGE(e.target.value);
            }}
            value={nameGE}
          />
          <FormControlLabel
            control={<Switch checked={hideBenefit} onChange={() => setHideBenefit((prevVal) => !prevVal)} />}
            label={"Hide"}
            sx={{
              marginLeft: 0,
            }}
          />

          <Divider />
          <Typography variant="h6" sx={{ textAlign: "center" }}>
            Child Benefits
          </Typography>

          <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <SortableContext items={data.map((items) => items.id)} strategy={verticalListSortingStrategy}>
              {data.map((subBenefit) => (
                <BenefitSubItem
                  key={subBenefit.id}
                  categoryId={id}
                  data={subBenefit}
                  updateData={updateChildData}
                  deleteBenefit={() => setDeleteBenefit({ name: subBenefit.name, id: subBenefit.id, parent: false })}
                />
              ))}
            </SortableContext>
          </DndContext>

          <Box className={classes.buttonWrapper}>
            <Button onClick={addSubBenefit}>Add Sub Benefit</Button>
            <Button onClick={saveBenefit}>Save Benefit</Button>
            <Button onClick={() => setDeleteBenefit({ name: parentName, id, parent: true })} variant="outlined" color="error">
              Delete Benefit
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default BenefitItem;
