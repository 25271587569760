import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../store";
import { getPopularCompanyStatistics } from "../../../store/apps/statisticsSlice";
import { DateType } from "../../../types/forms/reactDatepickerTypes";
import { generateQueryString } from "../../../utils/helperFunctions/helpers";
import DateRange from "../Components/Date Range";
import Typography from "@mui/material/Typography";
import PageHeader from "../../../components/@core/components/page-header";
import Box from "@mui/material/Box";

import { DataGrid, GridToolbarExport } from "@mui/x-data-grid";
import { dataType } from "../Components/types";
import ExportCSV from "../../../utils/views/export/ExportCSV";
import { Paginate } from "../../../utils/views/table/data-grid/Pagination";

const PopularCompanies = () => {
  const [dateRange, setDateRange] = useState<number>(2);
  const [endDate, setEndDate] = useState<DateType>(new Date());
  const [startDate, setStartDate] = useState<DateType>(new Date());

  const { popularCompanyStatistics, isLoading } = useSelector((state: RootState) => state.statistics);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getPopularCompanyStatistics(generateQueryString(dateRange, startDate, endDate)));
  }, [dateRange, endDate, startDate]);

  const columns = [
    { field: "companyName", headerName: "Company Name", flex: 1 },
    {
      field: "totalApplications",
      headerName: "Total Applications",
      flex: 1,
    },
    {
      field: "totalClicks",
      headerName: "Total Clicks",
      flex: 1,
    },
  ];

  const generateRows = () => {
    let rows: any[] = [];
    popularCompanyStatistics?.forEach((el: dataType) => {
      let object = {
        id: el.companyId,
        companyName: el.companyName,
        totalApplications: el.totalApplications,
        totalClicks: el.totalClicks,
      };

      rows.push(object);
    });
    return rows;
  };

  return (
    <>
      <Grid container flexDirection="column">
        <PageHeader
          title={
            <Typography variant="h5" color="#666CFF">
              Popular Companies
            </Typography>
          }
        />

        <Grid mb={2} mt={2}>
          <DateRange setDateRange={setDateRange} dateRange={dateRange} endDate={endDate} setEndDate={setEndDate} startDate={startDate} setStartDate={setStartDate} />
        </Grid>
        <Grid>
          <DataGrid
            columns={columns}
            rows={generateRows()}
            sx={{ height: 600 }}
            loading={isLoading}
            disableColumnSelector
            disableDensitySelector
            disableColumnMenu
            components={{
              Toolbar: ExportCSV,
              Pagination: Paginate,
            }}
            componentsProps={{
              toolbar: {
                componentSection: "popularCompanies",
                exportFilter: {
                  dateFilter: { dateRange, startDate, endDate },
                },
              },
            }}
          />
        </Grid>
      </Grid>
    </>
  );
};

export default PopularCompanies;
