import * as API from "../../../service";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { tr } from "date-fns/locale";
import { applicationVacancyType } from "../../../service";

export interface StatisticsState {
  isLoading: boolean;
  vacancyStatistics: any;
  activeDayStatistics: any;
  applicationStatistics: any;
  popularCompanyStatistics: any;
  popularVacancyStatistics: any;
  applicationVacancy: any
}

const initialState: StatisticsState = {
  isLoading: true,
  vacancyStatistics: null,
  activeDayStatistics: null,
  applicationStatistics: null,
  popularCompanyStatistics: null,
  popularVacancyStatistics: null,
  applicationVacancy: null
};

export const getStatistics =
  createAsyncThunk(
    "statistics/getStatisticsRequest",
    async (queryString: string) => {
      const response = await API.getStatisticsRequest(queryString);
      return response.data;
    }
  );

export const getTopAttractiveCompanies = createAsyncThunk("statistics/getTopAttractiveCompaniesRequest", async (queryString: string) => {
  const response = await API.getTopAttractiveCompaniesRequest(queryString);
  return response.data;
});

export const getVacancyAppliedStatistics = createAsyncThunk(
  "statistics/getVacancyAppliedStatisticsRequest",
  async (queryString: string) => {
    const response = await API.getVacancyAppliedStatisticsRequest(queryString);
    return response.data;
  }
);

export const PostGetStatisticsForUserVacsAndApps = createAsyncThunk<
  API.PostGetStatisticsForUserVacsAndAppsType,
  API.PostGetStatisticsForUserVacsAndAppsParams
>(
  "statistics/PostGetStatisticsForUserVacsAndApps",
  async (params: API.PostGetStatisticsForUserVacsAndAppsParams) => {
    const response = await API.PostGetStatisticsForUserVacsAndAppsRequest(
      params
    );
    return response.data;
  }
);

export const getUserPageActivityStatistics = createAsyncThunk(
  "statistics/GetUserPageActivityStatisticsRequest",
  async (queryString: string) => {
    const response = await API.getUserPageActivityStatisticsRequest(
      queryString
    );
    return response;
  }
);

export const getApplicationStatistics = createAsyncThunk(
  "statistics/GetApplicationStatisticsRequest",
  async (queryString: string) => {
    const response = await API.getApplicationStatisticsRequest(queryString);
    return response;
  }
);

export const getPopularVacancyStatistics = createAsyncThunk("statistics/getPopularVacancyStatistics", async (queryString: string) => {
  const response = await API.getPopularVacancyStatisticsRequest(queryString);
  return response
})

export const getPopularCompanyStatistics = createAsyncThunk("statistics/getPopularCompanyStatistics", async (queryString: string) => {
  const response = await API.getPopularCompanyStatisticsRequest(queryString);
  return response
})

export const getApplicationTalentStatistics = createAsyncThunk("statistics/getApplicationTalentStatistics", async ({statisticsFilter, vacancyId, dateFrom, dateTo}: applicationVacancyType) => {
  const response = await API.getApplicationTalentStatisticsRequest({statisticsFilter, vacancyId, dateFrom, dateTo})
  return response
})



export const statisticsSlice = createSlice({
  name: "statistics",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getStatistics.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(getStatistics.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getStatistics.rejected, (state, payload) => {
        state.isLoading = false;
      })
      .addCase(getTopAttractiveCompanies.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(getTopAttractiveCompanies.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTopAttractiveCompanies.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(PostGetStatisticsForUserVacsAndApps.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(PostGetStatisticsForUserVacsAndApps.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(PostGetStatisticsForUserVacsAndApps.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getUserPageActivityStatistics.fulfilled, (state, action) => {
        state.activeDayStatistics = action.payload;
        state.isLoading = false;
      })
      .addCase(getUserPageActivityStatistics.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getVacancyAppliedStatistics.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getVacancyAppliedStatistics.rejected, (state, action) => {
        state.isLoading = false;
      })
      .addCase(getVacancyAppliedStatistics.fulfilled, (state, action) => {
        state.isLoading = false;
        state.vacancyStatistics = action.payload;
      })
      .addCase(getApplicationStatistics.pending, (state, action) => {
        state.isLoading = true;
      })
      .addCase(getApplicationStatistics.fulfilled, (state, action) => {
        state.isLoading = false;
        state.applicationStatistics = action.payload;
      })
      .addCase(getApplicationStatistics.rejected, (state, action) => {
        state.isLoading = false;
        state.applicationStatistics = null;
      })
      .addCase(getPopularVacancyStatistics.pending, state => {
        state.isLoading = true
      })
      .addCase(getPopularVacancyStatistics.rejected, state => {
        state.isLoading = false
      })
      .addCase(getPopularVacancyStatistics.fulfilled, (state, action) => {
        state.isLoading = false
        state.popularVacancyStatistics = action.payload.data
      })
      .addCase(getPopularCompanyStatistics.pending, state => {
        state.isLoading = true
      })
      .addCase(getPopularCompanyStatistics.rejected, state => {
        state.isLoading = false
      })
      .addCase(getPopularCompanyStatistics.fulfilled, (state, action) => {
        state.isLoading = false
        state.popularCompanyStatistics = action.payload.data
      })
      .addCase(getApplicationTalentStatistics.pending, state => {
        state.isLoading = true
      })
      .addCase(getApplicationTalentStatistics.rejected, state => {
        state.isLoading = false
      })
      .addCase(getApplicationTalentStatistics.fulfilled, (state, action) => {
        state.isLoading = false
        state.applicationVacancy = action.payload.data
      })
  },
});

// export const {  } =
//   statisticsSlice.actions;
export default statisticsSlice.reducer;
