// ** Next Import
// import { GetStaticPaths, GetStaticProps, GetStaticPropsContext, InferGetStaticPropsType } from 'next/types'

// ** Third Party Imports
import axios from "axios";

// ** Types
import { InvoiceType } from "../../../../types/apps/invoiceTypes";

// ** Demo components Imports
import Edit from "../../../../utils/views/apps/invoice/edit/Edit";

//here was InferGetStaticPropsType<typeof getStaticProps> type
const InvoiceEdit = () => {
  return <Edit />;
};

//here was GetStaticPaths type
export const getStaticPaths: any = async () => {
  const res = await axios.get("/apps/invoice/invoices");
  const data: InvoiceType[] = await res.data.allData;

  const paths = data.map((item: InvoiceType) => ({
    params: { id: `${item.id}` },
  }));

  return {
    paths,
    fallback: false,
  };
};

//here was GetStaticProps type and params type GetStaticPropsContext
export const getStaticProps: any = ({ params }: any) => {
  return {
    props: {
      id: params?.id,
    },
  };
};

export default InvoiceEdit;
