export enum DictionaryTypeEnum {
    Benefit = 1,
    City = 2,
    EmploymentType = 3,
    Experience = 4,
    Field = 5,
    JobFunction = 6,
    Technology = 7,
    TechnologyCategory = 8,
    WorkLocation = 9,
    BenefitCategory = 10,
    Country = 11
  }
  
  export enum StatusTypeEnum {
    None = 0,
    Active = 1,
    Expired = 2,
    Draft = 3,
    Deactivated = 4
  }

export enum keyFactEnum {
  Text = 1,
  Number = 2,
  Year = 3,
  MultipleChoice = 4,
  SingleChoice = 5
}
  
export enum FAQEnum {
  general = 0,
  employers = 1,
  talents = 2, 
}