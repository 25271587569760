import { ReactElement } from "react";
import { useSelector } from "react-redux";
import { Routes, Route, Navigate, Outlet, useLocation } from "react-router-dom";

// ** Wrapper Imports
import UserLayout from "../components/layouts/UserLayout";
import BlankLayout from "../components/@core/layouts/BlankLayout";
// import BlankLayoutWithAppBar from "../components/@core/layouts/BlankLayoutWithAppBar";

import { RootState } from "../store";

// ** Page Imports
// import CrmDashboard from "../pages/dashboards/crm/index";
import Talents from "../pages/talents";
import Vacancies from "../pages/vacancies";
import AdminUsers from "../pages/admins";
import Support from "../pages/contents/support";
import PrivacyPolicy from "../pages/contents/privacyPolicy";
import Faq from "../pages/contents/faq";
import TermsOfUse from "../pages/contents/termsOfUse";
import AboutUs from "../pages/contents/aboutUs";
import Seo from "../pages/seo";
import Companies from "../pages/companies";
import LoginPage from "../pages/login";
import Dashboard from "../pages/dashboard/index";
import ForgotPassword from "../pages/reset-password";
import ResetPassword from "../pages/forgot-password";
import { ContentDataType } from "../service/StaticData";
import InvoiceEdit from "../pages/apps/invoice/edit/[id]";
import Attributes from "../pages/attributes";
import EditVacancy from "../pages/editVacancy";
import Statistics from "../pages/statistics";
import HeroImage from "../pages/contents/heroImage";

interface ProtectedRouteProps {
  isAllowed: boolean;
  // initialLogInTried: boolean;
  redirectPath?: string;
  children?: ReactElement;
}

const ProtectedRoute = ({
  isAllowed = false,
  // initialLogInTried,
  redirectPath = "/login",
  children,
}: ProtectedRouteProps) => {
  const location = useLocation();

  if (!isAllowed) {
    return (
      <Navigate
        to={redirectPath}
        replace
      />
    );
  }
  // if (!initialLogInTried) {
  //   return <div>loading...</div>;
  // }
  const loginPage = location.pathname.includes("login");
  const resetpassword = location.pathname.includes("reset-password");
  const forgotPassword = location.pathname.includes("passwordrecovery");

  if (loginPage || resetpassword || forgotPassword)
    return <BlankLayout>{children}</BlankLayout>;

  return children ? <UserLayout>{children}</UserLayout> : <Outlet />;
};

const Component = () => {
  const { isLoggedIn, initialLogInTried } = useSelector(
    (state: RootState) => state.auth
  );

  return (
    <Routes>
      <Route
        path="/"
        element={
          <ProtectedRoute
            // // initialLogInTried={initialLogInTried}
            isAllowed={isLoggedIn}
            redirectPath="/login"
          >
            <Dashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path="/login"
        element={
          <ProtectedRoute
            // // initialLogInTried={initialLogInTried}
            isAllowed={isLoggedIn === false}
            redirectPath="/"
          >
            <LoginPage />
          </ProtectedRoute>
        }
      />
      <Route
        path="/reset-password"
        element={
          <ProtectedRoute
            // // initialLogInTried={initialLogInTried}
            isAllowed={isLoggedIn === false}
            redirectPath="/"
          >
            <ResetPassword />
          </ProtectedRoute>
        }
      />
      <Route
        path="/passwordrecovery"
        element={
          <ProtectedRoute
            // // initialLogInTried={initialLogInTried}
            isAllowed={isLoggedIn === false}
            redirectPath="/"
          >
            <ForgotPassword />
          </ProtectedRoute>
        }
      />
      <Route
        path="/pages/companies"
        element={
          <ProtectedRoute
            // // initialLogInTried={initialLogInTried}
            isAllowed={isLoggedIn === true}
          >
            <Companies />
          </ProtectedRoute>
        }
      />
      <Route
        path="/pages/companies/:id"
        element={
          <ProtectedRoute
            // // initialLogInTried={initialLogInTried}
            isAllowed={isLoggedIn === true}
          >
            <InvoiceEdit />
          </ProtectedRoute>
        }
      />
      <Route
        path="/pages/talents"
        element={
          <ProtectedRoute
            // // initialLogInTried={initialLogInTried}
            isAllowed={isLoggedIn}
            redirectPath="/login"
          >
            <Talents />
          </ProtectedRoute>
        }
      />
      <Route
        path="/pages/vacancies"
        element={
          <ProtectedRoute
            // // initialLogInTried={initialLogInTried}
            isAllowed={isLoggedIn}
            redirectPath="/login"
          >
            <Vacancies />
          </ProtectedRoute>
        }
      />
      <Route
        path="/pages/vacancies/:id"
        element={
          <ProtectedRoute
            // // initialLogInTried={initialLogInTried}
            isAllowed={isLoggedIn}
            redirectPath="/login"
          >
            <EditVacancy />
          </ProtectedRoute>
        }
      />
      <Route
        path="/pages/vacancies/add"
        element={
          <ProtectedRoute
            // // initialLogInTried={initialLogInTried}
            isAllowed={isLoggedIn}
            redirectPath="/login"
          >
            <EditVacancy isAdd/>
          </ProtectedRoute>
        }
      />

      <Route
        path="/pages/AdminUsers"
        element={
          <ProtectedRoute
            // initialLogInTried={initialLogInTried}
            isAllowed={isLoggedIn}
            redirectPath="/login"
          >
            <AdminUsers />
          </ProtectedRoute>
        }
      />

      <Route
        path="/pages/seo"
        element={
          <ProtectedRoute
            // initialLogInTried={initialLogInTried}
            isAllowed={isLoggedIn}
            redirectPath="/login"
          >
            <Seo />
          </ProtectedRoute>
        }
      />

      {/* Contents */}
      <Route
        path="/pages/contents/about-us"
        element={
          <ProtectedRoute
            // initialLogInTried={initialLogInTried}
            isAllowed={isLoggedIn}
            redirectPath="/login"
          >
            <AboutUs contentDataType={ContentDataType.AboutUs} />
          </ProtectedRoute>
        }
      />

      <Route
        path="/pages/contents/support"
        element={
          <ProtectedRoute
            // initialLogInTried={initialLogInTried}
            isAllowed={isLoggedIn}
            redirectPath="/login"
          >
            <Support contentDataType={ContentDataType.Support} />
          </ProtectedRoute>
        }
      />

      <Route
        path="/pages/contents/privacy-policy"
        element={
          <ProtectedRoute
            // initialLogInTried={initialLogInTried}
            isAllowed={isLoggedIn}
            redirectPath="/login"
          >
            <PrivacyPolicy contentDataType={ContentDataType.PrivacyPolicy} />
          </ProtectedRoute>
        }
      />

      <Route
        path="/pages/contents/terms-of-use"
        element={
          <ProtectedRoute
            // initialLogInTried={initialLogInTried}
            isAllowed={isLoggedIn}
            redirectPath="/login"
          >
            <TermsOfUse contentDataType={ContentDataType.TermsOfUse} />
          </ProtectedRoute>
        }
      />

      <Route
        path="/pages/contents/faq"
        element={
          <ProtectedRoute
            // initialLogInTried={initialLogInTried}
            isAllowed={isLoggedIn}
            redirectPath="/login"
          >
            <Faq />
          </ProtectedRoute>
        }
      />

      <Route
        path="/pages/contents/website-hero-image"
        element={
          <ProtectedRoute
            // initialLogInTried={initialLogInTried}
            isAllowed={isLoggedIn}
            redirectPath="/login"
          >
            <HeroImage />
          </ProtectedRoute>
        }
      />

      {/* Attributes */}
      <Route
        path="/pages/attributes/:pageName"
        element={
          <ProtectedRoute
            // initialLogInTried={initialLogInTried}
            isAllowed={isLoggedIn}
            redirectPath="/login"
          >
            <Attributes />
          </ProtectedRoute>
        }
      />
      <Route
        path="/pages/statistics/:pageName"
        element={
          <ProtectedRoute
            // initialLogInTried={initialLogInTried}
            isAllowed={isLoggedIn}
            redirectPath="/login"
          >
            <Statistics />
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default Component;
