// ** React Imports
import { Ref, useState, forwardRef, ReactElement, ChangeEvent, Dispatch, SetStateAction, useEffect } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Fade, { FadeProps } from "@mui/material/Fade";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import FormControlLabel from "@mui/material/FormControlLabel";

// ** Third Party Imports
import Payment from "payment";
import Cards, { Focused } from "react-credit-cards";

// ** Util Import
import { formatCVC, formatExpirationDate, formatCreditCardNumber } from "../../../../components/@core/utils/format";

// ** Styled Component Imports
import CardWrapper from "../../../../components/@core/styles/libs/react-credit-cards";

// ** Styles Import
import "react-credit-cards/es/styles-compiled.css";

// ** Icons Imports
import Close from "mdi-material-ui/Close";
import CreditCardOutline from "mdi-material-ui/CreditCardOutline";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";

import { RootState, useAppDispatch } from "../../../../store";
import { getSearchTalents, getTalent, postUpdateTalent } from "../../../../store/apps/talentsSlice";
import { useSelector } from "react-redux";
import { GetTalentRequestType, PostUpdateTalentsRequestParams } from "../../../../service";
import { Avatar, FormControl, Input, InputLabel, MenuItem, Select } from "@mui/material";
import { format } from "date-fns";
import data from "../../../../@fake-db/components/data";
import { DictionaryTypeEnum } from "../../../../types/enums";
import { handleObjectForQueryString } from "../../../helperFunctions";

type props = {
  modal: boolean;
  setModal: Dispatch<SetStateAction<boolean>>;
  dataID: string | undefined;
  setDataID: Dispatch<SetStateAction<string | undefined>>;
  backBtn?: boolean;
};

const Transition = forwardRef(function Transition(props: FadeProps & { children?: ReactElement<any, any> }, ref: Ref<unknown>) {
  return <Fade ref={ref} {...props} />;
});

const DialogAddCard = ({ modal, setModal, dataID, setDataID, backBtn }: props) => {
  const dispatch = useAppDispatch();

  // ** States
  const [talentObject, setTalentObject] = useState<GetTalentRequestType>();
  const { dictionaries, submittedFilteredObject, submittedPage } = useSelector((state: RootState) => state.talents);

  // const [name, setName] = useState<string>("");
  // const [cvc, setCvc] = useState<string | number>("");
  // const [cardNumber, setCardNumber] = useState<string>("");
  // const [focus, setFocus] = useState<Focused | undefined>();
  // const [expiry, setExpiry] = useState<string | number>("");

  // const handleBlur = () => setFocus(undefined);

  // const handleInputChange = ({ target }: ChangeEvent<HTMLInputElement>) => {
  //   if (target.name === "number") {
  //     target.value = formatCreditCardNumber(target.value, Payment);
  //     setCardNumber(target.value);
  //   } else if (target.name === "expiry") {
  //     target.value = formatExpirationDate(target.value);
  //     setExpiry(target.value);
  //   } else if (target.name === "cvc") {
  //     target.value = formatCVC(target.value, cardNumber, Payment);
  //     setCvc(target.value);
  //   }
  // };

  const handleClose = () => {
    setModal(false);
    // setCvc("");
    // setName("");
    // setExpiry("");
    // setCardNumber("");
    // setFocus(undefined);
  };

  useEffect(() => {
    if (dataID)
      dispatch(getTalent(dataID))
        .unwrap()
        .then((res) => setTalentObject(res));

    return () => setDataID(undefined);
  }, [dataID]);

  const handleStateUpdate = (key: string, value: string | boolean | null | string[]) => {
    if (!talentObject) return;
    setTalentObject(() => {
      return {
        ...talentObject,
        data: {
          ...talentObject.data,
          [key]: value,
        },
      };
    });
  };

  const handleSubmit = () => {
    dispatch(
      postUpdateTalent({
        body: talentObject?.data,
      } as PostUpdateTalentsRequestParams)
    )
      .unwrap()
      .then((res) => {
        dispatch(getSearchTalents(handleObjectForQueryString(submittedFilteredObject, submittedPage)));
      });
    handleClose();
  };

  return (
    <>
      {talentObject && (
        <Card>
          {/* <CardContent sx={{ textAlign: "center" }}>
        <CreditCardOutline sx={{ mb: 2, fontSize: "2rem" }} />
        <Typography variant="h6" sx={{ mb: 4 }}>
          Add New Card
        </Typography>
        <Typography sx={{ mb: 3 }}>
          Quickly collect the credit card details, built in input mask and form
          validation support.
        </Typography>
        <Button variant="contained" onClick={() => setShow(true)}>
          Show
        </Button>
      </CardContent> */}

          <Dialog fullWidth open={modal} maxWidth="sm" scroll="body" onClose={handleClose} onBackdropClick={handleClose} TransitionComponent={Transition}>
            <DialogContent
              sx={{
                pb: 6,
                px: { xs: 8, sm: 15 },
                pt: { xs: 8, sm: 12.5 },
                position: "relative",
              }}
            >
              <IconButton
                size="small"
                onClick={handleClose}
                sx={{
                  position: "absolute",
                  ...(backBtn ? { left: "1rem" } : { right: "1rem" }),
                  top: "1rem",
                }}
              >
                {backBtn ? (
                  <ArrowBackIcon
                    sx={{
                      backgroundColor: (theme) => `${theme.palette.secondary}`,
                    }}
                  />
                ) : (
                  <Close />
                )}
              </IconButton>

              <Grid sx={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)" }}>
                <Avatar
                  alt="Victor Anderson"
                  sx={{
                    width: 300,
                    height: 250,
                    borderRadius: "4px",
                  }}
                  src={talentObject?.data.profileImageUrl || ""}
                />
                <Box
                  sx={{
                    mb: 4,
                    textAlign: "center",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "space-evenly",
                  }}
                >
                  <Typography>Created at: {talentObject?.data.createDate && format(new Date(talentObject?.data.createDate), "dd MMM yyyy")}</Typography>
                  <TextField
                    fullWidth
                    name="Name"
                    value={talentObject?.data.name}
                    autoComplete="off"
                    label="Name"
                    // onBlur={handleBlur}
                    onChange={(e) => handleStateUpdate("name", e.target.value)}
                    // onFocus={(e) => setFocus(e.target.name as Focused)}
                    sx={{ mb: 3 }}
                  />
                  <TextField
                    fullWidth
                    name="Email"
                    value={talentObject?.data.email}
                    disabled={true}
                    autoComplete="off"
                    label="Email"
                    // onBlur={handleBlur}
                    onChange={(e) => handleStateUpdate("email", e.target.value)}
                    // onFocus={(e) => setFocus(e.target.name as Focused)}
                    sx={{ mb: 3 }}
                  />
                </Box>
              </Grid>

              <Grid container spacing={6}>
                <Grid item xs={12}>
                  <CardWrapper
                    sx={{
                      "& .rccs": {
                        m: "0 auto",
                        display: { xs: "none", sm: "block" },
                      },
                    }}
                  >
                    {/* <Cards
                  cvc={cvc}
                  focused={focus}
                  expiry={expiry}
                  name={name}
                  number={cardNumber}
                /> */}
                  </CardWrapper>
                </Grid>
                <Grid item xs={12} sx={{ pt: (theme) => `${theme.spacing(5)} !important` }}>
                  <Grid container spacing={6}>
                    <Grid item xs={12} sx={{ mt: 7 }}>
                      <FormControl fullWidth>
                        <InputLabel id="technology_label">Technologies</InputLabel>
                        {dictionaries && (
                          <Select
                            multiple
                            label="Technologies"
                            labelId="technology_label"
                            // @ts-ignore: Unreachable code error
                            value={talentObject?.data.technologies || []}
                            onChange={(e) => handleStateUpdate("technologies", e.target.value)}
                            sx={{
                              mb: 4,
                              width: "100%",
                              color: "text.secondary",
                            }}
                          >
                            {dictionaries
                              .find((dictionary) => dictionary.dictionaryName === DictionaryTypeEnum.Technology)
                              ?.dictionaryList.map((el) => (
                                <MenuItem key={el.id} value={el.id}>
                                  {el.name}
                                </MenuItem>
                              ))}
                          </Select>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <InputLabel id="experience_label">Experience</InputLabel>
                        {dictionaries && (
                          <Select
                            label="Experience"
                            labelId="experience_label"
                            value={talentObject?.data.experienceId}
                            onChange={(e) => handleStateUpdate("experienceId", e.target.value)}
                            sx={{
                              mb: 4,
                              width: "100%",
                              color: "text.secondary",
                            }}
                          >
                            {dictionaries
                              .find((dictionary) => dictionary.dictionaryName === DictionaryTypeEnum.Experience)
                              ?.dictionaryList.map((el) => (
                                <MenuItem key={el.id} value={el.id}>
                                  {el.name}
                                </MenuItem>
                              ))}
                          </Select>
                        )}
                      </FormControl>
                      {/* <TextField
                    fullWidth
                    name="name"
                    value={name}
                    autoComplete="off"
                    onBlur={handleBlur}
                    label="Name on Card"
                    placeholder="John Doe"
                    onChange={(e) => setName(e.target.value)}
                    onFocus={(e) => setFocus(e.target.name as Focused)}
                  /> */}
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormControl fullWidth>
                        <InputLabel id="fieldId">Field</InputLabel>
                        {dictionaries && (
                          <Select
                            label="Field"
                            labelId="fieldId"
                            // @ts-ignore: Unreachable code error
                            value={talentObject?.data.fieldId}
                            onChange={(e) => handleStateUpdate("fieldId", e.target.value)}
                            sx={{
                              mb: 4,
                              width: "100%",
                              color: "text.secondary",
                            }}
                          >
                            {dictionaries
                              .find((dictionary) => dictionary.dictionaryName === DictionaryTypeEnum.Field)
                              ?.dictionaryList.map((el) => (
                                <MenuItem key={el.id} value={el.id}>
                                  {el.name}
                                </MenuItem>
                              ))}
                          </Select>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={<Switch checked={talentObject?.data.isActive} onChange={(e) => handleStateUpdate("isActive", e.target.checked)} />}
                        label={talentObject?.data.isActive ? "Active" : "Not Active"}
                        sx={{
                          "& .MuiTypography-root": {
                            color: talentObject?.data.isActive ? "text.secondary" : "text.error",
                          },
                        }}
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
            <DialogActions sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: "center" }}>
              <Button variant="contained" sx={{ mr: 2 }} onClick={handleSubmit}>
                Submit
              </Button>
              <Button variant="outlined" color="secondary" onClick={handleClose}>
                Cancel
              </Button>
            </DialogActions>
          </Dialog>
        </Card>
      )}
    </>
  );
};

export default DialogAddCard;
