// ** React
import { useCallback, useEffect, useMemo, useState } from "react";
import { useParams } from "react-router-dom";

// ** MUI
import { GridColumns } from "@mui/x-data-grid";
import { Button, CircularProgress, Grid, Typography } from "@mui/material";

// ** 3rd party
import toast, { LoaderIcon } from "react-hot-toast";
import { useSelector } from "react-redux";

// ** service
import { DictionaryTypeEnum, GetDictionaryRequestType } from "../../service";
import { RootState, useAppDispatch } from "../../store";
import { getDictionary, getParentDictionary, postDeleteDictionary, postUpdateDictionaryOrder, updateDictionaryType } from "../../store/apps/attributesSlice";

// ** components
import Table from "../../utils/views/table/data-grid/TableFilter";
import { Modal as CustomModal } from "./components/modal";
import Benefit from "./attributePages/Benefit/Benefit";
import KeyFacts from "./attributePages/KeyFacts/KeyFacts";
import { Modal as DeleteModal } from "../../utils/views/modal/modal";

// ** Column
import useDynamicColumns from "./components/dynamicColumns";
import CountryAttribute from "./attributePages/Country/Country";
import ReorderModal from "./components/reorderModal";

const Attributes: React.FC = () => {
  const [editUser, setEditUser] = useState<null | boolean | GetDictionaryRequestType["data"]["dictionaryList"][0]>(null);
  const [dictionaryTypeEnum, setDictionaryTypeEnum] = useState<DictionaryTypeEnum>(1);
  const [modal, setModal] = useState<boolean>(false);
  const { isLoading, dictionaryState, dictionryType, dictionaryName } = useSelector((state: RootState) => state.attributes);
  const [openReorderModal, setOpenReorderModal] = useState<number | null>(null);

  const { columns, rowData, setRowData, deleteData, setDeleteData } = useDynamicColumns({
    dictionaryType: dictionaryTypeEnum,
    openReorderModal: setOpenReorderModal,
  });

  const { pageName } = useParams();
  const dispatch = useAppDispatch();

  const updateDictionaryStates = (param: number) => {
    setDictionaryTypeEnum(param);
    dispatch(updateDictionaryType(param));
  };

  useEffect(() => {
    //check if no data stored for editMode hide modal
    if (Object.keys(rowData).length < 1) return setModal(false);
    setModal(true);
  }, [rowData]);

  useEffect(() => {
    // get rid of modal side effect(leaving data when closing modal)
    if (modal === false) setRowData({} as GetDictionaryRequestType["data"]["dictionaryList"][0]);
  }, [modal]);

  useEffect(() => {
    if (!pageName) return;
    switch (pageName.toLowerCase()) {
      case "employment-type":
        updateDictionaryStates(DictionaryTypeEnum.EmploymentType);
        // setColumns(employmentTypeColumn());
        break;
      case "field":
        updateDictionaryStates(DictionaryTypeEnum.Field);
        // setColumns(fieldColumn());
        break;
      case "experience":
        updateDictionaryStates(DictionaryTypeEnum.Experience);
        // setColumns(experienceColumn());
        break;
      case "benefit":
        updateDictionaryStates(DictionaryTypeEnum.Benefit);
        // setColumns(benefitsColumn());
        break;
      case "city":
        updateDictionaryStates(DictionaryTypeEnum.City);
        // setColumns(cityColumn());
        break;
      case "positions":
        updateDictionaryStates(DictionaryTypeEnum.JobFunction);
        // setColumns(positionsColumn());
        break;
      case "technologies":
        updateDictionaryStates(DictionaryTypeEnum.Technology);
        // setColumns(technologiesColumn());
        break;
      case "tech-categories":
        updateDictionaryStates(DictionaryTypeEnum.TechnologyCategory);
        // setColumns(techCategoriesColumn());
        break;
      case "work-location":
        updateDictionaryStates(DictionaryTypeEnum.WorkLocation);
        // setColumns(workLocationColumn());
        break;
      case "keyfacts":
        break;
      case "country":
        updateDictionaryStates(DictionaryTypeEnum.Country);
        break;
      default:
        toast.error("Something went very wrong!");
        break;
    }
  }, [pageName]);

  useEffect(() => {
    if (!dictionaryTypeEnum) return;
    initData();
    if (dictionaryTypeEnum === DictionaryTypeEnum.Technology) dispatch(getParentDictionary(DictionaryTypeEnum.TechnologyCategory));
  }, [dictionaryTypeEnum]);

  const initData = async () => {
    await dispatch(getDictionary(dictionaryTypeEnum));
  };

  const AddNewData: React.FunctionComponent<{}> = (props: any) => {
    return (
      <Button sx={{ background: "primary", mb: 2 }} onClick={() => setModal(!modal)}>
        Add {dictionaryName}
      </Button>
    );
  };

  const RenderTable = useCallback(() => {
    if (!dictionaryState || !dictionaryState.data || !columns || !pageName) return null;
    switch (pageName.toLowerCase()) {
      case "benefit":
        return <Benefit tableData={dictionaryState.data.dictionaryList} loading={isLoading} />;
      case "keyfacts":
        return <KeyFacts />;
      case "country":
        return <CountryAttribute section={DictionaryTypeEnum.Country} updateReorder={handleUpdatePosition} />;
      case "field":
        return <CountryAttribute section={DictionaryTypeEnum.Field} updateReorder={handleUpdatePosition} parentName="Field" childName="Positions" />;
      default:
        return <Table tableData={dictionaryState.data.dictionaryList} name={dictionaryName} columns={columns} AddNewData={AddNewData} loading={isLoading} attributes={true} />;
    }
  }, [pageName, dictionaryState]);

  const handleUpdatePosition = async ({ currentPosition, newPosition }: { currentPosition: number; newPosition: number }) => {
    try {
      const dataIds = dictionaryState.data.dictionaryList.map((el) => el.id);
      const deletedIdArray = dataIds.filter((_, idx) => idx !== currentPosition);
      const reorderedId = dataIds[currentPosition];
      deletedIdArray.splice(newPosition - 1, 0, reorderedId);
      await dispatch(postUpdateDictionaryOrder({ dictionaryType: dictionaryTypeEnum, ids: deletedIdArray }));
      await dispatch(getDictionary(dictionaryTypeEnum));
      setOpenReorderModal(null);
      toast.success("Reordered successfully");
    } catch (err) {
      toast.error("error occured");
    }
  };

  const handleDeleteData = async () => {
    if (!pageName) return;
    const initialValues: Array<[string | number, number]> = [
      ["benefit", 1],
      ["city", 2],
      ["employment-type", 3],
      ["experience", 4],
      ["field", 5],
      ["positions", 6],
      ["technologies", 7],
      ["tech-categories", 8],
      ["work-location", 9],
      ["benefitCategory", 10],
      ["country", 11],
    ];
    const pageEnumType = new Map(initialValues);
    const dictionaryType = pageEnumType.get(pageName.toLowerCase());
    try {
      await dispatch(postDeleteDictionary({ dictionaryType, itemId: deleteData?.id })).unwrap();
      toast.success(`${deleteData?.name} deleted sucessfully`);
      setDeleteData(null);
      initData();
    } catch (err) {
      toast.error(typeof err === "string" ? err : "Something went wrong");
    }
  };

  return (
    <>
      {
        <>
          {isLoading && (
            <Grid
              item
              xs={12}
              sx={{
                display: "flex",
                justifyContent: "center",
                position: "fixed",
                left: 0,
                top: 0,
                height: "100%",
                width: "100%",
                background: "rgba(0,0,0, 0.1)",
                alignItems: "center",
                zIndex: 999999,
              }}
            >
              <CircularProgress />
            </Grid>
          )}
          {RenderTable()}
          <CustomModal show={modal} setShow={setModal} data={rowData} />
          <DeleteModal show={!!deleteData} onClose={() => setDeleteData(null)} submitAction={handleDeleteData} submitBtnColor="error" submitText="Delete">
            <Typography>
              Are you sure you want to delete <b>{deleteData?.name}</b>?
            </Typography>
          </DeleteModal>
          {openReorderModal !== null && <ReorderModal updatePosition={handleUpdatePosition} onClose={() => setOpenReorderModal(null)} currentPosition={openReorderModal} />}
        </>
      }
    </>
  );
};

export default Attributes;
