import { Box, Button, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { DictionaryTypeEnum, GetDictionaryRequestType } from "../../../service";

type columnPropType = {
  dictionaryType: DictionaryTypeEnum;
  openReorderModal?(val: number): void;
};

type columnType = {
  flex: number;
  minWidth: number;
  field: string;
  headerName: string;
  renderCell: (params: GridRenderCellParams<any, any, any>) => JSX.Element;
}[];

const useDynamicColumns = ({ dictionaryType, openReorderModal }: columnPropType) => {
  const [rowData, setRowData] = useState<GetDictionaryRequestType["data"]["dictionaryList"][0]>({} as GetDictionaryRequestType["data"]["dictionaryList"][0]);
  const [deleteData, setDeleteData] = useState<GetDictionaryRequestType["data"]["dictionaryList"][0] | null>(null);
  const [columns, setColumns] = useState<columnType>();

  useEffect(() => {
    if (dictionaryType === DictionaryTypeEnum.Technology) {
      setColumns([
        {
          flex: 0.1,
          minWidth: 50,
          field: "index",
          headerName: "Order",
          renderCell: (params: GridRenderCellParams) => {
            const { row } = params;
            return (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography noWrap variant="body2" sx={{ color: "primary", fontWeight: 600 }}>
                  {parseInt(row.index) + 1}
                </Typography>
                <Button sx={{ marginLeft: 3 }} onClick={() => openReorderModal && openReorderModal(row.index)}>
                  Reorder
                </Button>
              </Box>
            );
          },
        },
        {
          flex: 0.275,
          minWidth: 290,
          field: "name",
          headerName: "Name (EN)",
          renderCell: (params: GridRenderCellParams) => {
            const { row } = params;

            return (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography noWrap variant="body2" sx={{ color: "primary", fontWeight: 600 }}>
                    {row.name}
                  </Typography>
                </Box>
              </Box>
            );
          },
        },
        {
          flex: 0.275,
          minWidth: 290,
          field: "nameGE",
          headerName: "Name (GE)",
          renderCell: (params: GridRenderCellParams) => {
            const { row } = params;

            return (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography noWrap variant="body2" sx={{ color: "primary", fontWeight: 600 }}>
                    {row.nameGE}
                  </Typography>
                </Box>
              </Box>
            );
          },
        },
        {
          flex: 0.175,
          minWidth: 120,
          headerName: "Actions",
          field: "isVerified",
          renderCell: (params: GridRenderCellParams) => (
            <Typography variant="body2" sx={{ color: "text.primary" }}>
              <Button size="small" variant="outlined" color="secondary" onClick={() => setRowData(params.row)} style={{ margin: "0 10px 0 0" }}>
                Edit
              </Button>
              <Button size="small" variant="outlined" color="error" onClick={() => setDeleteData(params.row)} style={{ margin: "0 10px 0 0" }}>
                Delete
              </Button>
            </Typography>
          ),
        },
      ]);
    } else if (dictionaryType === DictionaryTypeEnum.Benefit) {
      setColumns([
        {
          flex: 0.1,
          minWidth: 50,
          field: "index",
          headerName: "Order",
          renderCell: (params: GridRenderCellParams) => {
            const { row } = params;
            return (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography noWrap variant="body2" sx={{ color: "primary", fontWeight: 600 }}>
                  {parseInt(row.index) + 1}
                </Typography>
                <Button sx={{ marginLeft: 3 }} onClick={() => openReorderModal && openReorderModal(row.index)}>
                  Reorder
                </Button>
              </Box>
            );
          },
        },
        {
          flex: 0.05,
          minWidth: 100,
          field: "iconUrl",
          headerName: "Icon",
          renderCell: (params: GridRenderCellParams) => {
            const { row } = params;

            return (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>{row.iconUrl && <img src={row.iconUrl} alt="" style={{ height: "30px", width: "30px" }} />}</Box>
              </Box>
            );
          },
        },
        {
          flex: 0.1,
          minWidth: 290,
          field: "name",
          headerName: "Name (EN)",
          renderCell: (params: GridRenderCellParams) => {
            const { row } = params;

            return (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography noWrap variant="body2" sx={{ color: "primary", fontWeight: 600 }}>
                    {row.name}
                  </Typography>
                </Box>
              </Box>
            );
          },
        },
        {
          flex: 0.1,
          minWidth: 290,
          field: "nameGE",
          headerName: "Name (GE)",
          renderCell: (params: GridRenderCellParams) => {
            const { row } = params;

            return (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography noWrap variant="body2" sx={{ color: "primary", fontWeight: 600 }}>
                    {row.nameGE}
                  </Typography>
                </Box>
              </Box>
            );
          },
        },
        {
          flex: 1,
          minWidth: 120,
          headerName: "Description (EN)",
          field: "description",
          renderCell: (params: GridRenderCellParams) => (
            <Typography variant="body2" sx={{ color: "text.primary" }}>
              {params.row?.description}
            </Typography>
          ),
        },
        {
          flex: 1,
          minWidth: 120,
          headerName: "Description (GE)",
          field: "descriptionGE",
          renderCell: (params: GridRenderCellParams) => (
            <Typography variant="body2" sx={{ color: "text.primary" }}>
              {params.row?.descriptionGE}
            </Typography>
          ),
        },
        {
          flex: 0.175,
          minWidth: 120,
          headerName: "Actions",
          field: "isVerified",
          renderCell: (params: GridRenderCellParams) => (
            <Typography variant="body2" sx={{ color: "text.primary" }}>
              <Button size="small" variant="outlined" color="secondary" onClick={() => setRowData(params.row)} style={{ margin: "0 10px 0 0" }}>
                Edit
              </Button>
              <Button size="small" variant="outlined" color="error" onClick={() => setDeleteData(params.row)} style={{ margin: "0 10px 0 0" }}>
                Delete
              </Button>
            </Typography>
          ),
        },
      ]);
    } else {
      setColumns([
        {
          flex: 0.1,
          minWidth: 50,
          field: "index",
          headerName: "Order",
          renderCell: (params: GridRenderCellParams) => {
            const { row } = params;
            return (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Typography noWrap variant="body2" sx={{ color: "primary", fontWeight: 600 }}>
                  {parseInt(row.index) + 1}
                </Typography>
                <Button sx={{ marginLeft: 3 }} onClick={() => openReorderModal && openReorderModal(row.index)}>
                  Reorder
                </Button>
              </Box>
            );
          },
        },
        {
          flex: 0.275,
          minWidth: 290,
          field: "name",
          headerName: "Name (EN)",
          renderCell: (params: GridRenderCellParams) => {
            const { row } = params;

            return (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography noWrap variant="body2" sx={{ color: "primary", fontWeight: 600 }}>
                    {row.name}
                  </Typography>
                </Box>
              </Box>
            );
          },
        },
        {
          flex: 0.275,
          minWidth: 290,
          field: "nameGE",
          headerName: "Name (GE)",
          renderCell: (params: GridRenderCellParams) => {
            const { row } = params;

            return (
              <Box sx={{ display: "flex", alignItems: "center" }}>
                <Box sx={{ display: "flex", flexDirection: "column" }}>
                  <Typography noWrap variant="body2" sx={{ color: "primary", fontWeight: 600 }}>
                    {row.nameGE}
                  </Typography>
                </Box>
              </Box>
            );
          },
        },
        {
          flex: 0.175,
          minWidth: 120,
          headerName: "Actions",
          field: "isVerified",
          renderCell: (params: GridRenderCellParams) => (
            <Typography variant="body2" sx={{ color: "text.primary" }}>
              <Button size="small" variant="outlined" color="secondary" onClick={() => setRowData(params.row)} style={{ margin: "0 10px 0 0" }}>
                Edit
              </Button>
              <Button size="small" variant="outlined" color="error" onClick={() => setDeleteData(params.row)} style={{ margin: "0 10px 0 0" }}>
                Delete
              </Button>
            </Typography>
          ),
        },
      ]);
    }
  }, [dictionaryType]);

  return { columns, rowData, setRowData, deleteData, setDeleteData };
};

export default useDynamicColumns;
