import { GridSortModel } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../store";
import { getAdmins, getSearchAdmins, removeAdminsFromMemory } from "../../store/apps/adminsSlice";
import { useColumnsForAdmins } from "./useColumnsForAdmins";
import TableForAdmins from "./TableForAdmins";
import DialogAddAdmin from "./DialogAddAdmin";
import { AdminSortEnum } from "../../types/enums/AdminSortEnum";
import { getAdminsRequestParams } from "../../service/Account/params";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import AccountOutline from "mdi-material-ui/AccountOutline";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import DialogDeleteAdmin from "./DialogDeleteAdmin";

const Admins = () => {
  const { admins } = useSelector((state: RootState) => state.admins);
  const dispatch = useAppDispatch();
  const [showModal, setShowModal] = useState<boolean>(false);
  const [searchParameters, setSearchParameters] = useState<getAdminsRequestParams>({
    searchTerm: "",
    pageNumber: 1,
    pageSize: 10,
  });

  const { columns, dataID, setDataID, deleteData, setDeleteData } = useColumnsForAdmins();

  useEffect(() => {
    return () => {
      dispatch(removeAdminsFromMemory());
    };
  }, []);

  useEffect(() => {
    if (dataID) setShowModal(true);
    else setShowModal(false);
  }, [dataID]);

  useEffect(() => {
    if (!showModal) setDataID(undefined);
  }, [showModal]);

  useEffect(() => {
    const timeOut = setTimeout(() => {
      dispatch(
        getSearchAdmins({
          searchTerm: searchParameters.searchTerm.trim(),
          pageNumber: searchParameters.pageNumber,
          pageSize: searchParameters.pageSize,
        })
      );
    }, 400);
    return () => clearTimeout(timeOut);
  }, [searchParameters.searchTerm]);

  const handleSortModel = (newModel: GridSortModel) => {
    let updatedSearchParams: getAdminsRequestParams = {
      ...searchParameters,
    };

    if (newModel.length === 0) {
      updatedSearchParams.SortBy = undefined;
      updatedSearchParams.IsDesc = undefined;
      setSearchParameters(updatedSearchParams);
    } else {
      // @ts-ignore: Unreachable code error
      const enumIndex: number = AdminSortEnum[newModel[0].field];
      updatedSearchParams.SortBy = enumIndex;
      updatedSearchParams.IsDesc = newModel[0].sort === "desc" ? true : false;
      setSearchParameters(updatedSearchParams);
    }

    dispatch(getSearchAdmins(updatedSearchParams));
  };

  const updatePageSizeHandler = (pageSize: number) => {
    let updatedSearchParams: getAdminsRequestParams = {
      ...searchParameters,
      pageSize,
    };

    dispatch(
      getSearchAdmins({
        ...updatedSearchParams,
      })
    );

    setSearchParameters(updatedSearchParams);
  };

  const updatePageNumberHandler = (pageNumber: number) => {
    let updatedSearchParams: getAdminsRequestParams = {
      ...searchParameters,
      pageNumber: pageNumber + 1,
    };

    dispatch(
      getSearchAdmins({
        ...updatedSearchParams,
      })
    );

    setSearchParameters(updatedSearchParams);
  };

  const searchValueChangeHandler = (value: string) => {
    setSearchParameters((prev) => ({
      ...prev,
      searchTerm: value,
    }));
  };

  const newAdminAddedHandler = () => {
    dispatch(
      getSearchAdmins({
        ...searchParameters,
      })
    );
  };

  return (
    <div>
      <Card>
        <CardContent sx={{ textAlign: "center" }}>
          <AccountOutline sx={{ mb: 2, fontSize: "2rem" }} />
          <Typography variant="h6" sx={{ mb: 4 }}>
            Add New Admin
          </Typography>
          <Button variant="contained" onClick={() => setShowModal(true)}>
            Add
          </Button>
        </CardContent>
      </Card>
      <DialogAddAdmin onSuccess={newAdminAddedHandler} show={showModal} setShow={setShowModal} editAdmin={dataID} setDataId={setDataID} refetchData={newAdminAddedHandler} />
      <DialogDeleteAdmin open={!!deleteData} setOpen={setDeleteData} data={deleteData} refetchData={newAdminAddedHandler} />
      <br />
      {admins && (
        <TableForAdmins
          searchValue={searchParameters.searchTerm}
          data={admins}
          columns={columns}
          title="Admins"
          updatePage={updatePageNumberHandler}
          updatePageSize={updatePageSizeHandler}
          onSearchValueChange={searchValueChangeHandler}
          stateDispatcher={getAdmins}
          storedState="admins"
          sortTable={handleSortModel}
        />
      )}
    </div>
  );
};

export default Admins;
