interface Values {
    readonly Email?: string;
    readonly Name?: string;
    readonly Technologies?: string[];
    readonly IsActive?: string | boolean;
    readonly IsVerified?: string | boolean;
    readonly PageSize?: number;
    readonly SortBy?: number;
    readonly IsDesc?: boolean;
    readonly SearchTerm?: string;
    readonly SearchIds?: string[]
    // [key: string]: string | string[] | boolean;
  }

export const handleObjectForQueryString = (obj: Values, page: number) => {
    
    const initialValues: Values = {
        Email: "",
        Name: "",
        Technologies: [],
        IsActive: "",
        IsVerified: "",
        PageSize: 10,
        SearchTerm: "",
        SearchIds: []
      };
    
    const generateQueryString = (obj: typeof initialValues) => {
        let localString = `PageNumber=${page}${
          (obj.Email && obj.Email.length > 0) ? "&Email=" + obj.Email : ""
        }${(obj.Name && obj.Name.length > 0) ? "&Name=" + obj.Name : ""}${
          obj.IsVerified ? "&IsVerified=" + obj.IsVerified : ""
        }${obj.IsActive ? "&IsActive=" + obj.IsActive : ""}${
          obj.PageSize ? "&pageSize=" + obj.PageSize : "&pageSize=10"
        }${obj.SortBy ? "&SortBy=" + obj.SortBy : ""}${
          obj.IsDesc !== undefined ? "&IsDesc=" + obj.IsDesc : ""
        }${obj.SearchTerm ? "&SearchTerm=" + obj.SearchTerm : ""}${obj.SearchIds ? obj.SearchIds.map(el => `&SearchIds=${el}`).join("") : ""}`;
        for (const prop in obj) {
          prop === "Technologies" && obj.Technologies &&
            obj.Technologies.forEach(
              (propItem: string) => (localString += `&${prop}=${propItem}`)
            );
        }
        return localString;
      };

      return generateQueryString(obj)
}

export const splitName = (name: string) => {
  let originalName = name;
  const splitedName = name.split("");
  for (let i = 0; i < splitedName.length; i++) {
    if (i !== 0) {
      if (splitedName[i] === splitedName[i].toUpperCase())
        originalName = `${originalName.slice(0, i)} ${originalName.slice(
          i,
          originalName.length
        )}`;
    }
  }
  return originalName;
};

 