// ** MUI Imports
import FilterListIcon from "@mui/icons-material/FilterList";
import { Button, ButtonProps, IconButton, InputAdornment, OutlinedInput, TextField, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import ClearIcon from "@mui/icons-material/Clear";

// ** State Imports
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../store";
import { DictionaryTypeEnum } from "../../../../types/enums";
import { ChangeEvent, forwardRef, useState } from "react";
import { setSubmittedFilterObject } from "../../../../store/apps/vacanciesSlice";
import DatePicker from "react-datepicker";
import DatePickerWrapper from "../../../../components/@core/styles/libs/react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

// ** Style imports
import classes from "./vacancyFilter.module.sass";

const CustomInput = forwardRef(
  (
    props: {
      label: string;
    },
    ref
  ) => {
    return <TextField inputRef={ref} fullWidth {...props} />;
  }
);

const ButtonWrapper = styled(Button)<ButtonProps>(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "7px 12px",
  margin: "0 0 5px -2px",
}));

type propType = {
  componentSection: string;
};

const VacancyFilter = ({ componentSection }: propType) => {
  const dispatch = useAppDispatch();
  // ** State
  const { dictionaries, submittedFilteredObject } = useSelector(
    // @ts-ignore: Unreachable code error
    (state: RootState) => state[componentSection]
  );
  const [searchTerm, setSearchTerm] = useState<string>("");
  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [vacancyStatus, setVacancyStatus] = useState<string>("");
  const [technologies, setTechnologies] = useState<string[]>([]);
  const [jobFunctions, setJobFunctions] = useState<string[]>([]);
  const [employmentTypies, setEmploymentTypies] = useState<string[]>([]);
  const [publishedFrom, setPublishedFrom] = useState<Date>();
  const [publishedTo, setPublishedTo] = useState<Date>();
  const [deadlineFrom, setDeadlineFrom] = useState<Date>();
  const [deadlineTo, setDeadlineTo] = useState<Date>();

  const filterData = () => {
    dispatch(
      setSubmittedFilterObject({
        ...submittedFilteredObject,
        VacancyStatus: vacancyStatus,
        JobFunctions: jobFunctions,
        Technologies: technologies,
        EmploymentTypies: employmentTypies,
        PublishedFrom: publishedFrom,
        PublishedTo: publishedTo,
        DeadlineFrom: deadlineFrom,
        DeadlineTo: deadlineTo,
        SearchTerm: searchTerm,
      })
    );
  };

  return (
    <>
      <Box sx={{ position: "relative" }}>
        <ButtonWrapper onClick={() => setShowFilter(!showFilter)}>
          <FilterListIcon style={{ fill: "#666CFF", marginRight: "8px" }} />
          <Typography color="#666CFF" variant="body2" fontWeight={"500"}>
            Filters
          </Typography>
        </ButtonWrapper>
        <Box
          sx={{
            position: "absolute",
            // top: "107px",
            zIndex: "10",
            backgroundColor: (theme) => (theme.palette.mode === "dark" ? theme.palette.background.default : theme.palette.info.contrastText),
          }}
        >
          {showFilter && (
            <Box
              className={classes.filterWrapper}
              sx={{
                display: "flex",
                alignItems: "center",
                padding: "10px",
                zIndex: "100",
                borderRadius: "4px",
                boxShadow: "3px 3px 7px 0px black",
                position: "absolute",
                bottom: "-80px",
                backgroundColor: (theme) => (theme.palette.mode === "dark" ? theme.palette.background.default : theme.palette.background.paper),
              }}
            >
              <FormControl fullWidth variant="outlined" sx={{ mr: "5px" }}>
                <InputLabel
                  style={{
                    paddingLeft: "5px",
                    paddingRight: "10px",
                  }}
                  htmlFor="outlined-adornment-title"
                >
                  Title
                </InputLabel>
                <OutlinedInput
                  id="outlined-adornment-title"
                  value={searchTerm}
                  onChange={(event: ChangeEvent<HTMLInputElement>) => setSearchTerm(event.target.value)}
                  endAdornment={
                    <InputAdornment position="end">
                      <IconButton aria-label="toggle password visibility" edge="end" onClick={() => setSearchTerm("")}>
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <FormControl fullWidth sx={{ mr: "5px" }}>
                <InputLabel id="Active">Status</InputLabel>
                <Select
                  label="Status"
                  id="Active"
                  labelId="Active"
                  // MenuProps={MenuProps}
                  // @ts-ignore: Unreachable code error
                  value={vacancyStatus}
                  onChange={(e) => setVacancyStatus(e.target.value)}
                  sx={{ width: "100%", color: "text.secondary" }}
                >
                  <MenuItem value="">None</MenuItem>
                  <MenuItem value="1">Active</MenuItem>
                  <MenuItem value="2">Expired</MenuItem>
                  <MenuItem value="3">Draft</MenuItem>
                  <MenuItem value="4">Deactivated</MenuItem>
                </Select>
              </FormControl>
              <FormControl fullWidth sx={{ mr: "5px" }}>
                <InputLabel id="technology_label">Technologies</InputLabel>

                {dictionaries && (
                  <Select
                    multiple
                    label="Technologies"
                    labelId="technology_label"
                    // @ts-ignore: Unreachable code error
                    value={technologies}
                    // @ts-ignore: Unreachable code error
                    onChange={(e) => setTechnologies(e.target.value)}
                    sx={{ width: "100%", color: "text.secondary" }}
                    MenuProps={{
                      PaperProps: { sx: { maxHeight: "250px", top: "336px" } },
                    }}
                  >
                    {dictionaries
                      .find(
                        // @ts-ignore: Unreachable code error
                        (dictionary) => dictionary.dictionaryName === DictionaryTypeEnum.Technology
                      )
                      ?.dictionaryList.map(
                        // @ts-ignore: Unreachable code error
                        (el) => (
                          <MenuItem key={el.id} value={el.id}>
                            {el.name}
                          </MenuItem>
                        )
                      )}
                  </Select>
                )}
              </FormControl>
              <FormControl fullWidth sx={{ mr: "5px" }}>
                <InputLabel id="jobFunctions">Position</InputLabel>

                {dictionaries && (
                  <Select
                    multiple
                    label="Position"
                    labelId="jobFunctions"
                    // @ts-ignore: Unreachable code error
                    value={jobFunctions}
                    // @ts-ignore: Unreachable code error
                    onChange={(e) => setJobFunctions(e.target.value)}
                    sx={{ width: "100%", color: "text.secondary" }}
                    MenuProps={{
                      PaperProps: { sx: { maxHeight: "250px", top: "336px" } },
                    }}
                  >
                    {dictionaries
                      .find(
                        // @ts-ignore: Unreachable code error
                        (dictionary) => dictionary.dictionaryName === DictionaryTypeEnum.JobFunction
                      )
                      ?.dictionaryList.map(
                        // @ts-ignore: Unreachable code error
                        (el) => (
                          <MenuItem key={el.id} value={el.id}>
                            {el.name}
                          </MenuItem>
                        )
                      )}
                  </Select>
                )}
              </FormControl>
              <FormControl fullWidth sx={{ mr: "5px" }}>
                <InputLabel id="employmentTypies">Employment Type</InputLabel>

                {dictionaries && (
                  <Select
                    multiple
                    label="Employment Type"
                    labelId="employmentTypies"
                    // @ts-ignore: Unreachable code error
                    value={employmentTypies}
                    // @ts-ignore: Unreachable code error
                    onChange={(e) => setEmploymentTypies(e.target.value)}
                    sx={{ width: "100%", color: "text.secondary" }}
                  >
                    {dictionaries
                      .find(
                        // @ts-ignore: Unreachable code error
                        (dictionary) => dictionary.dictionaryName === DictionaryTypeEnum.EmploymentType
                      )
                      ?.dictionaryList.map(
                        // @ts-ignore: Unreachable code error
                        (el) => (
                          <MenuItem key={el.id} value={el.id}>
                            {el.name}
                          </MenuItem>
                        )
                      )}
                  </Select>
                )}
              </FormControl>
              <FormControl fullWidth sx={{ mr: "5px" }}>
                <DatePickerWrapper>
                  <DatePicker
                    selected={deadlineFrom}
                    showYearDropdown
                    showMonthDropdown
                    id="account-settings-date"
                    placeholderText="MM-DD-YYYY"
                    isClearable
                    customInput={<CustomInput label="Deadline From" />}
                    onChange={(date: Date) => setDeadlineFrom(date)}
                  />
                </DatePickerWrapper>
              </FormControl>
              <FormControl fullWidth sx={{ mr: "5px" }}>
                <DatePickerWrapper>
                  <DatePicker
                    selected={deadlineTo}
                    showYearDropdown
                    showMonthDropdown
                    id="account-settings-date"
                    placeholderText="MM-DD-YYYY"
                    isClearable
                    customInput={<CustomInput label="Deadline To" />}
                    onChange={(date: Date) => setDeadlineTo(date)}
                  />
                </DatePickerWrapper>
              </FormControl>
              <FormControl fullWidth sx={{ mr: "5px" }}>
                <DatePickerWrapper>
                  <DatePicker
                    selected={publishedFrom}
                    showYearDropdown
                    showMonthDropdown
                    id="account-settings-date"
                    placeholderText="MM-DD-YYYY"
                    isClearable
                    customInput={<CustomInput label="Published From" />}
                    onChange={(date: Date) => setPublishedFrom(date)}
                  />
                </DatePickerWrapper>
              </FormControl>
              <FormControl fullWidth sx={{ mr: "5px" }}>
                <DatePickerWrapper>
                  <DatePicker
                    selected={publishedTo}
                    showYearDropdown
                    showMonthDropdown
                    id="account-settings-date"
                    placeholderText="MM-DD-YYYY"
                    isClearable
                    customInput={<CustomInput label="Published To" />}
                    onChange={(date: Date) => setPublishedTo(date)}
                  />
                </DatePickerWrapper>
              </FormControl>
              <Button onClick={filterData}>Search</Button>
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export default VacancyFilter;
