import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";
import * as Api from "../../../service";
import { companyBenefitParent } from "../../../types/apps/companyTypes";
import { PostUpdateCompanyBenefitsRequestParams } from "../../../service";

interface ManageCompaniesState {
  readonly isLoading: boolean;
  readonly companies: Api.GetSearchTalentsRequestType | null;
  readonly dictionaries: Api.DictionaryType[];
  readonly submittedFilteredObject: {
    readonly Email: string;
    readonly Name: string;
    readonly Technologies: string[];
    readonly IsActive: string | boolean;
    readonly IsVerified: string | boolean;
    readonly PageSize: number;
    readonly SortBy?: number;
    readonly IsDesc?: boolean;
    readonly SearchTerm?: string;
  };
  readonly submittedFilteredTechnologies:
    | Api.DictionaryType["dictionaryList"]
    | undefined;
  readonly submittedPage: number;
  readonly company: Api.SingleCompanyType | null;
  readonly companyBenefits: companyBenefitParent[]
}

const initState: ManageCompaniesState = {
  isLoading: false,
  companies: null,
  submittedFilteredObject: {
    Email: "",
    Name: "",
    Technologies: [],
    IsActive: "",
    IsVerified: "",
    PageSize: 10,
  },
  dictionaries: [],
  submittedFilteredTechnologies: [],
  submittedPage: 1,
  company: null,
  companyBenefits: []
};

export const getSearchCompanies = createAsyncThunk(
  "companies/getSearchCompanies",
  async (queryString: string) => {
    const response = await Api.getSearchCompaniesRequest(queryString);
    return response.data;
  }
);

export const getAllDictionaries = createAsyncThunk<Api.DictionaryType[]>(
  "companies/getDictionaries",
  async () => {
    const response = await Api.getAllDictionariesRequest();
    return response.data;
  }
);

export const getCompany = createAsyncThunk(
  "companies/getCompany",
  async (id: string) => {
    const response = await Api.getGetCompanyRequest(id);
    return response.data.data;
  }
);

export const updateCompany = createAsyncThunk(
  "companies/updateCompany",
  async (object: Api.CompanyValues) => {
    const response = await Api.postUpdateCompanyRequest(object);
    return response.data;
  }
);

export const updateCompanyVerifyOrActive = createAsyncThunk(
  "companies/updateCompanyVerifyOrActive",
  async (object: Api.PostUpdateCompanyVerifyOrActiveRequestType) => {
    const response = await Api.postUpdateCompanyVerifyOrActiveRequest(object);
    return response.data;
  }
);

export const updateCompanyEmailAndSendHandover = createAsyncThunk(
  "companies/updateCompanyEmailAndSendHandover",
  async (object: Api.UpdateCompanyEmailAndSendHandoverRequestType) => {
    const response = await Api.postUpdateCompanyEmailAndSendHandoverRequest(object);
    return response.data;
  }
);

export const getCompanyBenefits = createAsyncThunk(
  "companies/getCompanyBenefits",
  async (id: string) => {
    const response = await Api.getCompanyBenefitsRequest(id);
    return response.data;
  }
);

export const updateCompanyBenefits = createAsyncThunk(
  "companies/updateCompanyBenefits",
  async ({id, data}: {id: string, data: PostUpdateCompanyBenefitsRequestParams[]}) => {
    const response = await Api.updateCompanyBenefitsRequest(id, data);
    return response.data;
  }
);



export const manageCompaniesSlice = createSlice({
  name: "manageCompanies",
  initialState: initState,
  reducers: {
    setSubmittedFilterObject: (
      state,
      action: PayloadAction<{
        Email: string;
        Name: string;
        Technologies: string[];
        IsActive: string | boolean;
        IsVerified: string | boolean;
        PageSize: number;
        SortBy?: number;
        IsDesc?: boolean;
        SearchTerm?: string;
      }>
    ) => {
      state.submittedFilteredObject = action.payload;
      state.submittedPage = 1;
    },
    setSubmittedPage: (
      state,
      action: PayloadAction<{
        submittedPage: number;
      }>
    ) => {
      state.submittedPage = action.payload.submittedPage;
    },
    setInitialState: (state) => {
      state = initState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSearchCompanies.fulfilled, (state, action) => {
        state.isLoading = false;
        state.companies = action.payload;
      })
      .addCase(getSearchCompanies.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSearchCompanies.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updateCompany.fulfilled, () => {
        toast.success("Company updated successfully");
      })
      .addCase(updateCompany.rejected, () => {
        toast.error("Something went wrong!");
      })
      .addCase(updateCompanyVerifyOrActive.fulfilled, () => {
        toast.success("Company status updated successfully");
      })
      .addCase(updateCompanyVerifyOrActive.rejected, () => {
        toast.error("Something went wrong!");
      })
      // .addCase(getAllDictionaries.pending, (state) => {
      //   state.isLoading = true;
      // })
      .addCase(getAllDictionaries.fulfilled, (state, action) => {
        // state.isLoading = false;
        state.dictionaries = action.payload;
      })
      .addCase(getAllDictionaries.rejected, (state) => {
        // state.isLoading = false;
      })
      .addCase(getCompany.fulfilled, (state, action) => {
        state.isLoading = false;
        state.company = action.payload;
      })
      .addCase(getCompany.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCompany.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getCompanyBenefits.pending, state => {
        state.isLoading = true
      })
      .addCase(getCompanyBenefits.fulfilled, (state, action) => {
        state.companyBenefits = action.payload.data
      })
      .addCase(getCompanyBenefits.rejected, state => {
        state.isLoading = false
      })
      .addCase(updateCompanyBenefits.rejected, state => {
        state.isLoading = false
      })
      .addCase(updateCompanyBenefits.fulfilled, state => {
        state.isLoading = false
      })
      .addCase(updateCompanyBenefits.pending, state => {
        state.isLoading = true
      })
  },
});

export const { setSubmittedFilterObject, setSubmittedPage, setInitialState } =
  manageCompaniesSlice.actions;
export default manageCompaniesSlice.reducer;
