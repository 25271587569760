// ** MUI Imports
import FilterListIcon from "@mui/icons-material/FilterList";
import { Button, ButtonProps, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

// ** State Imports
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../store";
import { DictionaryTypeEnum } from "../../../../types/enums";
import { useState } from "react";
import { setSubmittedFilterObject as setCompaniesObject } from "../../../../store/apps/companiesSlice";
import { setSubmittedFilterObject as setTaletsObject } from "../../../../store/apps/talentsSlice";

const ButtonWrapper = styled(Button)<ButtonProps>(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: "7px 12px",
  margin: "0 0 5px -2px",
}));

type propType = {
  componentSection: string;
};

const CompanyFilter = ({ componentSection }: propType) => {
  const dispatch = useAppDispatch();
  // ** State
  const { dictionaries, submittedFilteredObject } = useSelector(
    // @ts-ignore: Unreachable code error
    (state: RootState) => state[componentSection || "companies"]
  );

  const [showFilter, setShowFilter] = useState<boolean>(false);
  const [verification, setVerification] = useState<string>("");
  const [activeStatus, setActiveStatus] = useState<string>("");
  const [technologies, setTechnologies] = useState<string[]>([]);

  if (!componentSection) return <></>;

  const filterData = () => {
    if (componentSection === "talents") {
      return dispatch(
        setTaletsObject({
          ...submittedFilteredObject,
          IsActive: activeStatus,
          IsVerified: verification,
          Technologies: technologies,
        })
      );
    }
    dispatch(
      setCompaniesObject({
        ...submittedFilteredObject,
        IsActive: activeStatus,
        IsVerified: verification,
        Technologies: technologies,
      })
    );
  };

  return (
    <>
      {dictionaries && (
        <Box sx={{ position: "relative" }}>
          <ButtonWrapper onClick={() => setShowFilter(!showFilter)}>
            <FilterListIcon style={{ fill: "#666CFF", marginRight: "8px" }} />
            <Typography color="#666CFF" variant="body2" fontWeight={"500"}>
              Filters
            </Typography>
          </ButtonWrapper>
          <Box
            sx={{
              position: "absolute",
              top: "100px",
              zIndex: "10",
              backgroundColor: (theme) =>
                theme.palette.mode === "dark"
                  ? theme.palette.background.default
                  : theme.palette.info.contrastText,
            }}
          >
            {showFilter && (
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  padding: "10px",
                  zIndex: "20",
                  borderRadius: "4px",
                  boxShadow: "3px 3px 7px 0px black",
                }}
              >
                {componentSection === "companies" && (
                  <FormControl fullWidth sx={{ mr: "5px" }}>
                    <InputLabel id="Verification">Verification</InputLabel>
                    <Select
                      label="Verification"
                      id="Verification"
                      labelId="Verification"
                      // MenuProps={MenuProps}
                      // @ts-ignore: Unreachable code error
                      value={verification}
                      onChange={(e) => setVerification(e.target.value)}
                      sx={{ width: "100%", color: "text.secondary" }}
                    >
                      <MenuItem value="">None</MenuItem>
                      <MenuItem value="1">Verified</MenuItem>
                      <MenuItem value="2">Not Verified</MenuItem>
                      <MenuItem value="3">Pending Review</MenuItem>
                    </Select>
                  </FormControl>
                )}

                <FormControl fullWidth sx={{ mr: "5px" }}>
                  <InputLabel id="Active">Active Status</InputLabel>
                  <Select
                    label="Active Status"
                    id="Active"
                    labelId="Active"
                    // MenuProps={MenuProps}
                    // @ts-ignore: Unreachable code error
                    value={activeStatus}
                    onChange={(e) => setActiveStatus(e.target.value)}
                    sx={{ width: "100%", color: "text.secondary" }}
                  >
                    <MenuItem value="">None</MenuItem>
                    <MenuItem value="1">Active</MenuItem>
                    <MenuItem value="2">Not Active</MenuItem>
                  </Select>
                </FormControl>
                <FormControl fullWidth sx={{ mr: "5px" }}>
                  <InputLabel id="technology_label">Technologies</InputLabel>
                  <Select
                    multiple
                    label="Technologies"
                    labelId="technology_label"
                    // @ts-ignore: Unreachable code error
                    value={technologies}
                    // @ts-ignore: Unreachable code error
                    onChange={(e) => setTechnologies(e.target.value)}
                    sx={{
                      width: "100%",
                      color: "text.secondary",
                      maxHeight: "50px",
                    }}
                    MenuProps={{
                      PaperProps: { sx: { maxHeight: "250px", top: "336px" } },
                    }}
                  >
                    {dictionaries
                      .find(
                        // @ts-ignore: Unreachable code error
                        (dictionary) =>
                          dictionary.dictionaryName ===
                          DictionaryTypeEnum.Technology
                      )
                      ?.dictionaryList.map(
                        // @ts-ignore: Unreachable code error
                        (el) => (
                          <MenuItem key={el.id} value={el.id}>
                            {el.name}
                          </MenuItem>
                        )
                      )}
                  </Select>
                </FormControl>
                <Button onClick={filterData}>Search</Button>
              </Box>
            )}
          </Box>
        </Box>
      )}
    </>
  );
};

export default CompanyFilter;
