// ** MUI Imports
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { useState } from "react";

type propType = {
  id?: string;
  name: string;
  nameGE: string;
  deleteOption(): void;
  updateOption({ id, optName, optNameGE }: { id: string | undefined; optName: string; optNameGE: string }): void;
};

const KeyFactSubItem = ({ id, name, nameGE, deleteOption, updateOption }: propType) => {
  const [localName, setLocalName] = useState<string>(name);
  const [localNameGE, setLocalNameGE] = useState<string>(nameGE);
  return (
    <>
      <TextField
        autoFocus
        fullWidth
        id="Name"
        label="Name"
        name="name"
        sx={{ mb: 0, width: "100%" }}
        onChange={(e) => {
          setLocalName(e.target.value);
        }}
        onBlur={() => updateOption({ id, optName: localName, optNameGE: localNameGE })}
        value={localName}
      />
      <TextField
        autoFocus
        fullWidth
        id="nameGE"
        label="NameGE"
        name="nameGE"
        sx={{ mb: 0, width: "100%" }}
        onBlur={() => updateOption({ id, optName: localName, optNameGE: localNameGE })}
        onChange={(e) => {
          setLocalNameGE(e.target.value);
        }}
        value={localNameGE}
      />
      <Button color="error" onClick={deleteOption}>
        Delete Option
      </Button>

      <Divider />
    </>
  );
};

export default KeyFactSubItem;
