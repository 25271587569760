export enum CompanyTalentSortEnum {
  createDate = 0,
  email = 1,
  name = 2,
  isActive = 3,
  isVerified = 4,
  isEmailVerified = 5,
}

export enum CompanyVacancySortEnum {
  createDate = 0,
  publishDate = 1,
  deadline = 2,
  title = 3,
  isDraft = 4,
  isActive = 5,
  company = 6,
}

export enum DictionaryTypeEnum {
  Benefit = "Benefit",
  Field = "Field",
  City = "City",
  EmploymentType = "EmploymentType",
  Experience = "Experience",
  JobFunction = "JobFunction",
  WorkLocation = "WorkLocation",
  TechnologyCategory = "TechnologyCategory",
  Technology = "Technology",
  Country = "Country",
  Language = "Language",
}

export enum LanguageEnum {
  NotDefined = 0,
  Georgian = 1,
  English = 2,
  German = 3,
  French = 4,
  Spanish = 5,
  Chinese = 6,
  Turkish = 7,
  Russian = 8,
}

export enum CurrencyEnum {
  None = 0,
  Gel = 1,
  Dollar = 2,
  Euro = 3,
  PoundSterling = 4,
}
