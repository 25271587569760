import { Fragment, useEffect, useState } from "react";

// ** Mui
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

// ** types
import { companyBenefit, companyBenefitParent } from "../../../../../types/apps/companyTypes";
import CompanyBenefitsModal from "./CompanyBenefitsModal";
import { benefitTypeEnum } from "../../../../../service";

type propType = {
  data: companyBenefitParent;
  updateBenefit(newObj: companyBenefitParent): void;
};

const CompanyBenefits = (props: propType) => {
  const { data, updateBenefit } = props;

  const [openModal, setOpenModal] = useState<boolean>(false);
  const [localData, setLocalData] = useState<companyBenefitParent>();

  useEffect(() => {
    setLocalData({ ...data, companyBenefits: data.companyBenefits.map((el) => ({ ...el, checked: true })) });

    return () => setLocalData(undefined);
  }, [data]);

  const handleUpdateBenefit = (id: string, key: keyof companyBenefit, value: string) => {
    if (!localData) return;

    const newData = { ...localData };
    const updatedBenefits = newData.companyBenefits.map((el) => {
      if (el.id === id) {
        return { ...el, [key]: value };
      }
      return el;
    });
    newData.companyBenefits = updatedBenefits;
    setLocalData(newData);
  };

  const handleUpdateModalData = (modalData: companyBenefitParent) => {
    setLocalData({ ...modalData, companyBenefits: modalData.companyBenefits.filter((el) => el.checked) });
    setOpenModal(false);
  };

  const deleteBenefit = (id: string) => {
    // @ts-ignore
    setLocalData({ ...localData, companyBenefits: localData?.companyBenefits.filter((el) => el.id !== id) });
  };

  return (
    <Fragment key={data.id}>
      <Card sx={{ p: 4 }} onBlur={() => updateBenefit(localData!)}>
        <Grid>
          <Box sx={{ display: "grid", gridTemplateColumns: "repeat(2, 1fr)", gap: "20px" }}>
            <Typography variant="body1" sx={{ display: "flex", alignItems: "center", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
              Category Name:{" "}
              <Typography variant="body2" sx={{ fontWeight: "bold", ml: 2, maxWidth: "370px" }}>
                {data.name}
              </Typography>
            </Typography>
          </Box>
          <Divider sx={{ marginY: 5 }} />
          <Box sx={{ display: "grid", gridTemplateColumns: "repeat(1, 1fr)", gap: "10px" }}>
            {localData?.companyBenefits.map((el) => {
              return (
                <Fragment key={el.id}>
                  <Typography variant="body2" sx={{ display: "flex", alignItems: "center", whiteSpace: "nowrap" }}>
                    Benefit:{" "}
                    <Typography variant="body2" sx={{ fontWeight: "bold", ml: 2, maxWidth: "380px", overflow: "hidden", textOverflow: "ellipsis" }}>
                      {el.name}
                    </Typography>
                  </Typography>
                  <Box sx={{ display: "grid", gridTemplateColumns: el.benefitType === benefitTypeEnum.text ? "repeat(2, 1fr)" : "repeat(1, 1fr)", gap: "20px" }}>
                    {el.benefitType === benefitTypeEnum.text ? (
                      <>
                        <TextField
                          multiline
                          value={el.description || ""}
                          label="Description"
                          onChange={(e) => {
                            handleUpdateBenefit(el.id, "description", e.target.value);
                          }}
                          sx={{ width: "100%" }}
                          InputLabelProps={{ shrink: true }}
                        />
                        <TextField
                          multiline
                          value={el.descriptionGE || ""}
                          label="DescriptionGE"
                          onChange={(e) => {
                            handleUpdateBenefit(el.id, "descriptionGE", e.target.value);
                          }}
                          sx={{ width: "100%" }}
                          InputLabelProps={{ shrink: true }}
                        />
                      </>
                    ) : (
                      <TextField
                        multiline
                        value={el.descriptionNumber}
                        label="Description Number"
                        onChange={(e) => {
                          const numericValue = e.target.value.replace(/[^0-9]/g, "");
                          handleUpdateBenefit(el.id, "descriptionNumber", numericValue);
                        }}
                        type="number"
                        sx={{ width: "100%" }}
                        InputLabelProps={{ shrink: true }}
                      />
                    )}
                  </Box>
                  {!el.canNotBeDeletedByTheCompany && (
                    <Button color="error" onClick={() => deleteBenefit(el.id)}>
                      Delete Benefit
                    </Button>
                  )}

                  <Divider sx={{ marginY: 2 }} />
                </Fragment>
              );
            })}
            <Button color="primary" onClick={() => setOpenModal(true)}>
              Add Benefit
            </Button>
          </Box>
        </Grid>
      </Card>
      <CompanyBenefitsModal data={localData} updateData={handleUpdateModalData} open={openModal} onClose={() => setOpenModal(false)} />
    </Fragment>
  );
};

export default CompanyBenefits;
