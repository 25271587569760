// ** React Imports
import { useState, Fragment, FC, forwardRef, ReactElement, Ref } from 'react'

// ** MUI Imports
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Typography from '@mui/material/Typography'
import * as Api from "../../../service"
import DragVertical from 'mdi-material-ui/DragVertical'


import { Button, Dialog, DialogActions, DialogContent, Fade, FadeProps, Grid } from '@mui/material'
import { useAppDispatch } from '../../../store'
import {useSortable} from '@dnd-kit/sortable';
import {CSS} from '@dnd-kit/utilities';
import DialogEditButton from './DialogEditButton'
import { getHeroImageDetails, updateHeroImageText } from '../../../store/apps/HeroImageSlice'
import toast from 'react-hot-toast'

const Transition = forwardRef(function Transition(
  props: FadeProps & { children?: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Fade ref={ref} {...props} />;
});

const ButtonsTableRow: FC<{ TableRow: Api.HeroImageButtonType, heroImageDetails: Api.HeroImageDetailsType }> = (props) => {

  const dispatch = useAppDispatch()
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({id: props.TableRow.id});
  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);

  // ** State
  const [showEdit, setShowEdit] = useState<Api.HeroImageButtonType>()

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const deleteHeroButtonHandler = ()=>{

    const newObj = {
      ...props.heroImageDetails,
      heroButtons: props.heroImageDetails.heroButtons.filter(button => button.id !== props.TableRow.id)
    }

    dispatch(updateHeroImageText(newObj))
      .unwrap()
      .then(() => {
        dispatch(getHeroImageDetails());
        toast.success("Deleted successfully");
        setShowDeleteModal(false);
      })
      .catch((response) => {
        toast.error(response.error);
      });
  }
 
  return (
    <Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} ref={setNodeRef} style={style}>
        <TableCell>{props.TableRow.name}</TableCell>
        <TableCell>{props.TableRow.nameGE}</TableCell>
        <TableCell>
          <Button
            size="small"
            variant="outlined"
            color="secondary"
            onClick={() => setShowEdit(props.TableRow)}
            style={{ margin: "0 10px 0 0" }}
          >
            Edit
          </Button>

          <Button
            size="small"
            variant="outlined"
            color="secondary"
            onClick={() => setShowDeleteModal(true)}
            style={{ margin: "0 10px 0 0" }}
          >
            Delete
          </Button>
          
          <Button
            size="small"
            variant="outlined"
            color="secondary"
            {...listeners} {...attributes}
          >
            <DragVertical />
          </Button>
        </TableCell>
      </TableRow>
      { showEdit && <DialogEditButton onClose={() => setShowEdit(undefined)} heroImageDetails={props.heroImageDetails} button={showEdit} /> }

      <Dialog
        fullWidth
        open={showDeleteModal}
        maxWidth="sm"
        scroll="body"
        onClose={() => setShowDeleteModal(false)}
        TransitionComponent={Transition}
        onBackdropClick={() => setShowDeleteModal(false)}
      >
        <DialogContent
          sx={{
            pb: 6,
            px: { xs: 8, sm: 15 },
            pt: { xs: 8, sm: 12.5 },
            position: "relative",
          }}
        >
          <Grid container justifyContent={"center"}>
            <Grid>
              <Typography variant="body1">
                Are you sure you want to delete data?
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: "center" }}
        >
          <Button variant="contained" sx={{ mr: 2 }} onClick={deleteHeroButtonHandler}>
            Submit
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setShowDeleteModal(false)}
          >
            Discard
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}

export default  ButtonsTableRow