import { ReactNode, useEffect, useState } from "react";
import { Provider } from "react-redux";
import { store } from "./store";
import { CacheProvider } from "@emotion/react";
import type { EmotionCache } from "@emotion/cache";
import { AuthProvider } from "./context/AuthContext";
import {
  SettingsConsumer,
  SettingsProvider,
} from "./components/@core/context/settingsContext";
import ReactHotToast from "./components/@core/styles/libs/react-hot-toast";
import { defaultACLObj } from "./configs/acl";

import ThemeComponent from "./components/@core/theme/ThemeComponent";
import WindowWrapper from "./components/@core/components/window-wrapper";
import GuestGuard from "./components/@core/components/auth/GuestGuard";
import AuthGuard from "./components/@core/components/auth/AuthGuard";
import Spinner from "./components/@core/components/spinner";
import AclGuard from "./components/@core/components/auth/AclGuard";
import UserLayout from "./components/layouts/UserLayout";

import { Toaster } from "react-hot-toast";
import { createEmotionCache } from "./components/@core/utils/create-emotion-cache";

import Router from "./routing";

import "./App.css";
import { logIn } from "./store/apps/authSlice";
import { useDispatch } from "react-redux";
import axiosInstance from "./axios/axiosInstance";
import { PostSignInRequestType } from "./service";

type GuardProps = {
  authGuard: boolean;
  guestGuard: boolean;
  children: ReactNode;
};

type ExtendedAppProps = any & {
  Component: any;
  emotionCache: EmotionCache;
};

const clientSideEmotionCache = createEmotionCache();

// const Guard = ({ children, authGuard, guestGuard }: GuardProps) => {
//   if (guestGuard) {
//     return <GuestGuard fallback={<Spinner />}>{children}</GuestGuard>;
//   } else if (!guestGuard && !authGuard) {
//     return <>{children}</>;
//   } else {
//     return <AuthGuard fallback={<Spinner />}>{children}</AuthGuard>;
//   }
// };

function App(props: ExtendedAppProps) {
  const dispatch = useDispatch();

  const [loadApp, setLoadApp] = useState(false)

  const { Component, emotionCache = clientSideEmotionCache, pageProps } = props;

  useEffect(() => {
    if(localStorage.getItem('softAdminToken')){
      axiosInstance.post<PostSignInRequestType>(`api/BackOfficeAccount/refreshToken`, { token: localStorage.getItem('softAdminToken') })
        .then((response)=>{
          dispatch(logIn(response.data));
          setLoadApp(true)
        })
        .catch(() => { 
          setLoadApp(true)
        })
    } else {
      setLoadApp(true)
    }

  }, []);

  return loadApp ?
    <div>
      <CacheProvider value={emotionCache}>
        <AuthProvider>
          <SettingsProvider>
            <SettingsConsumer>
              {({ settings }) => {
                return (
                  <ThemeComponent settings={settings}>
                    <WindowWrapper>
                      <Router />
                    </WindowWrapper>
                    <ReactHotToast>
                      <Toaster
                        position={settings.toastPosition}
                        toastOptions={{ className: "react-hot-toast" }}
                      />
                    </ReactHotToast>
                  </ThemeComponent>
                );
              }}
            </SettingsConsumer>
          </SettingsProvider>
        </AuthProvider>
      </CacheProvider>
    </div>
   : null
}

export default App;
