import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import * as Api from "../../../service";
import { AxiosError } from "axios";

export interface SeoSliceState {
  readonly seoData?: Api.SeoData | null;
  readonly isLoading: boolean;
  readonly pageSeoData: Api.PageSeoData;
}

const initialState: SeoSliceState = {
  seoData: null,
  isLoading: false,
  // @ts-ignore
  pageSeoData: null,
};

export const getSeoDetails = createAsyncThunk(
  "backOffice/GetSeoDetails",
  async () => {
    try {
      const response = await Api.getSeoDetailsRequest();
      return response.data;
    } catch (err) {
      const error = err;
      throw error;
    }
  }
);

export const postUpdateSeoDetailsForPage = createAsyncThunk(
  "/backOffice/UpdateSeoDetailsForPage",
  async (params: Api.postUpdateSeoDetailsForPageRequestParams, thunkApi) => {
    try {
      const response = await Api.postUpdateSeoDetailsForPageRequest(params);
      return response.data;
    } catch (err) {
      const error = err;
      if (error) {
        return thunkApi.rejectWithValue((error as AxiosError).response?.data);
      }
      throw err;
    }
  }
);

export const postUpdateSeoImage = createAsyncThunk(
  `/backOffice/UpdateSeoDetailsImage`,
  async (params: Api.postUpdateSEOImageRequestParams, thunkApi) => {
    try {
      const response = await Api.postUpdateSeoImage(params);
      return response.data;
    } catch (err) {
      const error = err;
      if (error) {
        return thunkApi.rejectWithValue((error as AxiosError).response?.data);
      }
      throw err;
    }
  }
);

export const seoSlice = createSlice({
  name: "seo",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(getSeoDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSeoDetails.fulfilled, (state, action) => {
        state.isLoading = false;
        state.seoData = action.payload;
      })
      .addCase(getSeoDetails.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(postUpdateSeoDetailsForPage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postUpdateSeoDetailsForPage.fulfilled, (state, action) => {
        state.isLoading = false;
        state.seoData = action.payload;
      })
      .addCase(postUpdateSeoDetailsForPage.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(postUpdateSeoImage.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postUpdateSeoImage.fulfilled, (state, action) => {
        state.isLoading = false;
      })
      .addCase(postUpdateSeoImage.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export default seoSlice.reducer;
