import axiosInstance from "../../axios/axiosInstance";
import { UpdateHeroImageTextParams } from "./params";


export const getHeroImageDetails = () => {
  return axiosInstance.get(`api/BackOfficeStaticData/GetHeroTextImage`);
};

export const updateHeroImageText = (params: UpdateHeroImageTextParams) => {
  return axiosInstance.post<UpdateHeroImageTextParams>(
    `api/BackOfficeStaticData/UpdateHeroTextImage`,
    params
  );
};


