import { ContentDataType } from "./types";
import { UpdateContentDataParams } from './params';
import axiosInstance from "../../axios/axiosInstance";


export const getContentData = (contentDataType: ContentDataType) => {
  const params = new URLSearchParams([["type", contentDataType.toString()]]);
  return axiosInstance.get(`api/BackOfficeStaticData/GetStaticData`, {
    params,
  });
};

export const updateContentData = (params: UpdateContentDataParams) => {
  return axiosInstance.post<UpdateContentDataParams>(
    `api/BackOfficeStaticData/UpdateOrCreateStaticData`,
    params.body
  );
};
