import React from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { useTheme } from "@mui/material/styles";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import CustomChip from "../../../../../components/@core/components/mui/chip";
import { styled } from "@mui/material/styles";
import { AvatarProps } from "@mui/material/Avatar";

// ** Third Party Imports
import { ApexOptions } from "apexcharts";

// ** Custom components Imports
import ReactApexcharts from "../../../../../components/@core/components/react-apexcharts";

// ** Util Import
import { hexToRGBA } from "../../../../../components/@core/utils/hex-to-rgba";
import { SvgIconProps } from "@mui/material";

// ** Custom components Imports
import CustomAvatar from "../../../../../components/@core/components/mui/avatar";

const series = [
  {
    name: "Earning",
    data: [120, 200, 150, 120],
  },
  {
    name: "Expense",
    data: [72, 120, 50, 65],
  },
];

interface cardStatisticsPropType {
  name: string;
  value: number;
  Icon: React.ReactNode;
  iconColor:
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning"
    | undefined;
  minWidth?: number;
}

const Avatar = styled(CustomAvatar)<AvatarProps>(({ theme }) => ({
  width: 40,
  height: 40,
  marginRight: theme.spacing(4),
}));

const CardStatisticsBarChart = ({
  name,
  value,
  Icon,
  iconColor,
  minWidth,
}: cardStatisticsPropType) => {
  // ** Hook
  const theme = useTheme();

  // const options: ApexOptions = {
  //   chart: {
  //     parentHeightOffset: 0,
  //     toolbar: { show: false },
  //   },
  //   grid: {
  //     padding: {
  //       top: -15,
  //       left: -14,
  //       right: -4,
  //       bottom: -15,
  //     },
  //     yaxis: {
  //       lines: { show: false },
  //     },
  //   },
  //   legend: { show: false },
  //   dataLabels: { enabled: false },
  //   colors: [
  //     hexToRGBA(theme.palette.primary.main, 1),
  //     hexToRGBA(theme.palette.warning.main, 1),
  //   ],
  //   plotOptions: {
  //     bar: {
  //       borderRadius: 5,
  //       columnWidth: "48%",
  //       // startingShape: "rounded",
  //     },
  //   },
  //   states: {
  //     hover: {
  //       filter: { type: "none" },
  //     },
  //     active: {
  //       filter: { type: "none" },
  //     },
  //   },
  //   xaxis: {
  //     labels: { show: false },
  //     axisTicks: { show: false },
  //     axisBorder: { show: false },
  //     categories: ["Jan", "Feb", "Mar", "Apr"],
  //   },
  //   yaxis: {
  //     labels: { show: false },
  //   },
  // };

  return (
    <Card sx={{ minWidth: `${minWidth}px` }}>
      <CardContent sx={{ display: "flex", alignItems: "center" }}>
        <Avatar skin="light" color={iconColor} variant="rounded">
          {Icon}
        </Avatar>
        <Box>
          <Typography variant="h6" sx={{ mr: 1.5 }}>
            {value}
          </Typography>
          {/* <Typography variant="subtitle2" sx={{ color: "error.main" }}>
            -22%
          </Typography> */}
          <Typography variant="body2">{name}</Typography>
          {/* <ReactApexcharts
          type="bar"
          height={108}
          options={options}
          series={series}
        /> */}
        </Box>
      </CardContent>
    </Card>
  );
};

export default CardStatisticsBarChart;
