import TableDrawer from "./index";
import { drawerProps } from "./types";
import { useEffect, useState } from "react";
import DialogAddCard from "../../../../utils/views/pages/dialog-examples/DialogAddCard";

const DrawerWrapper = ({ data, open, onClose }: drawerProps) => {
  const [talentId, setTalentId] = useState<string | undefined>(undefined);
  const [talentEdit, setTalentEdit] = useState<boolean>(false);
  const [openTable, setOpenTable] = useState<boolean>(false);

  useEffect(() => {
    setTalentEdit(true);
  }, [talentId]);

  useEffect(() => {
    if (talentEdit === false) setTalentId(undefined);
  }, [talentEdit]);

  useEffect(() => {
    setOpenTable(open);

    return () => {
      setOpenTable(false);
    };
  }, [open]);

  return (
    <>
      {talentId ? (
        <DialogAddCard
          modal={talentEdit}
          setModal={setTalentEdit}
          dataID={talentId}
          setDataID={setTalentId}
          backBtn={true}
        />
      ) : (
        <TableDrawer
          data={data}
          open={openTable}
          onClose={onClose}
          setTalentId={setTalentId}
        />
      )}
    </>
  );
};

export default DrawerWrapper;
