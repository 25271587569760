// ** Next Import
// import Link from 'next/link'

// ** MUI Imports
import Card from "@mui/material/Card";
import Select from "@mui/material/Select";
import Button from "@mui/material/Button";
import Switch from "@mui/material/Switch";
import MenuItem from "@mui/material/MenuItem";
import { styled } from "@mui/material/styles";
import InputLabel from "@mui/material/InputLabel";
import Box, { BoxProps } from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";

// ** store
import { useAppDispatch } from "../../../../../store";
import { postGenerateTokenForViewAsUser } from "../../../../../store/apps/authSlice";

// ** Icons Imports
import SendOutline from "mdi-material-ui/SendOutline";
import CurrencyUsd from "mdi-material-ui/CurrencyUsd";
import { CompanyValues } from "../../../../../types/apps/companyTypes";

interface Props {
  id: string | undefined;
  toggleAddPaymentDrawer: () => void;
  toggleSendInvoiceDrawer: () => void;
  toggleIsActiveOrIsVerified: (type: number, value: any) => void;
  values: CompanyValues;
  setFieldValue: (field: string, value: any) => void;
}

const OptionsWrapper = styled(Box)<BoxProps>(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
}));

const EditActions = ({ id, values, setFieldValue, toggleSendInvoiceDrawer, toggleAddPaymentDrawer, toggleIsActiveOrIsVerified }: Props) => {
  const dispatch = useAppDispatch();
  const handleViewAs = () => {
    if (!id) return;
    dispatch(postGenerateTokenForViewAsUser(id))
      .unwrap()
      .then((res) => {
        window.location.href = res.data;
      });
  };

  return (
    <Box>
      <Card sx={{ mb: 4 }}>
        <CardContent>
          <Button fullWidth color="primary" variant="outlined" sx={{ mb: 3.5 }} type="submit">
            Save
          </Button>
          <Button fullWidth color="warning" variant="outlined" sx={{ mb: 3.5 }} onClick={handleViewAs}>
            View As
          </Button>
        </CardContent>
      </Card>
      <OptionsWrapper sx={{ mb: 1 }}>
        <InputLabel
          htmlFor="Active"
          sx={{
            cursor: "pointer",
            fontSize: "0.875rem",
            color: "text.secondary",
          }}
        >
          Activate
        </InputLabel>
        <Switch
          checked={values.isActive}
          onChange={(e) => {
            toggleIsActiveOrIsVerified(2, e.target.checked);
            setFieldValue("isActive", e.target.checked);
          }}
          id="Active"
        />
      </OptionsWrapper>

      <OptionsWrapper sx={{ mb: 1 }}>
        <InputLabel
          htmlFor="verification"
          sx={{
            cursor: "pointer",
            fontSize: "0.875rem",
            color: "text.secondary",
          }}
        >
          Verify
        </InputLabel>
        <Switch
          checked={values.isVerified}
          onChange={(e) => {
            toggleIsActiveOrIsVerified(1, e.target.checked);
            setFieldValue("isVerified", e.target.checked);
          }}
          id="verification"
        />
      </OptionsWrapper>
 
      <OptionsWrapper sx={{ mb: 1 }}>
        <InputLabel
          htmlFor="verification"
          sx={{
            cursor: "pointer",
            fontSize: "0.875rem",
            color: "text.secondary",
          }}
        >
          Company Need To Be Claimed
        </InputLabel>
        <Switch
          checked={values.isClaimingNecessary}
          onChange={(e) => {
            toggleIsActiveOrIsVerified(3, e.target.checked);
            setFieldValue("isClaimingNecessary", e.target.checked);
            setFieldValue("isVerified", e.target.checked);
          }}
          id="verification"
        />
      </OptionsWrapper>
    </Box>
  );
};

export default EditActions;
