import { createSlice, PayloadAction, createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import { toast } from "react-hot-toast";
import * as Api from "../../../service";

export interface AdminSliceState {
  readonly isLoading: boolean;
  readonly vacancies: Api.getSearchVacancyDataType | null;
  readonly dictionaries: Api.DictionaryType[];
  readonly submittedFilteredObject: Api.getSearchVacancyType;
  readonly submittedFilteredTechnologies:
    | Api.DictionaryType["dictionaryList"]
    | undefined;
  readonly submittedPage: number;
  readonly company: Api.CompanyVacancyType | null;
  readonly selectedItems: string[];
}

const initialState: AdminSliceState = {
  isLoading: false,
  vacancies: null,
  submittedFilteredObject: { PageNumber: 1, PageSize: 10 },
  dictionaries: [],
  submittedFilteredTechnologies: [],
  submittedPage: 1,
  company: null,
  selectedItems: [],
};

export const getAllDictionaries = createAsyncThunk<Api.DictionaryType[]>(
  "companies/getDictionaries",
  async () => {
    const response = await Api.getAllDictionariesRequest();
    return response.data;
  }
);

export const getSearchVacancy = createAsyncThunk<
  Api.getSearchVacancyDataType,
  Api.getSearchVacancyType | null
>(
  "backOffice/getSearchVacancy",
  async (params: Api.getSearchVacancyType | null) => {
    const response = await Api.getSearchVacancyRequest(params);
    return response.data;
  }
);

export const getCompanyVacancy = createAsyncThunk<
  Api.CompanyVacancyType,
  string
>("backOffice/getCompanyVacancy", async (params: string) => {
  const response = await Api.getCompanyVacancyRequest(params);
  return response.data;
});

export const deleteCompanyVacancy = createAsyncThunk<
  Api.CompanyVacancyType,
  string
>("backOffice/DeleteCompanyVacancy", async (params: string) => {
  const response = await Api.postDeleteCompanyVacancyRequest(params);
  return response.data;
});

export const postUpdateCompanyVacancy = createAsyncThunk<
  Api.CompanyVacancyType,
  Api.postUpdateCompanyVacancyParams
>("backOffice/postUpdateCompanyVacancy", async (params) => {
  const response = await Api.postUpdateCompanyVacancyRequest(
    params.obj,
    params.vacancyId
  );
  return response.data;
});

export const postAddCompanyVacancy = createAsyncThunk<
  Api.CompanyVacancyType,
  Api.postAddCompanyVacancyParams
>("backOffice/postAddCompanyVacancy", async (params, thunkApi) => {
  try {
    const response = await Api.postAddCompanyVacancyRequest(
      params.obj,
      params.companyId
    );
    return response.data;
  } catch (err) {
    const error = err;
    if (error) {
      return thunkApi.rejectWithValue((error as AxiosError).response?.data);
    }

    throw error;
  }
});

export const putRenewAllExpiredVacancies = createAsyncThunk(
  "BackOfficeVacancy/RenewAllExpiredVacancies",
  async (deadline: string) => {
    const response = await Api.putRenewAllExpiredVacanciesRequest({ deadline });
    return response.data;
  }
);
export const putRenewExpiredVacancies = createAsyncThunk(
  "BackOfficeVacancy/RenewExpiredVacancies",
  async (object: Api.RenewExpiredVacanciesParam) => {
    const response = await Api.putRenewExpiredVacanciesRequest(object);
    return response.data;
  }
);

export const postDeleteCompanyVacancies = createAsyncThunk(
  "BackOfficeVacancy/DeleteCompanyVacancies",
  async (arr: string[]) => {
    const response = await Api.postDeleteCompanyVacancies(arr);
    return response.data;
  }
);

export const vacancySlice = createSlice({
  name: "vacancy",
  initialState,
  reducers: {
    setSubmittedFilterObject: (
      state,
      action: PayloadAction<Api.getSearchVacancyType>
    ) => {
      state.submittedFilteredObject = action.payload;
    },
    setSubmittedPage: (
      state,
      action: PayloadAction<{
        submittedPage: number;
      }>
    ) => {
      state.submittedPage = action.payload.submittedPage;
      state.submittedFilteredObject = {
        ...state.submittedFilteredObject,
        PageNumber: action.payload.submittedPage,
      };
    },
    setSelectedItems: (state, action: PayloadAction<{ ids: string[] }>) => {
      state.selectedItems = action.payload.ids;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSearchVacancy.fulfilled, (state, action) => {
        state.isLoading = false;
        state.vacancies = action.payload;
      })
      .addCase(getSearchVacancy.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSearchVacancy.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(postUpdateCompanyVacancy.fulfilled, () => {
        toast.success("Vacancy updated successfully");
      })
      .addCase(postUpdateCompanyVacancy.rejected, () => {
        toast.error("Something went wrong!");
      })
      .addCase(postAddCompanyVacancy.fulfilled, () => {
        toast.success("Vacancy added successfully");
      })
      .addCase(postAddCompanyVacancy.rejected, (data, { payload }) => {
        if (payload) {
          for (const key in payload) {
            if (payload.hasOwnProperty(key)) {
              // @ts-ignore
              toast.error(payload[key]);
              return;
            }
          }
        }
      })
      .addCase(deleteCompanyVacancy.fulfilled, () => {
        toast.success("Vacancy deleted");
      })
      .addCase(deleteCompanyVacancy.rejected, () => {
        toast.error("Something went wrong!");
      })
      // .addCase(getAllDictionaries.pending, (state) => {
      //   state.isLoading = true;
      // })
      .addCase(getAllDictionaries.fulfilled, (state, action) => {
        // state.isLoading = false;
        state.dictionaries = action.payload;
      })
      .addCase(getAllDictionaries.rejected, (state) => {
        // state.isLoading = false;
      })
      .addCase(getCompanyVacancy.fulfilled, (state, action) => {
        state.isLoading = false;
        state.company = action.payload;
      })
      .addCase(getCompanyVacancy.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getCompanyVacancy.rejected, (state) => {
        state.isLoading = false;
      });
  },
});

export const { setSubmittedPage, setSubmittedFilterObject, setSelectedItems } =
  vacancySlice.actions;
export default vacancySlice.reducer;
