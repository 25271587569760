import CardStatisticsBarChart from "../../../../utils/views/ui/cards/statistics/CardStatisticsBarChart";
import Grid from "@mui/material/Grid";

// ** Icon imports
import Poll from "mdi-material-ui/Poll";
import TrendingUp from "mdi-material-ui/TrendingUp";
import CurrencyUsd from "mdi-material-ui/CurrencyUsd";
import AccountOutline from "mdi-material-ui/AccountOutline";
import Link from "mdi-material-ui/Link";
import CartPlus from "mdi-material-ui/CartPlus";
import WalletGiftcard from "mdi-material-ui/WalletGiftcard";
import BriefcaseOutline from "mdi-material-ui/BriefcaseOutline";

import "./index.sass";

type props = {
  vacancyAppliedStatistics: number;
  totalVisitStatistics: number;
};

const iconArray: {
  Icon: React.ReactNode;
  iconColor: "primary" | "secondary" | "error" | "info" | "success" | "warning" | undefined;
}[] = [
  { Icon: <BriefcaseOutline />, iconColor: "warning" },
  { Icon: <AccountOutline />, iconColor: "success" },
  { Icon: <CurrencyUsd />, iconColor: "primary" },
  { Icon: <AccountOutline />, iconColor: "primary" },
];

const StatisticsComponent = ({ vacancyAppliedStatistics, totalVisitStatistics }: props) => {
  return (
    <Grid container sx={{ justifyContent: "center", gap: "20px", mt: 5, mb: 5 }}>
      <Grid item sx={{ minWidth: "250px" }}>
        <CardStatisticsBarChart name={"Vacancy Applications"} value={vacancyAppliedStatistics} Icon={iconArray[0].Icon} iconColor={iconArray[0].iconColor} minWidth={250} />
      </Grid>
      <Grid item sx={{ minWidth: "250px" }}>
        <CardStatisticsBarChart name={"Total Visits"} value={totalVisitStatistics} Icon={iconArray[1].Icon} iconColor={iconArray[1].iconColor} minWidth={250} />
      </Grid>
    </Grid>
  );
};

export default StatisticsComponent;
