// ** React Imports
import React from "react";
import { ChangeEvent } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";

// ** Icons Imports
import Close from "mdi-material-ui/Close";
import Magnify from "mdi-material-ui/Magnify";
import CompanyFilter from "./CompanyFilter";
import VacancyFilter from "./VacancyFilter";
import Button from "@mui/material/Button";
import ExportCSV from "../../export/ExportCSV";
import zIndex from "@mui/material/styles/zIndex";

interface Props {
  value: string;
  clearSearch: () => void;
  onChange: (e: ChangeEvent) => void;
  componentSection: string;
  AddNewData: React.FunctionComponent<{}>;
  handleRenewModal: () => void;
  handleDeleteData: () => void;
  attributes?: boolean;
  exportFilter?: {
    dateFilter?: {
      dateRange: number;
      startDate?: Date;
      endDate?: Date;
    };
    searchTerm?: string;
    selectedCheckboxes?: string[];
  };
  renewBtn?: boolean;
}

const QuickSearchToolbar = ({ AddNewData, attributes, exportFilter, renewBtn, ...props }: Props) => {
  const deleteBtn = props.componentSection === "vacancies" && exportFilter?.selectedCheckboxes && exportFilter?.selectedCheckboxes.length > 0;
  return (
    <>
      <Box
        sx={{
          p: 2,
          pb: 0,
          display: "flex",
          flexWrap: "wrap",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {/* <GridToolbarFilterButton /> */}
          {/* { props.componentSection && <CompanyFilter componentSection={props.componentSection} /> } */}
          {props.componentSection === "vacancies" ? (
            <>
              {AddNewData && <AddNewData />}
              <VacancyFilter componentSection={props.componentSection} />
            </>
          ) : (
            <>{AddNewData ? <AddNewData /> : <>{props.componentSection && <CompanyFilter componentSection={props.componentSection} />}</>}</>
          )}
          <ExportCSV
            componentSection={props.componentSection}
            attributes={attributes}
            exportFilter={{ ...exportFilter, searchTerm: props.value, selectedCheckboxes: exportFilter?.selectedCheckboxes }}
          />
          <Box
            sx={
              {
                // position: "absolute",
                // right: "0",
                // zIndex: "20",
              }
            }
          >
            {renewBtn && <Button onClick={props.handleRenewModal}>Renew</Button>}
            {deleteBtn && <Button onClick={props.handleDeleteData}>Delete</Button>}
          </Box>
        </Box>
        {props.componentSection !== "vacancies" && (
          <TextField
            variant="standard"
            value={props.value}
            onChange={props.onChange}
            placeholder="Search…"
            InputProps={{
              startAdornment: <Magnify fontSize="small" />,
              endAdornment: (
                <IconButton size="small" title="Clear" aria-label="Clear" onClick={props.clearSearch}>
                  <Close fontSize="small" />
                </IconButton>
              ),
            }}
            sx={{
              width: {
                xs: 1,
                sm: "auto",
              },
              m: (theme) => theme.spacing(1, 0.5, 1.5),
              "& .MuiSvgIcon-root": {
                mr: 0.5,
              },
              "& .MuiInput-underline:before": {
                borderBottom: 1,
                borderColor: "divider",
              },
            }}
          />
        )}
      </Box>
    </>
  );
};

export default React.memo(QuickSearchToolbar);
