import React, { useState, useEffect, forwardRef, ReactElement, Ref } from "react";
import { postAddCompanyVacancyParams, postUpdateCompanyVacancyParam, postUpdateCompanyVacancyParams } from "../../service";
// import { DictionaryTypeEnum } from "../../types/enums";
import { convertFromRaw, convertToRaw, EditorState } from "draft-js";
import { RootState, useAppDispatch } from "../../store";
import { Route, Link, Routes, useParams } from "react-router-dom";
import { getCompanyVacancy, postAddCompanyVacancy, postUpdateCompanyVacancy } from "../../store/apps/vacanciesSlice";

import Grid from "@mui/material/Grid";
import Switch from "@mui/material/Switch";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import FormControlLabel from "@mui/material/FormControlLabel";
import DatePicker from "react-datepicker";

// import MultiChoiceField from "./MultiChoiceField";

import DatePickerWrapper from "../../components/@core/styles/libs/react-datepicker";
import MultiChoiceField from "../vacancies/MultiChoiceField";
import { CurrencyEnum, DictionaryTypeEnum, LanguageEnum } from "../../types/enums";
import ContentDataText from "../../utils/contentDataText";
import { getAllDictionaries } from "../../store/apps/companiesSlice";
import CompanySelect from "../vacancies/CompanySelect";

const CustomInput = forwardRef(
  (
    props: {
      label: string;
    },
    ref
  ) => {
    return <TextField inputRef={ref} fullWidth {...props} />;
  }
);

const contentTemplate = `{"blocks":[{"text":"","type":"unstyled","depth":0,"inlineStyleRanges":[],"entityRanges":[],"data":{}}],"entityMap":{}}`;

type propTypes = {
  isAdd?: boolean;
};

const EditVacancy = ({ isAdd = false }: propTypes) => {
  const [vacancyObject, setVacancyObject] = useState<postUpdateCompanyVacancyParam>();

  const [editorStateEn, setEditorStateEn] = useState(() => EditorState.createEmpty());
  const [editorStateGe, setEditorStateGe] = useState(() => EditorState.createEmpty());

  const dispatch = useAppDispatch();

  const params = useParams();

  useEffect(() => {
    if (params.id) handleGetVacancy();
    if (isAdd) {
      setVacancyObject({
        title: "",
        titleGE: "",
        isDraft: false,
        workLocation: [],
        technology: [],
        employmentType: [],
        jobFunction: [],
        city: [],
        vacancyDescriptionSections: [],
        deadline: new Date(),
        vacancyStatus: 0,
        isActive: false,
        elementContext: JSON.stringify(convertToRaw(EditorState.createEmpty().getCurrentContent())),
        elementContextGE: JSON.stringify(convertToRaw(EditorState.createEmpty().getCurrentContent())),
        language: [],
        currency: 0,
        amountFrom: null,
        amountTo: null,
        displaySalary: false,
      });
    }
  }, [params.id, isAdd]);

  const handleStateUpdate = (key: string, value: string | boolean | Date | null | string[] | { id: string; name: string }) => {
    if (!vacancyObject) return;
    setVacancyObject(() => {
      return {
        ...vacancyObject,
        [key]: value,
      };
    });
  };

  const handleGetVacancy = () => {
    // @ts-ignore
    dispatch(getCompanyVacancy(params.id))
      .unwrap()
      .then((res) => {
        const response = {
          ...res,
          data: {
            ...res.data,
            isActive: !res.data.isDeactivated,
            city: res.data.city.map((el) => el.id),
            employmentType: res.data.employmentType.map((el) => el.id),
            jobFunction: res.data.jobFunction.map((el) => el.id),
            technology: res.data.technology.map((el) => el.id),
            workLocation: res.data.workLocation.map((el) => el.id),
            country: res.data.country.map((el) => el.id),
            experience: res.data.experience.map((el) => el.id),
            field: null,
          },
        };

        const enContent = JSON.parse(response?.data.elementContext || contentTemplate);
        const contentStateEn = convertFromRaw(enContent);
        setEditorStateEn(EditorState.createWithContent(contentStateEn));

        const geContent = JSON.parse(response?.data.elementContextGE || contentTemplate);
        const contentStateGe = convertFromRaw(geContent);
        setEditorStateGe(EditorState.createWithContent(contentStateGe));
        dispatch(getAllDictionaries());

        setVacancyObject(response.data);
      });
  };

  const handleSubmit = () => {
    if (isAdd) {
      dispatch(
        postAddCompanyVacancy({
          obj: vacancyObject,
          companyId: vacancyObject?.companyId,
        } as postAddCompanyVacancyParams)
      );
    } else {
      dispatch(
        postUpdateCompanyVacancy({
          obj: vacancyObject,
          vacancyId: params.id,
        } as postUpdateCompanyVacancyParams)
      );
    }
  };

  const handleReset = () => {
    handleGetVacancy();
  };

  const handleDate = (date: Date) => {
    const chosenYear = new Date(date).getFullYear();
    const chosenMonth = new Date(date).getMonth();
    const chosenDay = new Date(date).getDay();
    const chosenDate = new Date(`${chosenYear}-${chosenMonth}-${chosenDay}`);
    const currentYear = new Date().getFullYear();
    const currentMonth = new Date().getMonth();
    const currentDay = new Date().getDay();
    const dateNow = new Date(`${currentYear}-${currentMonth}-${currentDay}`);
    if (chosenDate >= dateNow) handleStateUpdate("deadline", date);
  };

  return (
    <>
      {vacancyObject && (
        <>
          <TextField
            fullWidth
            name="title"
            value={vacancyObject.title}
            autoComplete="off"
            label="Title (EN)"
            // onBlur={handleBlur}
            onChange={(e) => handleStateUpdate("title", e.target.value)}
            // onFocus={(e) => setFocus(e.target.name as Focused)}
            sx={{ mb: 3 }}
          />
          <TextField
            fullWidth
            name="titleGE"
            value={vacancyObject.titleGE}
            autoComplete="off"
            label="Title (GE)"
            // onBlur={handleBlur}
            onChange={(e) => handleStateUpdate("titleGE", e.target.value)}
            // onFocus={(e) => setFocus(e.target.name as Focused)}
            sx={{ mb: 3 }}
          />
          {isAdd && <CompanySelect handleStateUpdate={handleStateUpdate} modelObject={vacancyObject} name={"companyId"} title={"Company"} enumType={DictionaryTypeEnum.Technology} />}

          <Grid container spacing={6} rowSpacing={2}>
            <Grid item xs={12} sx={{ pt: (theme) => `${theme.spacing(5)} !important` }}>
              <Grid container spacing={6}>
                <MultiChoiceField handleStateUpdate={handleStateUpdate} modelObject={vacancyObject} name={"technology"} title={"Technologies"} enumType={DictionaryTypeEnum.Technology} />
                <Grid item xs={3}>
                  <FormControlLabel
                    control={<Switch checked={vacancyObject?.isActive} onChange={(e) => handleStateUpdate("isActive", e.target.checked)} />}
                    label={vacancyObject?.isActive ? "Active" : "Not Active"}
                    sx={{
                      "& .MuiTypography-root": {
                        color: vacancyObject?.isActive === false ? "text.secondary" : "text.error",
                      },
                    }}
                  />
                </Grid>
                <Grid item xs={3}>
                  <FormControlLabel
                    control={<Switch checked={vacancyObject?.isDraft} onChange={(e) => handleStateUpdate("isDraft", e.target.checked)} />}
                    label={vacancyObject?.isDraft ? "Draft" : "Real"}
                    sx={{
                      "& .MuiTypography-root": {
                        color: vacancyObject?.isDraft ? "text.secondary" : "text.error",
                      },
                    }}
                  />
                </Grid>
                <MultiChoiceField handleStateUpdate={handleStateUpdate} modelObject={vacancyObject} name={"employmentType"} title={"Employment Type"} enumType={DictionaryTypeEnum.EmploymentType} />
                <MultiChoiceField handleStateUpdate={handleStateUpdate} modelObject={vacancyObject} name={"jobFunction"} title={"Position"} enumType={DictionaryTypeEnum.JobFunction} />
                <MultiChoiceField handleStateUpdate={handleStateUpdate} modelObject={vacancyObject} name={"country"} title={"Country"} enumType={DictionaryTypeEnum.Country} />
                <MultiChoiceField handleStateUpdate={handleStateUpdate} modelObject={vacancyObject} name={"city"} title={"City"} enumType={DictionaryTypeEnum.City} />
                <MultiChoiceField handleStateUpdate={handleStateUpdate} modelObject={vacancyObject} name={"workLocation"} title={"Work Location"} enumType={DictionaryTypeEnum.WorkLocation} />
                <MultiChoiceField
                  handleStateUpdate={handleStateUpdate}
                  modelObject={vacancyObject}
                  name={"language"}
                  title={"Language"}
                  customOptions={[
                    { id: LanguageEnum.English, name: "English" },
                    { id: LanguageEnum.Georgian, name: "Georgian" },
                    { id: LanguageEnum.Chinese, name: "Chinese" },
                    { id: LanguageEnum.French, name: "French" },
                    { id: LanguageEnum.Russian, name: "Russian" },
                    { id: LanguageEnum.Spanish, name: "Spanish" },
                    { id: LanguageEnum.Turkish, name: "Turkish" },
                  ]}
                />
                <MultiChoiceField handleStateUpdate={handleStateUpdate} modelObject={vacancyObject} name={"experience"} title={"Experience"} enumType={DictionaryTypeEnum.Experience} />

                <Grid item xs={6}>
                  <DatePickerWrapper>
                    <DatePicker
                      selected={vacancyObject.deadline ? new Date(vacancyObject.deadline) : null}
                      showYearDropdown
                      showMonthDropdown
                      id="account-settings-date"
                      placeholderText="MM-DD-YYYY"
                      isClearable
                      customInput={<CustomInput label="Deadline" />}
                      onChange={handleDate}
                    />
                  </DatePickerWrapper>
                </Grid>
              </Grid>
              <Grid item spacing={6} mb={3}>
                <FormControlLabel
                  control={<Switch checked={vacancyObject?.displaySalary} onChange={(e) => handleStateUpdate("displaySalary", e.target.checked)} />}
                  label={vacancyObject?.displaySalary ? "Show Salary" : "Hide Salary"}
                  sx={{
                    "& .MuiTypography-root": {
                      color: vacancyObject?.displaySalary === false ? "text.secondary" : "text.error",
                    },
                  }}
                />
              </Grid>
              {vacancyObject.displaySalary && (
                <Grid container spacing={6}>
                  <MultiChoiceField
                    // @ts-ignore
                    handleStateUpdate={(_, value) => setVacancyObject((prevVal) => ({ ...prevVal, currency: value[1] }))}
                    modelObject={vacancyObject}
                    name={"currency"}
                    title={"Currency"}
                    singleChoice
                    customOptions={[
                      { id: CurrencyEnum.Dollar, name: "$" },
                      { id: CurrencyEnum.Euro, name: "€" },
                      { id: CurrencyEnum.Gel, name: "₾" },
                      { id: CurrencyEnum.PoundSterling, name: "£" },
                    ]}
                    xs={4}
                  />
                  <Grid xs={4} item>
                    <TextField
                      fullWidth
                      name="amountFrom"
                      value={vacancyObject.amountFrom}
                      autoComplete="off"
                      label="Salary min"
                      // onBlur={handleBlur}
                      onChange={(e) => handleStateUpdate("amountFrom", e.target.value)}
                      // onFocus={(e) => setFocus(e.target.name as Focused)}
                    />
                  </Grid>
                  <Grid xs={4} item>
                    <TextField
                      fullWidth
                      name="amountTo"
                      value={vacancyObject.amountTo}
                      autoComplete="off"
                      label="Salary max"
                      // onBlur={handleBlur}
                      onChange={(e) => handleStateUpdate("amountTo", e.target.value)}
                      // onFocus={(e) => setFocus(e.target.name as Focused)}
                    />
                  </Grid>
                </Grid>
              )}
              <Typography
                sx={{
                  mb: 6,
                  padding: "20px 0px",
                  fontWeight: 600,
                  whiteSpace: "nowrap",
                }}
              >
                Description (EN)
              </Typography>
              <Grid>
                <ContentDataText
                  editorState={editorStateEn}
                  onEditorStateChange={setEditorStateEn}
                  onChange={(e: any) => handleStateUpdate("elementContext", JSON.stringify(convertToRaw(editorStateEn.getCurrentContent())))}
                />
              </Grid>
              <Typography
                sx={{
                  mb: 6,
                  padding: "20px 0px",
                  fontWeight: 600,
                  whiteSpace: "nowrap",
                }}
              >
                Description (GE)
              </Typography>
              <Grid>
                <ContentDataText
                  editorState={editorStateGe}
                  onEditorStateChange={setEditorStateGe}
                  onChange={(e: any) => handleStateUpdate("elementContextGE", JSON.stringify(convertToRaw(editorStateGe.getCurrentContent())))}
                />
              </Grid>
              <Grid sx={{ padding: "20px 0px" }}>
                <Button variant="contained" sx={{ mr: 2 }} onClick={handleSubmit}>
                  Submit
                </Button>
                <Button variant="outlined" color="secondary" type="reset" onClick={handleReset}>
                  Cancel
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}
    </>
  );
};

export default EditVacancy;
