import { useState } from "react";

import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid";
import { format } from "date-fns";
import CustomChip from "../../components/@core/components/mui/chip";
import { ThemeColor } from "../../components/@core/layouts/types";
import ConfirmationDialog from "./ConfirmationDialog";
import { Routes, Route, useNavigate } from "react-router-dom";

export const useColumns = () => {
  const [modal, setModal] = useState<boolean>(false);
  const [dataID, setDataID] = useState<string | undefined>();
  const [deleteID, setDeleteID] = useState<string | undefined>();
  const [renewVacancyID, setRenewVacancyID] = useState<string | undefined>();
  const navigate = useNavigate();
  interface StatusObj {
    [key: number]: {
      title: string;
      color: ThemeColor;
    };
  }

  const statusObj: StatusObj = {
    1: { title: "Active", color: "primary" },
    2: { title: "Expired", color: "error" },
    3: { title: "Draft", color: "primary" },
    4: { title: "Deactivated", color: "error" },
  };

  const verificationObj: StatusObj = {
    1: { title: "Verified", color: "primary" },
    2: { title: "Not Verified", color: "error" },
  };

  const handleVerificationStatus = (isEmailVerified: boolean | null, isVerified: boolean | null) => {
    if (!isEmailVerified && !isVerified) return 4;
    else if (isEmailVerified && !isVerified) return 5;
    else return 3;
  };

  const handleEdit = (params: GridRenderCellParams) => {
    navigate(`/pages/vacancies/${params.row.id}`);
    // setModal(!modal);
    setDataID(params.row.id);
  };

  const handleDelete = (params: GridRenderCellParams) => {
    setDeleteID(params.row.id);
  };

  const columns: GridColumns = [
    {
      flex: 0.15,
      minWidth: 290,
      field: "title",
      headerName: "Title",
      renderCell: (params: GridRenderCellParams) => {
        const { row } = params;
        return (
          <Typography noWrap variant="body2" sx={{ color: "text.primary", fontWeight: 600 }}>
            {row.title}
          </Typography>
        );
      },
    },
    {
      flex: 0.15,
      minWidth: 200,
      field: "company",
      headerName: "Company",
      renderCell: (params: GridRenderCellParams) => {
        const { row } = params;

        return (
          <Typography noWrap variant="body2" sx={{ color: "text.primary", fontWeight: 600 }}>
            {row.company.name}
          </Typography>
        );
      },
    },
    {
      flex: 0.1,
      minWidth: 120,
      headerName: "Published",
      field: "publishDate",
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="body2" sx={{ color: "text.primary" }}>
          {format(new Date(params.row.publishDate), "dd MMM yyyy")}
        </Typography>
      ),
    },
    {
      flex: 0.1,
      minWidth: 120,
      headerName: "Deadline",
      field: "deadline",
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="body2" sx={{ color: "text.primary" }}>
          {format(new Date(params.row.deadline), "dd MMM yyyy")}
        </Typography>
      ),
    },
    {
      flex: 0.09,
      minWidth: 100,
      field: "vacancyStatus",
      headerName: "Status",
      renderCell: (params: GridRenderCellParams) => {
        const status = statusObj[params.row.vacancyStatus];

        return <CustomChip size="small" skin="light" color={status.color} label={status.title} sx={{ "& .MuiChip-label": { textTransform: "capitalize" } }} />;
      },
    },
    {
      flex: 0.09,
      minWidth: 120,
      field: "company.isVerified",
      headerName: "Verification",
      renderCell: (params: GridRenderCellParams) => {
        const verified = params.row.company.isVerified === true ? 1 : 2;
        const status = verificationObj[verified];

        return <CustomChip size="small" skin="light" color={status.color} label={status.title} sx={{ "& .MuiChip-label": { textTransform: "capitalize" } }} />;
      },
    },
    {
      flex: 0.175,
      minWidth: 200,
      headerName: "Actions",
      field: "isVerified",
      renderCell: (params: GridRenderCellParams) => {
        return (
          <Typography variant="body2" sx={{ color: "text.primary" }}>
            <Button size="small" variant="outlined" color="secondary" onClick={() => handleEdit(params)} style={{ margin: "0 10px 0 0" }}>
              Edit
            </Button>
            {params.row.vacancyStatus === 2 && (
              <Button size="small" variant="outlined" color="secondary" onClick={() => setRenewVacancyID(params.row.id)} style={{ margin: "0 10px 0 0" }}>
                Renew
              </Button>
            )}
            <ConfirmationDialog handleDelete={() => handleDelete(params)} />
          </Typography>
        );
      },
    },
  ];
  return {
    columns,
    modal,
    setModal,
    setDataID,
    dataID,
    deleteID,
    setDeleteID,
    renewVacancyID,
    setRenewVacancyID,
  };
};
