// ** React Imports
import { useState, Fragment, useEffect, ChangeEvent } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import Collapse from "@mui/material/Collapse";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import TableContainer from "@mui/material/TableContainer";

// ** Icons Imports
import ChevronUp from "mdi-material-ui/ChevronUp";
import ChevronDown from "mdi-material-ui/ChevronDown";

import { useAppDispatch } from "../../../../store";

import { SeoData, PageSeoData } from "../../../../service";
import { TextField, Button } from "@mui/material";
import { getSeoDetails, postUpdateSeoDetailsForPage, postUpdateSeoImage } from "../../../../store/apps/seoSlice";
import { useDropzone } from "react-dropzone";
import { FileDocumentOutline } from "mdi-material-ui";

interface FileProp {
  name: string;
  type: string;
  size: number;
}

const Row = (props: { row: RowTypes }) => {
  const renderFilePreview = (file: FileProp | string) => {
    if (typeof file === "string") {
      return <img width={150} height={150} style={{ objectFit: "cover" }} alt={"Benefit"} src={file} />;
    } else if (file.type.startsWith("image")) {
      return <img width={150} height={150} style={{ objectFit: "cover" }} alt={file?.name || ""} src={URL.createObjectURL(file as any)} />;
    } else {
      return <FileDocumentOutline />;
    }
  };

  const dispatch = useAppDispatch();
  const [rowData, setRowData] = useState(props.row);
  // ** Props
  const { row } = props;

  // ** State
  const [open, setOpen] = useState<boolean>(false);

  const handleChange = (e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const { name, value } = e.target as HTMLInputElement;

    setRowData({
      ...rowData,
      [name]: value,
    });
  };

  const [image, setImage] = useState<File[] | null | string>(row.iconUrl || null);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles: File[]) => {
      setImage(acceptedFiles);
    },
  });

  const handleSave = () => {
    let objToSend = {
      pageType: rowData.pageType as number,
      body: {
        titleEn: rowData.titleEn,
        titleGe: rowData.titleGe,
        descriptionEn: rowData.descriptionEn,
        descriptionGe: rowData.descriptionGe,
      },
    };

    const formData = new FormData();

    formData.append("ProfileImage", image && Array.isArray(image) ? image[0] : "");

    dispatch(postUpdateSeoDetailsForPage(objToSend)).then((payload) => {
      if (payload?.payload.succeeded) {
        if (Array.isArray(image)) {
          dispatch(
            postUpdateSeoImage({
              pageType: rowData.pageType as number,
              formData: formData,
            })
          ).then((payload) => {
            if (payload?.payload.succeeded) {
              setOpen(false);
              dispatch(getSeoDetails());
            }
          });
        }
        setOpen(false);
        dispatch(getSeoDetails());
      }
    });
  };

  return (
    <Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <ChevronUp /> : <ChevronDown />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>
        <TableCell align="right">{row.titleEn}</TableCell>
        <TableCell align="right">{row.titleGe}</TableCell>
        <TableCell align="right">{row.descriptionEn}</TableCell>
        <TableCell align="right">{row.descriptionGe}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell colSpan={6} sx={{ py: "0 !important" }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ m: 2 }}>
              <Typography variant="h6" gutterBottom component="div">
                Edit
              </Typography>
              <Table size="small" aria-label="purchases">
                <TableHead>
                  <TableRow>
                    <TableCell>
                      <TextField name="titleEn" label="Title English" variant="standard" value={rowData.titleEn ? rowData.titleEn : ""} onChange={handleChange} />
                    </TableCell>
                    <TableCell>
                      <TextField name="titleGe" label="Title Georgian" variant="standard" value={rowData.titleGe ? rowData.titleGe : ""} onChange={handleChange} />
                    </TableCell>
                    <TableCell>
                      <TextField name="descriptionEn" label="Description English" variant="standard" value={rowData.descriptionEn ? rowData.descriptionEn : ""} onChange={handleChange} />
                    </TableCell>
                    <TableCell>
                      <TextField name="descriptionGe" label="Description Georgian" variant="standard" value={rowData.descriptionGe ? rowData.descriptionGe : ""} onChange={handleChange} />
                    </TableCell>
                    <TableCell>
                      <Box>{image && renderFilePreview(typeof image === "string" ? image : image[0])}</Box>
                      <Box {...getRootProps({ className: "dropzone" })}>
                        <Button>Choose Image</Button>
                        <input {...getInputProps()} />
                        {/* {renderFilePreview()} */}
                      </Box>
                    </TableCell>
                    <TableCell>
                      <Button onClick={handleSave}>SAVE</Button>
                    </TableCell>
                  </TableRow>
                </TableHead>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </Fragment>
  );
};

interface dummyDataTypes {
  [key: string]: string;
}

const dataProps: dummyDataTypes = {
  homePageSeoDetails: "Home",
  aboutUsSeoDetails: "About Us",
  authSeoDetails: "Sign In, Sign Up, Forgot Password",
  companiesListSeoDetails: "Companies List",
  vacanciesListSeoDetails: "Vacancies List",
  staticPagesSeoDetails: "Static Pages",
  notFoundSeoDetails: "Not Found",
  compareCompanySeoDetails: "Compare companies",
  blogSeoDetails: "Blog/news",
};

interface RowTypes extends PageSeoData {
  name: string;
}

const TableCollapsible = (props: { data?: SeoData }) => {
  const [rows, setRows] = useState<RowTypes[]>([]);

  useEffect(() => {
    props.data && arrayFromData();
  }, [props.data]);

  const arrayFromData = () => {
    let arr = [];
    for (let prop in props?.data?.data) {
      let obj = {
        name: dataProps[prop],
        ...props?.data?.data[prop],
      };
      arr.push(obj);
    }
    // @ts-ignore
    setRows(arr);
  };
  return (
    <TableContainer component={Paper}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Page</TableCell>
            <TableCell align="right">Title English</TableCell>
            <TableCell align="right">Title Georgian</TableCell>
            <TableCell align="right">Description English</TableCell>
            <TableCell align="right">Description Georgian</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row) => (
            <Row key={row.name} row={row} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableCollapsible;
