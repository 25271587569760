import axiosInstance from "../../axios/axiosInstance";
import { UpdateFAQParams } from "./params";
import { UpdateOrderFAQRequestType } from "./types";

export const getFAQList = () => {
  return axiosInstance.get(`api/BackOfficeStaticData/GetFaqList`);
};

export const getFAQDetails = (id: string) => {
  return axiosInstance.get(`api/BackOfficeStaticData/GetFaqDetail?faqId=${id}`);
};

export const updateFAQ = (params: UpdateFAQParams) => {
  return axiosInstance.post<UpdateFAQParams>(
    `api/BackOfficeStaticData/UpdateOrCreateFaq`,
    params.body
  );
};

export const updateFAQListOrder = (params: UpdateOrderFAQRequestType[]) => {
  return axiosInstance.post<UpdateOrderFAQRequestType[]>(
    `api/BackOfficeStaticData/UpdateOrderFAQ`,
    params
  );
};
