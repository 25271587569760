// ** React Imports
import { Ref, useState, forwardRef, ReactElement, FC, useEffect } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Fade, { FadeProps } from "@mui/material/Fade";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import * as Yup from "yup";
import { format } from "date-fns";

// ** Icons Imports
import Close from "mdi-material-ui/Close";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useAppDispatch } from "../../store";
import DatePicker from "react-datepicker";
import DatePickerWrapper from "../../components/@core/styles/libs/react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { getSearchVacancy, putRenewAllExpiredVacancies } from "../../store/apps/vacanciesSlice";

const getDateOfTomorrow = () => {
  var date = new Date();
  date.setDate(date.getDate() + 1);
  return date;
};

const Transition = forwardRef(function Transition(props: FadeProps & { children?: ReactElement<any, any> }, ref: Ref<unknown>) {
  return <Fade ref={ref} {...props} />;
});

interface addAdminFormInputs {
  readonly deadline: Date;
}

const addAdminFormSchema = Yup.object()
  .shape({
    deadline: Yup.date().required(),
  })
  .required();

const DialogRenewVacancy: FC<{
  vacancyId: any;
  onClose: () => void;
  onSubmit: (deadline: Date) => void;
  additionalBtn?: boolean;
  additionalAction?: () => void;
  setDeadlineTorenew?: any;
  setVacanciesToRenew?: any;
  submittedFilteredObject?: any;
  vacanciesToRenew?: string[];
}> = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
    setError,
  } = useForm<addAdminFormInputs>({
    resolver: yupResolver(addAdminFormSchema),
  });

  const [deadlineFrom, setDeadlineFrom] = useState<Date>();
  const [confirmModal, toggleConfirmModal] = useState(false);
  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<addAdminFormInputs> = (data) => {
    props.onSubmit(data.deadline);
  };

  const handleSetDate = (e: Date) => {
    setDeadlineFrom(e);

    if (props.setDeadlineTorenew) props.setDeadlineTorenew(e);
  };

  const handleRenewAllExpiredVacancies = () => {
    let date = format(deadlineFrom!, "yyyy-MM-dd");
    deadlineFrom &&
      dispatch(putRenewAllExpiredVacancies(date)).then(() => {
        props.setVacanciesToRenew([]);
        dispatch(getSearchVacancy(props.submittedFilteredObject));
      });
    props.onClose();
  };

  return (
    <>
      <Card>
        <Dialog
          // fullWidth
          open={true}
          maxWidth="md"
          scroll="body"
          onClose={() => {
            reset();
            props.onClose();
          }}
          TransitionComponent={Transition}
          onBackdropClick={() => {
            reset();
            props.onClose();
          }}
        >
          <form onSubmit={handleSubmit(onSubmit)} noValidate spellCheck="false" autoComplete="off">
            <DialogContent
              sx={{
                pb: 6,
                px: { xs: 8, sm: 15 },
                pt: { xs: 8, sm: 12.5 },
                height: "530px",
                position: "relative",
              }}
            >
              <IconButton
                size="small"
                onClick={() => {
                  reset();
                  props.onClose();
                }}
                sx={{ position: "absolute", right: "1rem", top: "1rem" }}
              >
                <Close />
              </IconButton>
              <Box sx={{ mb: 8, textAlign: "center" }}>
                <Typography variant="h5" sx={{ mb: 3, lineHeight: "2rem" }}>
                  Choose Vacancy Deadline
                </Typography>
              </Box>
              <DatePickerWrapper>
                <DatePicker
                  selected={deadlineFrom}
                  showYearDropdown
                  showMonthDropdown
                  placeholderText="MM/DD/YYYY"
                  isClearable
                  open={true}
                  minDate={getDateOfTomorrow()}
                  customInput={<TextField {...register("deadline")} error={!!errors["deadline"]} fullWidth label="Deadline" />}
                  onChange={(date: Date) => {
                    handleSetDate(date);
                    reset({ deadline: date });
                  }}
                />
              </DatePickerWrapper>
              <TextField style={{ display: "none" }} {...register("deadline", { required: true })} />
            </DialogContent>
            <DialogActions sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: "center" }}>
              <Button type="submit" variant="contained" sx={{ mr: 2 }} disabled={props.additionalBtn && props.vacanciesToRenew!.length === 0}>
                Submit
              </Button>
              {props.additionalBtn && (
                <Button
                  variant="outlined"
                  type="button"
                  color="secondary"
                  onClick={() => {
                    !deadlineFrom ? setError("deadline", { type: "focus" }, { shouldFocus: true }) : toggleConfirmModal(!confirmModal);
                  }}
                >
                  Renew All
                </Button>
              )}
              <Button
                variant="outlined"
                type="reset"
                color="secondary"
                onClick={() => {
                  reset();
                  props.onClose();
                }}
              >
                Discard
              </Button>
            </DialogActions>
          </form>
        </Dialog>
      </Card>
      <Dialog open={confirmModal && !!deadlineFrom}>
        <DialogContent>
          <Box sx={{ mb: 8, textAlign: "center" }}>
            <Typography variant="h5" sx={{ mb: 3, lineHeight: "2rem" }}>
              Are you sure you want to renew all expired vacancies?
            </Typography>
          </Box>
        </DialogContent>
        <DialogActions sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: "center" }}>
          <Button type="submit" variant="contained" sx={{ mr: 2 }} onClick={handleRenewAllExpiredVacancies}>
            Confirm
          </Button>
          <Button variant="outlined" type="reset" color="secondary" onClick={() => toggleConfirmModal(!confirmModal)}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogRenewVacancy;
