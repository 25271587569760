// ** React Imports
import { Ref, useState, forwardRef, ReactElement } from "react";

import Grid from "@mui/material/Grid";
import Dialog from "@mui/material/Dialog";
// import Button from "@mui/material/Button";
import { Button } from "@mui/material";

import Typography from "@mui/material/Typography";
import Fade, { FadeProps } from "@mui/material/Fade";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";


const Transition = forwardRef(function Transition(
  props: FadeProps & { children?: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Fade ref={ref} {...props} />;
});

type localTypes = {
  handleDelete: () => void;
};

const ConfirmationDialog = ({ handleDelete }: localTypes) => {
  // ** States
  const [show, setShow] = useState<boolean>(false);

  return (
    <>
      <Button size="small" variant="outlined" onClick={() => setShow(true)}>
        Delete
      </Button>
      <Dialog
        fullWidth
        open={show}
        maxWidth="sm"
        scroll="body"
        onClose={() => setShow(false)}
        TransitionComponent={Transition}
        onBackdropClick={() => setShow(false)}
      >
        <DialogContent
          sx={{
            pb: 6,
            px: { xs: 8, sm: 15 },
            pt: { xs: 8, sm: 12.5 },
            position: "relative",
          }}
        >
          <Grid container justifyContent={"center"}>
            <Grid>
              <Typography variant="body1">
                Are you sure you want to delete data?
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: "center" }}
        >
          <Button variant="contained" sx={{ mr: 2 }} onClick={handleDelete}>
            Submit
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setShow(false)}
          >
            Discard
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConfirmationDialog;
