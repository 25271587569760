import * as Api from "../../../service";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";
import { splitName } from "../../../utils/helperFunctions";
import { ChildBenefitType, KeyfactDataType, UpdateBenefitCategoryType } from "../../../types/apps/attributeTypes";


export interface DictionaryState {
  isLoading: boolean;
  dictionaryState: Api.GetDictionaryRequestType;
  dictionryType: Api.DictionaryTypeEnum;
  parentDictionary: Api.GetDictionaryRequestType;
  dictionaryName: string;
  editData?: Api.GetDictionaryRequestType["data"]["dictionaryList"][0]
  Benefits: Api.NewBenefitRequestType['data']
  keyFacts: KeyfactDataType[]
}

const initialState: DictionaryState = {
  isLoading: false,
  dictionaryState: {} as Api.GetDictionaryRequestType,
  dictionryType: 1,
  parentDictionary: {} as Api.GetDictionaryRequestType,
  dictionaryName: "",
  editData: undefined,
  Benefits: [],
  keyFacts: []
};

export const getDictionary =
  createAsyncThunk<Api.GetDictionaryRequestType, Api.DictionaryTypeEnum>(
    "dictionary/getDictionary",
    async (params: Api.DictionaryTypeEnum) => {
      const response = await Api.getDictionaryRequest(params);
      return response.data;
    }
  );

  export const postCreateUpdateDictionary = createAsyncThunk<Api.postCreateUpdateDictionaryType, Api.PostCreateUpdateDictionaryRequestParams>(
    "dictionary/postEmploymentTypes",
    async (params: Api.PostCreateUpdateDictionaryRequestParams) => {
      const response = await Api.postCreateUpdateDictionary(params)
      return response.data;
    }
  );

  export const getParentDictionary = 
  createAsyncThunk<Api.GetDictionaryRequestType, Api.DictionaryTypeEnum>(
    "dictionary/getParentDictionary",
    async (params: Api.DictionaryTypeEnum) => {
      const response = await Api.getDictionaryRequest(params);
      return response.data;
    }
  );

  export const postUpdateProfileImage = createAsyncThunk<Api.GetDictionaryRequestType, Api.PostUpdateDictionaryImageRequestParams>(
    "dictionary/postUpdateProfileImage",
    async (params: Api.PostUpdateDictionaryImageRequestParams) => {
      const response = await Api.postUpdateDictionaryImageRequest(params)
      return response.data;
    }
  );

  export const postUpdateHeroImage = createAsyncThunk<string, Api.PostUpdateHeroImageRequestParams>(
    "dictionary/postUpdateHeroImage",
    async (params: Api.PostUpdateHeroImageRequestParams) => {
      const response = await Api.postUpdateHeroImageRequest(params)
      return response.data;
    }
  );

  export const getAllBenefits = createAsyncThunk<Api.NewBenefitRequestType>(
    "benefits/getAll",
    async () => {
      const response = await Api.getAllBenefitsRequest()
      return response.data;
    }
  );
  
  export const postUpdateBenefitCategory = createAsyncThunk<Api.NewBenefitRequestType, UpdateBenefitCategoryType>(
    "benefits/postUpdateBenefitCategory",
    async (params: UpdateBenefitCategoryType) => {
      const response = await Api.postUpdateBenefitCategoryRequest(params)
      return response.data;
    }
  );

  export const postCreateUpdateBenefit = createAsyncThunk<Api.NewBenefitRequestType, FormData>(
    "benefits/postCreateUpdateBenefit",
    async (params: FormData) => {
      const response = await Api.postCreateUpdateBenefitRequest(params)
      return response.data;
    }
  );

  export const postUpdateBenefitOrder = createAsyncThunk<Api.NewBenefitRequestType, Api.PostUpdateBenefitOrderRequestParams>(
    "benefits/postUpdateBenefitOrder",
    async (params: Api.PostUpdateBenefitOrderRequestParams) => {
      const response = await Api.postUpdateBenefitOrderRequest(params)
      return response.data;
    }
  );

  export const getKeyFacts = createAsyncThunk<Api.keyFactRequestType>(
    "keyfact/getKeyFacts",
    async () => {
      const response = await Api.getKeyFactsRequest()
      return response.data;
    }
  );

  export const postUpdateOrderKeyFact = createAsyncThunk<Api.keyFactRequestType, string[]>(
    "keyfact/postUpdateKeyfactOrder",
    async (params: string[]) => {
      const response = await Api.postUpdateOrderKeyFact(params)
      return response.data;
    }
  );

  export const postCreateUpdateKeyFact = createAsyncThunk<Api.keyFactRequestType, Api.PostCreateUpdateKeyFactParams>(
    "keyfact/postCreateUpdateKeyFact",
    async (params: Api.PostCreateUpdateKeyFactParams) => {
      const response = await Api.postCreateUpdateKeyFact(params)
      return response.data;
    }
  );

  export const postUpdateDictionaryOrder = createAsyncThunk<{dictionaryType: number, ids: string[]}, any>('attributes/postUpdateDictionaryOrder', async (params: {dictionaryType: number, ids: string[]}) => {
    const response = await Api.postUpdateDictionaryOrderRequest(params)
    return response.data
  })

  export const postDeleteDictionary = createAsyncThunk<{dictionaryType: number, itemId: string}, any>('attributes/postDeleteDictionary', async (params: {dictionaryType: number, itemId: string}) => {
    const response = await Api.postDeleteDictionaryRequest(params)
    return response.data
  })

  
  export const postDeleteBenefits = createAsyncThunk<{benefitId: string}, any>('attributes/postDeleteBenefits', async (benefitId: string) => {
    const response = await Api.postDeleteBenefitsRequest(benefitId)
    return response.data
  })

  export const postDeleteKeyfacts = createAsyncThunk<{keyFactId: string}, any>('attributes/postDeleteKeyfacts', async (keyFactId: string) => {
    const response = await Api.postdeleteKeyFactRequest(keyFactId)
    return response.data
  })

  export const postDeleteBenefitCategory = createAsyncThunk<{benefitCategoryId: string}, any>('attributes/postDeleteBenefitCategory', async (benefitCategoryId: string) => {
    const response = await Api.postDeleteBenefitCategoryRequest(benefitCategoryId)
    return response.data
  })


export const attributesSlice = createSlice({
  name: "dictionary",
  initialState,
  reducers: {
    getHandleDictionary: (
      state,
      action: PayloadAction<Api.GetDictionaryRequestType>
    ) => {
      state.dictionaryState = action.payload;
    },
    createUpdateDictionary: (state, action: PayloadAction<Api.PostCreateUpdateDictionaryRequestParams>) => {
      if(!action.payload) return
    },
    updateDictionaryType: (state, action: PayloadAction<number>) => {
      state.dictionryType = action.payload
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getDictionary.fulfilled, (state, action) => {
      state.dictionaryState = action.payload;
      const name = action.payload.data.dictionaryName
      state.dictionaryName = splitName(name)
      state.isLoading = false
    });
    builder.addCase(getDictionary.pending, state => {
        state.isLoading = true
    })
    builder.addCase(getDictionary.rejected, state => {
        state.isLoading = false
        toast.error("Something Went Wrong");
    })
    builder.addCase(postUpdateProfileImage.pending, state => {
      state.isLoading = true
    })
    builder.addCase(postUpdateProfileImage.fulfilled, state => {
      state.isLoading = false
    })
    builder.addCase(postUpdateProfileImage.rejected, state => {
      state.isLoading = false
    })
    builder.addCase(getParentDictionary.fulfilled, (state, action) => {
      state.parentDictionary = action.payload;
      state.isLoading = false
    });
    builder.addCase(getParentDictionary.pending, state => {
        state.isLoading = true
    })
    builder.addCase(getParentDictionary.rejected, state => {
        state.isLoading = false
        toast.error("Something Went Wrong");
    })
    builder.addCase(postCreateUpdateDictionary.fulfilled, state => {
      state.isLoading = false
    })
    builder.addCase(postCreateUpdateDictionary.pending, state => {
      state.isLoading = true
    })
    builder.addCase(postCreateUpdateDictionary.rejected, state => {
      state.isLoading = false
      toast.error("Something Went Wrong");
    })
    builder.addCase(getAllBenefits.fulfilled, (state, action) => {
      state.isLoading = false
      state.Benefits = action.payload.data
    })
    builder.addCase(getAllBenefits.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(getAllBenefits.rejected, state => {
      state.isLoading = false
      toast.error("Something Went Wrong");
    })
    builder.addCase(postUpdateBenefitCategory.fulfilled, (state) => {
      state.isLoading = false
      toast.success("Successfuly updated")
    })
    builder.addCase(postUpdateBenefitCategory.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(postUpdateBenefitCategory.rejected, (state, action) => {
      state.isLoading = false 
      toast.error("Something Went Wrong");
    })
    builder.addCase(postCreateUpdateBenefit.fulfilled, (state) => {
      state.isLoading = false
      toast.success("Successfuly updated")
    })
    builder.addCase(postCreateUpdateBenefit.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(postCreateUpdateBenefit.rejected, state => {
      state.isLoading = false
      toast.error("Something Went Wrong");
    })
    builder.addCase(postUpdateBenefitOrder.fulfilled, (state) => {
      state.isLoading = false
      toast.success("Successfuly updated")
    })
    builder.addCase(postUpdateBenefitOrder.pending, (state) => {
      state.isLoading = true
    })
    builder.addCase(postUpdateBenefitOrder.rejected, state => {
      state.isLoading = false
      toast.error("Something Went Wrong");
    })
    builder.addCase(getKeyFacts.pending, state => {
      state.isLoading = true
    })
    builder.addCase(getKeyFacts.rejected, state => {
      state.isLoading = false
      toast.error('Something Went Wrong')
    })
    builder.addCase(getKeyFacts.fulfilled, (state, action) => {
      state.keyFacts = action.payload.data
      state.isLoading = false
    })
    builder.addCase(postCreateUpdateKeyFact.rejected, (state, action) => {
      state.isLoading = false
    })
    builder.addCase(postCreateUpdateKeyFact.pending, state => {
      state.isLoading = true
    })
    builder.addCase(postCreateUpdateKeyFact.fulfilled, (state) => {
      state.isLoading = false
      toast.success("Successfuly updated")
    })
    builder.addCase(postUpdateOrderKeyFact.fulfilled, state => {
      state.isLoading = false
      toast.success('Successfuly updated')
    })
    builder.addCase(postUpdateOrderKeyFact.rejected, state => {
      state.isLoading = false
      toast.error('Something Went Wrong')
    })
    builder.addCase(postUpdateOrderKeyFact.pending, state => {
      state.isLoading = true
    })
    builder.addCase(postUpdateDictionaryOrder.pending, state => {
      state.isLoading = true
    })
    builder.addCase(postUpdateDictionaryOrder.fulfilled, state => {
      state.isLoading = false
    })
    builder.addCase(postUpdateDictionaryOrder.rejected, state => {
      state.isLoading = false
    })
    builder.addCase(postDeleteDictionary.fulfilled, state => {
      state.isLoading = false
    })
    builder.addCase(postDeleteDictionary.rejected, state => {
      state.isLoading = false
    })
    builder.addCase(postDeleteDictionary.pending, state => {
      state.isLoading = true
    })
    builder.addCase(postDeleteBenefits.pending, state => {
      state.isLoading = true
    })
    builder.addCase(postDeleteBenefits.rejected, state => {
      state.isLoading = false
    })
    builder.addCase(postDeleteBenefits.fulfilled, state => {
      state.isLoading = false
    })
    builder.addCase(postDeleteKeyfacts.fulfilled, state => {
      state.isLoading = false
    })
    builder.addCase(postDeleteKeyfacts.rejected, state => {
      state.isLoading = false
    })
    builder.addCase(postDeleteKeyfacts.pending, state => {
      state.isLoading = true
    })
    builder.addCase(postDeleteBenefitCategory.pending, state => {
      state.isLoading = true
    })
    builder.addCase(postDeleteBenefitCategory.rejected, state => {
      state.isLoading = false
    })
    builder.addCase(postDeleteBenefitCategory.fulfilled, state => {
      state.isLoading = false
    })
  },
});




export const { getHandleDictionary, updateDictionaryType } = attributesSlice.actions;
export default attributesSlice.reducer;
