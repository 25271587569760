import { useState, MouseEvent, SetStateAction, Dispatch } from "react";

// ** Material
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import { FormControlLabel, MenuItem } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

// ** icons
import DragHandleIcon from "@mui/icons-material/DragHandle";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

// ** components
import { Modal as DeleteModal } from "../../../../utils/views/modal/modal";
import KeyFactSubItem from "./KeyFactSubItem";

// ** dnd
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

// ** style
import classes from "./KeyFactItem.module.sass";

// ** type
import { KeyfactDataType, keyfactOptionType } from "../../../../types/apps/attributeTypes";
import { keyFactEnum } from "../../../../service";
import { postDeleteKeyfacts } from "../../../../store/apps/attributesSlice";
import { useAppDispatch } from "../../../../store";
import toast from "react-hot-toast";

type propType = {
  data: KeyfactDataType;
  open: string | null;
  setOpen: Dispatch<SetStateAction<string | null>>;
  saveData(data: KeyfactDataType): void;
  refetchData: () => void;
};

const KeyFactItem = ({ data, open, setOpen, saveData, refetchData }: propType) => {
  const dispatch = useAppDispatch();
  const [name, setName] = useState<string>(data.name);
  const [nameGE, setNameGE] = useState<string>(data.nameGE);
  const [keyfactName, setKeyfactName] = useState<string>(data.keyFactName);
  const [mandatory, setMandatory] = useState<boolean>(data.isMandatory);
  const [hideKeyfacts, setHideKeyfacts] = useState<boolean>(data.hide);
  const [hideKeyfactInCompanySearch, setHideKeyfactInCompanySearch] = useState<boolean>(!!data.hideInSearch);
  const [options, setOptions] = useState<keyfactOptionType[]>(data.options);
  const [keyFactType, setKeyFactType] = useState<keyFactEnum>(data.keyFactType);
  const [deleteData, setDeleteData] = useState<{ name: string; id: string } | null>(null);
  const dropDownOpen = open === data.id;

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: data.id || "" });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const openDropDown = () => {
    if (open === data.id) setOpen("");
    else setOpen(data.id || "");
  };

  const handleKeyFactTypeChange = (event: SelectChangeEvent) => {
    setKeyFactType(parseInt(event.target.value));
  };

  const addOption = () => {
    const newOptions = [...options, { name: "", nameGE: "" }];
    setOptions(newOptions);
  };

  const deleteOption = (idx: number | string) => {
    setOptions((prevOptions) => {
      const newOptions = prevOptions.filter((el, index) => (typeof idx === "string" ? el.id !== idx : index !== idx));
      return newOptions;
    });
  };

  const updateOption = (id: string | undefined, optName: string, optNameGE: string, idx: number) => {
    const modifiedOptions = options.map((el, index) => (index === idx ? { id, name: optName, nameGE: optNameGE } : el));
    setOptions(modifiedOptions);
  };

  const handleSaveData = () => {
    const newData: KeyfactDataType = {
      hide: hideKeyfacts,
      isMandatory: mandatory,
      keyFactName: data.keyFactName || keyfactName,
      keyFactType,
      name,
      nameGE,
      options,
      orderIndex: data.orderIndex,
      id: data.id === "new" || !data.id ? null : data.id,
      hideInSearch: hideKeyfactInCompanySearch,
    };
    saveData(newData);
  };

  const handleDeleteData = async (keyfactId: string) => {
    try {
      await dispatch(postDeleteKeyfacts(keyfactId)).unwrap();
      refetchData();
      setDeleteData(null);
      toast.success(`${deleteData?.name} sucessfully deleted`);
    } catch (err) {
      toast.error(typeof err === "string" ? err : "Something went wrong!");
    }
  };

  return (
    <>
      <DeleteModal show={!!deleteData} onClose={() => setDeleteData(null)} submitAction={() => data.id && handleDeleteData(data.id)} submitBtnColor="error" submitText="Delete">
        <Typography>
          Are you sure you want to delete <b>{deleteData?.name}</b>?
        </Typography>
      </DeleteModal>
      <Box className={`${classes.BenefitItem} ${dropDownOpen && classes.activeMenu}`} ref={setNodeRef} style={style}>
        <Box className={classes.header} onClick={openDropDown}>
          <DragHandleIcon {...listeners} {...attributes} />
          <Box>
            <Typography variant="h6">{name}</Typography>
            {!dropDownOpen ? <AddIcon /> : <RemoveIcon />}
          </Box>
        </Box>
        <Box className={`${classes.body} ${dropDownOpen && classes.activeMenu}`}>
          <TextField
            autoFocus
            fullWidth
            disabled={!!data.keyFactName}
            id="keyFactIdName"
            label="Keyfact Name"
            name="keyFactIdName"
            sx={{ mb: 0, width: "100%" }}
            onChange={(e) => {
              setKeyfactName(e.target.value);
            }}
            value={keyfactName}
          />
          <TextField
            autoFocus
            fullWidth
            id="keyFactName"
            label="Section Name"
            name="keyFactName"
            sx={{ mb: 0, width: "100%" }}
            onChange={(e) => {
              setName(e.target.value);
            }}
            value={name}
          />
          <TextField
            fullWidth
            id="keyFactNameGE"
            label="Section Name (GE)"
            name="keyFactNameGE"
            sx={{ mb: 0, width: "100%" }}
            onChange={(e) => {
              setNameGE(e.target.value);
            }}
            value={nameGE}
          />

          <FormControl>
            <InputLabel id="keyfactType">Keyfact Type</InputLabel>
            <Select labelId="keyfactType" id="keyfactSelect" label="Keyfact Type" value={`${keyFactType}`} onChange={handleKeyFactTypeChange} disabled={data.id !== "new"}>
              <MenuItem value={keyFactEnum.Text}>Text</MenuItem>
              <MenuItem value={keyFactEnum.Number}>Number</MenuItem>
              <MenuItem value={keyFactEnum.Year}>Year</MenuItem>
              <MenuItem value={keyFactEnum.MultipleChoice}>Multiple Choice</MenuItem>
              <MenuItem value={keyFactEnum.SingleChoice}>Single Choice</MenuItem>
            </Select>
          </FormControl>

          <FormControlLabel
            control={<Switch checked={hideKeyfacts} onChange={() => setHideKeyfacts((prevVal) => !prevVal)} />}
            label={"Hide"}
            sx={{
              marginLeft: 0,
            }}
          />

          <FormControlLabel
            control={<Switch checked={hideKeyfactInCompanySearch} onChange={() => setHideKeyfactInCompanySearch((prevVal) => !prevVal)} />}
            label={"Hide in search"}
            sx={{
              marginLeft: 0,
            }}
          />

          <FormControlLabel
            control={<Switch checked={mandatory} onChange={() => setMandatory((prevVal) => !prevVal)} />}
            label={"Mandatory"}
            sx={{
              marginLeft: 0,
            }}
          />

          {(keyFactType === keyFactEnum.MultipleChoice || keyFactType === keyFactEnum.SingleChoice) && (
            <>
              <Divider />
              {options.map((el, idx) => {
                return (
                  <KeyFactSubItem
                    updateOption={({ id, optName, optNameGE }) => updateOption(id, optName, optNameGE, idx)}
                    name={el.name}
                    nameGE={el.nameGE}
                    id={el.id}
                    deleteOption={() => deleteOption(el.id || idx)}
                    key={el.id}
                  />
                );
              })}
              <Button onClick={addOption}>Add Option</Button>
            </>
          )}

          <Divider />

          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <Button onClick={handleSaveData}>Save Keyfact</Button>
            <Button onClick={() => setDeleteData({ name: data.name, id: data.id || "" })} color="error">
              Delete Keyfact
            </Button>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default KeyFactItem;
