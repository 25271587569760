// ** React Imports
import { useState, Fragment, FC } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import { Select, Options, SelectChangeEvent } from "@mui/material";
import * as Api from "../../../service";
import DragVertical from "mdi-material-ui/DragVertical";
import MenuItem from "@mui/material/MenuItem";

// ** Icons Imports
import ChevronUp from "mdi-material-ui/ChevronUp";
import ChevronDown from "mdi-material-ui/ChevronDown";

import { Button, Grid } from "@mui/material";
import { useAppDispatch } from "../../../store";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import DialogEditQuestion from "./DialogEditQuestion";

const FaqTableRow: FC<{ faqTableRow: Api.GetFAQListResponseType }> = (props) => {
  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: props.faqTableRow.id });

  // ** State
  const [open, setOpen] = useState<boolean>(false);
  const [showEdit, setShowEdit] = useState<Api.GetFAQListResponseType>();

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const questionType = ["General", "Employers", "Talents"];

  return (
    <Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }} ref={setNodeRef} style={style}>
        <TableCell>
          <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
            {open ? <ChevronUp /> : <ChevronDown />}
          </IconButton>
        </TableCell>
        <TableCell>{props.faqTableRow.titleEN}</TableCell>
        <TableCell>{props.faqTableRow.titleGE}</TableCell>
        <TableCell>{questionType[props.faqTableRow.forType || 0]}</TableCell>
        <TableCell>{props.faqTableRow.active ? "Active" : "Not Active"}</TableCell>
        <TableCell>
          <Button size="small" variant="outlined" color="secondary" onClick={() => setShowEdit(props.faqTableRow)} style={{ margin: "0 10px 0 0" }}>
            Edit
          </Button>
          <Button size="small" variant="outlined" color="secondary" {...listeners} {...attributes}>
            <DragVertical />
          </Button>
        </TableCell>
      </TableRow>
      <TableRow ref={setNodeRef} style={style}>
        <TableCell colSpan={6} sx={{ py: "0 !important" }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ m: 2 }}>
              <Typography variant="h6" gutterBottom component="div">
                Answers
              </Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <h4>Answer In English</h4>
                  <p>{props.faqTableRow.descriptionEN}</p>
                </Grid>
                <Grid item xs={6}>
                  <h4>Answer In Georgian</h4>
                  <p>{props.faqTableRow.descriptionGE}</p>
                </Grid>
              </Grid>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      {showEdit && <DialogEditQuestion onClose={() => setShowEdit(undefined)} question={showEdit} />}
    </Fragment>
  );
};

export default FaqTableRow;
