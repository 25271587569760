import { PostGetStatisticsForUserVacsAndAppsType } from "../../../../service";
import Grid from "@mui/material/Grid";

import "./index.sass";
import CardStatisticsBarChart from "../../../../utils/views/ui/cards/statistics/CardStatisticsBarChart";

// ** Icon imports
import Poll from "mdi-material-ui/Poll";
import TrendingUp from "mdi-material-ui/TrendingUp";
import CurrencyUsd from "mdi-material-ui/CurrencyUsd";
import AccountOutline from "mdi-material-ui/AccountOutline";
import Link from "mdi-material-ui/Link";
import CartPlus from "mdi-material-ui/CartPlus";
import WalletGiftcard from "mdi-material-ui/WalletGiftcard";
import BriefcaseOutline from "mdi-material-ui/BriefcaseOutline";

type props = {
  name: string;
  data: PostGetStatisticsForUserVacsAndAppsType;
};

const iconArray: {
  Icon: React.ReactNode;
  iconColor:
    | "primary"
    | "secondary"
    | "error"
    | "info"
    | "success"
    | "warning"
    | undefined;
}[] = [
  { Icon: <BriefcaseOutline />, iconColor: "primary" },
  { Icon: <AccountOutline />, iconColor: "success" },
  { Icon: <Poll />, iconColor: "error" },
  { Icon: <TrendingUp />, iconColor: "info" },
];

const handleData = (data: PostGetStatisticsForUserVacsAndAppsType) => {
  let array = [];
  for (const key in data) {
    const splitedText = key.split(/(?=[A-Z])/);
    const firstEle =
      splitedText[0].charAt(0).toUpperCase() + splitedText[0].slice(1);
    splitedText[0] = firstEle;
    array.push({
      name: splitedText.join(" "),
      //@ts-ignore
      value: data[key],
    });
  }
  return array;
};

const StatisticsComponent = ({ name, data }: props) => {
  return (
    <Grid sx={{ display: "flex", justifyContent: "center" }}>
      <Grid
        container
        sx={{
          mt: 5,
          justifyContent: "center",
          flex: "0.6",
          gap: "20px",
        }}
      >
        {Object.keys(data) &&
          handleData(data).map((el, key) => (
            <Grid item key={key}>
              <CardStatisticsBarChart
                name={el.name}
                value={el.value}
                Icon={iconArray[key].Icon}
                iconColor={iconArray[key].iconColor}
                minWidth={330}
              />
            </Grid>
          ))}
      </Grid>
    </Grid>
  );
};

export default StatisticsComponent;
