// ** MUI Imports
import Box from "@mui/material/Box";

// ** Chart Imports
import { useEffect, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useAppDispatch } from "../../store";
import { getStatistics, getTopAttractiveCompanies } from "../../store/apps/statisticsSlice";
import ChartjsHorizontalBarChart from "../../utils/views/charts/chartjs/ChartjsHorizontalBarChart";
import Grid from "@mui/material/Grid";

import "chart.js/auto";
import { logOut } from "../../store/apps/authSlice";
import { GetAttractiveCompaniesRequestType, GetStatisticsRequestType } from "../../service";
import CircularProgress from "@mui/material/CircularProgress";
import ChartjsBarChart from "../../utils/views/charts/chartjs/ChartjsBarChart";
import DateRange from "../statistics/Components/Date Range";
import { DateType } from "../../types/forms/reactDatepickerTypes";
import { generateQueryString } from "../../utils/helperFunctions/helpers";
import chartBarMaxValue from "../../utils/helperFunctions/chartBarMaxValue";
import ExportPDF from "../../utils/views/export/ExportPDF";

// ** colors
const horizontalBarInfo = "#26c6da";
const warningColorShade = "#ffbd1f";
const labelColor = "#ff9800";
const borderColor = "#28dac6";
const gridLineColor = "#787EFF";

const Dashboard: React.FC = () => {
  const [statisticsData, setStatisticsData] = useState<GetStatisticsRequestType>();
  const [attractiveStatistics, setAttractiveStatistics] = useState<GetAttractiveCompaniesRequestType[]>();
  const [loading, setLoading] = useState<boolean>(false);
  const [dateRange, setDateRange] = useState<number>(2);
  const [endDate, setEndDate] = useState<DateType>(new Date());
  const [startDate, setStartDate] = useState<DateType>(new Date());

  const chartRef = useRef<HTMLDivElement>(null);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setLoading(true);
    dispatch(getStatistics(generateQueryString(dateRange, startDate, endDate)))
      .unwrap()
      .then((response) => {
        const newData = {
          labels: [...response.labels],
          datasets: [
            {
              ...response.datasets[0],
              borderColor: "transparent",
              backgroundColor: warningColorShade,
              data: [...response.datasets[0].data],
              maxBarThickness: 15,
            },
          ],
        };
        setStatisticsData(newData);
      })
      .catch((response) => {
        toast.error(response.name);
        dispatch(logOut());
      });
    dispatch(getTopAttractiveCompanies(generateQueryString(dateRange, startDate, endDate)))
      .unwrap()
      .then((res) => {
        setAttractiveStatistics(res);
        setLoading(false);
      });
  }, [dateRange, endDate, startDate]);

  return (
    <Grid container spacing={6} className="match-height">
      <Grid item mb={2} mt={2}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
          }}
        >
          <DateRange setDateRange={setDateRange} dateRange={dateRange} endDate={endDate} setEndDate={setEndDate} startDate={startDate} setStartDate={setStartDate} />
          {chartRef.current && <ExportPDF disabled={loading} chartRef={chartRef} />}
        </Box>
      </Grid>
      <Grid
        container
        sx={{
          display: "block",
        }}
        spacing={6}
        ref={chartRef}
      >
        <Grid item xs={12}>
          {statisticsData && (
            <ChartjsBarChart
              title="Registered Users/Vacancies"
              yellow={warningColorShade}
              labelColor={labelColor}
              borderColor={borderColor}
              gridLineColor={gridLineColor}
              data={statisticsData}
              // max={chartBarMaxValue(statisticsData.datasets[0].data, 200)}
            />
          )}
        </Grid>
        {!attractiveStatistics || loading ? (
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Grid>
        ) : (
          attractiveStatistics.map((el) => {
            const newOption = {
              ...el.datasets[0],
              backgroundColor: gridLineColor,
            };
            const newData: GetAttractiveCompaniesRequestType = {
              ...el,
              datasets: [newOption],
            };
            return (
              <Grid item xs={12}>
                <ChartjsHorizontalBarChart
                  labelColor={labelColor}
                  info={horizontalBarInfo}
                  error="red"
                  borderColor={borderColor}
                  warning={warningColorShade}
                  gridLineColor={gridLineColor}
                  data={newData}
                  // max={chartBarMaxValue(newData.datasets[0].data, 500)}
                />
              </Grid>
            );
          })
        )}
      </Grid>
    </Grid>
  );
};

export default Dashboard;
