import { useCallback, useEffect, useState } from "react";

// ** Material
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Magnify from "mdi-material-ui/Magnify";
import IconButton from "@mui/material/IconButton";
import Close from "mdi-material-ui/Close";

// ** store
import { useAppDispatch } from "../../../../store";
import { DictionaryListType, DictionaryTypeEnum, GetDictionaryRequestType } from "../../../../service";

// ** components
import CountryItem from "./CountryItem";
import { Modal as DeleteModal } from "../../../../utils/views/modal/modal";

// ** 3rd party
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors, DragEndEvent } from "@dnd-kit/core";
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from "@dnd-kit/sortable";

// ** style
import classes from "./styles.module.sass";

// ** types
import { countryDataType } from "../../../../types/apps/attributeTypes";
import { getParentDictionary, postDeleteDictionary } from "../../../../store/apps/attributesSlice";
import toast from "react-hot-toast";
import { Typography } from "@mui/material";

type parentType = DictionaryListType & {
  childData?: updateCityType[];
};

type updateCityType = DictionaryListType & {
  modified?: boolean;
};

type CountryAttributePropType = {
  section: DictionaryTypeEnum;
  parentName?: string;
  childName?: string;
  updateReorder({ currentPosition, newPosition }: { currentPosition: number; newPosition: number }): void;
};

const CountryAttribute = ({ section, updateReorder, parentName = "Country", childName = "City" }: CountryAttributePropType) => {
  const dispatch = useAppDispatch();
  const [data, setData] = useState<parentType[]>([]);
  const [searchVal, setSearchVal] = useState<string>("");
  const [mainDropDown, setmainDropDown] = useState<string | null>(null);
  const [deleteData, setDeleteData] = useState<{ name: string; id: string | null; dictionaryType?: number } | null>(null);

  const childSection = section === DictionaryTypeEnum.Country ? DictionaryTypeEnum.City : DictionaryTypeEnum.JobFunction;

  const initData = useCallback(async () => {
    const response = await dispatch(getParentDictionary(section)).unwrap();
    const childResponse = await dispatch(getParentDictionary(childSection)).unwrap();
    const newData: parentType[] = response.data.dictionaryList.map((el) => ({
      ...el,
      childData: childResponse.data.dictionaryList.filter((city) => city.parentDictionary?.name.toLowerCase() === el.name.toLowerCase()),
    }));
    setData(newData);
  }, [section, updateReorder]);

  useEffect(() => {
    initData();
  }, [initData]);

  const addCountry = () => {
    const newCountry: DictionaryListType = {
      id: "new",
      description: null,
      iconUrl: "",
      parentDictionary: null,
      childDictionaries: null,
      descriptionGE: null,
      name: "",
      nameGE: "",
    };
    setData([newCountry, ...data]);
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;
    if (over && active.id !== over!.id) {
      const oldIndex = data.findIndex((item) => item.id === active.id);
      const newIndex = data.findIndex((item) => item.id === over!.id);
      updateReorder({ currentPosition: oldIndex, newPosition: newIndex + 1 });
      const newOrderedList = [...arrayMove(data, oldIndex, newIndex)];
      const updatedArrayList = [];
      for (let i = 0; i < newOrderedList.length; i++) {
        updatedArrayList.push({ ...newOrderedList[i], orderIndex: i });
      }
      setData(updatedArrayList);
    }
  };

  const handleDeleteData = async (dictionaryType: number = 11) => {
    try {
      await dispatch(postDeleteDictionary({ dictionaryType, itemId: deleteData?.id })).unwrap();
      setDeleteData(null);
      initData();
      toast.success(`${deleteData?.name} deleted sucessfully`);
    } catch (err) {
      toast.error(typeof err === "string" ? err : "Something went wrong");
    }
  };

  return (
    <>
      <DeleteModal show={!!deleteData} onClose={() => setDeleteData(null)} submitAction={() => handleDeleteData(deleteData?.dictionaryType)} submitBtnColor="error" submitText="Delete">
        <Typography>
          Are you sure you want to delete <b>{deleteData?.name}</b>?
        </Typography>
      </DeleteModal>
      <Card>
        <CardHeader title={`${parentName} & ${childName}`} />
        <Box className={classes.countryTable}>
          <Box className={classes.headerWrapper}>
            <Button onClick={addCountry}>Add {parentName}</Button>
            <TextField
              variant="standard"
              value={searchVal}
              onChange={(e) => setSearchVal(e.target.value)}
              placeholder="Search…"
              InputProps={{
                startAdornment: <Magnify fontSize="small" />,
                endAdornment: (
                  <IconButton size="small" title="Clear" aria-label="Clear" onClick={() => setSearchVal("")}>
                    <Close fontSize="small" />
                  </IconButton>
                ),
              }}
              sx={{
                width: {
                  xs: 1,
                  sm: "auto",
                },
                m: (theme) => theme.spacing(1, 0.5, 1.5),
                "& .MuiSvgIcon-root": {
                  mr: 0.5,
                },
                "& .MuiInput-underline:before": {
                  borderBottom: 1,
                  borderColor: "divider",
                },
              }}
            />
          </Box>
          <Box className={classes.contentWrapper}>
            <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd} onDragStart={() => setmainDropDown("")}>
              <SortableContext items={data.map((items) => items.id || "")} strategy={verticalListSortingStrategy}>
                {data
                  .filter((el) => el.name?.toLowerCase().includes(searchVal.toLowerCase()))
                  .map((el: DictionaryListType, idx) => (
                    <Box key={el.id}>
                      <CountryItem
                        open={mainDropDown}
                        setOpen={setmainDropDown}
                        data={el}
                        section={childSection}
                        refetchData={initData}
                        setDeleteData={(data, dictionaryType) => setDeleteData({ name: data.name, id: data.id, dictionaryType })}
                      />
                    </Box>
                  ))}
              </SortableContext>
            </DndContext>
          </Box>
        </Box>
      </Card>
    </>
  );
};

export default CountryAttribute;
