import ReactDOM from "react-dom";
import { Box, Button, Typography, Input } from "@mui/material";
import TextField from "@mui/material/TextField";
import useOutsideClick from "../../../hooks/useOutsideclick";
import { useRef, useState } from "react";

const ReorderModal = ({
  onClose,
  currentPosition,
  updatePosition,
}: {
  onClose: () => void;
  currentPosition: number;
  updatePosition: ({ currentPosition, newPosition }: { currentPosition: number; newPosition: number }) => void;
}) => {
  const modalRef = useRef<HTMLDivElement>(null);
  const [position, setPosition] = useState<number>(currentPosition + 1);
  useOutsideClick(modalRef, onClose);
  return ReactDOM.createPortal(
    <Box sx={{ position: "fixed", left: "0", top: "0", height: "100%", width: "100%", display: "flex", alignItems: "center", justifyContent: "center", background: "rgba(0, 0, 0, 0.3)" }}>
      <Box sx={{ background: "#ffffff", padding: 5, borderRadius: "8px" }} ref={modalRef}>
        <TextField placeholder={`current position: ${currentPosition + 1}`} label="Position" type="number" value={position} onChange={(e) => setPosition(parseInt(e.target.value))} />
        <Box sx={{ display: "flex", alignItems: "center", justifyContent: "space-evenly", marginTop: 3 }}>
          <Button onClick={() => updatePosition({ currentPosition, newPosition: position })}>Save</Button>
          <Button color="error" onClick={onClose}>
            Cancel
          </Button>
        </Box>
      </Box>
    </Box>,
    document.getElementById("reorderModal") as HTMLDivElement
  );
};

export default ReorderModal;
