import React, { RefObject, useEffect, useRef, useState } from "react";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";

import {
  getAdminsRequestParams,
  getApplicationStatisticsRequest,
  getPopularCompanyStatisticsRequest,
  getPopularVacancyStatisticsRequest,
  getSearchAdminsRequest,
  getSearchCompaniesRequest,
  getSearchTalentsRequest,
  GetSearchTalentsRequestType,
  getSearchVacancyRequest,
  getSearchVacancyType,
} from "../../../../service";

import { CSVLink } from "react-csv";

import classes from "./styles.module.sass";
import { generateQueryString } from "../../../helperFunctions/helpers";
import { handleObjectForQueryString } from "../../../helperFunctions";
import { AxiosResponse } from "axios";
import usePrevious from "../../../../hooks/usePrevious";

interface MyCSSProperties extends React.CSSProperties {
  "::after"?: React.CSSProperties;
  "::before"?: React.CSSProperties;
}

interface exportPropTypes {
  componentSection: string;
  attributes?: boolean;
  exportFilter?: {
    dateFilter?: {
      dateRange: number;
      startDate?: Date;
      endDate?: Date;
    };
    searchTerm?: string;
    filterObject?: getSearchVacancyType;
    selectedCheckboxes?: string[];
  };
}

function ExportCSV({ componentSection, attributes, exportFilter }: exportPropTypes) {
  const [data, setData] = useState<GetSearchTalentsRequestType["data"]>([]);
  const prevData = usePrevious(data);
  const [loading, setLoading] = useState<boolean>(false);
  const csvLinkRef: RefObject<any> = useRef(null);
  const sectionName = componentSection.replace(" ", "").toLocaleLowerCase();
  const emptyData = data.length === 0 || loading;

  const makeAPICall = async (apiFunction: Promise<AxiosResponse>) => {
    const res = await apiFunction;
    if (Array.isArray(res.data)) {
      setData(res.data);
    } else {
      setData(res.data.data);
    }
    setLoading(false);
    return;
  };

  const fetchData = async () => {
    setLoading(true);
    let filter = generateQueryString(exportFilter?.dateFilter?.dateRange, exportFilter?.dateFilter?.startDate, exportFilter?.dateFilter?.endDate, exportFilter?.selectedCheckboxes);
    const pageSize = 999999;

    switch (sectionName) {
      case "companies":
        await makeAPICall(
          getSearchCompaniesRequest(
            handleObjectForQueryString({ ...exportFilter?.filterObject, SearchTerm: exportFilter?.searchTerm, PageSize: pageSize, SearchIds: exportFilter?.selectedCheckboxes }, 1)
          )
        );
        break;
      case "talents":
        await makeAPICall(
          getSearchTalentsRequest(
            handleObjectForQueryString({ ...exportFilter?.filterObject, SearchTerm: exportFilter?.searchTerm, PageSize: pageSize, SearchIds: exportFilter?.selectedCheckboxes }, 1)
          )
        );
        break;
      case "vacancies":
        await makeAPICall(
          getSearchVacancyRequest({ ...exportFilter?.filterObject, SearchTerm: exportFilter?.filterObject?.SearchTerm, PageSize: pageSize, PageNumber: 1, SearchIds: exportFilter?.selectedCheckboxes })
        );
        break;
      case "admins":
        await makeAPICall(getSearchAdminsRequest({ pageNumber: 1, searchTerm: exportFilter?.searchTerm, pageSize: pageSize, SearchIds: exportFilter?.selectedCheckboxes } as getAdminsRequestParams));
        break;
      case "popularvacancies":
        await makeAPICall(getPopularVacancyStatisticsRequest(filter));
        break;
      case "popularcompanies":
        await makeAPICall(getPopularCompanyStatisticsRequest(filter));
        break;
      case "applications":
        await makeAPICall(getApplicationStatisticsRequest(filter));
        break;

      default:
        setLoading(false);
        break;
    }
  };

  useEffect(() => {
    if (data.length > 0 && prevData !== data) {
      csvLinkRef.current.link.click();
    }
  }, [data]);

  const containerStyles: MyCSSProperties = {
    pointerEvents: loading ? "none" : "auto",
    color: loading ? "gray" : "#666CFF",
  };

  if (attributes) return <></>;

  return (
    <>
      <Box style={containerStyles} className={`${classes.wrapper} ${loading && classes.showToolTip}`}>
        <Box className={`${classes.boxWrapper} ${loading && classes.showToolTip}`} onClick={fetchData}>
          <FileDownloadIcon />
          <Typography
            sx={{
              color: loading ? "gray" : "#666CFF",
              fontSize: "0.875rem",
              lineHeight: "1.429",
              letterSpacing: "0.15px",
            }}
          >
            Export
          </Typography>
        </Box>
      </Box>
      <CSVLink data={data} style={containerStyles} asyncOnClick={true} ref={csvLinkRef} className={`${classes.wrapper} ${loading && classes.showToolTip}`}></CSVLink>
    </>
  );
}

export default ExportCSV;
