import axiosInstance from "../../axios/axiosInstance";
import { getCompanyBenefitsRequestType } from "../../types/apps/companyTypes";
import {
  PostUpdateCompanyBenefitsRequestParams,
  PostUpdateCompanyVerifyOrActiveRequestType,
  PostUpdateTalentsRequestParams,
  UpdateCompanyEmailAndSendHandoverRequestType,
} from "./params";
import {
  CompanyValues,
  GetSearchTalentsRequestType,
  GetTalentRequestType,
} from "./types";


export const getSearchCompaniesRequest = (queryString: string) => {
  return axiosInstance.get(
    `api/BackOfficeCompanyTalent/SearchCompanies?${queryString}`
  );
};

export const getGetCompanyRequest = (id: string) => {
  return axiosInstance.get(`api/BackOfficeCompanyTalent/GetCompany?userId=${id}`);
};

export const postUpdateCompanyRequest = (object: CompanyValues) => {
  return axiosInstance.post(`api/BackOfficeCompanyTalent/UpdateCompany`, object);
};

export const getSearchTalentsRequest = (
  queryString: string
) => {
  return axiosInstance.get<GetSearchTalentsRequestType>(
    `api/BackOfficeCompanyTalent/SearchTalents?${queryString}`
  );
};

export const getTalentRequest = (id: string) => {
  return axiosInstance.get<GetTalentRequestType>(
    `api/BackOfficeCompanyTalent/GetTalent?userId=${id}`
  );
};

export const postUpdateTalentRequest = (params: PostUpdateTalentsRequestParams) => {
  return axiosInstance.post(
    `api/BackOfficeCompanyTalent/UpdateTalent`,
    params.body
  );
};

export const postDeleteCompanyOrTalent = (userId: string) => {
  return axiosInstance.post(`api/BackOfficeCompanyTalent/DeleteCompanyOrTalent/?userId=${userId}`)
}

export const postUpdateCompanyVerifyOrActiveRequest = (object: PostUpdateCompanyVerifyOrActiveRequestType) => {
  return axiosInstance.post(`api/BackOfficeCompanyTalent/UpdateCompanyVerifyOrActive`, object.body);
};

export const postUpdateCompanyEmailAndSendHandoverRequest = (object: UpdateCompanyEmailAndSendHandoverRequestType) => {
  return axiosInstance.post(`api/BackOfficeCompanyTalent/UpdateCompanyEmailAndSendHandover`, object.body);
};

export const getCompanyBenefitsRequest = (id: string) => {
  return axiosInstance.get<getCompanyBenefitsRequestType>(`api/BackOfficeBenefits/GetCompanyBenefits?companyId=${id}`)
}

export const updateCompanyBenefitsRequest = (id: string, data: PostUpdateCompanyBenefitsRequestParams[]) => {
  return axiosInstance.post<{data: []}>(`api/BackOfficeBenefits/updateCompanyBenefits?companyId=${id}`, data)
}