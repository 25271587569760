// ** React Imports
import React, { ReactNode } from "react";
import { ReactComponent as MainLogo } from "../../assets/SVG/Techwave.ge-Dark.svg";

// ** Next Imports
// import Link from 'next/link'
import { Link } from "react-router-dom";

// ** MUI components
import Box from "@mui/material/Box";
import MuiLink from "@mui/material/Link";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import { styled, useTheme } from "@mui/material/styles";
import MuiCard, { CardProps } from "@mui/material/Card";

// ** Icons Imports
import ChevronLeft from "mdi-material-ui/ChevronLeft";

// ** Configs
import themeConfig from "../../configs/themeConfig";

// ** Layout Import
import BlankLayout from "../../components/@core/layouts/BlankLayout";

// ** Demo Imports
import FooterIllustrationsV1 from "../../utils/views/pages/auth/FooterIllustrationsV1";

// ** State imports
import { postSendPasswordRecoveryLink } from "../../store/apps/authSlice";
import { RootState, useAppDispatch } from "../../store";

// ** Styled components
const Card = styled(MuiCard)<CardProps>(({ theme }) => ({
  [theme.breakpoints.up("sm")]: { width: 450 },
}));

const ForgotPasswordV1 = () => {
  // ** Hook
  const theme = useTheme();
  const dispatch = useAppDispatch();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const input = e.currentTarget[0] as HTMLInputElement;
    dispatch(
      postSendPasswordRecoveryLink({ body: { email: input.value } })
    ).unwrap();
  };

  return (
    <Box className="content-center">
      <Card sx={{ zIndex: 1 }}>
        <CardContent
          sx={{ p: (theme) => `${theme.spacing(15.5, 7, 8)} !important` }}
        >
          <Box
            sx={{
              mb: 8,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <MainLogo style={{ height: "30px", width: "30px" }} />
            {/* <svg
              width={47}
              fill="none"
              height={26}
              viewBox="0 0 268 150"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                rx="25.1443"
                width="50.2886"
                height="143.953"
                fill={theme.palette.primary.main}
                transform="matrix(-0.865206 0.501417 0.498585 0.866841 195.571 0)"
              />
              <rect
                rx="25.1443"
                width="50.2886"
                height="143.953"
                fillOpacity="0.4"
                fill="url(#paint0_linear_7821_79167)"
                transform="matrix(-0.865206 0.501417 0.498585 0.866841 196.084 0)"
              />
              <rect
                rx="25.1443"
                width="50.2886"
                height="143.953"
                fill={theme.palette.primary.main}
                transform="matrix(0.865206 0.501417 -0.498585 0.866841 173.147 0)"
              />
              <rect
                rx="25.1443"
                width="50.2886"
                height="143.953"
                fill={theme.palette.primary.main}
                transform="matrix(-0.865206 0.501417 0.498585 0.866841 94.1973 0)"
              />
              <rect
                rx="25.1443"
                width="50.2886"
                height="143.953"
                fillOpacity="0.4"
                fill="url(#paint1_linear_7821_79167)"
                transform="matrix(-0.865206 0.501417 0.498585 0.866841 94.1973 0)"
              />
              <rect
                rx="25.1443"
                width="50.2886"
                height="143.953"
                fill={theme.palette.primary.main}
                transform="matrix(0.865206 0.501417 -0.498585 0.866841 71.7728 0)"
              />
              <defs>
                <linearGradient
                  y1="0"
                  x1="25.1443"
                  x2="25.1443"
                  y2="143.953"
                  id="paint0_linear_7821_79167"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop />
                  <stop offset="1" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                  y1="0"
                  x1="25.1443"
                  x2="25.1443"
                  y2="143.953"
                  id="paint1_linear_7821_79167"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop />
                  <stop offset="1" stopOpacity="0" />
                </linearGradient>
              </defs>
            </svg> */}
            <Typography
              variant="h6"
              sx={{
                ml: 2,
                lineHeight: 1,
                fontWeight: 700,
                fontSize: "1.5rem !important",
              }}
            >
              {themeConfig.templateName}
            </Typography>
          </Box>
          <Box sx={{ mb: 6.5 }}>
            <Typography
              variant="h5"
              sx={{ mb: 1.5, letterSpacing: "0.18px", fontWeight: 600 }}
            >
              Forgot Password? 🔒
            </Typography>
            <Typography variant="body2">
              Enter your email and we&prime;ll send you instructions to reset
              your password
            </Typography>
          </Box>
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <TextField
              autoFocus
              type="email"
              label="Email"
              sx={{ display: "flex", mb: 4 }}
            />
            <Button
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              sx={{ mb: 5.25 }}
            >
              Send reset link
            </Button>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Link to={"/login"}>
                <Typography
                  component={MuiLink}
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    color: "primary.main",
                    justifyContent: "center",
                  }}
                >
                  <ChevronLeft sx={{ mr: 1.5, fontSize: "2rem" }} />
                  <span>Back to login</span>
                </Typography>
              </Link>
            </Typography>
          </form>
        </CardContent>
      </Card>

      <FooterIllustrationsV1
        image={`/images/pages/auth-v1-forgot-password-mask-${theme.palette.mode}.png`}
      />
    </Box>
  );
};

ForgotPasswordV1.getLayout = (page: ReactNode) => (
  <BlankLayout>{page}</BlankLayout>
);

export default ForgotPasswordV1;
