// ** Icon imports
import Apps from 'mdi-material-ui/Apps'
import Menu from 'mdi-material-ui/Menu'
import Table from 'mdi-material-ui/Table'
import Lifebuoy from 'mdi-material-ui/Lifebuoy'
import ChartLine from 'mdi-material-ui/ChartLine'
import CogOutline from 'mdi-material-ui/CogOutline'
import ChartDonut from 'mdi-material-ui/ChartDonut'
import FormSelect from 'mdi-material-ui/FormSelect'
import CurrencyUsd from 'mdi-material-ui/CurrencyUsd'
import CartOutline from 'mdi-material-ui/CartOutline'
import CubeOutline from 'mdi-material-ui/CubeOutline'
import LockOutline from 'mdi-material-ui/LockOutline'
import FileOutline from 'mdi-material-ui/FileOutline'
import HomeOutline from 'mdi-material-ui/HomeOutline'
import EmailOutline from 'mdi-material-ui/EmailOutline'
import ShieldOutline from 'mdi-material-ui/ShieldOutline'
import EyeOffOutline from 'mdi-material-ui/EyeOffOutline'
import DotsHorizontal from 'mdi-material-ui/DotsHorizontal'
import AccountOutline from 'mdi-material-ui/AccountOutline'
import ArchiveOutline from 'mdi-material-ui/ArchiveOutline'
import ChartBellCurve from 'mdi-material-ui/ChartBellCurve'
import MessageOutline from 'mdi-material-ui/MessageOutline'
import BookOpenOutline from 'mdi-material-ui/BookOpenOutline'
import FormatLetterCase from 'mdi-material-ui/FormatLetterCase'
import HelpCircleOutline from 'mdi-material-ui/HelpCircleOutline'
import CreditCardOutline from 'mdi-material-ui/CreditCardOutline'
import VectorArrangeBelow from 'mdi-material-ui/VectorArrangeBelow'
import FileDocumentOutline from 'mdi-material-ui/FileDocumentOutline'
import CalendarBlankOutline from 'mdi-material-ui/CalendarBlankOutline'
import ChartTimelineVariant from 'mdi-material-ui/ChartTimelineVariant'
import PackageVariantClosed from 'mdi-material-ui/PackageVariantClosed'
import PaletteSwatchOutline from 'mdi-material-ui/PaletteSwatchOutline'
import CheckboxMarkedOutline from 'mdi-material-ui/CheckboxMarkedOutline'
import GoogleCirclesExtended from 'mdi-material-ui/GoogleCirclesExtended'
import ChartBellCurveCumulative from 'mdi-material-ui/ChartBellCurveCumulative'
import CheckboxMarkedCircleOutline from 'mdi-material-ui/CheckboxMarkedCircleOutline'

import ContentCopy from 'mdi-material-ui/ContentDuplicate'
import OfficeBuilding from 'mdi-material-ui/OfficeBuilding'
import FaceManOutline from 'mdi-material-ui/FaceManOutline'
import TextBoxCheckOutline from 'mdi-material-ui/TextBoxCheckOutline'
import Account from 'mdi-material-ui/Account'
import FileSettings from 'mdi-material-ui/FileSettings'
import File from 'mdi-material-ui/FileSign'


// ** Type import
import { HorizontalNavItemsType } from '../../components/@core/layouts/types'

const navigation = (): HorizontalNavItemsType => {
  return [
    // {
    //   icon: HomeOutline,
    //   title: 'Dashboards',
    //   children: [
    //     {
    //       icon: ChartDonut,
    //       title: 'CRM',
    //       path: '/dashboards/crm'
    //     },
    //     {
    //       icon: ChartTimelineVariant,
    //       title: 'Analytics',
    //       path: '/dashboards/analytics'
    //     },
    //     {
    //       icon: CartOutline,
    //       title: 'eCommerce',
    //       path: '/dashboards/ecommerce'
    //     }
    //   ]
    // },
        {
      title: 'Dashboard',
      path: '/',
      icon: HomeOutline
    },
    {
      title: 'Companies',
      path: '/pages/companies',
      icon: OfficeBuilding
    },
    {
      title: 'Vacancies',
      path: '/pages/vacancies',
      icon:  TextBoxCheckOutline
    },
    {
      title: 'Admin Users',
      path: '/pages/adminusers',
      icon: Account
    },
    {
      title: 'Seo',
      path: '/pages/seo',
      icon: FileSettings
    },
    {
      title: 'Statistics',
      icon: ChartDonut,
      children: [
      {
        openInNewTab: true,
        title: 'Registrations',
        path: '/pages/statistics/registrations'
      },
      {
        openInNewTab: true,
        title: 'Active Day',
        path: '/pages/statistics/active-day'
      },
      {
        openInNewTab: true,
        title: 'Submitted Vacancies',
        path: '/pages/statistics/submitted-vacancies'
      },
      {
        openInNewTab: true,
        title: 'Popular Vacancies',
        path: '/pages/statistics/popular-vacancies'
      },
      {
        openInNewTab: true,
        title: 'Popular Companies',
        path: '/pages/statistics/popular-companies'
      },
      {
        openInNewTab: true,
        title: 'Applications',
        path: '/pages/statistics/applications'
      },
    ]
    },
    {
      title: 'Attributes',
      icon: File,
      children: [
      {
        openInNewTab: true,
        title: 'Technologies',
        path: '/pages/attributes/technologies'
      },
      {
        openInNewTab: true,
        title: 'Tech. Categories',
        path: '/pages/attributes/tech-categories'
      },
      {
        openInNewTab: true,
        title: 'Employment Type',
        path: '/pages/attributes/employment-type'
      },
      {
        openInNewTab: true,
        title: 'Positions',
        path: '/pages/attributes/positions'
      },
      {
        openInNewTab: true,
        title: 'Work Location',
        path: '/pages/attributes/work-location'
      },
      {
        openInNewTab: true,
        title: 'Benefit',
        path: '/pages/attributes/benefit'
      },
      {
        openInNewTab: true,
        title: 'City',
        path: '/pages/attributes/city'
      },
      {
        openInNewTab: true,
        title: 'Country',
        path: '/pages/attributes/country'
      },
      {
        openInNewTab: true,
        title: 'Field',
        path: '/pages/attributes/field'
      },
      {
        openInNewTab: true,
        title: 'Experience',
        path: '/pages/attributes/experience'
      }
    ]
    },

    {
      title: 'Contents',
      icon: ContentCopy,
      children: [
      {
        openInNewTab: true,
        title: 'About Us',
        path: '/pages/contents/about-us'
      },
      {
        openInNewTab: true,
        title: 'Support',
        path: '/pages/contents/support'
      },
      {
        openInNewTab: true,
        title: 'Privacy Policy',
        path: '/pages/contents/privacy-policy'
      },
      {
        openInNewTab: true,
        title: 'Terms Of Use',
        path: '/pages/contents/terms-of-use'
      },
      {
        openInNewTab: true,
        title: 'Faq',
        path: '/pages/contents/faq'
      },
      {
        openInNewTab: true,
        title: 'Website hero image',
        path: '/pages/contents/website-hero-image'
      },
    ]
    },

  ]
}

// const navigation = (): HorizontalNavItemsType => {
//   return [
//     // {
//     //   title: 'Dashboards',
//     //   icon: HomeOutline,
//     //   badgeContent: 'new',
//     //   badgeColor: 'error',
//     //   children: [
//     //     {
//     //       title: 'CRM',
//     //       path: '/dashboards/crm'
//     //     },
//     //     {
//     //       title: 'Analytics',
//     //       path: '/dashboards/analytics'
//     //     },
//     //     {
//     //       title: 'eCommerce',
//     //       path: '/dashboards/ecommerce'
//     //     }
//     //   ]
//     // },
//     // {
//     //   sectionTitle: 'Apps & Pages'
//     // },
//     // {
//     //   title: 'Email',
//     //   icon: EmailOutline,
//     //   path: '/apps/email'
//     // },
//     // {
//     //   title: 'Chat',
//     //   icon: MessageOutline,
//     //   path: '/apps/chat'
//     // },
//     // {
//     //   title: 'Calendar',
//     //   icon: CalendarBlankOutline,
//     //   path: '/apps/calendar'
//     // },
//     {
//       title: 'Dashboard',
//       path: '/',
//       icon: HomeOutline
//     },
//     {
//       title: 'Companies',
//       path: '/pages/companies',
//       icon: OfficeBuilding
//     },
//     {
//       title: 'Talents',
//       path: '/pages/talents',
//       icon: FaceManOutline
//     },
//     {
//       title: 'Vacancies',
//       path: '/pages/vacancies',
//       icon:  TextBoxCheckOutline
//     },
//     {
//       title: 'Admin Users',
//       path: '/pages/adminusers',
//       icon: Account
//     },
//     {
//       title: 'Seo',
//       path: '/pages/seo',
//       icon: FileSettings
//     },
//     {
//       title: 'Attributes',
//       icon: File,
//       children: [
//       {
//         openInNewTab: true,
//         title: 'Technologies',
//         path: '/pages/attributes/technologies'
//       },
//       {
//         openInNewTab: true,
//         title: 'Tech. Categories',
//         path: '/pages/attributes/tech-categories'
//       },
//       {
//         openInNewTab: true,
//         title: 'Employment Type',
//         path: '/pages/attributes/employment-type'
//       },
//       {
//         openInNewTab: true,
//         title: 'Positions',
//         path: '/pages/attributes/positions'
//       },
//       {
//         openInNewTab: true,
//         title: 'Work Location',
//         path: '/pages/attributes/work-location'
//       },
//       {
//         openInNewTab: true,
//         title: 'Benefit',
//         path: '/pages/attributes/benefit'
//       },
//       {
//         openInNewTab: true,
//         title: 'City',
//         path: '/pages/attributes/city'
//       },
//       {
//         openInNewTab: true,
//         title: 'Field',
//         path: '/pages/attributes/field'
//       },
//       {
//         openInNewTab: true,
//         title: 'Experience',
//         path: '/pages/attributes/experience'
//       }
//     ]
//     },
//     {
//       title: 'Contents',
//       icon: ContentCopy,
//       children: [
//       {
//         openInNewTab: true,
//         title: 'About Us',
//         path: '/pages/contents/about-us'
//       },
//       {
//         openInNewTab: true,
//         title: 'Support',
//         path: '/pages/contents/support'
//       },
//       {
//         openInNewTab: true,
//         title: 'Privacy Policy',
//         path: '/pages/contents/privacy-policy'
//       },
//       {
//         openInNewTab: true,
//         title: 'Terms Of Use',
//         path: '/pages/contents/terms-of-use'
//       },
//       {
//         openInNewTab: true,
//         title: 'Faq',
//         path: '/pages/contents/faq'
//       },
//     ]
//     },
//     // {
//     //   title: 'Invoice',
//     //   icon: FileDocumentOutline,
//     //   children: [
//     //     {
//     //       title: 'List',
//     //       path: '/apps/invoice/list'
//     //     },
//     //     {
//     //       title: 'Preview',
//     //       path: '/apps/invoice/preview'
//     //     },
//     //     {
//     //       title: 'Edit',
//     //       path: '/apps/invoice/edit'
//     //     },
//     //     {
//     //       title: 'Add',
//     //       path: '/apps/invoice/add'
//     //     }
//     //   ]
//     // },
//     // {
//     //   title: 'User',
//     //   icon: AccountOutline,
//     //   children: [
//     //     {
//     //       title: 'List',
//     //       path: '/apps/user/list'
//     //     },
//     //     {
//     //       title: 'View',
//     //       path: '/apps/user/view'
//     //     }
//     //   ]
//     // },
//     // {
//     //   title: 'Roles & Permissions',
//     //   icon: LockOutline,
//     //   children: [
//     //     {
//     //       title: 'Roles',
//     //       path: '/apps/roles'
//     //     },
//     //     {
//     //       title: 'Permissions',
//     //       path: '/apps/permissions'
//     //     }
//     //   ]
//     // },
//     // {
//     //   title: 'Pages',
//     //   icon: FileDocumentOutline,
//     //   children: [
//     //     // {
//     //     //   title: 'Authentication',
//     //     //   children: [
//     //     //     {
//     //     //       title: 'Login',
//     //     //       children: [
//     //     //         {
//     //     //           openInNewTab: true,
//     //     //           title: 'Login v1',
//     //     //           path: '/pages/auth/login-v1'
//     //     //         },
//     //     //         {
//     //     //           openInNewTab: true,
//     //     //           title: 'Login v2',
//     //     //           path: '/pages/auth/login-v2'
//     //     //         },
//     //     //         {
//     //     //           openInNewTab: true,
//     //     //           title: 'Login With AppBar',
//     //     //           path: '/pages/auth/login-with-appbar'
//     //     //         }
//     //     //       ]
//     //     //     },
//     //     //     {
//     //     //       title: 'Register',
//     //     //       children: [
//     //     //         {
//     //     //           openInNewTab: true,
//     //     //           title: 'Register v1',
//     //     //           path: '/pages/auth/register-v1'
//     //     //         },
//     //     //         {
//     //     //           openInNewTab: true,
//     //     //           title: 'Register v2',
//     //     //           path: '/pages/auth/register-v2'
//     //     //         }
//     //     //       ]
//     //     //     },
//     //     //     {
//     //     //       title: 'Forgot Password',
//     //     //       children: [
//     //     //         {
//     //     //           openInNewTab: true,
//     //     //           title: 'Forgot Password v1',
//     //     //           path: '/pages/auth/forgot-password-v1'
//     //     //         },
//     //     //         {
//     //     //           openInNewTab: true,
//     //     //           title: 'Forgot Password v2',
//     //     //           path: '/pages/auth/forgot-password-v2'
//     //     //         }
//     //     //       ]
//     //     //     },
//     //     //     {
//     //     //       title: 'Reset Password',
//     //     //       children: [
//     //     //         {
//     //     //           openInNewTab: true,
//     //     //           title: 'Reset Password v1',
//     //     //           path: '/pages/auth/reset-password-v1'
//     //     //         },
//     //     //         {
//     //     //           openInNewTab: true,
//     //     //           title: 'Reset Password v2',
//     //     //           path: '/pages/auth/reset-password-v2'
//     //     //         }
//     //     //       ]
//     //     //     }
//     //     //   ]
//     //     // },
//     //     // {
//     //     //   title: 'Account Settings',
//     //     //   path: '/pages/account-settings'
//     //     // },
//     //     // {
//     //     //   title: 'Dashboard',
//     //     //   path: '/',
//     //     //   icon: HomeOutline
//     //     // },
//     //     // {
//     //     //   title: 'Companies',
//     //     //   path: '/pages/companies'
//     //     // },
//     //     // {
//     //     //   title: 'Talents',
//     //     //   path: '/pages/talents'
//     //     // },
//     //     // {
//     //     //   title: 'Vacancies',
//     //     //   path: '/pages/vacancies'
//     //     // },
//     //     // {
//     //     //   title: 'Admin Users',
//     //     //   path: '/pages/adminusers'
//     //     // },
//     //     // {
//     //     //   title: 'Seo',
//     //     //   path: '/pages/seo'
//     //     // },
//     //     // {
//     //     //   title: 'Contents',
//     //     //   children: [
//     //     //   {
//     //     //     openInNewTab: true,
//     //     //     title: 'About Us',
//     //     //     path: '/pages/contents/about-us'
//     //     //   },
//     //     //   {
//     //     //     openInNewTab: true,
//     //     //     title: 'Support',
//     //     //     path: '/pages/contents/support'
//     //     //   },
//     //     //   {
//     //     //     openInNewTab: true,
//     //     //     title: 'Privacy Policy',
//     //     //     path: '/pages/contents/privacy-policy'
//     //     //   },
//     //     //   {
//     //     //     openInNewTab: true,
//     //     //     title: 'Terms Of Use',
//     //     //     path: '/pages/contents/terms-of-use'
//     //     //   },
//     //     //   {
//     //     //     openInNewTab: true,
//     //     //     title: 'Faq',
//     //     //     path: '/pages/contents/faq'
//     //     //   },
//     //     // ]
//     //     // },
//     //     // {
//     //     //   title: 'Attributes',
//     //     //   children: [
//     //     //   {
//     //     //     openInNewTab: true,
//     //     //     title: 'Technologies',
//     //     //     path: '/pages/attributes/technologies'
//     //     //   },
//     //     //   {
//     //     //     openInNewTab: true,
//     //     //     title: 'Tech. Categories',
//     //     //     path: '/pages/attributes/tech-categories'
//     //     //   },
//     //     //   {
//     //     //     openInNewTab: true,
//     //     //     title: 'Employment Type',
//     //     //     path: '/pages/attributes/employment-type'
//     //     //   },
//     //     //   {
//     //     //     openInNewTab: true,
//     //     //     title: 'Positions',
//     //     //     path: '/pages/attributes/positions'
//     //     //   },
//     //     //   {
//     //     //     openInNewTab: true,
//     //     //     title: 'Work Location',
//     //     //     path: '/pages/attributes/work-location'
//     //     //   },
//     //     //   {
//     //     //     openInNewTab: true,
//     //     //     title: 'Benefit',
//     //     //     path: '/pages/attributes/benefit'
//     //     //   },
//     //     //   {
//     //     //     openInNewTab: true,
//     //     //     title: 'City',
//     //     //     path: '/pages/attributes/city'
//     //     //   },
//     //     //   {
//     //     //     openInNewTab: true,
//     //     //     title: 'Field',
//     //     //     path: '/pages/attributes/field'
//     //     //   },
//     //     //   {
//     //     //     openInNewTab: true,
//     //     //     title: 'Experience',
//     //     //     path: '/pages/attributes/experience'
//     //     //   }
//     //     // ]
//     //     // },
//     //     // {
//     //     //   title: 'Contents',
//     //     //   path: '/pages/contents'
//     //     // },
//     //     // {
//     //     //   title: 'Attributes',
//     //     //   path: '/pages/contents'
//     //     // }
//     //     // {
//     //     //   title: 'Miscellaneous',
//     //     //   children: [
//     //     //     {
//     //     //       openInNewTab: true,
//     //     //       title: 'Coming Soon',
//     //     //       path: '/pages/misc/coming-soon'
//     //     //     },
//     //     //     {
//     //     //       openInNewTab: true,
//     //     //       title: 'Under Maintenance',
//     //     //       path: '/pages/misc/under-maintenance'
//     //     //     },
//     //     //     {
//     //     //       openInNewTab: true,
//     //     //       title: 'Page Not Found - 404',
//     //     //       path: '/pages/misc/404-not-found'
//     //     //     },
//     //     //     {
//     //     //       openInNewTab: true,
//     //     //       title: 'Not Authorized - 401',
//     //     //       path: '/pages/misc/401-not-authorized'
//     //     //     },
//     //     //     {
//     //     //       openInNewTab: true,
//     //     //       title: 'Server Error - 500',
//     //     //       path: '/pages/misc/500-server-error'
//     //     //     }
//     //     //   ]
//     //     // }
//     //   ]
//     // },
//     // {
//     //   icon: VectorArrangeBelow,
//     //   title: 'Dialog Examples',
//     //   path: '/pages/dialog-examples'
//     // },
//     // {
//     //   sectionTitle: 'User Interface'
//     // },
//     // {
//     //   title: 'Typography',
//     //   icon: FormatLetterCase,
//     //   path: '/ui/typography'
//     // },
//     // {
//     //   title: 'Icons',
//     //   path: '/ui/icons',
//     //   icon: GoogleCirclesExtended
//     // },
//     // {
//     //   title: 'Cards',
//     //   icon: CreditCardOutline,
//     //   children: [
//     //     {
//     //       title: 'Basic',
//     //       path: '/ui/cards/basic'
//     //     },
//     //     {
//     //       title: 'Statistics',
//     //       path: '/ui/cards/statistics'
//     //     },
//     //     {
//     //       title: 'Advanced',
//     //       path: '/ui/cards/advanced'
//     //     },
//     //     {
//     //       title: 'Gamification',
//     //       path: '/ui/cards/gamification'
//     //     },
//     //     {
//     //       title: 'Actions',
//     //       path: '/ui/cards/actions'
//     //     },
//     //     {
//     //       title: 'Widgets',
//     //       path: '/ui/cards/widgets'
//     //     }
//     //   ]
//     // },
//     // {
//     //   badgeContent: '18',
//     //   title: 'components',
//     //   icon: ArchiveOutline,
//     //   badgeColor: 'primary',
//     //   children: [
//     //     {
//     //       title: 'Accordion',
//     //       path: '/components/accordion'
//     //     },
//     //     {
//     //       title: 'Alerts',
//     //       path: '/components/alerts'
//     //     },
//     //     {
//     //       title: 'Avatars',
//     //       path: '/components/avatars'
//     //     },
//     //     {
//     //       title: 'Badges',
//     //       path: '/components/badges'
//     //     },
//     //     {
//     //       title: 'Buttons',
//     //       path: '/components/buttons'
//     //     },
//     //     {
//     //       title: 'Button Group',
//     //       path: '/components/button-group'
//     //     },
//     //     {
//     //       title: 'Chips',
//     //       path: '/components/chips'
//     //     },
//     //     {
//     //       title: 'Dialogs',
//     //       path: '/components/dialogs'
//     //     },
//     //     {
//     //       title: 'List',
//     //       path: '/components/list'
//     //     },
//     //     {
//     //       title: 'Menu',
//     //       path: '/components/menu'
//     //     },
//     //     {
//     //       title: 'Pagination',
//     //       path: '/components/pagination'
//     //     },
//     //     {
//     //       title: 'Ratings',
//     //       path: '/components/ratings'
//     //     },
//     //     {
//     //       title: 'Snackbar',
//     //       path: '/components/snackbar'
//     //     },
//     //     {
//     //       title: 'Swiper',
//     //       path: '/components/swiper'
//     //     },
//     //     {
//     //       title: 'Tabs',
//     //       path: '/components/tabs'
//     //     },
//     //     {
//     //       title: 'Timeline',
//     //       path: '/components/timeline'
//     //     },
//     //     {
//     //       title: 'Toasts',
//     //       path: '/components/toast'
//     //     },
//     //     {
//     //       title: 'Tree View',
//     //       path: '/components/tree-view'
//     //     },
//     //     {
//     //       title: 'More',
//     //       path: '/components/more'
//     //     },
//     //   ]
//     // },
//     // {
//     //   sectionTitle: 'Forms & Tables'
//     // },
//     // {
//     //   title: 'Form Elements',
//     //   icon: FormSelect,
//     //   children: [
//     //     {
//     //       title: 'Text Field',
//     //       path: '/forms/form-elements/text-field'
//     //     },
//     //     {
//     //       title: 'Select',
//     //       path: '/forms/form-elements/select'
//     //     },
//     //     {
//     //       title: 'Checkbox',
//     //       path: '/forms/form-elements/checkbox'
//     //     },
//     //     {
//     //       title: 'Radio',
//     //       path: '/forms/form-elements/radio'
//     //     },
//     //     {
//     //       title: 'Textarea',
//     //       path: '/forms/form-elements/textarea'
//     //     },
//     //     {
//     //       title: 'Autocomplete',
//     //       path: '/forms/form-elements/autocomplete'
//     //     },
//     //     {
//     //       title: 'Date Pickers',
//     //       path: '/forms/form-elements/pickers'
//     //     },
//     //     {
//     //       title: 'Switch',
//     //       path: '/forms/form-elements/switch'
//     //     },
//     //     {
//     //       title: 'File Uploader',
//     //       path: '/forms/form-elements/file-uploader'
//     //     },
//     //     {
//     //       title: 'Editor',
//     //       path: '/forms/form-elements/editor'
//     //     },
//     //     {
//     //       title: 'Slider',
//     //       path: '/forms/form-elements/slider'
//     //     },
//     //     {
//     //       title: 'Input Mask',
//     //       path: '/forms/form-elements/input-mask'
//     //     },
//     //   ]
//     // },
//     // {
//     //   icon: CubeOutline,
//     //   title: 'Form Layouts',
//     //   path: '/forms/form-layouts'
//     // },
//     // {
//     //   title: 'Form Validation',
//     //   path: '/forms/form-validation',
//     //   icon: CheckboxMarkedCircleOutline
//     // },
//     // {
//     //   title: 'Form Wizard',
//     //   path: '/forms/form-wizard',
//     //   icon: PackageVariantClosed
//     // },
//     // {
//     //   title: 'Table',
//     //   icon: Table,
//     //   path: '/tables/mui'
//     // },
//     // {
//     //   title: 'Mui DataGrid',
//     //   icon: Table,
//     //   path: '/tables/data-grid'
//     // },
//     // {
//     //   sectionTitle: 'Charts & Misc'
//     // },
//     // {
//     //   title: 'Charts',
//     //   icon: ChartDonut,
//     //   children: [
//     //     {
//     //       title: 'Apex',
//     //       path: '/charts/apex-charts'
//     //     },
//     //     {
//     //       title: 'Recharts',
//     //       path: '/charts/recharts'
//     //     },
//     //     {
//     //       title: 'ChartJS',
//     //       path: '/charts/chartjs'
//     //     }
//     //   ]
//     // },
//     // {
//     //   path: '/acl',
//     //   action: 'read',
//     //   subject: 'acl-page',
//     //   icon: ShieldOutline,
//     //   title: 'Access Control'
//     // },
//     // {
//     //   title: 'Others',
//     //   icon: DotsHorizontal,
//     //   children: [
//     //     {
//     //       title: 'Menu Levels',
//     //       children: [
//     //         {
//     //           title: 'Menu Level 2.1'
//     //         },
//     //         {
//     //           title: 'Menu Level 2.2',
//     //           children: [
//     //             {
//     //               title: 'Menu Level 3.1'
//     //             },
//     //             {
//     //               title: 'Menu Level 3.2'
//     //             }
//     //           ]
//     //         }
//     //       ]
//     //     },
//     //     {
//     //       title: 'Disabled Menu',
//     //       disabled: true
//     //     },
//     //     {
//     //       title: 'Raise Support',
//     //       externalLink: true,
//     //       openInNewTab: true,
//     //       path: 'https://pixinvent.ticksy.com/'
//     //     },
//     //     {
//     //       title: 'Documentation',
//     //       externalLink: true,
//     //       openInNewTab: true,
//     //       path: 'https://pixinvent.com/demo/materialize-mui-react-nextjs-admin-template/documentation'
//     //     }
//     //   ]
//     // }
//   ]
// }

export default navigation
