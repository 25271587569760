import { KeyfactDataType } from "../../types/apps/attributeTypes"
import { companyBenefitParent } from "../../types/apps/companyTypes"

export interface GetDictionaryRequestType {
    readonly data: {
      readonly dictionaryName: string,
      readonly dictionaryList: DictionaryListType[]
    }
    readonly errors: null
    readonly succeeded: boolean
  }

  export interface DictionaryListType {
    readonly id: string | null,
    readonly name: string,
    readonly nameGE: string,
    readonly description: null | string,
    readonly descriptionGE: null | string,
    readonly iconUrl: null | string,
    readonly parentDictionary: null | GetParentDictionary,
    readonly childDictionaries: null,
    readonly modified?: boolean
  }



  export interface GetParentDictionary {
      readonly dictionaryName: string
      readonly id: string
      readonly name: string
  }

  export type DictionaryOptionTypes = {
    label: string,
    value: string | number
  }

  export interface postCreateUpdateDictionaryType{
    data: string
errors: null | string
succeeded: boolean
  }

  export interface NewBenefitRequestType {
    readonly data: companyBenefitParent[] | string
    readonly errors: null
    readonly succeeded: boolean
  }

  export interface keyFactRequestType {
    readonly data: KeyfactDataType[]
    readonly errors: null
    readonly succeeded: boolean;
  }

  export enum benefitTypeEnum {
    text = 0,
    number = 1
  }

  export type benefitType = { id: string; description: string; descriptionGE: string; descriptionNumber: number };
