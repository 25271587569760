// ** React Imports
import { ChangeEvent, useState } from "react";

// ** MUI Imports
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { DataGrid, GridColumns, GridSortModel } from "@mui/x-data-grid";

// ** Types Imports
import * as Api from "../../service";

// ** utils Import
import { RootState } from "../../store";
import { useSelector } from "react-redux";
import { ThunkAction } from "@reduxjs/toolkit";
import QuickSearchToolbar from "../../utils/views/table/data-grid/QuickSearchToolbar";
import { Paginate } from "../../utils/views/table/data-grid/Pagination";

type propTypes = {
  data: Api.GetSearchTalentsRequestType | Api.AdminType;
  title: string;
  updatePage: (pageNum: number) => void;
  updatePageSize: (pageSize: number) => void;
  sortTable: (objectArr: GridSortModel) => void;
  columns: GridColumns;
  // @ts-ignore: Unreachable code error
  stateDispatcher: (arg: string) => ThunkAction;
  onSearchValueChange: (value: string) => void;
  storedState: string;
  // @ts-ignore: Unreachable code error
  dictionaryStateDispatcher?();
  searchValue: string;
};

const TableForAdmins = ({ data, title, updatePage, updatePageSize, sortTable, columns, stateDispatcher, storedState, dictionaryStateDispatcher, onSearchValueChange, searchValue }: propTypes) => {
  const { isLoading } = useSelector(
    // @ts-ignore: Unreachable code error
    (state: RootState) => state[storedState]
  );

  // const [searchValue, setSearchValue] = useState<string>();
  const { data: tableData, pageSize, totalRecords } = data;

  return (
    <Card>
      <CardHeader title={title} />
      <DataGrid
        autoHeight
        pagination
        rows={tableData}
        rowCount={totalRecords}
        columns={columns}
        loading={isLoading}
        // checkboxSelection
        pageSize={pageSize}
        sortingMode="server"
        paginationMode="server"
        onSortModelChange={sortTable}
        rowsPerPageOptions={[7, 10, 25, 50]}
        onPageChange={(newPage) => updatePage(newPage)}
        components={{ Toolbar: QuickSearchToolbar, Pagination: Paginate }}
        onPageSizeChange={(newPageSize) => updatePageSize(newPageSize)}
        componentsProps={{
          toolbar: {
            value: searchValue,
            clearSearch: () => onSearchValueChange(""),
            onChange: (event: ChangeEvent<HTMLInputElement>) => onSearchValueChange(event.target.value),
            componentSection: storedState,
            exportFilter: {
              searchTerm: searchValue,
            },
          },
        }}
      />
    </Card>
  );
};

export default TableForAdmins;
