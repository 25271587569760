// @ts-nocheck
import { format } from "date-fns";
export const getQuerryText = (query) => {
  let keys;
  if (query) keys = Object.keys(query);
  let queryString = "";
  let firstSymbol = "";
  keys?.forEach((key: string) => {
    if (!query[key] && query[key] !== false) {
      return;
    }
    if (!firstSymbol) firstSymbol = "?";
    else {
      firstSymbol = "&";
    }

    if (Array.isArray(query[key]))
      query[key].map((el) => (queryString += `${firstSymbol + key}=${el}`));
    else {
      if (query[key] instanceof Date) {
        queryString += `${firstSymbol + key}=${format(
          query[key],
          "dd MMM, yyyy"
        )}`;
      } else {
        queryString += `${firstSymbol + key}=${query[key]}`;
      }
    }
  });
  return queryString;
};




//for statistics query string
export const generateQueryString = (dateRange, startDate, endDate, selectedItems?: string[]) => {
  let dateFrom = startDate?.toISOString();
  let dateTo = endDate?.toISOString();
  let queryString = `${dateRange}`
  if (startDate && !endDate) {
    queryString = `${dateRange}&dateFrom=${dateFrom}`
  }
  if (endDate && !startDate) {
    queryString = `${dateRange}&dateTo=${dateTo}`
  }
  if (startDate && endDate) {
    queryString = `${dateRange}&dateFrom=${dateFrom}&dateTo=${dateTo}`
  }
  queryString += selectedItems ? selectedItems?.map(el => `&searchIds=${el}`).join("") : "";
  return queryString
}