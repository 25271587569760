import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { AxiosError } from "axios";
import * as Api from "../../../service";

export interface AdminSliceState {
  admins?: Api.AdminType;
  isLoading: boolean;
}

const initialState: AdminSliceState = {
  admins: undefined,
  isLoading: false,
};

export const postCreateNewAdmin = createAsyncThunk<Api.CreateNewAdminType, Api.postCreateNewAdminRequestParams>(
  "backOffice/postCreateNewAdmin",
  async (params: Api.postCreateNewAdminRequestParams, thunkApi) => {
    try {
      const response = await Api.postCreateNewAdminRequest(params);
      return response.data;
    } catch (err) {
      const error = err;
      if (error) {
        return thunkApi.rejectWithValue((error as AxiosError).response?.data);
      }

      throw error;
    }
  }
);

export const postEditAdminUser = createAsyncThunk<Api.CreateNewAdminType, Api.postEditAdminUserRequestParams>(
  "backOffice/postEditAdminUser",
  async (params: Api.postEditAdminUserRequestParams, thunkApi) => {
    try {
      const response = await Api.postEditAdminUserRequest(params);
      return response.data;
    } catch (err) {
      const error = err;
      if (error) {
        return thunkApi.rejectWithValue((error as AxiosError).response?.data);
      }

      throw error;
    }
  }
);

export const postDeleteAdminUser = createAsyncThunk<Api.CreateNewAdminType, string>("backOffice/postDeleteAdminUser", async (email: string, thunkApi) => {
  try {
    const response = await Api.postDeleteAdminUserRequest(email);
    return response.data;
  } catch (err) {
    const error = err;
    if (error) {
      return thunkApi.rejectWithValue((error as AxiosError).response?.data);
    }

    throw error;
  }
});

export const getAdmins = createAsyncThunk<Api.AdminType>("backOffice/SearchUsers", async (NULL, thunkApi) => {
  try {
    const response = await Api.getAdminsRequest();
    return response.data;
  } catch (err) {
    const error = err;
    if (error) {
      return thunkApi.rejectWithValue((error as AxiosError).response?.data);
    }
    throw error;
  }
});

export const getSearchAdmins = createAsyncThunk<Api.AdminType, Api.getAdminsRequestParams>("account/SearchUsers", async (params, thunkApi) => {
  try {
    const response = await Api.getSearchAdminsRequest(params);
    return response.data;
  } catch (err) {
    const error = err;
    if (error) {
      return thunkApi.rejectWithValue((error as AxiosError).response?.data);
    }
    throw error;
  }
});

export const adminsSlice = createSlice({
  name: "admins",
  initialState,
  reducers: {
    removeAdminsFromMemory: (state) => {
      state.admins = undefined;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(postCreateNewAdmin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postCreateNewAdmin.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(postCreateNewAdmin.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getAdmins.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getAdmins.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getAdmins.fulfilled, (state, action) => {
        state.isLoading = false;
        state.admins = action.payload;
      })
      .addCase(getSearchAdmins.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSearchAdmins.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getSearchAdmins.fulfilled, (state, action) => {
        state.isLoading = false;
        state.admins = action.payload;
      })
      .addCase(postEditAdminUser.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(postEditAdminUser.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(postEditAdminUser.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postDeleteAdminUser.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(postDeleteAdminUser.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(postDeleteAdminUser.pending, (state) => {
        state.isLoading = true;
      });
  },
});

export const { removeAdminsFromMemory } = adminsSlice.actions;
export default adminsSlice.reducer;
