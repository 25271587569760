import { useEffect, useState } from "react";

import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid";
import { ThemeColor } from "../../components/@core/layouts/types";
import CustomAvatar from "../../components/@core/components/mui/avatar";
import { getInitials } from "../../components/@core/utils/get-initials";
import Box from "@mui/material/Box";

export const useColumnsForAdmins = () => {
  const [modal, setModal] = useState<boolean>(false);
  const [dataID, setDataID] = useState<GridRenderCellParams["row"] | undefined>();
  const [deleteData, setDeleteData] = useState<GridRenderCellParams["row"] | undefined>();

  interface StatusObj {
    [key: number]: {
      title: string;
      color: ThemeColor;
    };
  }

  const statusObj: StatusObj = {
    1: { title: "Active", color: "primary" },
    2: { title: "Not Active", color: "error" },
    3: { title: "Verified", color: "primary" },
    4: { title: "Not Verified", color: "error" },
    5: { title: "Pending Review", color: "warning" },
  };

  const handleVerificationStatus = (isEmailVerified: boolean | null, isVerified: boolean | null) => {
    if (!isEmailVerified && !isVerified) return 4;
    else if (isEmailVerified && !isVerified) return 5;
    else return 3;
  };

  const handleEdit = (params: GridRenderCellParams) => {
    setModal(!modal);
    setDataID(params.row);
  };

  const handleDelete = (params: GridRenderCellParams) => {
    setDeleteData(params.row);
  };

  // ** renders client column
  const renderClient = (params: GridRenderCellParams) => {
    const { row } = params;
    const stateNum = Math.floor(Math.random() * 6);
    const states = ["success", "error", "warning", "info", "primary", "secondary"];
    const color = states[stateNum];

    if (row.smallProfileImageUrl && row.smallProfileImageUrl.length) {
      return <CustomAvatar src={`${row.smallProfileImageUrl}`} sx={{ mr: 3, width: "1.875rem", height: "1.875rem" }} />;
    } else {
      return (
        <CustomAvatar
          skin="light"
          color={color as ThemeColor}
          sx={{
            mr: 3,
            fontSize: ".8rem",
            width: "1.875rem",
            height: "1.875rem",
          }}
        >
          {getInitials(row.name ? row.name : "No name found")}
        </CustomAvatar>
      );
    }
  };

  const columns: GridColumns = [
    {
      flex: 0.25,
      minWidth: 290,
      field: "name",
      headerName: "Name",
      renderCell: (params: GridRenderCellParams) => {
        const { row } = params;

        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {renderClient(params)}
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography noWrap variant="body2" sx={{ color: "text.primary", fontWeight: 600 }}>
                {row.name}
              </Typography>
              <Typography noWrap variant="caption">
                {row.email}
              </Typography>
            </Box>
          </Box>
        );
      },
    },
    {
      flex: 0.175,
      minWidth: 120,
      field: "lastName",
      headerName: "LastName",
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="body2" sx={{ color: "text.primary" }}>
          {params.row.lastName}
        </Typography>
      ),
    },
    {
      flex: 0.175,
      minWidth: 120,
      field: "status",
      headerName: "Status",
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="body2" sx={{ color: "text.primary" }}>
          {params.row.status === 1 ? "Active" : "InActive"}
        </Typography>
      ),
    },
    {
      flex: 0.175,
      minWidth: 120,
      field: "role",
      headerName: "Role",
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="body2" sx={{ color: "text.primary" }}>
          {params.row.role === 1 ? "Admin" : "Admin"}
        </Typography>
      ),
    },
    {
      flex: 0.175,
      minWidth: 120,
      field: "actions",
      headerName: "Actions",
      renderCell: (params: GridRenderCellParams) => (
        <Box>
          <Button onClick={() => handleEdit(params)}>Edit</Button>
          <Button onClick={() => handleDelete(params)} color="error">
            Delete
          </Button>
        </Box>
      ),
    },
  ];
  return { columns, modal, setModal, setDataID, dataID, deleteData, setDeleteData };
};
