// ** MUI Imports
import { styled } from '@mui/material/styles'

const CardWrapper = styled('div')({
  display: 'flex',
  '& .rccs, & .rccs__card': {
    margin: 0
  }
})

export default CardWrapper
