import React from "react";

function useOutsideClick(elRef: { current: null | React.ElementRef<keyof JSX.IntrinsicElements> }, handler: (e: Event) => unknown): void {
  React.useEffect(() => {
    const listener = (event: MouseEvent): void => {
      if (event.target == null) return undefined;
      if (elRef.current == null) return undefined;
      if (elRef.current.contains(event.target as Node)) return undefined;
      if (event.target instanceof HTMLButtonElement) return undefined;
      if (event.target instanceof HTMLLabelElement) return undefined;
      if (event.target instanceof HTMLInputElement) return undefined;
      if (event.target instanceof HTMLLIElement) return undefined;
      if (event.target instanceof HTMLSpanElement) return undefined;

      handler(event);
    };
    document.addEventListener("mouseup", listener);
    return () => document.removeEventListener("mouseup", listener);
  }, [elRef, handler]);
}

export default useOutsideClick;
