import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as Api from "../../../service";


interface HeroImageDetailsInitialValueType {
  details?: Api.HeroImageDetailsType
}

const initialState: HeroImageDetailsInitialValueType = {
  details: undefined
};

export const getHeroImageDetails = createAsyncThunk<Api.HeroImageDetailsType>(
  "heroImage/getHeroImageDetails",
  async () => {
    const response = await Api.getHeroImageDetails();
    return response.data.data;
  }
);

export const updateHeroImageText = createAsyncThunk(
  "heroImage/updateText",
  async (params: Api.UpdateHeroImageTextParams) => {
    const response = await Api.updateHeroImageText(params);
    return response.data;
  }
);

export const HeroImageSlice = createSlice({
  name: "HeroImage",
  initialState,
  reducers: {
    updateDetails: (
      state,
      action: PayloadAction<Api.HeroImageDetailsType>
    ) => {
      if(state.details){
        state.details = action.payload
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getHeroImageDetails.fulfilled, (state, action) => {
        state.details = action.payload;
      })
  },
});

export const { updateDetails } =
HeroImageSlice.actions;
export default HeroImageSlice.reducer;
