import { FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../store";

type MainProps = {
  handleStateUpdate: (key: string, value: string | boolean | null | string[] | { id: string; name: string }) => void;
  modelObject: any;
  name: string;
  title: string;
  xs?: number;
  singleChoice?: boolean;
};

type PropsWithCustomOptions = MainProps & {
  customOptions: { id: number | string; name: string }[];
  enumType?: never;
};

type PropsWithEnumType = MainProps & {
  customOptions?: never;
  enumType: string;
};

type props = PropsWithCustomOptions | PropsWithEnumType;

function MultiChoiceField({ handleStateUpdate, modelObject, name, title, enumType, customOptions, xs, singleChoice }: props) {
  const { dictionaries } = useSelector((state: RootState) => state.vacancies);

  const value = singleChoice ? [modelObject[name]] : modelObject[name];

  // @ts-ignore: Unreachable code error
  return (
    <Grid item xs={xs || 6}>
      <FormControl fullWidth>
        <InputLabel id={name}>{title}</InputLabel>
        {dictionaries && (
          <Select
            multiple
            label={title}
            labelId={name}
            // @ts-ignore: Unreachable code error
            value={value || []}
            onChange={(e) =>
              handleStateUpdate(
                name,
                // @ts-ignore: Unreachable code error
                e.target.value
              )
            }
            sx={{
              mb: 4,
              width: "100%",
              color: "text.secondary",
            }}
          >
            {customOptions
              ? customOptions.map((el) => (
                  <MenuItem key={el.id} value={el.id} id={el.name}>
                    {el.name}
                  </MenuItem>
                ))
              : dictionaries
                  .find((dictionary) => dictionary.dictionaryName === enumType)
                  ?.dictionaryList.map((el) => (
                    <MenuItem key={el.id} value={el.id} id={el.name}>
                      {el.name}
                    </MenuItem>
                  ))}
          </Select>
        )}
      </FormControl>
    </Grid>
  );
}

export default MultiChoiceField;
