// ** React Imports
import { FC } from 'react'

// ** MUI Imports
import Paper from '@mui/material/Paper'
import Table from '@mui/material/Table'
import TableRow from '@mui/material/TableRow'
import TableHead from '@mui/material/TableHead'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'

// ** Icons Imports

import { useAppDispatch } from '../../../store'
import * as Api from "../../../service"
import {
  DndContext, 
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent,
} from '@dnd-kit/core';

import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable';
import ButtonsTableRow from './ButtonsTableRow'
import { getHeroImageDetails, updateDetails, updateHeroImageText } from '../../../store/apps/HeroImageSlice'
import toast from 'react-hot-toast'


const ButtonsTable: FC<{ heroImageDetails: Api.HeroImageDetailsType }> = (props) => {

  const dispatch = useAppDispatch();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over!.id) {
      const oldIndex = props.heroImageDetails.heroButtons.findIndex((item) => item.id === active.id);
      const newIndex = props.heroImageDetails.heroButtons.findIndex((item) => item.id === over!.id);
      const newOrderedList = [...arrayMove(props.heroImageDetails.heroButtons, oldIndex, newIndex)];

      const newObj = {
        ...props.heroImageDetails,
        heroButtons: newOrderedList
      }

      dispatch(updateDetails(newObj));
      dispatch(updateHeroImageText(newObj))
        .unwrap()
        .then(() => {
          dispatch(getHeroImageDetails());
          toast.success("Order Updated successfully");
        })
        .catch((response) => {
          toast.error(response.error);
        });
    }
  };

  return (
    <TableContainer component={Paper} style={{overflow: "hidden"}}>
      <Table aria-label='collapsible table'>
        <TableHead>
          <TableRow>
            {/* <TableCell /> */}
            <TableCell>Button In English</TableCell>
            <TableCell>Button In Georgian</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <SortableContext items={props.heroImageDetails.heroButtons.map(items => items.id)} strategy={verticalListSortingStrategy}>
              { props.heroImageDetails.heroButtons.map(button => <ButtonsTableRow key={button.id} TableRow={button} heroImageDetails={props.heroImageDetails}  />)}
            </SortableContext>
          </DndContext>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default ButtonsTable


