// ** React Imports
import { Ref, useState, forwardRef, ReactElement } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Switch from "@mui/material/Switch";
import Dialog from "@mui/material/Dialog";
// import Button from "@mui/material/Button";
import { Button } from "@mui/material";

import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import CardContent from "@mui/material/CardContent";
import Fade, { FadeProps } from "@mui/material/Fade";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import FormControlLabel from "@mui/material/FormControlLabel";
import Select, { SelectChangeEvent } from "@mui/material/Select";

// ** Icons Imports
import Close from "mdi-material-ui/Close";
import AccountOutline from "mdi-material-ui/AccountOutline";
import { RootState, useAppDispatch } from "../../../../store";
import {
  deleteCompanyOrTalent,
  getSearchTalents,
} from "../../../../store/apps/talentsSlice";
import { handleObjectForQueryString } from "../../../helperFunctions";
import { useSelector } from "react-redux";
import { getSearchCompanies } from "../../../../store/apps/companiesSlice";

const Transition = forwardRef(function Transition(
  props: FadeProps & { children?: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Fade ref={ref} {...props} />;
});

type localTypes = {
  id: string;
  pageName: string;
};

const DialogEditUserInfo = ({ id, pageName }: localTypes) => {
  // ** States
  const [show, setShow] = useState<boolean>(false);
  const { submittedFilteredObject: talentsObject, submittedPage: talentsPage } =
    useSelector((state: RootState) => state.talents);
  const {
    submittedFilteredObject: companiesObject,
    submittedPage: companiesPage,
  } = useSelector((state: RootState) => state.companies);
  const dispatch = useAppDispatch();

  const handleDelete = () => {
    dispatch(deleteCompanyOrTalent(id))
      .unwrap()
      .then(() => {
        if (pageName === "talents") {
          dispatch(
            getSearchTalents(
              handleObjectForQueryString(talentsObject, talentsPage)
            )
          )
            .unwrap()
            .then(() => {
              setShow(false);
            });
        } else {
          dispatch(
            getSearchCompanies(
              handleObjectForQueryString(companiesObject, companiesPage)
            )
          )
            .unwrap()
            .then(() => {
              setShow(false);
            });
        }
      });
  };

  return (
    <>
      <Button size="small" variant="outlined" onClick={() => setShow(true)}>
        Delete
      </Button>
      <Dialog
        fullWidth
        open={show}
        maxWidth="sm"
        scroll="body"
        onClose={() => setShow(false)}
        TransitionComponent={Transition}
        onBackdropClick={() => setShow(false)}
      >
        <DialogContent
          sx={{
            pb: 6,
            px: { xs: 8, sm: 15 },
            pt: { xs: 8, sm: 12.5 },
            position: "relative",
          }}
        >
          <Grid container justifyContent={"center"}>
            <Grid>
              <Typography variant="body1">
                Are you sure you want to delete data?
              </Typography>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions
          sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: "center" }}
        >
          <Button variant="contained" sx={{ mr: 2 }} onClick={handleDelete}>
            Submit
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            onClick={() => setShow(false)}
          >
            Discard
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default DialogEditUserInfo;
