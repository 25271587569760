// ** MUI Imports
import Box from "@mui/material/Box";

// ** Type Import
import { Settings } from "../../../@core/context/settingsContext";

// ** components
import Autocomplete from "../Autocomplete";
import ModeToggler from "../../../@core/layouts/components/shared-components/ModeToggler";
import UserDropdown from "../../../@core/layouts/components/shared-components/UserDropdown";
import LanguageDropdown from "../../../@core/layouts/components/shared-components/LanguageDropdown";
import NotificationDropdown from "../../../@core/layouts/components/shared-components/NotificationDropdown";

interface Props {
  hidden: boolean;
  settings: Settings;
  saveSettings: (values: Settings) => void;
}

const AppBarContent = (props: Props) => {
  // ** Props
  const { hidden, settings, saveSettings } = props;

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      {/* <Autocomplete hidden={hidden} settings={settings} />
      <LanguageDropdown settings={settings} saveSettings={saveSettings} /> */}
      <ModeToggler settings={settings} saveSettings={saveSettings} />
      {/* <NotificationDropdown settings={settings} /> */}
      <UserDropdown settings={settings} />
    </Box>
  );
};

export default AppBarContent;
