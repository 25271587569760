import axios, { AxiosError } from 'axios';

const url = process.env.REACT_APP_API_URL;

const axiosInstance = axios.create({ baseURL: url });

axiosInstance.interceptors.request.use(function (config) {
  // Do something before request is sent
  if (!config.headers!['Authorization']) {
    config.headers!["Authorization"] = `bearer ${localStorage.getItem('softAdminToken')}`
  }

  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});

axiosInstance.interceptors.response.use(
  (response) => {
    return response
  },
  async (error: AxiosError) => {

    const prevReqConfig = error.config

    if (prevReqConfig !== undefined && error.response?.status === 401) {
      let newAccessToken = ''

      await axiosInstance.post(`api/BackOfficeAccount/refreshToken`, { token: localStorage.getItem('softAdminToken') })
        .then((response)=>{
          newAccessToken = response.data.token
        })
        .catch(() => { 
          localStorage.clear();
          window.location.reload()
        })

      if (newAccessToken) {
        localStorage.setItem('softAdminToken', newAccessToken)
        return axiosInstance({
          ...prevReqConfig,
          headers: {...prevReqConfig.headers, Authorization: `bearer ${newAccessToken}`},
        });
      }
    }

  // Any status codes that falls outside the range of 2xx cause this function to trigger
  // Do something with response error
  return Promise.reject(error);
});


export default axiosInstance;

