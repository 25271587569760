// ** MUI Imports
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";

// ** Third Party Imports
import { Bar } from "react-chartjs-2";

// ** Type Imports
import { GetAttractiveCompaniesRequestType } from "../../../../service";

interface HorizontalBarProps {
  info: string;
  warning: string;
  error?: string;
  labelColor: string;
  borderColor: string;
  gridLineColor: string;
  data: GetAttractiveCompaniesRequestType | any;
  // max: number;
}

const ChartjsHorizontalBarChart = (props: HorizontalBarProps) => {
  // ** Props
  const { info, warning, labelColor, borderColor, gridLineColor, error } =
    props;

  const options: any = {
    indexAxis: "y",
    responsive: true,
    maintainAspectRatio: false,
    animation: { duration: 500 },
    elements: {
      bar: {
        borderRadius: {
          topRight: 15,
          bottomRight: 15,
        },
      },
    },
    layout: {
      padding: { top: -4 },
    },
    scales: {
      x: {
        // max: props.max,
        min: 0,
        grid: {
          drawTicks: false,
          color: gridLineColor,
          borderColor: "transparent",
        },
        ticks: { color: labelColor },
      },
      y: {
        grid: {
          borderColor,
          display: false,
        },
        ticks: { color: labelColor },
      },
    },
    plugins: {
      legend: {
        align: "end",
        position: "top",
        labels: { color: labelColor },
      },
    },
  };

  const data = {
    labels: ["MON", "TUE", "WED ", "THU", "FRI"],
    datasets: [
      {
        maxBarThickness: 15,
        label: "Company clicks",
        backgroundColor: warning,
        borderColor: "transparent",
        data: [710, 350, 580, 460, 120],
      },
    ],
  };

  return (
    <Card>
      <CardHeader
        title={props.data?.datasets[0].label}
        titleTypographyProps={{ variant: "h6" }}
        subheaderTypographyProps={{ variant: "caption" }}
      />
      <CardContent>
        {/* @ts-ignore: Unreachable code errorts-ignore next line */}
        <Bar data={props.data} options={options} height={400} />
      </CardContent>
    </Card>
  );
};

export default ChartjsHorizontalBarChart;
