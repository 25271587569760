// ** React Imports
import { ChangeEvent, useEffect, useState } from "react";

// ** MUI Imports
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { DataGrid, GridColumns, GridSortModel } from "@mui/x-data-grid";

// ** Custom components
import QuickSearchToolbar from "../../../../utils/views/table/data-grid/QuickSearchToolbar";

// ** Types Imports
import { DataGridRowType } from "../../../../@fake-db/types";

// ** Data Import
import React from "react";

const escapeRegExp = (value: string) => {
  return value.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, "\\$&");
};

type propType = {
  tableData: any;
  columns: GridColumns;
  name: string;
  AddNewData?: React.FunctionComponent<{}>;
  loading?: boolean;
  attributes?: boolean;
  // sortTable?: (model: GridSortModel, details: GridCallbackDetails<any>) => void;
};

const TableColumns = ({ tableData, columns, name, AddNewData, loading, attributes }: propType) => {
  // ** States
  const [data, setData] = useState<DataGridRowType[]>([]);
  const [pageSize, setPageSize] = useState<number>(7);
  const [searchText, setSearchText] = useState<string>("");
  const [filteredData, setFilteredData] = useState<DataGridRowType[]>([]);

  useEffect(() => {
    // @ts-ignore
    setData(tableData.map((el, index) => ({ index, ...el })));
  }, [tableData]);

  useEffect(() => {
    setFilteredData(data);
  }, [data]);

  const handleSearch = (searchValue: string) => {
    setSearchText(searchValue);
    const searchRegex = new RegExp(escapeRegExp(searchValue), "i");
    const filteredRows = data.filter((row) => {
      return Object.keys(row).some((field) => {
        if (field === "parentDictionary") {
          // @ts-ignore
          return searchRegex.test(row.parentDictionary?.name?.toString());
        }
        // @ts-ignore
        return searchRegex.test(row[field]?.toString());
      });
    });
    if (searchValue.length) {
      setFilteredData(filteredRows);
    } else {
      setFilteredData([]);
    }
  };

  const sortTable = (model: GridSortModel) => {
    if (model.length < 1) return;
    const sortedData = [...filteredData];
    const returnValue = -1;
    sortedData.sort((a, b) => {
      if (model[0].field === "parentDictionary") {
        if (
          // @ts-ignore
          a[model[0].field].name.toLowerCase().localeCompare(
            // @ts-ignore
            b[model[0].field].name.toLowerCase()
          )
        ) {
          return returnValue;
        }
        return 0;
      } else {
        if (
          // @ts-ignore
          a[model[0].field]
            .toLowerCase()
            // @ts-ignore
            .localeCompare(b[model[0].field].toLowerCase())
        ) {
          return returnValue;
        }
        return 0;
      }
    });
    setFilteredData(sortedData);
  };

  return (
    <>
      {
        <Card>
          <CardHeader title={name} />
          <DataGrid
            autoHeight
            columns={columns}
            pageSize={pageSize}
            loading={loading}
            rowsPerPageOptions={[7, 10, 25, 50]}
            components={{ Toolbar: QuickSearchToolbar }}
            onSortModelChange={sortTable}
            rows={filteredData.length ? filteredData : data}
            onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
            componentsProps={{
              toolbar: {
                value: searchText,
                clearSearch: () => handleSearch(""),
                onChange: (event: ChangeEvent<HTMLInputElement>) => handleSearch(event.target.value),
                componentSection: name,
                AddNewData,
                attributes,
              },
            }}
          />
        </Card>
      }
    </>
  );
};

export default TableColumns;
