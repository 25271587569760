import { useState, useEffect, useRef } from "react";
import DateRange from "../Components/Date Range";
import { DateType } from "../../../types/forms/reactDatepickerTypes";
import { useAppDispatch, RootState } from "../../../store";
import { getVacancyAppliedStatistics } from "../../../store/apps/statisticsSlice";
import { useSelector } from "react-redux";
import Grid from "@mui/material/Grid";
import { Bar } from "react-chartjs-2";
import Box from "@mui/material/Box";
import CircularProgress from "@mui/material/CircularProgress";
import PageHeader from "../../../components/@core/components/page-header";
import Typography from "@mui/material/Typography/Typography";
import { generateQueryString } from "../../../utils/helperFunctions/helpers";
import chartBarMaxValue from "../../../utils/helperFunctions/chartBarMaxValue";
import ExportPDF from "../../../utils/views/export/ExportPDF";

const SubmittedVacancies = () => {
  const { vacancyStatistics, isLoading } = useSelector((state: RootState) => state.statistics);
  const dispatch = useAppDispatch();
  const [dateRange, setDateRange] = useState<number>(2);
  const [endDate, setEndDate] = useState<DateType>(new Date());
  const [startDate, setStartDate] = useState<DateType>(new Date());

  const chartRef = useRef<HTMLDivElement>(null);

  const labelColor = "#ff9800";
  const borderColor = "#28dac6";
  const gridLineColor = "#787EFF";

  let chartData = {
    labels: vacancyStatistics?.labels,
    datasets: [
      {
        label: vacancyStatistics?.datasets[0].label,
        maxBarThickness: 15,
        backgroundColor: "#787EFF",
        borderColor: "#787EFF",
        borderRadius: {
          topRight: 15,
          topLeft: 15,
        },
        data: vacancyStatistics?.datasets[0].data,
      },
      {
        label: vacancyStatistics?.datasets[1].label,
        maxBarThickness: 15,
        backgroundColor: "#cc9900",
        borderColor: "#cc9900",
        borderRadius: {
          topRight: 15,
          topLeft: 15,
        },
        data: vacancyStatistics?.datasets[1].data,
      },
    ],
    total: vacancyStatistics?.datasets[1].total,
  };

  useEffect(() => {
    dispatch(getVacancyAppliedStatistics(generateQueryString(dateRange, startDate, endDate)));
  }, [dateRange, endDate, startDate]);

  const options: any = {
    maintainAspectRatio: false,
    indexAxis: "y",
    responsive: true,
    animation: { duration: 500 },
    elements: {
      bar: {
        borderRadius: {
          topRight: 15,
          bottomRight: 15,
        },
      },
    },
    layout: {
      padding: { top: -4 },
    },
    scales: {
      x: {
        min: 0,
        // max: Math.max(max0, max1),
        grid: {
          drawTicks: false,
          color: gridLineColor,
          borderColor: "transparent",
        },
        ticks: {
          color: labelColor,
        },
      },
      y: {
        grid: {
          borderColor,
          display: false,
        },
        ticks: { color: labelColor },
      },
    },
    plugins: {
      legend: {
        align: "end",
        position: "top",
        labels: { color: labelColor },
      },
    },
  };
  return (
    <>
      <PageHeader
        title={
          <>
            <Typography variant="h5" color="#666CFF">
              Total Applied: {vacancyStatistics?.total} <br />
            </Typography>
            <Typography variant="h5" color="#cc9900">
              Total Published: {vacancyStatistics?.totalSecond}
            </Typography>
          </>
        }
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <DateRange setDateRange={setDateRange} dateRange={dateRange} endDate={endDate} setEndDate={setEndDate} startDate={startDate} setStartDate={setStartDate} />
        {chartRef.current && <ExportPDF chartRef={chartRef} disabled={isLoading} />}
      </Box>
      <Box ref={chartRef}>
        {isLoading ? (
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Grid>
        ) : (
          <Grid style={{ width: "100%", overflow: "auto" }}>
            <div>
              <Bar data={chartData} options={options} height={400} />
            </div>
          </Grid>
        )}
      </Box>
    </>
  );
};

export default SubmittedVacancies;
