// ** React Imports
import { FC } from "react";

// ** MUI Imports
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableRow from "@mui/material/TableRow";
import TableHead from "@mui/material/TableHead";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";

// ** Icons Imports

import { useAppDispatch } from "../../../store";
import FaqTableRow from "./FaqTableRow";
import * as Api from "../../../service";
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors, DragEndEvent } from "@dnd-kit/core";

import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from "@dnd-kit/sortable";
import { updateFAQList, updateFAQListOrder } from "../../../store/apps/faqSlice";

const FaqTable: FC<{ faqList: Api.GetFAQListResponseType[] }> = (props) => {
  const dispatch = useAppDispatch();

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event;

    if (over && active.id !== over!.id) {
      const oldIndex = props.faqList.findIndex((item) => item.id === active.id);
      const newIndex = props.faqList.findIndex((item) => item.id === over!.id);
      const newOrderedList = [...arrayMove(props.faqList, oldIndex, newIndex)];

      const updatedArrayList = [];
      const sortedArray: Api.UpdateOrderFAQRequestType[] = [];

      for (let i = 0; i < newOrderedList.length; i++) {
        updatedArrayList.push({ ...newOrderedList[i], orderNumber: i });
        sortedArray.push({
          faqId: updatedArrayList[i].id,
          orderNumber: i,
        });
      }
      dispatch(updateFAQList(updatedArrayList));
      dispatch(updateFAQListOrder(sortedArray));
    }
  };

  return (
    <TableContainer component={Paper} style={{ overflow: "hidden" }}>
      <Table aria-label="collapsible table">
        <TableHead>
          <TableRow>
            <TableCell />
            <TableCell>Question In English</TableCell>
            <TableCell>Question In Georgian</TableCell>
            <TableCell>Question For</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>Actions</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
            <SortableContext items={props.faqList.map((items) => items.id)} strategy={verticalListSortingStrategy}>
              {props.faqList.map((question) => (
                <FaqTableRow key={question.id} faqTableRow={question} />
              ))}
            </SortableContext>
          </DndContext>
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default FaqTable;
