import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PostGetStatisticsForUserVacsAndAppsType } from "../../../service";
import { RootState, useAppDispatch } from "../../../store";
import { PostGetStatisticsForUserVacsAndApps } from "../../../store/apps/statisticsSlice";
import { DateType } from "../../../types/forms/reactDatepickerTypes";
import DateRange from "../Components/Date Range";
import StatisticsComponent from "../Components/StatisticsComponent";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import PageHeader from "../../../components/@core/components/page-header";
import Typography from "@mui/material/Typography/Typography";


const Registrations = () => {
  const [dateRange, setDateRange] = useState<number>(2);
  const [endDate, setEndDate] = useState<DateType>(new Date());
  const [startDate, setStartDate] = useState<DateType>(new Date());
  const [chartData, setChartData] =
    useState<PostGetStatisticsForUserVacsAndAppsType>(
      {} as PostGetStatisticsForUserVacsAndAppsType
    );
  const dispatch = useAppDispatch();
  const { isLoading } = useSelector((state: RootState) => state.statistics)


  useEffect(() => {
    dispatch(
      PostGetStatisticsForUserVacsAndApps({
        dateType: dateRange,
        startDate: startDate?.toISOString(),
        endDate: endDate?.toISOString(),
      })
    )
      .unwrap()
      .then((res) => setChartData(res));
  }, [dateRange, endDate, startDate]);

  return (
    <>
      <PageHeader
        title={
          <Typography variant="h5" color="#666CFF">
            Registrations Statistics Report
          </Typography>
        }
      />
      <Grid>
        <DateRange
          setDateRange={setDateRange}
          dateRange={dateRange}
          endDate={endDate}
          setEndDate={setEndDate}
          startDate={startDate}
          setStartDate={setStartDate}
        />
      </Grid>

      {isLoading ? <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
        <CircularProgress />
      </Grid> : <StatisticsComponent name="Registration Results" data={chartData} />
      }

    </>
  );
};

export default Registrations;
