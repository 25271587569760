// ** React Imports
import { Ref, useState, forwardRef, ReactElement, FC, useEffect } from "react";
// ** MUI Imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Fade, { FadeProps } from "@mui/material/Fade";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import * as Yup from "yup";
import * as Api from "../../../service";

// ** Icons Imports
import Close from "mdi-material-ui/Close";
import Pencil from "mdi-material-ui/Pencil";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// import { getAdmins, postCreateNewAdmin } from '../../store/apps/adminsSlice'
import toast from "react-hot-toast";
import { useAppDispatch } from "../../../store";
import { getHeroImageDetails, updateHeroImageText } from "../../../store/apps/HeroImageSlice";
import { EmptyGuid } from "../../../utils/constants/constants";
import { useDropzone } from "react-dropzone";
import { FileDocumentOutline } from "mdi-material-ui";
import { postUpdateProfileImage } from "../../../store/apps/attributesSlice";
import { postUpdateHeroImage } from "../../../store/apps/attributesSlice";

interface FileProp {
  name: string;
  type: string;
  size: number;
}

const Transition = forwardRef(function Transition(
  props: FadeProps & { children?: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Fade ref={ref} {...props} />;
});

const addQuestionFormSchema = Yup.object()
  .shape({
    mainText: Yup.string().trim().min(3).required(),
    mainTextGE: Yup.string().trim().min(3).required(),
    description: Yup.string().trim().min(3).required(),
    descriptionGE: Yup.string().trim().min(3).required(),
  })
  .required();

const updateButtonFormSchema = Yup.object()
  .shape({
    name: Yup.string().trim().min(3).required(),
    nameGE: Yup.string().trim().min(3).required(),
    url: Yup.string().trim().min(3).required(),
  })
  .required();

const DialogManageHeroImage: FC<{
  onSuccess: () => void;
  heroImageDetails: Api.HeroImageDetailsType
}> = (props) => {
  const [show, setShow] = useState<boolean>(false);
  const [showAddQuickButtonModal, setShowAddQuickButtonModal] = useState<boolean>(false);
  const [image, setImage] = useState<File[] | string>();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<Api.UpdateHeroImageTextParams>({
    resolver: yupResolver(addQuestionFormSchema),
  });

  const {
    register: register2,
    handleSubmit: handleSubmit2,
    formState: { errors: errors2 },
    reset : reset2,
  } = useForm<Api.UpdateHeroImageButtonType>({
    resolver: yupResolver(updateButtonFormSchema),
  });

  const dispatch = useAppDispatch();

  const { getRootProps, getInputProps } = useDropzone({
    accept: {
      'image/png': ['.png'],
      "image/jpeg": ['.jpeg', '.jpg'],
      "image/webp": ['.webp']
    },
    onDrop: (acceptedFiles: File[]) => {
      setImage(acceptedFiles);
      dispatch(
        postUpdateHeroImage({
          HeroImage: acceptedFiles[0]
        })
      )
      .then(() => {
        toast.success("Image Updated successfully");
        dispatch(getHeroImageDetails());
      })
    },
  });

  useEffect(()=>{
    reset({
      ...props.heroImageDetails
    })

    if (props.heroImageDetails.heroImageURL) setImage(props.heroImageDetails.heroImageURL);
    
    return () => {
      setImage(undefined);
    };

   
  }, [props.heroImageDetails])

  const onSubmit: SubmitHandler<Api.UpdateHeroImageTextParams> = (data) => {
    dispatch(updateHeroImageText({ ...data }))
      .unwrap()
      .then(() => {
        toast.success("Updated successfully");
        props.onSuccess();
        setShow(false);
      })
      .catch((response) => {
        toast.error(response.error);
      });
  };


  const onSubmitAddButtonHandler: SubmitHandler<Api.UpdateHeroImageButtonType> = (data) => {
    dispatch(updateHeroImageText({ ...props.heroImageDetails, heroButtons: [{ ...data, id: EmptyGuid }, ...props.heroImageDetails.heroButtons] }))
    .unwrap()
    .then(() => {
      toast.success("Added successfully");
      props.onSuccess();
      setShowAddQuickButtonModal(false);
      reset2();
    })
    .catch((response) => {
      toast.error(response.error);
    });
  };

  const renderFilePreview = (file: FileProp | string) => {
    if (typeof file === "string") {
      return <img width={150} height={150} style={{ objectFit: "cover" }} alt={"Benefit"} src={file} />;
    } else if (file.type.startsWith("image")) {
      return <img width={150} height={150} style={{ objectFit: "cover" }} alt={file?.name || ""} src={URL.createObjectURL(file as any)} />;
    } else {
      return <FileDocumentOutline />;
    }
  };

  return (
    <Card>
      <CardContent sx={{ textAlign: "center" }}>
        <Pencil sx={{ mb: 2, fontSize: "2rem" }} />
        <Typography variant="h6" sx={{ mb: 4 }}>
          Manage Website Hero Image
        </Typography>

        <Grid item {...getRootProps({ className: "dropzone" })}>
            <Button>Choose Image</Button>
          <input {...getInputProps()} accept="image/png, image/gif, image/jpeg"  />
          {/* {renderFilePreview()} */}
        </Grid>
        <br />
        <Grid item>{image && renderFilePreview(typeof image === "string" ? image : image[0])}</Grid>
        <br />

        <Button variant="contained" sx={{ mr: 2 }} onClick={() => setShow(true)}>
          Edit Text
        </Button>
        <Button variant="contained" onClick={() => setShowAddQuickButtonModal(true)}>
          Add Quick Button
        </Button>
      </CardContent>

      <Dialog
        fullWidth
        open={show}
        maxWidth="md"
        scroll="body"
        onClose={() => {
          reset();
          setShow(false);
        }}
        TransitionComponent={Transition}
        onBackdropClick={() => {
          reset();
          setShow(false);
        }}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          spellCheck="false"
          autoComplete="off"
        >
          <DialogContent
            sx={{
              pb: 6,
              px: { xs: 8, sm: 15 },
              pt: { xs: 8, sm: 12.5 },
              position: "relative",
            }}
          >
            <IconButton
              size="small"
              onClick={() => {
                reset();
                setShow(false);
              }}
              sx={{ position: "absolute", right: "1rem", top: "1rem" }}
            >
              <Close />
            </IconButton>
            <Box sx={{ mb: 8, textAlign: "center" }}>
              <Typography variant="h5" sx={{ mb: 3, lineHeight: "2rem" }}>
                Edit main text and subtext
              </Typography>
            </Box>
            <Grid container spacing={6}>
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  label="Main text EN"
                  error={!!errors["mainText"]}
                  {...register("mainText")}
                />
                <br />
                <br />
                <TextField
                  multiline
                  label="Subtext EN"
                  rows={4}
                  error={!!errors["description"]}
                  {...register("description")}
                  fullWidth
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  label="Main text GE"
                  error={!!errors["mainTextGE"]}
                  {...register("mainTextGE")}
                />
                <br />
                <br />
                <TextField
                  multiline
                  label="Subtext GE"
                  rows={4}
                  error={!!errors["descriptionGE"]}
                  {...register("descriptionGE")}
                  fullWidth
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: "center" }}
          >
            <Button
              type="submit"
              variant="contained"
              sx={{ mr: 2 }}
              // onClick={() => setShow(false)}
            >
              Update
            </Button>
            <Button
              variant="outlined"
              type="reset"
              color="secondary"
              onClick={() => {
                reset();
                setShow(false);
              }}
            >
              Discard
            </Button>
          </DialogActions>
        </form>
      </Dialog>


      <Dialog
        fullWidth
        open={showAddQuickButtonModal}
        maxWidth="md"
        scroll="body"
        onClose={() => {
          reset2();
          setShowAddQuickButtonModal(false);
        }}
        TransitionComponent={Transition}
        onBackdropClick={() => {
          reset2();
          setShowAddQuickButtonModal(false);
        }}
      >
        <form
          onSubmit={handleSubmit2(onSubmitAddButtonHandler)}
          noValidate
          spellCheck="false"
          autoComplete="off"
        >
          <DialogContent
            sx={{
              pb: 6,
              px: { xs: 8, sm: 15 },
              pt: { xs: 8, sm: 12.5 },
              position: "relative",
            }}
          >
            <IconButton
              size="small"
              onClick={() => {
                reset2();
                setShowAddQuickButtonModal(false);
              }}
              sx={{ position: "absolute", right: "1rem", top: "1rem" }}
            >
              <Close />
            </IconButton>
            <Box sx={{ mb: 8, textAlign: "center" }}>
              <Typography variant="h5" sx={{ mb: 3, lineHeight: "2rem" }}>
                Add Quiz Button
              </Typography>
            </Box>
            <Grid container spacing={6}>
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  label="Text EN"
                  error={!!errors2["name"]}
                  {...register2("name")}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  label="Text GE"
                  error={!!errors2["nameGE"]}
                  {...register2("nameGE")}
                />
              </Grid>

              <Grid item sm={12} xs={12}>
                <TextField
                  fullWidth
                  label="Url"
                  error={!!errors2["url"]}
                  {...register2("url")}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: "center" }}
          >
            <Button
              type="submit"
              variant="contained"
              sx={{ mr: 2 }}
            >
              Add
            </Button>
            <Button
              variant="outlined"
              type="reset"
              color="secondary"
              onClick={() => {
                reset2();
                setShowAddQuickButtonModal(false);
              }}
            >
              Discard
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Card>
  );
};

export default DialogManageHeroImage;
