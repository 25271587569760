// ** React Imports
import { SyntheticEvent, useState } from 'react'

// ** MUI Imports
import Tab from '@mui/material/Tab'
import TabPanel from '@mui/lab/TabPanel'
import TabContext from '@mui/lab/TabContext'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import MuiTabList, { TabListProps } from '@mui/lab/TabList'

// Styled TabList component
const TabList = styled(MuiTabList)<TabListProps>(({ theme }) => ({
  '& .MuiTabs-indicator': {
    backgroundColor: 'transparent'
  },
  '& .Mui-selected': {
    backgroundColor: theme.palette.primary.main,
    color: `${theme.palette.common.white} !important`
  },
  '& .MuiTab-root': {
    minHeight: 38,
    minWidth: 110,
    borderRadius: 8,
    paddingTop: theme.spacing(2),
    paddingBottom: theme.spacing(2)
  }
}))

const TabsCustomized = (props: { textEditorEnglish?: any, textEditorGeorgian?: any }) => {
  // ** State
  const [value, setValue] = useState<string>('1')

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }

  return (
    // <TabContext value={value}>
    //   <TabList onChange={handleChange} aria-label='customized tabs example'>
    //     <Tab value='1' label='Tab 1' />
    //     <Tab value='2' label='Tab 2' />
    //     <Tab value='3' label='Tab 3' />
    //   </TabList>
    //   <TabPanel value='1'>
    //     <Typography>
    //       Cake apple pie chupa chups biscuit liquorice tootsie roll liquorice sugar plum. Cotton candy wafer wafer jelly
    //       cake caramels brownie gummies.
    //     </Typography>
    //   </TabPanel>
    //   <TabPanel value='2'>
    //     <Typography>
    //       Chocolate bar carrot cake candy canes sesame snaps. Cupcake pie gummi bears jujubes candy canes. Chupa chups
    //       sesame snaps halvah.
    //     </Typography>
    //   </TabPanel>
    //   <TabPanel value='3'>
    //     <Typography>
    //       Danish tiramisu jujubes cupcake chocolate bar cake cheesecake chupa chups. Macaroon ice cream tootsie roll
    //       carrot cake gummi bears.
    //     </Typography>
    //   </TabPanel>
    // </TabContext>
    <TabContext value={value}>
      <TabList onChange={handleChange} aria-label='customized tabs example'>
        <Tab value='1' label='English' />
        <Tab value='2' label='Georgian' />
      </TabList>
      <TabPanel value='1'>
        <Typography>
          {props.textEditorEnglish}
        </Typography>
      </TabPanel>
      <TabPanel value='2'>
        <Typography>
          {props.textEditorGeorgian}
        </Typography>
      </TabPanel>

    </TabContext>
  )
}

export default TabsCustomized
