import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../store';
import { getFAQList } from '../../../store/apps/faqSlice';
import DialogAddQuestion from './DialogAddQuestion';
import FaqTable from './FaqTable';
import classes from './styles.module.sass'


const Faq = ()=>{

  const { FAQList } = useSelector((state: RootState) => state.FAQ);
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getFAQList());
  }, []);

  return <div className={classes.component}>
    <DialogAddQuestion onSuccess={()=> { dispatch(getFAQList()); }} />
    <br />
    <FaqTable faqList={FAQList} />
  </div>
}

export default Faq;