import { Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import PageHeader from "../../../components/@core/components/page-header";
import { RootState, useAppDispatch } from "../../../store";
import { getPopularVacancyStatistics } from "../../../store/apps/statisticsSlice";
import { DateType } from "../../../types/forms/reactDatepickerTypes";
import { generateQueryString } from "../../../utils/helperFunctions/helpers";
import DateRange from "../Components/Date Range";
// import StatisticsTable from "../Components/Statistics Table";
import Typography from "@mui/material/Typography";

import { DataGrid, GridToolbarExport } from "@mui/x-data-grid";
import { dataType } from "../Components/types";
import ExportCSV from "../../../utils/views/export/ExportCSV";
import { Paginate } from "../../../utils/views/table/data-grid/Pagination";

const PopularVacancies = () => {
  const [dateRange, setDateRange] = useState<number>(2);
  const [endDate, setEndDate] = useState<DateType>(new Date());
  const [startDate, setStartDate] = useState<DateType>(new Date());

  const { popularVacancyStatistics, isLoading } = useSelector((state: RootState) => state.statistics);

  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(getPopularVacancyStatistics(generateQueryString(dateRange, startDate, endDate)));
  }, [dateRange, endDate, startDate]);

  const columns = [
    { field: "vacancyTitle", headerName: "Vacancy Title", flex: 1 },
    { field: "companyName", headerName: "COMPANY NAME", flex: 1 },
    { field: "totalApplications", headerName: "Total Applications", flex: 1 },
    {
      field: "totalClicks",
      headerName: "Total Clicks",
      flex: 1,
    },
  ];

  const generateRows = () => {
    let rows: any[] = [];
    popularVacancyStatistics?.forEach((el: dataType) => {
      let object = {
        id: el.companyId + Math.random(),
        companyName: el.companyName,
        vacancyTitle: el.vacancyTitle,
        totalApplications: el.totalApplications,
        totalClicks: el.totalClicks,
      };

      rows.push(object);
    });
    return rows;
  };

  return (
    <Grid container flexDirection={"column"}>
      <PageHeader
        title={
          <Typography variant="h5" color="#666CFF">
            Popular Vacancies
          </Typography>
        }
      />
      <Grid mb={2} mt={2}>
        <DateRange setDateRange={setDateRange} dateRange={dateRange} endDate={endDate} setEndDate={setEndDate} startDate={startDate} setStartDate={setStartDate} />
      </Grid>

      <Grid>
        <DataGrid
          columns={columns}
          rows={generateRows()}
          loading={isLoading}
          sx={{ height: 600 }}
          disableColumnSelector
          disableDensitySelector
          disableColumnMenu
          components={{
            Toolbar: ExportCSV,
            Pagination: Paginate,
          }}
          componentsProps={{
            toolbar: {
              componentSection: "popularVacancies",
              filterPanel: {},
              exportFilter: {
                dateFilter: { dateRange, startDate, endDate },
              },
            },
          }}
        />
      </Grid>
    </Grid>
  );
};

export default PopularVacancies;
