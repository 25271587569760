// ** React Imports
import { ReactNode, useContext } from "react";

// ** Component Imports
import { AbilityContext } from "./Can";

// ** Types
import { NavLink } from "../../../@core/layouts/types";

interface Props {
  navLink?: NavLink;
  children: ReactNode;
}

const CanViewNavLink = (props: Props) => {
  // ** Props
  const { children, navLink } = props;

  // ** Hook
  // const ability = useContext(AbilityContext);

  // console.log(ability && ability.can(navLink?.action, navLink?.subject));

  // return ability && ability.can(navLink?.action, navLink?.subject) ? (
  //   <>{children}</>
  // ) : null;

  return <>{children}</>;
};

export default CanViewNavLink;
