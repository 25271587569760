// ** style imports
import classes from "./style.module.sass";

// ** MUI Imports
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import Typography from "@mui/material/Typography";
import PageHeader from "../../../components/@core/components/page-header";

import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../store";
import { useEffect, useState } from "react";
import { DateType } from "../../../types/forms/reactDatepickerTypes";
import { getApplicationStatistics, getApplicationTalentStatistics } from "../../../store/apps/statisticsSlice";
import Box from "@mui/material/Box";
import DateRange from "../Components/Date Range";
import { DataGrid, GridToolbarExport } from "@mui/x-data-grid";

// ** components
import TableDrawer from "../Components/TableDrawer";
import DrawerWrapper from "../Components/TableDrawer/DrawerWrapper";
import { getAllDictionaries } from "../../../store/apps/talentsSlice";
import ExportCSV from "../../../utils/views/export/ExportCSV";
import { Paginate } from "../../../utils/views/table/data-grid/Pagination";

const Applications = () => {
  const { applicationStatistics, applicationVacancy, isLoading } = useSelector((state: RootState) => state.statistics);
  const dispatch = useAppDispatch();
  const [dateRange, setDateRange] = useState<number>(2);
  const [endDate, setEndDate] = useState<DateType>(new Date());
  const [startDate, setStartDate] = useState<DateType>(new Date());
  const [openModal, setOpenModal] = useState<boolean>(false);

  const generateQueryString = () => {
    let dateFrom = startDate?.toISOString();
    let dateTo = endDate?.toISOString();
    let queryString = `${dateRange}`;
    if (startDate && !endDate) {
      queryString = `${dateRange}&dateFrom=${dateFrom}`;
    }
    if (endDate && !startDate) {
      queryString = `${dateRange}&dateTo=${dateTo}`;
    }
    if (startDate && endDate) {
      queryString = `${dateRange}&dateFrom=${dateFrom}&dateTo=${dateTo}`;
    }
    return queryString;
  };

  useEffect(() => {
    dispatch(getApplicationStatistics(generateQueryString()));
  }, [dateRange, endDate, startDate]);

  useEffect(() => {
    if (applicationVacancy) setOpenModal(true);
  }, [applicationVacancy]);

  useEffect(() => {
    dispatch(getAllDictionaries());
  }, []);

  // useEffect(() => {
  //   dispatch(getApplicationStatistics(generateQueryString()))
  // }, [])

  // const rows = [
  //   { id: 1, companyName: 'Hello', vacancyTitle: 'World', totalApplications: "traki" },
  //   { id: 2, companyName: 'DataGridPro', vacancyTitle: 'is Awesome', totalApplications: "traki2" },
  //   // { id: 3, companyName: 'MUI', vacancyTitle: 'is Amazing', totalApplications: "traki3" },
  // ];

  // const columns = [
  //   { id: 'companyName', label: 'Company Name', minWidth: 150, flex: 0.4 },
  //   { id: 'vacancyTitle', label: 'Vacancy Title', minWidth: 150, flex: 0.4 },
  //   { id: 'totalApplications', label: 'Total Applications', minWidth: 150, flex: 0.2 },
  // ];

  // const columns = [
  //   { id: "vacancyTitle", label: "Vacancy Title", minWidth: 170 },
  //   { id: "totalApplications", label: "Total Applications", minWidth: 100 },
  //   {
  //     id: "totalClicks",
  //     label: "Total Clicks",
  //     minWidth: 170,
  //   },
  // ];

  const columns = [
    {
      field: "companyName",
      headerName: "Company Name",
      minWidth: 150,
      flex: 0.4,
    },
    {
      field: "vacancyTitle",
      headerName: "Vacancy Title",
      minWidth: 150,
      flex: 0.4,
    },
    {
      field: "totalApplications",
      headerName: "Total Applications",
      minWidth: 150,
      flex: 0.2,
    },
  ];

  const generateRows = () => {
    let rows: any[] = [];
    applicationStatistics?.data.forEach((datum: any) => {
      let object = {
        id: datum.vacancyId,
        companyName: datum.companyName,
        vacancyTitle: datum.vacancyTitle,
        totalApplications: datum.totalApplications,
      };
      rows.push(object);
    });
    return rows;
  };

  const handleCellClick = (params: any) => {
    if (params.field.toLowerCase() !== "totalapplications") return;
    dispatch(
      getApplicationTalentStatistics({
        statisticsFilter: dateRange,
        vacancyId: params.id,
        dateFrom: `${startDate?.toISOString()}`,
        dateTo: endDate ? `${endDate.toISOString()}` : `${new Date().toISOString()}`,
      })
    );
  };

  return (
    <>
      <Grid container spacing={6}>
        <PageHeader
          title={
            <Typography variant="h5" color="#666CFF">
              Applications Report
            </Typography>
          }
        />
        <Grid item xs={6}>
          <DateRange setDateRange={setDateRange} dateRange={dateRange} endDate={endDate} setEndDate={setEndDate} startDate={startDate} setStartDate={setStartDate} />
        </Grid>
        <Grid item xs={12}>
          <Box sx={{ height: 600 }} className={classes.table}>
            <DataGrid
              columns={columns}
              rows={generateRows()}
              sx={{ height: 600 }}
              loading={isLoading}
              disableColumnSelector
              disableDensitySelector
              disableColumnMenu
              onCellClick={handleCellClick}
              components={{
                Toolbar: ExportCSV,
                Pagination: Paginate,
              }}
              componentsProps={{
                toolbar: {
                  componentSection: "applications",
                  filterPanel: {},
                  exportFilter: {
                    dateFilter: {
                      dateRange,
                      startDate,
                      endDate,
                    },
                  },
                },
              }}
            />
          </Box>
          <Box>
            <DrawerWrapper data={applicationVacancy} open={openModal} onClose={setOpenModal} />
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default Applications;
