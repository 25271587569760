import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import Box from "@mui/material/Box";
import { Typography } from "@mui/material";
import ReactDOMServer from "react-dom/server";

import classes from "./styles.module.sass";
import { CSSProperties, MutableRefObject } from "react";

import html2canvas from "html2canvas";
import jsPDF from "jspdf";

interface ExportPDFProps {
  chartRef: MutableRefObject<HTMLDivElement | null>;
  disabled: boolean;
}

const ExportPDF = ({ chartRef, disabled }: ExportPDFProps) => {
  if (!chartRef.current) return null;

  const convert = () => {
    if (!chartRef.current) return;
    return html2canvas(chartRef.current).then((canvas) => {
      if (!chartRef.current) return;
      const img = canvas.toDataURL("image/png");
      const pdf = new jsPDF("l", "px", [chartRef.current.clientWidth, chartRef.current.clientHeight]);

      const imgProps = pdf.getImageProperties(img);
      const pdfWidth = pdf.internal.pageSize.getWidth();
      const pdfHeight = (imgProps.height * pdfWidth) / imgProps.width;
      pdf.addImage(img, "PNG", 0, 0, pdfWidth, pdfHeight);
      pdf.save("chart.pdf");
      // pdf.html(chartRef.current, {
      //   callback(doc) {
      //     doc.save("chart.pdf");
      //   },
      // });
    });
  };

  const buttonStyles: CSSProperties = {
    pointerEvents: disabled ? "none" : "auto",
    backgroundColor: disabled ? "gray" : "",
  };

  return (
    <Box className={classes.wrapper} sx={buttonStyles} onClick={convert}>
      <PictureAsPdfIcon />
      <Typography>Export</Typography>
    </Box>
  );
};

export default ExportPDF;
