// ** React Imports
import { Fragment } from "react";

// ** MUI components
import useMediaQuery from "@mui/material/useMediaQuery";
import { styled, useTheme } from "@mui/material/styles";

interface FooterIllustrationsProp {
  image?: string;
}

// Styled components
const MaskImg = styled("img")(({ theme }) => ({
  zIndex: -1,
  bottom: "7%",
  width: "100%",
  position: "absolute",
  [theme.breakpoints.down("lg")]: {
    bottom: "10%",
  },
}));

const FooterIllustrationsV1 = (props: FooterIllustrationsProp) => {
  // ** Props
  const { image } = props;

  // ** Hook
  const theme = useTheme();

  // ** Vars
  const hidden = useMediaQuery(theme.breakpoints.down("md"));

  const src =
    image || `/images/pages/auth-v1-login-mask-${theme.palette.mode}.png`;

  if (!hidden) {
    return (
      <Fragment>
        <MaskImg alt="mask" src={src} />
      </Fragment>
    );
  } else {
    return null;
  }
};

export default FooterIllustrationsV1;
