import { useState, useEffect, useRef } from "react";
import { useAppDispatch, RootState } from "../../../store";
import { DateType } from "../../../types/forms/reactDatepickerTypes";
import { useSelector } from "react-redux";
import DateRange from "../Components/Date Range";
import CardContent from "@mui/material/CardContent";
import { Bar } from "react-chartjs-2";
import CircularProgress from "@mui/material/CircularProgress";
import PageHeader from "../../../components/@core/components/page-header";
import Typography from "@mui/material/Typography";

import Box from "@mui/material/Box";
import { getUserPageActivityStatistics, getVacancyAppliedStatistics } from "../../../store/apps/statisticsSlice";
import { Line } from "react-chartjs-2";
import Grid from "@mui/material/Grid";
import StatisticsComponent from "./StatisticsComponent";
import ExportPDF from "../../../utils/views/export/ExportPDF";

const labelColor = "#ff9800";
const borderColor = "#28dac6";
const gridLineColor = "#787EFF";

const ActiveDay = () => {
  const { vacancyStatistics, activeDayStatistics, isLoading } = useSelector((state: RootState) => state.statistics);

  const dispatch = useAppDispatch();

  const [dateRange, setDateRange] = useState<number>(2);
  const [endDate, setEndDate] = useState<DateType>(new Date());
  const [startDate, setStartDate] = useState<DateType>(new Date());

  const chartRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    handleSubmit();
  }, [dateRange, endDate, startDate]);

  const generateQueryString = () => {
    let dateFrom = startDate?.toISOString();
    let dateTo = endDate?.toISOString();
    let queryString = "";
    if (!startDate && !endDate) {
      queryString = `${dateRange}`;
    }
    if (startDate && !endDate) {
      queryString = `${dateRange}&dateFrom=${dateFrom}`;
    }
    if (endDate && !startDate) {
      queryString = `${dateRange}&dateTo=${dateTo}`;
    }
    if (startDate && endDate) {
      queryString = `${dateRange}&dateFrom=${dateFrom}&dateTo=${dateTo}`;
    }
    return queryString;
  };

  const handleSubmit = () => {
    let queryString = generateQueryString();
    dispatch(getUserPageActivityStatistics(queryString));
    dispatch(getVacancyAppliedStatistics(queryString));
  };

  const calculateMaxValue = () => {
    return activeDayStatistics && Math.ceil(Math.max(...activeDayStatistics?.data[1]?.datasets[0].data) / 10) * 10;
  };

  const areaChartOptions: any = {
    responsive: true,
    maintainAspectRatio: false,
    layout: {
      padding: { top: -20 },
    },
    scales: {
      x: {
        grid: {
          borderColor,
          color: "transparent",
        },
        ticks: { color: labelColor },
      },
      y: {
        min: 0,
        max: calculateMaxValue(),
        grid: {
          borderColor,
          color: "transparent",
        },
        ticks: {
          stepSize: 10,
          color: labelColor,
        },
      },
    },
    plugins: {
      legend: {
        align: "start",
        position: "top",
        labels: {
          padding: 25,
          boxWidth: 9,
          color: labelColor,
          usePointStyle: true,
        },
      },
    },
  };

  const areaChartData = {
    labels: activeDayStatistics?.data[1]?.labels,
    datasets: [
      {
        fill: true,
        tension: 0,
        label: activeDayStatistics?.data[1].datasets[0].label,
        pointRadius: 0.5,
        pointHoverRadius: 5,
        pointStyle: "circle",
        pointHoverBorderWidth: 5,
        borderColor: "transparent",
        backgroundColor: "#787EFF",
        pointHoverBorderColor: "#fff",
        pointBorderColor: "transparent",
        pointHoverBackgroundColor: "#787EFF",
        data: activeDayStatistics?.data[1]?.datasets[0].data,
      },
    ],
    total: activeDayStatistics?.data[1]?.datasets[0].total,
  };

  const barChartOptions: any = {
    indexAxis: "x",
    responsive: true,
    maintainAspectRatio: false,
    animation: { duration: 500 },
    elements: {
      bar: {
        borderRadius: {
          topRight: 15,
          bottomRight: 15,
        },
      },
    },
    layout: {
      padding: { top: -4 },
    },
    scales: {
      x: {
        min: 0,
        grid: {
          drawTicks: false,
          color: gridLineColor,
          borderColor: "#787EFF",
        },
        ticks: { color: labelColor },
      },
      y: {
        grid: {
          borderColor,
          display: false,
        },
        ticks: { color: labelColor },
      },
    },
    plugins: {
      legend: {
        align: "end",
        position: "top",
        labels: { color: labelColor },
      },
    },
  };

  const barChartData = {
    labels: activeDayStatistics?.data[0].labels,
    datasets: [
      {
        label: activeDayStatistics?.data[0].datasets[0].label,
        maxBarThickness: 150,
        backgroundColor: "#787EFF",
        borderColor: "#787EFF",
        borderRadius: {
          topRight: 4,
          topLeft: 4,
        },
        data: activeDayStatistics?.data[0].datasets[0].data,
      },
    ],
    total: activeDayStatistics?.data[0].datasets[0].total,
  };

  return (
    <>
      <PageHeader
        title={
          <Typography variant="h5" color="#666CFF">
            Daily Activity Statistics Report
          </Typography>
        }
      />
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          padding: "10px",
        }}
      >
        <DateRange setDateRange={setDateRange} dateRange={dateRange} endDate={endDate} setEndDate={setEndDate} startDate={startDate} setStartDate={setStartDate} />
        <ExportPDF chartRef={chartRef} disabled={isLoading} />
      </Box>
      <Box ref={chartRef}>
        {isLoading ? (
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Grid>
        ) : (
          <>
            <StatisticsComponent vacancyAppliedStatistics={vacancyStatistics?.total} totalVisitStatistics={activeDayStatistics?.data[0].total} />
            <Grid>{activeDayStatistics && <Bar data={barChartData} options={barChartOptions} height={400} />}</Grid>
            <CardContent>{activeDayStatistics && <Line data={areaChartData} options={areaChartOptions} height={450} />}</CardContent>
          </>
        )}
      </Box>
    </>
  );
};

export default ActiveDay;
