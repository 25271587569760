import { ReactElement, Ref, forwardRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import { Button, Fade, FadeProps, Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { useAppDispatch } from "../../store";
import { postDeleteAdminUser } from "../../store/apps/adminsSlice";
import toast from "react-hot-toast";

const Transition = forwardRef(function Transition(props: FadeProps & { children?: ReactElement<any, any> }, ref: Ref<unknown>) {
  return <Fade ref={ref} {...props} />;
});

const DialogDeleteAdmin = ({ open, setOpen, data, refetchData }: { open: boolean; setOpen: (val: undefined) => void; data: GridRenderCellParams["row"]; refetchData: () => void }) => {
  const dispatch = useAppDispatch();

  const handleDeleteAdmin = async () => {
    const response = await dispatch(postDeleteAdminUser(data.email));
    // @ts-ignore
    if (response.payload.succeeded) toast.success("Deleted");
    else toast.error("something went wrong");
    refetchData();
    setOpen(undefined);
  };

  return (
    <Dialog open={open} onClose={() => setOpen(undefined)} TransitionComponent={Transition} maxWidth="md" scroll="body">
      <DialogContent>
        <Typography variant="body2" sx={{ display: "flex", alignItems: "center" }}>
          Are you sure you want to delete
          <Typography variant="body2" color="primary" fontWeight="bold" sx={{ marginInline: 1 }}>
            {data?.name}
          </Typography>
          from admins?
        </Typography>
        <Typography variant="body1" fontWeight="bold" color="error" textAlign="center" marginY={2}>
          This action can not be reverted!
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleDeleteAdmin} color="error">
          Delete
        </Button>
        <Button onClick={() => setOpen(undefined)}>Discard</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DialogDeleteAdmin;
