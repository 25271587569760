import { useParams } from "react-router-dom";
import Error404 from "../404";
import ActiveDay from "./activeDay/index";
import Applications from "./applications/index";
import PopularCompanies from "./popularCompanies/index";
import PopularVacancies from "./popularVacancies/index";
import Registrations from "./registrations/index";
import SubmittedVacancies from "./submittedVacancies/index";

const Statistics = () => {
  const { pageName } = useParams();

  switch (pageName) {
    case "registrations":
      return <Registrations />;
    case "applications":
      return <Applications />;
    case "submitted-vacancies":
      return <SubmittedVacancies />;
    case "popular-vacancies":
      return <PopularVacancies />;
    case "popular-companies":
      return <PopularCompanies />;
    case "active-day":
      return <ActiveDay />;
    default:
      return <Error404 />;
  }
};

export default Statistics;
