// ** React Imports
import { Ref, useState, forwardRef, ReactElement, SetStateAction, Dispatch, useEffect } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import Fade, { FadeProps } from "@mui/material/Fade";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Select from "@mui/material/Select";
import FileDocumentOutline from "mdi-material-ui/FileDocumentOutline";

// ** Icons Imports
import Close from "mdi-material-ui/Close";
import AccountOutline from "mdi-material-ui/AccountOutline";
import { DictionaryListType, DictionaryTypeEnum, GetDictionaryRequestType } from "../../../service";
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../store";
import { useDropzone } from "react-dropzone";
import { getDictionary, postCreateUpdateDictionary, postUpdateProfileImage } from "../../../store/apps/attributesSlice";

const Transition = forwardRef(function Transition(props: FadeProps & { children?: ReactElement<any, any> }, ref: Ref<unknown>) {
  return <Fade ref={ref} {...props} />;
});

type ModalProps = {
  show: boolean;
  setShow: Dispatch<SetStateAction<boolean>>;
  data?: GetDictionaryRequestType["data"]["dictionaryList"][0];
};

interface FileProp {
  name: string;
  type: string;
  size: number;
}

export const Modal = ({ show, setShow, data }: ModalProps) => {
  const { dictionaryName, dictionryType, parentDictionary, isLoading } = useSelector((state: RootState) => state.attributes);
  const [image, setImage] = useState<File[] | string>();
  const [storedData, setStoredData] = useState<DictionaryListType>({} as DictionaryListType);

  const dispatch = useAppDispatch();

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles: File[]) => {
      setImage(acceptedFiles);
    },
  });

  useEffect(() => {
    if (!data) return;
    setStoredData(data);
    if (data.iconUrl) setImage(data.iconUrl);
    return () => {
      setStoredData({} as DictionaryListType);
      setImage(undefined);
    };
  }, [data]);

  useEffect(() => {
    return () => setImage(undefined);
  }, []);

  const renderFilePreview = (file: FileProp | string) => {
    if (typeof file === "string") {
      return <img width={150} height={150} style={{ objectFit: "cover" }} alt={"Benefit"} src={file} />;
    } else if (file.type.startsWith("image")) {
      return <img width={150} height={150} style={{ objectFit: "cover" }} alt={file?.name || ""} src={URL.createObjectURL(file as any)} />;
    } else {
      return <FileDocumentOutline />;
    }
  };

  const handleSubmit = () => {
    if (!storedData) return;
    dispatch(
      postCreateUpdateDictionary({
        id: storedData?.id || null,
        description: storedData?.description || "",
        descriptionGE: storedData.descriptionGE || "",
        name: storedData.name!,
        nameGE: storedData.nameGE!,
        dictionryType,
        parentDictionary: storedData?.parentDictionary?.id || null,
      })
    )
      .unwrap()
      .then((res) => {
        if (res.succeeded === true) {
          if (image) {
            dispatch(
              postUpdateProfileImage({
                body: {
                  dictionaryType: dictionryType,
                  itemId: storedData.id!,
                  ProfileImage: typeof image !== "string" ? image?.find((el) => el) : undefined,
                },
              })
            ).then(() => {
              dispatch(getDictionary(dictionryType));
              setShow(false);
            });
          }
        }
      })
      .then(() => {
        if (!image) {
          dispatch(getDictionary(dictionryType));
          setShow(false);
        }
      });
  };

  return (
    <Card>
      <Dialog fullWidth open={show} maxWidth="sm" scroll="body" onClose={() => setShow(false)} TransitionComponent={Transition} onBackdropClick={() => setShow(false)}>
        <DialogContent
          sx={{
            pb: 6,
            px: { xs: 8, sm: 15 },
            pt: { xs: 8, sm: 12.5 },
            position: "relative",
          }}
        >
          <IconButton size="small" onClick={() => setShow(false)} sx={{ position: "absolute", right: "1rem", top: "1rem" }}>
            <Close />
          </IconButton>
          <Box sx={{ mb: 8, textAlign: "center" }}>
            <Typography variant="h5" sx={{ mb: 3, lineHeight: "2rem" }}>
              {data && Object.keys(data).length > 0 ? "Edit" : "Add"} {dictionaryName}
            </Typography>
          </Box>
          <Grid
            container
            spacing={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            <Grid item sm={6} xs={12}>
              <TextField fullWidth value={storedData?.name} onChange={(e) => setStoredData({ ...storedData, name: e.target.value })} label={`${dictionaryName} (EN)`} />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField fullWidth value={storedData?.nameGE} onChange={(e) => setStoredData({ ...storedData, nameGE: e.target.value })} label={`${dictionaryName} (GE)`} />
            </Grid>
            {dictionryType === DictionaryTypeEnum.Technology && (
              <Grid item sm={12} xs={12} sx={{ width: "35%", margin: "0 auto" }}>
                <FormControl fullWidth>
                  <InputLabel id="tech_category">Tech. Category</InputLabel>
                  <Select
                    value={storedData?.parentDictionary?.id}
                    onChange={(e) =>
                      setStoredData({
                        ...storedData,
                        parentDictionary: {
                          id: e.target.value,
                          name: parentDictionary?.data?.dictionaryList.find((el) => el.id === e.target.value)?.name!,
                          // @ts-ignore: Unreachable code error
                          dictionaryName: parentDictionary?.data?.dictionaryList.find((el) => el.id === e.target.value)?.parentDictionary?.dictionaryName || null,
                        },
                      })
                    }
                    fullWidth
                    labelId="tech_category"
                    label="Tech. Category"
                  >
                    {parentDictionary?.data?.dictionaryList.map((el) => (
                      // @ts-ignore: Unreachable code error
                      <MenuItem value={el.id} name={el.name}>
                        {el.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <Grid item {...getRootProps({ className: "dropzone" })}>
                  <Button>Choose Image</Button>
                  <input {...getInputProps()} />
                  {/* {renderFilePreview()} */}
                </Grid>
                <Grid item>{image && renderFilePreview(typeof image === "string" ? image : image[0])}</Grid>
              </Grid>
            )}

            {dictionryType === DictionaryTypeEnum.Benefit && (
              <>
                <Grid item sx={{ width: "100%" }}>
                  <TextField
                    label="Description (EN)"
                    multiline
                    fullWidth
                    value={storedData?.description}
                    onChange={(e) =>
                      setStoredData({
                        ...storedData,
                        description: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item sx={{ width: "100%" }}>
                  <TextField
                    label="Description (GE)"
                    multiline
                    fullWidth
                    value={storedData?.descriptionGE}
                    onChange={(e) =>
                      setStoredData({
                        ...storedData,
                        descriptionGE: e.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid item {...getRootProps({ className: "dropzone" })}>
                  <Button>Choose Image</Button>
                  <input {...getInputProps()} />
                  {/* {renderFilePreview()} */}
                </Grid>
                <Grid item>{image && renderFilePreview(typeof image === "string" ? image : image[0])}</Grid>
              </>
            )}
            <Grid item>
              {isLoading && (
                <Typography variant="body1" color="primary" sx={{ mt: 1 }}>
                  your request is being processed... Please Wait!
                </Typography>
              )}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: "center" }}>
          <Button variant="contained" sx={{ mr: 2 }} onClick={handleSubmit}>
            Submit
          </Button>
          <Button variant="outlined" color="secondary" onClick={() => setShow(false)}>
            Discard
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};
