import { useEffect, useState } from "react";
import { RootState, useAppDispatch } from "../../store";
import {
  deleteCompanyVacancy,
  getAllDictionaries,
  getSearchVacancy,
  putRenewAllExpiredVacancies,
  putRenewExpiredVacancies,
  setSubmittedFilterObject,
  setSubmittedPage,
} from "../../store/apps/vacanciesSlice";
import { CompanyVacancySortEnum } from "../../types/enums";
import TableServerSide from "../../utils/views/table/data-grid/TableServerSide";
import { useColumns } from "./Columns";

// ** Third Party Imports
import { useSelector } from "react-redux";

// ** MUI Imports
import { GridSortModel } from "@mui/x-data-grid";
import { Button, Grid } from "@mui/material";
import DialogAddCard from "./DialogAddCard";
import DialogRenewVacancy from "./DialogRenewVacancy";
import axiosInstance from "../../axios/axiosInstance";
import { format } from "date-fns";
import { useNavigate } from "react-router";
import DeleteModal from "../ui/modals/deleteModal";
import { postDeleteCompanyVacancies } from "../../service";

const Vacancies: React.FC = () => {
  const navigate = useNavigate();
  const [renewModal, setRenewModal] = useState<boolean>(false);
  const [deleteModal, setDeleteModal] = useState<boolean>(false);
  const [deleteSelected, setDeleteSelected] = useState<boolean>(true);

  const toggleRenewModal = () => {
    setRenewModal(!renewModal);
  };
  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const {
    vacancies,
    submittedFilteredObject,
    submittedPage,
    isLoading,
    selectedItems,
  } = useSelector((state: RootState) => state.vacancies);
  const dispatch = useAppDispatch();
  const { columns, deleteID, setDeleteID, renewVacancyID, setRenewVacancyID } =
    useColumns();

  const handleDelete = async (deleteID: string) => {
    await dispatch(deleteCompanyVacancy(deleteID));
    dispatch(getSearchVacancy(submittedFilteredObject));
    setDeleteID("");
  };

  useEffect(() => {
    if (deleteID) {
      handleDelete(deleteID);
    }
  }, [deleteID, submittedFilteredObject]);

  useEffect(() => {
    dispatch(
      getSearchVacancy({
        ...submittedFilteredObject,
        PageNumber: submittedPage,
      })
    );
  }, [submittedFilteredObject, submittedPage]);

  const handleSortModel = (newModel: GridSortModel) => {
    if (newModel.length === 0) {
      const cloned = { ...submittedFilteredObject };

      delete cloned["SortBy"];
      delete cloned["IsDesc"];

      dispatch(
        setSubmittedFilterObject({
          ...cloned,
        })
      );
      return;
    }
    // @ts-ignore: Unreachable code error
    const sortColumn = CompanyVacancySortEnum[newModel[0].field];
    const desc = newModel[0].sort === "desc" ? true : false;

    dispatch(
      setSubmittedFilterObject({
        ...submittedFilteredObject,
        SortBy: parseInt(sortColumn),
        IsDesc: desc,
      })
    );
  };

  const renewVacancyHandler = (deadline: Date) => {
    axiosInstance
      .put(`api/backOfficeVacancy/renewCompanyVacancy`, {
        vacancyId: renewVacancyID,
        deadline: format(deadline, "yyyy-MM-dd"),
      })
      .then(() => {
        setRenewVacancyID(undefined);
        dispatch(getSearchVacancy(submittedFilteredObject));
      })
      .catch(() => {
        setRenewVacancyID(undefined);
      });
  };

  const handleEdit = () => {
    navigate(`/pages/vacancies/add`);
  };

  const AddNewData: React.FunctionComponent<{}> = (props: any) => {
    return (
      <Button
        sx={{ background: "primary", mb: 2 }}
        onClick={handleEdit}
      >
        Add vacancy
      </Button>
    );
  };
  const deleteVacancies = () => {
    postDeleteCompanyVacancies(selectedItems).then(() => {
      dispatch(getSearchVacancy(submittedFilteredObject));
    });
    setDeleteModal(false);
  };

  return (
    <div>
      {vacancies && (
        <TableServerSide
          data={vacancies}
          toggleRenewModal={toggleRenewModal}
          toggleDeleteModal={toggleDeleteModal}
          renewModal={renewModal}
          columns={columns}
          checkboxField={true}
          title="Vacancies"
          stateDispatcher={getSearchVacancy}
          storedState="vacancies"
          dictionaryStateDispatcher={getAllDictionaries}
          deleteSelected={deleteSelected}
          sortTable={handleSortModel}
          filterObject={submittedFilteredObject}
          AddNewData={AddNewData}
          updatePage={(pageNum) => {
            dispatch(setSubmittedPage({ submittedPage: pageNum + 1 }));
          }}
          updatePageSize={(pageSize) =>
            dispatch(
              setSubmittedFilterObject({
                ...submittedFilteredObject,
                PageSize: parseInt(pageSize),
              })
            )
          }
        />
      )}
      {renewVacancyID && (
        <DialogRenewVacancy
          onSubmit={renewVacancyHandler}
          vacancyId={renewVacancyID}
          onClose={() => setRenewVacancyID(undefined)}
        />
      )}
      {deleteModal && (
        <DeleteModal
          onSubmit={deleteVacancies}
          onClose={() => setDeleteModal(false)}
        />
      )}
    </div>
  );
};

export default Vacancies;
