import {
  postUpdateSeoDetailsForPageRequestParams,
  postUpdateSEOImageRequestParams,
} from "./params";
import axiosInstance from "../../axios/axiosInstance";

export const getSeoDetailsRequest = () => {
  return axiosInstance.get(`api/BackOfficeSeo/GetSeoDetails`);
};

export const postUpdateSeoDetailsForPageRequest = (
  params: postUpdateSeoDetailsForPageRequestParams
) => {
  return axiosInstance.post(
    `api/BackOfficeSeo/UpdateSeoDetailsForPage?page=${params.pageType}`,
    params.body,
    {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    }
  );
};

export const postUpdateSeoImage = (params: postUpdateSEOImageRequestParams) => {
  return axiosInstance.post(
    `api/BackOfficeSeo/UpdateSeoDetailsImage?page=${params.pageType}`,
    params.formData
  );
};
