// ** React Imports
import { forwardRef, useState } from "react";

// ** MUI Imports
import Card from "@mui/material/Card";
import TextField from "@mui/material/TextField";
import CardHeader from "@mui/material/CardHeader";
import CardContent from "@mui/material/CardContent";
import InputAdornment from "@mui/material/InputAdornment";

// ** Third Party Imports
import format from "date-fns/format";
import { Bar } from "react-chartjs-2";
import DatePicker from "react-datepicker";

import { GetAttractiveCompaniesRequestType } from "../../../../service";
interface BarProp {
  yellow: string;
  labelColor: string;
  borderColor: string;
  gridLineColor: string;
  data: GetAttractiveCompaniesRequestType;
  title: string;
  // max: number;
}

const ChartjsBarChart = (props: BarProp) => {
  const { labelColor, borderColor, gridLineColor } = props;
   const highestNum = Math.max(...props.data.datasets[0].data);
   const step = highestNum < 100 ? 50 : 100;

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    animation: { duration: 500 },
    scales: {
      x: {
        grid: {
          borderColor,
          color: gridLineColor,
        },
        ticks: { color: labelColor },
      },
      y: {
        min: 0,
        // max: props.max,
        grid: {
          borderColor,
          color: gridLineColor,
        },
        ticks: {
          // stepSize: 100,
          stepSize: step,
          color: labelColor,
        },
      },
    },
    plugins: {
      legend: { display: false },
    },
  };

  return (
    <Card>
      <CardHeader
        title={props.title}
        titleTypographyProps={{ variant: "h6" }}
      />
      <CardContent>
        {/*  @ts-ignore: Unreachable code error */}
        <Bar data={props.data} options={options} height={400} />
      </CardContent>
    </Card>
  );
};

export default ChartjsBarChart;
