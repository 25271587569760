import { useState } from "react";

import { Button } from "@mui/material";
import Typography from "@mui/material/Typography";
import { GridColumns, GridRenderCellParams } from "@mui/x-data-grid";
import { format } from "date-fns";
import { useNavigate } from "react-router-dom";
import CustomChip from "../../../../components/@core/components/mui/chip";
import { ThemeColor } from "../../../../components/@core/layouts/types";
import CustomAvatar from "../../../../components/@core/components/mui/avatar";
import { getInitials } from "../../../../components/@core/utils/get-initials";
import Box from "@mui/material/Box";
import DeleteDataModal from "./DeleteDataModal";

export const useColumns = (
  pageName: string,
  handleDelete?: (params: GridRenderCellParams) => void
) => {
  const [modal, setModal] = useState<boolean>(false);
  const [dataID, setDataID] = useState<string | undefined>();
  const navigate = useNavigate();

  interface StatusObj {
    [key: number]: {
      title: string;
      color: ThemeColor;
    };
  }

  const statusObj: StatusObj = {
    1: { title: "Active", color: "primary" },
    2: { title: "Not Active", color: "error" },
    3: { title: "Verified", color: "primary" },
    4: { title: "Not Verified", color: "error" },
    5: { title: "Pending Review", color: "warning" },
  };

  const handleVerificationStatus = (
    isEmailVerified: boolean | null,
    isVerified: boolean | null
  ) => {
    if (pageName === "talents") {
      if (isEmailVerified) return 3;
      else return 4;
    } else {
      if (!isEmailVerified && !isVerified) return 4;
      else if (isEmailVerified && !isVerified) return 5;
      else return 3;
    }
  };

  const handleEdit = (params: GridRenderCellParams) => {
    if (pageName === "talents") {
      setModal(!modal);
      setDataID(params.row.id);
      return;
    }
    navigate(`/pages/${pageName}/${params.row.id}`);
  };

  // ** renders client column
  const renderClient = (params: GridRenderCellParams) => {
    const { row } = params;
    const stateNum = Math.floor(Math.random() * 6);
    const states = [
      "success",
      "error",
      "warning",
      "info",
      "primary",
      "secondary",
    ];
    const color = states[stateNum];

    if (row.smallProfileImageUrl && row.smallProfileImageUrl.length) {
      return (
        <CustomAvatar
          src={`${row.smallProfileImageUrl}`}
          sx={{ mr: 3, width: "1.875rem", height: "1.875rem" }}
        />
      );
    } else {
      return (
        <CustomAvatar
          skin="light"
          color={color as ThemeColor}
          sx={{
            mr: 3,
            fontSize: ".8rem",
            width: "1.875rem",
            height: "1.875rem",
          }}
        >
          {getInitials(row.name ? row.name : "No name found")}
        </CustomAvatar>
      );
    }
  };

  let columns: GridColumns = [
    {
      flex: 0.25,
      minWidth: 290,
      field: "name",
      headerName: "Name",
      renderCell: (params: GridRenderCellParams) => {
        const { row } = params;

        return (
          <Box sx={{ display: "flex", alignItems: "center" }}>
            {renderClient(params)}
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              <Typography
                noWrap
                variant="body2"
                sx={{ color: "text.primary", fontWeight: 600 }}
              >
                {row.name}
              </Typography>
              <Typography noWrap variant="caption">
                {row.email}
              </Typography>
            </Box>
          </Box>
        );
      },
    },
    {
      flex: 0.175,
      minWidth: 120,
      headerName: "Date",
      field: "createDate",
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="body2" sx={{ color: "text.primary" }}>
          {format(new Date(params.row.createDate), "dd MMM yyyy")}
        </Typography>
      ),
    },
    {
      flex: 0.175,
      minWidth: 140,
      field: "isActive",
      headerName: "Status",
      renderCell: (params: GridRenderCellParams) => {
        const status = statusObj[params.row.isActive ? 1 : 2];

        return (
          <CustomChip
            size="small"
            skin="light"
            color={status.color}
            label={status.title}
            sx={{ "& .MuiChip-label": { textTransform: "capitalize" } }}
          />
        );
      },
    },
    {
      flex: 0.175,
      minWidth: 140,
      field: `isEmailVerified`,
      headerName: "Verification",
      renderCell: (params: GridRenderCellParams) => {
        const status =
          statusObj[
            handleVerificationStatus(
              params.row.isEmailVerified,
              params.row.isVerified
            )
          ];

        return (
          <CustomChip
            size="small"
            skin="light"
            color={status.color}
            label={status.title}
            sx={{ "& .MuiChip-label": { textTransform: "capitalize" } }}
          />
        );
      },
    },
    {
      flex: 0.175,
      minWidth: 140,
      field: `isVerified`,
      headerName: "Verification",
      renderCell: (params: GridRenderCellParams) => {
        const status =
          statusObj[
            handleVerificationStatus(
              params.row.isEmailVerified,
              params.row.isVerified
            )
          ];

        return (
          <CustomChip
            size="small"
            skin="light"
            color={status.color}
            label={status.title}
            sx={{ "& .MuiChip-label": { textTransform: "capitalize" } }}
          />
        );
      },
    },
    {
      flex: 0.175,
      minWidth: 120,
      headerName: "Actions",
      field: "id",
      renderCell: (params: GridRenderCellParams) => (
        <Typography variant="body2" sx={{ color: "text.primary" }}>
          <Button
            size="small"
            variant="outlined"
            color="secondary"
            onClick={() => handleEdit(params)}
            style={{ margin: "0 10px 0 0" }}
          >
            Edit
          </Button>
          {/* <Button onClick={() => handleDelete(params)}> */}
          <DeleteDataModal id={params.row.id} pageName={pageName} />
          {/* </Button> */}
        </Typography>
      ),
    },
  ];

  if (pageName === "talents") {
    columns = columns.filter((el) => el.field !== "isVerified");
  } else {
    columns = columns.filter((el) => el.field !== "isEmailVerified");
  }

  return { columns, modal, setModal, setDataID, dataID, handleDelete };
};
