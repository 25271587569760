import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import Fade, { FadeProps } from "@mui/material/Fade";
import { ReactElement, Ref, forwardRef } from "react";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";

type modalProps = {
  onSubmit(): void;
  onClose(): void;
};

const Transition = forwardRef(function Transition(props: FadeProps & { children?: ReactElement<any, any> }, ref: Ref<unknown>) {
  return <Fade ref={ref} {...props} />;
});

const DeleteModal = ({ onSubmit, onClose }: modalProps) => {
  return (
    <Card>
      <Dialog open={true} TransitionComponent={Transition}>
        <DialogContent>
          <Typography variant="body1" sx={{ mb: 3, lineHeight: "2rem" }}>
            Are you sure you want to delete selected items?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={onSubmit}>Delete</Button>
          <Button type="reset" color="error" onClick={onClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

export default DeleteModal;
