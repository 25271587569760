import { useEffect } from "react";
import { RootState, useAppDispatch } from "../../store";
import { getAllDictionaries, getSearchCompanies, setSubmittedFilterObject, setSubmittedPage } from "../../store/apps/companiesSlice";
import { CompanyTalentSortEnum } from "../../types/enums";
import { handleObjectForQueryString } from "../../utils/helperFunctions";
import TableServerSide from "../../utils/views/table/data-grid/TableServerSide";
import { useColumns } from "../../utils/views/table/data-grid/Columns";

// ** Third Party Imports
import { useSelector } from "react-redux";

// ** MUI Imports
import { GridRenderCellParams, GridSortModel } from "@mui/x-data-grid";
import { deleteCompanyOrTalent } from "../../store/apps/talentsSlice";

const Companies: React.FC = () => {
  const { companies, submittedFilteredObject, submittedPage } = useSelector((state: RootState) => state.companies);
  const dispatch = useAppDispatch();

  const handleDelete = (params: GridRenderCellParams) => {
    dispatch(deleteCompanyOrTalent(params.row.id))
      .unwrap()
      .then(() => {
        dispatch(getSearchCompanies(handleObjectForQueryString(submittedFilteredObject, submittedPage)));
      });
  };

  const { columns } = useColumns("companies", handleDelete);

  useEffect(() => {
    dispatch(getSearchCompanies(handleObjectForQueryString(submittedFilteredObject, submittedPage)));
  }, [submittedFilteredObject, submittedPage]);

  useEffect(() => {
    return () => {
      dispatch(setSubmittedFilterObject({} as typeof submittedFilteredObject));
    };
  }, []);

  const handleSortModel = (newModel: GridSortModel) => {
    if (newModel.length === 0) return;
    // @ts-ignore: Unreachable code error
    const sortColumn = CompanyTalentSortEnum[newModel[0].field];
    const desc = newModel[0].sort === "desc" ? true : false;

    dispatch(
      setSubmittedFilterObject({
        ...submittedFilteredObject,
        SortBy: parseInt(sortColumn),
        IsDesc: desc,
      })
    );
  };

  return (
    <div>
      {companies && (
        <TableServerSide
          key="companiesTable"
          checkboxField={true}
          data={companies}
          columns={columns}
          title="Companies"
          stateDispatcher={getSearchCompanies}
          storedState="companies"
          dictionaryStateDispatcher={getAllDictionaries}
          sortTable={handleSortModel}
          updatePage={(pageNum) => dispatch(setSubmittedPage({ submittedPage: pageNum + 1 }))}
          filterObject={submittedFilteredObject}
          updatePageSize={(pageSize) =>
            dispatch(
              setSubmittedFilterObject({
                ...submittedFilteredObject,
                PageSize: parseInt(pageSize),
              })
            )
          }
        />
      )}
    </div>
  );
};

export default Companies;
