import { FormControl, Grid, InputAdornment, InputLabel, ListSubheader, MenuItem, Select, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import {
  getSearchCompaniesRequest,
  GetSearchTalentsRequestType,
} from "../../service";
import { handleObjectForQueryString } from "../../utils/helperFunctions";

type props = {
  handleStateUpdate: (
    key: string,
    value: string | boolean | null | string[] | { id: string; name: string }
  ) => void;
  modelObject: any;
  name: string;
  title: string;
  enumType: string;
};

function CompanySelect({
  handleStateUpdate,
  modelObject,
  name,
  title,
}: props) {
  const [searchTerm, setSearchTerm] = useState("");
  const [options, setOptions] = useState<GetSearchTalentsRequestType>();
  useEffect(() => {
    const getOptions = async () => {
      const resp = await getSearchCompaniesRequest(
        handleObjectForQueryString(
          {
            SearchTerm: searchTerm,
            PageSize: 20,
          },
          1
        )
      );
      setOptions(resp.data);
    };
    getOptions();
  }, [searchTerm]);

  // @ts-ignore: Unreachable code error
  return (
    <Grid
      item
      xs={6}
    >
      <FormControl fullWidth>
        <InputLabel id={name}>{title}</InputLabel>
        {options && (
          <Select
            label={title}
            labelId={name}
            // @ts-ignore: Unreachable code error
            value={modelObject[name] || []}
            onOpen={()=>setSearchTerm("")}
            onChange={(e) =>
              handleStateUpdate(
                name,
                // @ts-ignore: Unreachable code error
                e.target.value
              )
            }
            sx={{
              mb: 4,
              width: "100%",
              color: "text.secondary",
            }}
          >
            <ListSubheader>
              <TextField
                size="small"
                // Autofocus on textfield
                autoFocus
                placeholder="Type to search..."
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                    </InputAdornment>
                  ),
                }}
                onChange={(e) => setSearchTerm(e.target.value)}
                onKeyDown={(e) => {
                  if (e.key !== "Escape") {
                    // Prevents autoselecting item while typing (default Select behaviour)
                    e.stopPropagation();
                  }
                }}
              />
            </ListSubheader>
            {options?.data.map((el) => (
              <MenuItem
                key={el.id}
                value={el.id}
                id={el.name}
              >
                {el.name}
              </MenuItem>
            ))}
          </Select>
        )}
      </FormControl>
    </Grid>
  );
}

export default CompanySelect;
