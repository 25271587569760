import { useEffect, useState } from "react";

// ** Mui
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Dialog from "@mui/material/Dialog";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Checkbox from "@mui/material/Checkbox";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";

// ** types
import { companyBenefit, companyBenefitParent } from "../../../../../types/apps/companyTypes";
import { benefitTypeEnum } from "../../../../../service";

type propTypes = {
  data?: companyBenefitParent;
  updateData: (data: companyBenefitParent) => void;
  open: boolean;
  onClose(): void;
};

const CompanyBenefitsModal = ({ data, updateData, open, onClose }: propTypes) => {
  const [localData, setLocalData] = useState<companyBenefitParent | undefined>();

  useEffect(() => {
    setLocalData(data);
  }, [data]);

  const handleUpdateData = () => {
    if (localData) {
      updateData(localData);
    }
  };

  const handleUpdateModalData = (key: keyof companyBenefit, value: string | boolean | number, id: string) => {
    if (!localData || !Array.isArray(localData?.benefits)) return;
    // checkbox checked
    if (typeof value === "boolean" && value) {
      const addedObject = localData?.benefits.find((el) => el.id === id);
      const chosenData = { ...localData, companyBenefits: [...localData.companyBenefits, { ...addedObject!, checked: true }] };
      setLocalData(chosenData);
      // checkbox unchecked
    } else if (typeof value === "boolean" && !value) {
      const chosenData = localData?.companyBenefits.filter((el) => {
        if (el.id !== id && !el.canNotBeDeletedByTheCompany) return el;
      });
      setLocalData({ ...localData, companyBenefits: chosenData });
      // modified value
    } else {
      const chosenData = localData.companyBenefits.map((el) => {
        if (el.id === id) {
          return { ...el, [key]: value };
        }
        return el;
      });
      setLocalData({ ...localData, companyBenefits: chosenData });
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        {localData?.benefits.map((el) => {
          const chosenBenefit = localData?.companyBenefits.find((benefit) => benefit.id === el.id);
          return (
            <Box sx={{ display: "flex", alignItems: "center" }} key={el.id}>
              <Checkbox checked={chosenBenefit?.checked} disabled={el.canNotBeDeletedByTheCompany} onChange={(e) => handleUpdateModalData("checked", e.target.checked, el.id)} />
              <Card key={el.id} sx={{ mb: 2, p: 4, width: "100%" }}>
                <Box sx={{ display: "flex", alignItems: "center", flexFlow: "row nowrap" }}>
                  <Typography variant="body2">Name:</Typography>
                  <Typography variant="body2" sx={{ fontWeight: "bold", ml: 2 }}>
                    {el.name}
                  </Typography>
                </Box>
                <Divider sx={{ marginY: 4 }} />

                <Box sx={{ display: "grid", gridTemplateColumns: el.benefitType === benefitTypeEnum.text ? "repeat(2, 1fr)" : "repeat(1, 1fr)", gap: "20px" }}>
                  {el.benefitType === benefitTypeEnum.text ? (
                    <>
                      <TextField
                        multiline
                        value={chosenBenefit?.description}
                        label="Description"
                        onChange={(e) => {
                          handleUpdateModalData("description", e.target.value, el?.id);
                        }}
                        disabled={!chosenBenefit?.checked}
                        sx={{ width: "100%" }}
                      />
                      <TextField
                        multiline
                        value={chosenBenefit?.descriptionGE}
                        label="DescriptionGE"
                        onChange={(e) => {
                          handleUpdateModalData("descriptionGE", e.target.value, el?.id);
                        }}
                        disabled={!chosenBenefit?.checked}
                        sx={{ width: "100%" }}
                      />
                    </>
                  ) : (
                    <TextField
                      multiline
                      value={chosenBenefit?.descriptionNumber}
                      label="Description Number"
                      onChange={(e) => {
                        const numericValue = e.target.value.replace(/[^0-9]/g, "");
                        handleUpdateModalData("descriptionNumber", numericValue, el?.id);
                      }}
                      type="number"
                      disabled={!chosenBenefit?.checked}
                      sx={{ width: "100%" }}
                    />
                  )}
                </Box>
              </Card>
            </Box>
          );
        })}
      </DialogContent>
      <DialogActions>
        <Box>
          <Button onClick={onClose} color="error">
            cancel
          </Button>
          <Button onClick={() => handleUpdateData()}>Save Changes</Button>
        </Box>
      </DialogActions>
    </Dialog>
  );
};

export default CompanyBenefitsModal;
