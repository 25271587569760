import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '../../../store';
import classes from './styles.module.sass'
import DialogManageHeroImage from './DialogManageHeroImage';
import ButtonsTable from './ButtonsTable';
import { getHeroImageDetails } from '../../../store/apps/HeroImageSlice';

const HeroImage = ()=>{

  const heroImageDetails = useSelector((state: RootState) => state.heroImageSlice.details);
  const dispatch = useAppDispatch()

  useEffect(() => {
    dispatch(getHeroImageDetails());
  }, []);

  return heroImageDetails ? <div className={classes.component}>
    <DialogManageHeroImage heroImageDetails={heroImageDetails} onSuccess={()=> { dispatch(getHeroImageDetails()); }} />
    <br />
    <ButtonsTable heroImageDetails={heroImageDetails} />
  </div> : null
}

export default HeroImage;