import React, { useEffect, useState } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Magnify from "mdi-material-ui/Magnify";
import IconButton from "@mui/material/IconButton";
import Close from "mdi-material-ui/Close";

// ** 3rd party
import { DndContext, closestCenter, KeyboardSensor, PointerSensor, useSensor, useSensors, DragEndEvent } from "@dnd-kit/core";
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from "@dnd-kit/sortable";

// ** store
import { useSelector } from "react-redux";
import { RootState, useAppDispatch } from "../../../../store";
import { KeyfactDataType } from "../../../../types/apps/attributeTypes";

// ** components
import KeyFactItem from "./KeyFactItem";

// ** style
import classes from "./Keyfacts.module.sass";
import { getKeyFacts, postCreateUpdateKeyFact, postUpdateOrderKeyFact } from "../../../../store/apps/attributesSlice";
import toast from "react-hot-toast";

const KeyFacts = () => {
  const dispatch = useAppDispatch();

  const [data, setData] = useState<KeyfactDataType[]>([]);
  const [searchVal, setSearchVal] = useState<string>("");
  const [mainDropDown, setmainDropDown] = useState<string | null>(null);

  const { keyFacts } = useSelector((state: RootState) => state.attributes);

  useEffect(() => {
    initData();
  }, []);

  useEffect(() => {
    if (keyFacts) setData(keyFacts);
  }, [keyFacts]);

  const initData = async () => {
    await dispatch(getKeyFacts());
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  );

  const addKeyFact = () => {
    const alreadyAdded = data.find((el) => el.id === "new");
    if (alreadyAdded) return toast.error("Please add one Keyfact at once");
    const newObject: KeyfactDataType = {
      keyFactName: "",
      name: "",
      nameGE: "",
      keyFactType: 1,
      hide: false,
      isMandatory: false,
      options: [],
      orderIndex: 0,
      id: "new",
      hideInSearch: false,
    };
    const newData = [newObject, ...data];
    setData(newData);
  };

  const handleDragEnd = async (event: DragEndEvent) => {
    const { active, over } = event;
    if (over && active.id !== over!.id) {
      const oldIndex = data.findIndex((item) => item.id === active.id);
      const newIndex = data.findIndex((item) => item.id === over!.id);
      const newOrderedList = [...arrayMove(data, oldIndex, newIndex)];
      const updatedArrayList = [];
      for (let i = 0; i < newOrderedList.length; i++) {
        updatedArrayList.push({ ...newOrderedList[i], orderIndex: i });
      }
      setData(updatedArrayList);
      const idList = updatedArrayList.map((el) => el.id || "");
      await dispatch(postUpdateOrderKeyFact(idList));
    }
  };

  const handleUpdateData = async (newData: KeyfactDataType) => {
    const response = await dispatch(postCreateUpdateKeyFact(newData));
    if (response.meta.requestStatus === "fulfilled") dispatch(getKeyFacts());
  };

  return (
    <Card>
      <CardHeader title={"Benefit"} />
      <Box className={classes.keyFactsTable}>
        <Box className={classes.headerWrapper}>
          <Button onClick={addKeyFact}>Add Keyfact</Button>
          <TextField
            variant="standard"
            value={searchVal}
            onChange={(e) => setSearchVal(e.target.value)}
            placeholder="Search…"
            InputProps={{
              startAdornment: <Magnify fontSize="small" />,
              endAdornment: (
                <IconButton size="small" title="Clear" aria-label="Clear" onClick={() => setSearchVal("")}>
                  <Close fontSize="small" />
                </IconButton>
              ),
            }}
            sx={{
              width: {
                xs: 1,
                sm: "auto",
              },
              m: (theme) => theme.spacing(1, 0.5, 1.5),
              "& .MuiSvgIcon-root": {
                mr: 0.5,
              },
              "& .MuiInput-underline:before": {
                borderBottom: 1,
                borderColor: "divider",
              },
            }}
          />
        </Box>
        <Box className={classes.contentWrapper}>
          <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd} onDragStart={() => setmainDropDown("")}>
            <SortableContext items={data.map((items) => items.id || "")} strategy={verticalListSortingStrategy}>
              {data
                .filter((el) => el.name?.toLowerCase().includes(searchVal.toLowerCase()))
                .map((el: KeyfactDataType, idx) => (
                  <Box key={el.id}>
                    <KeyFactItem open={mainDropDown} setOpen={setmainDropDown} data={el} saveData={handleUpdateData} refetchData={initData} />
                  </Box>
                ))}
            </SortableContext>
          </DndContext>
        </Box>
      </Box>
    </Card>
  );
};

export default KeyFacts;
