import axiosInstance from "../../axios/axiosInstance";
import { applicationVacancyType, PostGetStatisticsForUserVacsAndAppsParams } from "./types";

export const getStatisticsRequest = (queryString: string) => {
  return axiosInstance.get(`api/BackOfficeStatistics/GetDashboardStatistics?statisticsFilter=${queryString}`);
};

export const getTopAttractiveCompaniesRequest = (queryString: string) => {
  return axiosInstance.get(
    `api/BackOfficeStatistics/GetTopAttractiveCompanies?statisticsFilter=${queryString}`
  );
};

export const getVacancyAppliedStatisticsRequest = (queryString: string) => {
  return axiosInstance.get(
    `api/BackOfficeStatistics/GetVacancyAppliedStatistics?statisticsFilter=${queryString}`
  );
};
export const PostGetStatisticsForUserVacsAndAppsRequest = (
  object: PostGetStatisticsForUserVacsAndAppsParams
) => {
  return axiosInstance.post(
    `api/BackOfficeStatistics/GetStatisticsForUsersVacsAndApps`,
    object
  );
};

export const getUserPageActivityStatisticsRequest = (queryString: string) => {
  return axiosInstance.get(
    `api/BackOfficeStatistics/GetUserPageActivityStatistics?statisticsFilter=${queryString}`
  );
};


export const getApplicationStatisticsRequest = (queryString: string) => {
  return axiosInstance.get(
    `api/BackOfficeStatistics/GetApplicationStatistics?statisticsFilter=${queryString}`
  );
};

export const getPopularVacancyStatisticsRequest = (queryString: string) => {
  return axiosInstance.get(`api/BackOfficeStatistics/GetPopularVacancyStatistics?statisticsFilter=${queryString}`)
}

export const getPopularCompanyStatisticsRequest = (queryString: string) => {
  return axiosInstance.get(`api/BackOfficeStatistics/getPopularCompanyStatistics?statisticsFilter=${queryString}`)
}

export const getApplicationTalentStatisticsRequest = ({statisticsFilter, vacancyId, dateFrom, dateTo}: applicationVacancyType) => {
  return axiosInstance.get(`api/BackOfficeStatistics/GetApplicationTalentStatistics?statisticsFilter=${statisticsFilter}&vacancyId=${vacancyId}&dateFrom=${dateFrom}&dateTo=${dateTo}`)
}