import axiosInstance from "../../axios/axiosInstance";
import { getQuerryText } from "../../utils/helperFunctions/helpers";
import {
  postUpdateCompanyVacancyParam,
  RenewAllExpiredVacanciesParam,
  RenewExpiredVacanciesParam,
} from "./params";
import { getSearchVacancyType } from "./types";

export const getSearchVacancyRequest = (query: getSearchVacancyType | null) => {
  return axiosInstance.get(
    `api/BackOfficeVacancy/SearchVacancy${getQuerryText(query)}`
  );
};

export const getCompanyVacancyRequest = (queryString: string) => {
  return axiosInstance.get(
    `api/BackOfficeVacancy/GetCompanyVacancy?vacancyId=${queryString}`
  );
};

export const postDeleteCompanyVacancyRequest = (id: string) => {
  return axiosInstance.post(
    `api/BackOfficeVacancy/DeleteCompanyVacancy?vacancyId=${id}`
  );
};

export const postUpdateCompanyVacancyRequest = (
  object: postUpdateCompanyVacancyParam,
  vacancyId: string
) => {
  if (!object.elementContext) {
    object.elementContext = "";
  }
  return axiosInstance.post(
    `api/BackOfficeVacancy/UpdateCompanyVacancy?vacancyId=${vacancyId}`,
    object
  );
};

export const postAddCompanyVacancyRequest = (
  object: postUpdateCompanyVacancyParam,
  companyId: string
) => {
  if (!object.elementContext) {
    object.elementContext = "";
  }
  return axiosInstance.post(
    `api/BackOfficeVacancy/CreateCompanyVacancy?companyId=${companyId}`,
    object
  );
};

export const putRenewAllExpiredVacanciesRequest = (
  deadline: RenewAllExpiredVacanciesParam
) => {
  return axiosInstance.put(
    "api/BackOfficeVacancy/RenewAllExpiredVacancies",
    deadline
  );
};

export const putRenewExpiredVacanciesRequest = (
  object: RenewExpiredVacanciesParam
) => {
  return axiosInstance.put(
    "api/BackOfficeVacancy/RenewExpiredVacancies",
    object
  );
};

export const postDeleteCompanyVacancies = (arr: string[]) => {
  return axiosInstance.post("api/BackOfficeVacancy/DeleteCompanyVacancies", arr)
}