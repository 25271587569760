import axiosInstance from '../../axios/axiosInstance';
import {
  getAdminsRequestParams,
  postCreateNewAdminRequestParams,
  postEditAdminUserRequestParams,
  PostRecoverAdminUserPasswordRequestParams,
  postRefreshTokenRequestParams,
  PostSendPasswordRecoveryLinkRequestParams,
  PostSignInRequestParams,
} from './params';
import {
  PostSendPasswordRecoveryLinkRequestType,
  PostSignInRequestType,
} from './types';

export const postSignInRequest = (params: PostSignInRequestParams) => {
  return axiosInstance.post<PostSignInRequestType>(
    `api/BackOfficeAccount/SignIn`,
    params.body
  );
};

export const postSendPasswordRecoveryLinkRequest = (
  params: PostSendPasswordRecoveryLinkRequestParams
) => {
  return axiosInstance.post<PostSendPasswordRecoveryLinkRequestType>(
    `api/BackOfficeAccount/SendPasswordRecoveryLink`,
    params.body
  );
};

export const postRefreshTokenRequest = (
  params: postRefreshTokenRequestParams
) => {
  return axiosInstance.post(`api/BackOfficeAccount/RefreshToken`, params.body, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
  });
};

export const postRecoverAdminUserPasswordRequest = (
  params: PostRecoverAdminUserPasswordRequestParams
) => {
  return axiosInstance.post(
    `api/BackOfficeAccount/RecoverAdminUserPassword`,
    params.body
  );
};

export const postCreateNewAdminRequest = (
  newAdminData: postCreateNewAdminRequestParams
) => {
  return axiosInstance.post(`api/BackOfficeUsers/CreateAdminUser`, newAdminData);
};

export const getAdminsRequest = () => {
  return axiosInstance.get(`api/BackOfficeUsers/SearchUsers?pageNumber=1&pageSize=10`);
};

export const getSearchAdminsRequest = (params: getAdminsRequestParams) => {
  return axiosInstance.get(
    `api/BackOfficeUsers/SearchUsers?pageNumber=${params.pageNumber}&pageSize=${params.pageSize}${params.SortBy ? `&sortBy=${params.SortBy}` : ''}${params.IsDesc !== undefined ? `&isDesc=${params.IsDesc}` : ''}${params.searchTerm.length > 0 ? `&searchTerm=${params.searchTerm}` : ''}`,
    {}
  );
};

export const getFrontUrlRequest = () => {
  return axiosInstance.get(`api/BackOfficeHelper/GetFrontUrl`, {});
};

export const postGenerateTokenForViewAsUserRequest = (id: string) => {
  return axiosInstance.post(`api/BackOfficeUsers/GenerateTokenForViewAsUser?userId=${id}`)
}

export const postEditAdminUserRequest = (data: postEditAdminUserRequestParams) => {
  return axiosInstance.post(`api/BackOfficeUsers/EditAdminUser`, data)
}

export const postDeleteAdminUserRequest = (email: string) => {
  return axiosInstance.post(`api/BackOfficeUsers/DeleteAdminUser?email=${email}`, {email})
}