// ** React Imports
import {
  useState,
  SyntheticEvent,
  ChangeEvent,
  MouseEvent,
  ReactNode,
} from "react";
import { ReactComponent as MainLogo } from "../../assets/SVG/Techwave.ge-Dark.svg";

// ** Next Imports
// import Link from 'next/link'
import { useSearchParams, useNavigate } from "react-router-dom";

// ** MUI components
import Box from "@mui/material/Box";
import MuiLink from "@mui/material/Link";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import InputLabel from "@mui/material/InputLabel";
import IconButton from "@mui/material/IconButton";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import { styled, useTheme } from "@mui/material/styles";
import MuiCard, { CardProps } from "@mui/material/Card";
import InputAdornment from "@mui/material/InputAdornment";

// ** Icons Imports
import EyeOutline from "mdi-material-ui/EyeOutline";
import ChevronLeft from "mdi-material-ui/ChevronLeft";
import EyeOffOutline from "mdi-material-ui/EyeOffOutline";

// ** Configs
import themeConfig from "../../configs/themeConfig";

// ** Layout Import
import BlankLayout from "../../components/@core/layouts/BlankLayout";

// ** Demo Imports
import FooterIllustrationsV1 from "../../utils/views/pages/auth/FooterIllustrationsV1";
import toast from "react-hot-toast";

// ** State imports
import { postRecoverAdminUserPassword } from "../../store/apps/authSlice";
import { RootState, useAppDispatch } from "../../store";

interface State {
  newPassword: string;
  showNewPassword: boolean;
  confirmNewPassword: string;
  showConfirmNewPassword: boolean;
}

// ** Styled components
const Card = styled(MuiCard)<CardProps>(({ theme }) => ({
  [theme.breakpoints.up("sm")]: { width: "28rem" },
}));

const ResetPasswordV1 = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // ** params
  const [searchParams] = useSearchParams();
  const passwordRecoveryId = searchParams.get("passwordrecoveryid");

  // ** States
  const [values, setValues] = useState<State>({
    newPassword: "",
    showNewPassword: false,
    confirmNewPassword: "",
    showConfirmNewPassword: false,
  });

  // ** Hook
  const theme = useTheme();

  const handleSubmit = (e: SyntheticEvent) => {
    e.preventDefault();
    if (passwordRecoveryId === null) return;
    if (values.newPassword !== values.confirmNewPassword) {
      return toast.error("Password doesn't match!");
    }

    dispatch(
      postRecoverAdminUserPassword({
        body: {
          passwordRecoveryId: passwordRecoveryId!,
          confirmPassword: values.confirmNewPassword,
          newPassword: values.newPassword,
        },
      })
    )
      .unwrap()
      .then(() => navigate("/login"));
  };

  // Handle New Password
  const handleNewPasswordChange =
    (prop: keyof State) => (event: ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };
  const handleClickShowNewPassword = () => {
    setValues({ ...values, showNewPassword: !values.showNewPassword });
  };
  const handleMouseDownNewPassword = (event: MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  // Handle Confirm New Password
  const handleConfirmNewPasswordChange =
    (prop: keyof State) => (event: ChangeEvent<HTMLInputElement>) => {
      setValues({ ...values, [prop]: event.target.value });
    };
  const handleClickShowConfirmNewPassword = () => {
    setValues({
      ...values,
      showConfirmNewPassword: !values.showConfirmNewPassword,
    });
  };
  const handleMouseDownConfirmNewPassword = (
    event: MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  return (
    <Box className="content-center">
      <Card sx={{ zIndex: 1 }}>
        <CardContent
          sx={{ p: (theme) => `${theme.spacing(15.5, 7, 8)} !important` }}
        >
          <Box
            sx={{
              mb: 8,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <MainLogo style={{ height: "30px", width: "30px" }} />
            {/* <svg
              width={47}
              fill="none"
              height={26}
              viewBox="0 0 268 150"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                rx="25.1443"
                width="50.2886"
                height="143.953"
                fill={theme.palette.primary.main}
                transform="matrix(-0.865206 0.501417 0.498585 0.866841 195.571 0)"
              />
              <rect
                rx="25.1443"
                width="50.2886"
                height="143.953"
                fillOpacity="0.4"
                fill="url(#paint0_linear_7821_79167)"
                transform="matrix(-0.865206 0.501417 0.498585 0.866841 196.084 0)"
              />
              <rect
                rx="25.1443"
                width="50.2886"
                height="143.953"
                fill={theme.palette.primary.main}
                transform="matrix(0.865206 0.501417 -0.498585 0.866841 173.147 0)"
              />
              <rect
                rx="25.1443"
                width="50.2886"
                height="143.953"
                fill={theme.palette.primary.main}
                transform="matrix(-0.865206 0.501417 0.498585 0.866841 94.1973 0)"
              />
              <rect
                rx="25.1443"
                width="50.2886"
                height="143.953"
                fillOpacity="0.4"
                fill="url(#paint1_linear_7821_79167)"
                transform="matrix(-0.865206 0.501417 0.498585 0.866841 94.1973 0)"
              />
              <rect
                rx="25.1443"
                width="50.2886"
                height="143.953"
                fill={theme.palette.primary.main}
                transform="matrix(0.865206 0.501417 -0.498585 0.866841 71.7728 0)"
              />
              <defs>
                <linearGradient
                  y1="0"
                  x1="25.1443"
                  x2="25.1443"
                  y2="143.953"
                  id="paint0_linear_7821_79167"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop />
                  <stop offset="1" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                  y1="0"
                  x1="25.1443"
                  x2="25.1443"
                  y2="143.953"
                  id="paint1_linear_7821_79167"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop />
                  <stop offset="1" stopOpacity="0" />
                </linearGradient>
              </defs>
            </svg> */}
            <Typography
              variant="h6"
              sx={{
                ml: 2,
                lineHeight: 1,
                fontWeight: 700,
                fontSize: "1.5rem !important",
              }}
            >
              {themeConfig.templateName}
            </Typography>
          </Box>
          <Box sx={{ mb: 6 }}>
            <Typography
              variant="h5"
              sx={{ mb: 1.5, letterSpacing: "0.18px", fontWeight: 600 }}
            >
              Reset Password 🔒
            </Typography>
            <Typography variant="body2">
              Your new password must be different from previously used passwords
            </Typography>
          </Box>
          <form noValidate autoComplete="off" onSubmit={handleSubmit}>
            <FormControl fullWidth sx={{ mb: 4 }}>
              <InputLabel htmlFor="auth-reset-password-new-password">
                New Password
              </InputLabel>
              <OutlinedInput
                autoFocus
                label="New Password"
                value={values.newPassword}
                id="auth-reset-password-new-password"
                onChange={handleNewPasswordChange("newPassword")}
                type={values.showNewPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={handleClickShowNewPassword}
                      aria-label="toggle password visibility"
                      onMouseDown={handleMouseDownNewPassword}
                    >
                      {values.showNewPassword ? (
                        <EyeOutline />
                      ) : (
                        <EyeOffOutline />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 4 }}>
              <InputLabel htmlFor="auth-reset-password-confirm-password">
                Confirm Password
              </InputLabel>
              <OutlinedInput
                label="Confirm Password"
                value={values.confirmNewPassword}
                id="auth-reset-password-confirm-password"
                type={values.showConfirmNewPassword ? "text" : "password"}
                onChange={handleConfirmNewPasswordChange("confirmNewPassword")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      aria-label="toggle password visibility"
                      onClick={handleClickShowConfirmNewPassword}
                      onMouseDown={handleMouseDownConfirmNewPassword}
                    >
                      {values.showConfirmNewPassword ? (
                        <EyeOutline />
                      ) : (
                        <EyeOffOutline />
                      )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <Button
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              sx={{ mb: 5.25 }}
            >
              Set New Password
            </Button>
            <Typography
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Typography
                href="/login"
                component={MuiLink}
                sx={{
                  display: "flex",
                  alignItems: "center",
                  color: "primary.main",
                  justifyContent: "center",
                }}
              >
                <ChevronLeft sx={{ mr: 1.5, fontSize: "2rem" }} />
                <span>Back to login</span>
              </Typography>
            </Typography>
          </form>
        </CardContent>
      </Card>
      <FooterIllustrationsV1
        image={`/images/pages/auth-v1-reset-password-mask-${theme.palette.mode}.png`}
      />
    </Box>
  );
};

ResetPasswordV1.getLayout = (page: ReactNode) => (
  <BlankLayout>{page}</BlankLayout>
);

export default ResetPasswordV1;
