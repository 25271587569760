import React, { useEffect, useState } from 'react';
import ContentDataText from '../../../utils/contentDataText';
import { ContentDataType } from '../../../service/StaticData/types';
import { convertFromRaw, convertToRaw, EditorState } from 'draft-js';

import { toast } from "react-hot-toast";
import classes from './styles.module.sass'
import { getContentData, updateContentData } from '../../../service/StaticData';
import CardSnippet from '../../../components/@core/components/card-snippet';
import TabsCustomized from '../../../utils/views/components/tabs/TabsCustomized';
import * as source from "../../../utils/views/components/tabs/TabsSourceCode";
import Button from '@mui/material/Button';

interface Props {
  readonly contentDataType: ContentDataType
}

const Support = ({ contentDataType }: Props) => {
  const [editorStateEn, setEditorStateEn] = useState(
    () => EditorState.createEmpty(),
  )
  const [editorStateGe, setEditorStateGe] = useState(
    () => EditorState.createEmpty(),
  )

  useEffect(() => {
    setEditorStateFromRaw();
  }, []);


  const setEditorStateFromRaw = async () => {
    const rawEditorData = await getContentData(contentDataType);

    try {
      const contentStateEn = convertFromRaw(JSON.parse(rawEditorData.data.data.valueEn));
      const contentStateGe = convertFromRaw(JSON.parse(rawEditorData.data.data.valueGe));
      setEditorStateEn(EditorState.createWithContent(contentStateEn));
      setEditorStateGe(EditorState.createWithContent(contentStateGe));
    } catch (error) {
      setEditorStateEn(EditorState.createEmpty());
      setEditorStateGe(EditorState.createEmpty());
    }
  }

  const saveTextRaw = () => {
    const rawDataEn = JSON.stringify(convertToRaw(editorStateEn.getCurrentContent()));
    const rawDataGe = JSON.stringify(convertToRaw(editorStateGe.getCurrentContent()));
    updateContentData({ body: { valueEn: rawDataEn, valueGe: rawDataGe, type: contentDataType } },).then(() => {
      toast.success("Support updated successfully")
    });
  }



  return <div className={classes.component}>
    <CardSnippet
      title="Support"
      code={{
        tsx: source.TabsCustomizedTSXCode,
        jsx: source.TabsCustomizedJSXCode,
      }}
    >
      <TabsCustomized
        textEditorEnglish={<ContentDataText editorState={editorStateEn} onEditorStateChange={setEditorStateEn} />}
        textEditorGeorgian={<ContentDataText editorState={editorStateGe} onEditorStateChange={setEditorStateGe} />} />
      <Button variant='contained' onClick={() => saveTextRaw()}>SAVE</Button>
    </CardSnippet>
  </div>
}

export default Support;