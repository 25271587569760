// ** React Imports
import { useState, useEffect } from "react";

// ** Next Import
// import Link from 'next/link'

// ** MUI Imports
import Grid from "@mui/material/Grid";
import Alert from "@mui/material/Alert";

// ** Types
import { SingleInvoiceType, InvoiceLayoutProps } from "../../../../../types/apps/invoiceTypes";

// ** Third Party components
import axios from "axios";
import { useParams } from "react-router-dom";

// ** Demo components Imports
import EditCard from "./EditCard";
import EditActions from "./EditActions";
import AddPaymentDrawer from "../../../../../utils/views/apps/invoice/shared-drawer/AddPaymentDrawer";
import SendInvoiceDrawer from "../../../../../utils/views/apps/invoice/shared-drawer/SendInvoiceDrawer";
import { benefitType, CompanyType, postUpdateCompanyRequest, PostUpdateCompanyVerifyOrActiveRequestType, SingleCompanyType, UpdateCompanyEmailAndSendHandoverRequestType } from "../../../../../service";
import { RootState, useAppDispatch } from "../../../../../store";
import { getAllDictionaries, getCompany, getCompanyBenefits, setInitialState, updateCompany, updateCompanyBenefits, updateCompanyEmailAndSendHandover, updateCompanyVerifyOrActive } from "../../../../../store/apps/companiesSlice";
import { useSelector } from "react-redux";
import { Form, Formik } from "formik";
import { companyBenefit, companyBenefitChild, CompanyValues } from "../../../../../types/apps/companyTypes";
import toast from "react-hot-toast";
import { getAllBenefits } from "../../../../../store/apps/attributesSlice";
import { CircularProgress } from "@mui/material";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import DialogActions from "@mui/material/DialogActions";

const InvoiceEdit = () => {
  const dispatch = useAppDispatch();
  const { id } = useParams();
  // ** State
  const [error, setError] = useState<boolean>(false);
  const [addPaymentOpen, setAddPaymentOpen] = useState<boolean>(false);
  const [sendInvoiceOpen, setSendInvoiceOpen] = useState<boolean>(false);
  const { company, companyBenefits, isLoading } = useSelector((state: RootState) => state.companies);
  const [handOverEmailOptions, setHandOverEmailOptions] = useState({ email: '', error: false })

  const toggleSendInvoiceDrawer = () => setSendInvoiceOpen(!sendInvoiceOpen);
  const toggleAddPaymentDrawer = () => setAddPaymentOpen(!addPaymentOpen);

  const setIsActiveOrIsVerified = (type: number, value: boolean) => {
    dispatch(
      updateCompanyVerifyOrActive({
        body: { id: id, type: type, value: value },
      } as PostUpdateCompanyVerifyOrActiveRequestType)
    );
  };

  useEffect(() => {
    if (id) {
      dispatch(getCompany(id));
      dispatch(getCompanyBenefits(id));
    }
    dispatch(getAllBenefits());
    dispatch(getAllDictionaries());
    return () => {
      dispatch(setInitialState());
    };
  }, [id]);

  const initialValues: CompanyValues = {
    id: company?.id,
    name: company?.name,
    isActive: company?.isActive,
    technologies: company?.technologies,
    about: company?.about || "",
    aboutGe: company?.aboutGe || "",
    tagLine: company?.tagLine || "",
    notificationEmail: company?.notificationEmail || "",
    keyFacts: company?.keyFacts!,
    benefits: company?.benefits,
    isVerified: company?.isVerified,
    isClaimingNecessary: company?.isClaimingNecessary,
    companyBenefits,
  };

  const handleEdit = (values: typeof initialValues) => {
    if (company) {
      let childBenefits: companyBenefitChild[] = [];
      values.companyBenefits.forEach((el) => {
        childBenefits.push(...el.companyBenefits);
      });

      const modifiedChildBenefits = childBenefits.map((el) => ({ id: el.id, description: el.description || "", descriptionGE: el.descriptionGE || "", descriptionNumber: el.descriptionNumber || 0 }));
      dispatch(updateCompanyBenefits({ id: company.id, data: modifiedChildBenefits }))
        .unwrap()
        .then(() => {
          dispatch(updateCompany(values));
        });
    }
  };

  const updateEmailHandler = ()=>{

    if (!handOverEmailOptions.email.trim().length || /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(handOverEmailOptions.email)){
      dispatch(
        updateCompanyEmailAndSendHandover({
          body: { id: company!.id, email: handOverEmailOptions.email.trim() },
        } as UpdateCompanyEmailAndSendHandoverRequestType)
      ).then((response) => {
        if(response.payload.succeeded){
          toast.success("Operation completed successfully ");
        }
        else {
          for(let error of response.payload.errors){
            toast.error(error)
          }
        }
      })
    }
    else {
      setHandOverEmailOptions(prev => ({ ...prev, error: true }))
    }
  }

  if (company) {
    return (
      <>
        <Formik key={id} onSubmit={handleEdit} initialValues={initialValues} enableReinitialize>
          {({ setFieldValue, values }) => (
            <Form>
              <Grid container spacing={6}>
                <Grid item xl={9} md={8} xs={12}>
                  <EditCard key={id} setFieldValue={setFieldValue} values={values} data={company} />
                </Grid>
                <Grid item xl={3} md={4} xs={12}>
                  <EditActions
                    id={id}
                    values={values}
                    setFieldValue={setFieldValue}
                    toggleSendInvoiceDrawer={toggleSendInvoiceDrawer}
                    toggleAddPaymentDrawer={toggleAddPaymentDrawer}
                    toggleIsActiveOrIsVerified={setIsActiveOrIsVerified}
                  />

                  <br />

                  {
                    values.isClaimingNecessary &&
                    <>
                      <TextField
                        fullWidth
                        label="Update email"
                        type="email"
                        onChange={(e)=> setHandOverEmailOptions({ email: e.target.value, error: false })}
                        value={handOverEmailOptions.email}
                        error={handOverEmailOptions.error}
                      />
                      <DialogActions
                        sx={{ justifyContent: "center" }}
                      >
                        <Button type="button" variant="contained" onClick={updateEmailHandler}>
                          Update & Send
                        </Button>
                      </DialogActions>
                    </>
                  }

                </Grid>
              </Grid>
            </Form>
          )}
        </Formik>
        {isLoading && (
          <Grid item xs={12} sx={{ display: "flex", justifyContent: "center", alignItems: "center", position: "fixed", left: "0", top: "0", height: "100%", width: "100%" }}>
            <CircularProgress />
          </Grid>
        )}
      </>
    );
  } else if (error) {
    return (
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <Alert severity="error">
            Invoice with the id: {id} does not exist. Please check the list of invoices: {/* <Link href='/apps/invoice/list'>Invoice List</Link> */}
            <button>invoice link is not working</button>
          </Alert>
        </Grid>
      </Grid>
    );
  } else {
    return null;
  }
};

export default InvoiceEdit;
