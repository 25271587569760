import classes from "./styles.module.sass";
import { RootState, useAppDispatch } from "../../store";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import { deleteCompanyOrTalent, getAllDictionaries, getSearchTalents, setSubmittedFilterObject, setSubmittedPage } from "../../store/apps/talentsSlice";
import { handleObjectForQueryString } from "../../utils/helperFunctions";
import TableServerSide from "../../utils/views/table/data-grid/TableServerSide";
import { GridRenderCellParams, GridSortModel } from "@mui/x-data-grid";
import { useColumns } from "../../utils/views/table/data-grid/Columns";
import { CompanyTalentSortEnum } from "../../types/enums";
import DialogAddCard from "../../utils/views/pages/dialog-examples/DialogAddCard";
import Grid from "@mui/material/Grid";

const Talents: React.FC = () => {
  // ** State Imports
  const { talents, submittedFilteredObject, submittedPage } = useSelector((state: RootState) => state.talents);
  const dispatch = useAppDispatch();

  const handleDelete = (params: GridRenderCellParams) => {
    dispatch(deleteCompanyOrTalent(params.row.id))
      .unwrap()
      .then(() => {
        dispatch(getSearchTalents(handleObjectForQueryString(submittedFilteredObject, submittedPage)));
      });
  };

  const { columns, modal, setModal, dataID, setDataID } = useColumns("talents", handleDelete);

  useEffect(() => {
    dispatch(getSearchTalents(handleObjectForQueryString(submittedFilteredObject, submittedPage)));
  }, [submittedFilteredObject, submittedPage]);

  useEffect(() => {
    return () => {
      dispatch(setSubmittedFilterObject({} as typeof submittedFilteredObject));
    };
  }, []);

  const handleSortModel = (newModel: GridSortModel) => {
    if (newModel.length === 0) return;
    // @ts-ignore: Unreachable code error
    const sortColumn = CompanyTalentSortEnum[newModel[0].field];
    //
    const desc = newModel[0].sort === "desc" ? true : false;
    //
    dispatch(
      setSubmittedFilterObject({
        ...submittedFilteredObject,
        SortBy: parseInt(sortColumn),
        IsDesc: desc,
      })
    );
  };

  return (
    <div className={classes.component}>
      {talents && (
        <>
          <TableServerSide
            key="talentsTable"
            checkboxField={true}
            data={talents}
            columns={columns}
            title="Talents"
            updatePage={(pageNum) => dispatch(setSubmittedPage({ submittedPage: pageNum + 1 }))}
            sortTable={handleSortModel}
            updatePageSize={(pageSize) =>
              dispatch(
                setSubmittedFilterObject({
                  ...submittedFilteredObject,
                  PageSize: parseInt(pageSize),
                })
              )
            }
            filterObject={submittedFilteredObject}
            stateDispatcher={getSearchTalents}
            storedState="talents"
            dictionaryStateDispatcher={getAllDictionaries}
          />
          {modal && (
            <Grid container spacing={6} className="match-height">
              <Grid item md={4} sm={6} xs={12}>
                <DialogAddCard modal={modal} setModal={setModal} dataID={dataID} setDataID={setDataID} />
              </Grid>
            </Grid>
          )}
        </>
      )}
    </div>
  );
};

export default Talents;
