// ** React Imports
import React, { Ref, forwardRef, ReactElement, SetStateAction, Dispatch } from "react";

// ** MUI Imports
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Fade, { FadeProps } from "@mui/material/Fade";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";

// ** Icons Imports
import Close from "mdi-material-ui/Close";

const Transition = forwardRef(function Transition(props: FadeProps & { children?: ReactElement<any, any> }, ref: Ref<unknown>) {
  return <Fade ref={ref} {...props} />;
});

type ModalProps = {
  show: boolean;
  onClose: () => void;
  children: React.ReactNode;
  submitAction: () => void;
  submitText?: string;
  submitBtnColor?: "inherit" | "primary" | "secondary" | "success" | "error" | "info" | "warning";
};

export const Modal = ({ show, onClose, children, submitAction, submitText, submitBtnColor }: ModalProps) => {
  return (
    <Card>
      <Dialog fullWidth open={show} maxWidth="sm" scroll="body" onClose={onClose} TransitionComponent={Transition} onBackdropClick={onClose}>
        <DialogContent
          sx={{
            pb: 6,
            px: { xs: 8, sm: 15 },
            pt: { xs: 8, sm: 12.5 },
            position: "relative",
          }}
        >
          <IconButton size="small" onClick={onClose} sx={{ position: "absolute", right: "1rem", top: "1rem" }}>
            <Close />
          </IconButton>

          <Grid
            container
            spacing={6}
            sx={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >
            {children}
          </Grid>
        </DialogContent>
        <DialogActions sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: "center" }}>
          <Button variant="outlined" color={submitBtnColor || "primary"} sx={{ mr: 2 }} onClick={submitAction}>
            {submitText || "Submit"}
          </Button>
          <Button variant="outlined" color="secondary" onClick={onClose}>
            Discard
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};
