// ** React Imports
import { Ref, forwardRef, ReactElement, FC, useEffect } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Fade, { FadeProps } from "@mui/material/Fade";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import * as Yup from "yup";

// ** Icons Imports
import Close from "mdi-material-ui/Close";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// import { getAdmins, postCreateNewAdmin } from '../../store/apps/adminsSlice'
import toast from "react-hot-toast";
import { useAppDispatch } from "../../../store";
import * as Api from "../../../service";
import { getHeroImageDetails, updateHeroImageText } from "../../../store/apps/HeroImageSlice";

const Transition = forwardRef(function Transition(
  props: FadeProps & { children?: ReactElement<any, any> },
  ref: Ref<unknown>
) {
  return <Fade ref={ref} {...props} />;
});

const updateButtonFormSchema = Yup.object()
  .shape({
    name: Yup.string().trim().min(3).required(),
    nameGE: Yup.string().trim().min(3).required(),
    url: Yup.string().trim().min(3).required(),
  })
  .required();



const DialogEditButton: FC<{
  button: Api.HeroImageButtonType;
  onClose: () => void;
  heroImageDetails: Api.HeroImageDetailsType
}> = (props) => {

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<Api.UpdateHeroImageButtonType>({
    resolver: yupResolver(updateButtonFormSchema),
  });

  const dispatch = useAppDispatch();

  useEffect(() => {
    reset({
      id: props.button.id,
      name: props.button.name,
      nameGE: props.button.nameGE,
      url: props.button.url,
    });
  }, []);

  const onSubmit: SubmitHandler<Api.UpdateHeroImageButtonType> = (data) => {
    const newObj = {
      ...props.heroImageDetails,
      heroButtons: props.heroImageDetails.heroButtons.map((button) => {
        if(button.id === data.id){
          const newButton = {
            ...button,
            name: data.name,
            nameGE: data.nameGE,
            url: data.url
          }
          return newButton;
        }
        return button
      })
    }

    dispatch(updateHeroImageText(newObj))
    .unwrap()
    .then(() => {
      dispatch(getHeroImageDetails());
      toast.success("Updated successfully");
      props.onClose()
    })
    .catch((response) => {
      toast.error(response.error);
    });
  };

  return (
    <Card>
      <Dialog
        fullWidth
        open={true}
        maxWidth="md"
        scroll="body"
        onClose={() => {
          reset();
          props.onClose();
        }}
        TransitionComponent={Transition}
        onBackdropClick={() => {
          reset();
          props.onClose();
        }}
      >
        <form
          onSubmit={handleSubmit(onSubmit)}
          noValidate
          spellCheck="false"
          autoComplete="off"
        >
          <DialogContent
            sx={{
              pb: 6,
              px: { xs: 8, sm: 15 },
              pt: { xs: 8, sm: 12.5 },
              position: "relative",
            }}
          >
            <IconButton
              size="small"
              onClick={() => {
                reset();
                props.onClose();
              }}
              sx={{ position: "absolute", right: "1rem", top: "1rem" }}
            >
              <Close />
            </IconButton>
            <Box sx={{ mb: 8, textAlign: "center" }}>
              <Typography variant="h5" sx={{ mb: 3, lineHeight: "2rem" }}>
                Edit Question
              </Typography>
            </Box>
            <Grid container spacing={6}>
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  label="Text EN"
                  error={!!errors["name"]}
                  {...register("name")}
                />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField
                  fullWidth
                  label="Text GE"
                  error={!!errors["nameGE"]}
                  {...register("nameGE")}
                />
              </Grid>
              <Grid item sm={12} xs={12}>
                <TextField
                  fullWidth
                  label="Url"
                  error={!!errors["url"]}
                  {...register("url")}
                />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions
            sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: "center" }}
          >
            <Button type="submit" variant="contained" sx={{ mr: 2 }}>
              Update
            </Button>
            <Button
              variant="outlined"
              type="reset"
              color="secondary"
              onClick={() => {
                reset();
                props.onClose();
              }}
            >
              Discard
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Card>
  );
};

export default DialogEditButton;
