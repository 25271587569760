import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { format } from "date-fns";
import {
  Dispatch,
  forwardRef,
  MutableRefObject,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from "react";
import DatePicker from "react-datepicker";
import { DateType } from "../../../../types/forms/reactDatepickerTypes";

import "./index.sass";

interface PickerProps {
  label?: string;
  end?: Date | number;
  start?: Date | number;
}

enum StatisticsDateEnum {
  AllData = 1,
  Today,
  Yesterday,
  ThisWeek,
  LastWeek,
  ThisMonth,
  LastMonth,
  CustomRange,
}

type props = {
  setDateRange: Dispatch<SetStateAction<number>>;
  dateRange: number;
  setEndDate: Dispatch<SetStateAction<DateType>>;
  endDate: DateType;
  setStartDate: Dispatch<SetStateAction<DateType>>;
  startDate: DateType;
};

const DateRange = ({
  setDateRange,
  dateRange,
  setEndDate,
  endDate,
  setStartDate,
  startDate,
}: props) => {
  const [localStartDate, setLocalStartDate] = useState<DateType>();
  const [localEndDate, setLocalEndDate] = useState<DateType>();

  useEffect(() => {
    setLocalStartDate(startDate);
    setLocalEndDate(endDate);
  }, [startDate, endDate]);

  const CustomInput = forwardRef((props: PickerProps, ref) => {
    const startDate = props.start && format(props.start, "MM/dd/yyyy");
    const endDate = props.end && format(props.end, "MM/dd/yyyy");

    return (
      <TextField
        inputRef={ref}
        label={props.label || ""}
        {...props}
        defaultValue={startDate || endDate}
      />
    );
  });

  const submitDate = () => {
    setStartDate(localStartDate);
    setEndDate(localEndDate);
  };

  return (
    <Box>
      <Box sx={{ display: "block", flexWrap: "wrap" }} className="demo-space-x">
        <Box>
          <FormControl fullWidth sx={{ mr: "5px", width: "210px" }}>
            <InputLabel id="DateRange">Date Range</InputLabel>
            <Select
              label="DateRange"
              id="DateRange"
              labelId="DateRange"
              defaultValue="1"
              value={dateRange.toString()}
              onChange={(e) => setDateRange(parseInt(e.target.value))}
              sx={{ width: "100%", color: "text.secondary" }}
            >
              <MenuItem value={StatisticsDateEnum.Today}>Today</MenuItem>
              <MenuItem value={StatisticsDateEnum.Yesterday}>
                Yesterday
              </MenuItem>
              <MenuItem value={StatisticsDateEnum.ThisWeek}>This Week</MenuItem>
              <MenuItem value={StatisticsDateEnum.LastWeek}>Last Week</MenuItem>
              <MenuItem value={StatisticsDateEnum.ThisMonth}>
                This Month
              </MenuItem>
              <MenuItem value={StatisticsDateEnum.LastMonth}>
                Last Month
              </MenuItem>
              <MenuItem value={StatisticsDateEnum.AllData}>All Data</MenuItem>
              <MenuItem value={StatisticsDateEnum.CustomRange}>
                Custom Range
              </MenuItem>
            </Select>
          </FormControl>
        </Box>
        {dateRange === StatisticsDateEnum.CustomRange && (
          <Grid container alignItems="center">
            <Grid item>
              <DatePicker
                selected={localStartDate}
                id="date-range-picker"
                onChange={setLocalStartDate}
                strictParsing={true}
                popperClassName="custom-datePicker"
                //@ts-ignore
                customInput={
                  <CustomInput
                    label="Start Date"
                    start={localStartDate as Date | number}
                  />
                }
              />
            </Grid>
            <Grid item pl={5}>
              <DatePicker
                selected={localEndDate}
                id="date-range-picker"
                onChange={setLocalEndDate}
                popperClassName="custom-datePicker"
                strictParsing={true}
                customInput={
                  <CustomInput
                    label="End Date"
                    end={localEndDate as Date | number}
                  />
                }
              />
            </Grid>
            <Grid item pl={5}>
              <Button onClick={submitDate}>Search</Button>
            </Grid>
          </Grid>
        )}
      </Box>
    </Box>
  );
};

export default DateRange;
