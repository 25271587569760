import { useEffect } from "react";
import { useDropzone } from "react-dropzone";

// ** store
import { useAppDispatch } from "../../../../store";

// ** state
import { useState } from "react";

// ** Material
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import FileDocumentOutline from "mdi-material-ui/FileDocumentOutline";
import Switch from "@mui/material/Switch";
import InputLabel from "@mui/material/InputLabel";

// ** icons
import DragHandleIcon from "@mui/icons-material/DragHandle";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

// ** drag
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

// ** styles
import classes from "./BenfitItem.module.sass";

// ** types
import { getAllBenefits, postCreateUpdateBenefit } from "../../../../store/apps/attributesSlice";
import { BenefitDataType } from "../../../../types/apps/attributeTypes";
import { toast } from "react-hot-toast";
import { FormControlLabel } from "@mui/material";
import { companyBenefitChild } from "../../../../types/apps/companyTypes";

type propTypes = {
  data: companyBenefitChild;
  categoryId?: string | null;
  updateData(data: companyBenefitChild): void;
  deleteBenefit: () => void;
};

interface FileProp {
  name: string;
  type: string;
  size: number;
}

const BenefitSubItem = ({ data, categoryId, updateData, deleteBenefit }: propTypes) => {
  const dispatch = useAppDispatch();

  const [dropDown, setDropDown] = useState(false);
  const [name, setName] = useState(data.name);
  const [nameGE, setNameGE] = useState(data.nameGE);
  const [benefitType, setBenefitType] = useState(data.benefitType);
  const [description, setDescription] = useState(data.description);
  const [descriptionGE, setDescriptionGE] = useState(data.descriptionGE);
  const [mandatory, setMandatory] = useState<boolean>(data.canNotBeDeletedByTheCompany);
  const [hide, setHide] = useState<boolean | undefined>(data?.hide);
  const [image, setImage] = useState<File[] | null | string>(data.iconUrl || null);
  const [uploading, setUploading] = useState(false);

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: data.id });

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles: File[]) => {
      setUploading(true);
      setImage(acceptedFiles);
    },
  });

  useEffect(() => {
    setUploading(false);
  }, [image]);

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  const openDropDown = () => {
    setDropDown((prevVal) => !prevVal);
  };

  const renderFilePreview = (file: FileProp | string) => {
    if (typeof file === "string") {
      return <img width={150} height={150} style={{ objectFit: "cover" }} alt={"Benefit"} src={file} />;
    } else if (file.type.startsWith("image")) {
      return <img width={150} height={150} style={{ objectFit: "cover" }} alt={file?.name || ""} src={URL.createObjectURL(file as any)} />;
    } else {
      return <FileDocumentOutline />;
    }
  };

  const saveSection = () => {
    if (uploading) return;
    const formData = new FormData();

    formData.append("Id", data.id || "");
    formData.append("Name", name);
    formData.append("NameGE", nameGE);
    formData.append("Description", description);
    formData.append("DescriptionGE", descriptionGE);
    formData.append("BenefitIcon", image && Array.isArray(image) ? image[0] : "");
    formData.append("CategoryId", categoryId || "");
    formData.append("CanNotBeDeletedByTheCompany", `${mandatory}`);
    formData.append("Hide", `${hide || false}`);
    formData.append("BenefitType", benefitType.toString());

    if (name.length === 0) return toast.error("Name field is must!");
    if (nameGE.length === 0) return toast.error("Name (GE) field is must!");

    dispatch(postCreateUpdateBenefit(formData))
      .unwrap()
      .then((res) => {
        dispatch(getAllBenefits());
      });
    setDropDown(false);
  };

  const headerPreviewImage = () => {
    if (image) {
      return renderFilePreview(typeof image === "string" ? image : image[0]);
    } else if (data.iconUrl) {
      return (
        <>
          <img src={data.iconUrl} alt="" />
        </>
      );
    }
    return <Box className={classes.imgPlaceHolder} />;
  };

  return (
    <Box className={classes.SubBenefitWrapper} ref={setNodeRef} style={style}>
      <Box className={classes.BenefitItem}>
        <Box className={classes.header} onClick={openDropDown}>
          {data.id && <DragHandleIcon {...listeners} {...attributes} />}
          <Box>
            <Box className={classes.headerWrapper}>
              {headerPreviewImage()}
              <Typography variant="body1">{name}</Typography>
            </Box>
            {!dropDown ? <AddIcon /> : <RemoveIcon />}
          </Box>
        </Box>
        {dropDown && (
          <Box className={classes.body}>
            <TextField
              fullWidth
              id="tagLine"
              label="Sub Section Name"
              name="tagLine"
              sx={{ mb: 0, width: "100%" }}
              onChange={(e) => {
                setName(e.target.value);
              }}
              value={name}
            />
            <TextField
              fullWidth
              id="tagLine"
              label="Sub Section Name (GE)"
              name="tagLine"
              sx={{ mb: 0, width: "100%" }}
              onChange={(e) => {
                setNameGE(e.target.value);
              }}
              value={nameGE}
            />

            <FormControlLabel
              control={<Switch checked={benefitType !== 0} onChange={(e) => setBenefitType((prev) => (!prev ? 1 : 0))} />}
              label={benefitType === 0 ? "TEXT" : "INTEGER"}
              sx={{
                "& .MuiTypography-root": {
                  color: benefitType === 0 ? "text.secondary" : "text.error",
                },
                marginLeft: 0,
              }}
            />

            <FormControlLabel
              control={<Switch checked={mandatory} onChange={() => setMandatory((prevVal) => !prevVal)} />}
              label={"Mandatory"}
              sx={{
                marginLeft: 0,
              }}
            />

            <FormControlLabel
              control={<Switch checked={hide} onChange={() => setHide((prevVal) => !prevVal)} />}
              label={"Hide"}
              sx={{
                marginLeft: 0,
              }}
            />

            <Box className={classes.imagePreview}>{image && renderFilePreview(typeof image === "string" ? image : image[0])}</Box>
            <Box className={classes.buttonWrapper}>
              <Box {...getRootProps({ className: "dropzone" })}>
                <Button>Choose Image</Button>
                <input {...getInputProps()} />
                {/* {renderFilePreview()} */}
              </Box>
              <Button onClick={saveSection}>Save Sub Section</Button>
              <Button variant="outlined" color="error" onClick={deleteBenefit}>
                Delete Sub Benefit
              </Button>
            </Box>
          </Box>
        )}
      </Box>
    </Box>
  );
};

export default BenefitSubItem;
