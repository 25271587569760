// ** Toolkit imports
import {
  AnyAction,
  configureStore,
  createListenerMiddleware,
} from "@reduxjs/toolkit";

import { useDispatch } from "react-redux";

// ** Reducers
import chat from "../store/apps/chat";
import user from "../store/apps/user";
import email from "../store/apps/email";
import invoice from "../store/apps/invoice";
import calendar from "../store/apps/calendar";
import permissions from "../store/apps/permissions";

import companies from "./apps/companiesSlice";
import vacancies from "./apps/vacanciesSlice";
import authSlice, { logOut } from "./apps/authSlice";
import seoSlice from "./apps/seoSlice";
import faqSlice from "./apps/faqSlice";
import heroImageSlice from "./apps/HeroImageSlice";
import talents from "./apps/talentsSlice";
import admins from "./apps/adminsSlice";
import attributesSlice from "./apps/attributesSlice";
import statistics from "./apps/statisticsSlice";

const listenerMiddleware = createListenerMiddleware();

type ListenerPredicate<Action extends AnyAction, State> = (
  action: Action,
  currentState?: State,
  originalState?: State
) => boolean;

// listenerMiddleware.startListening({
//   // @ts-ignore: Unreachable code error
//   predicate: (action) => {
//     // return true when the listener should run
//     if(action.error && action.error.message.includes("401")) {
//       window.localStorage.clear();
//       window.location.reload();
//     }

//   },
//   effect: async (action, listenerApi) => {

//   }
// })

export const store = configureStore({
  reducer: {
    user,
    chat,
    email,
    invoice,
    calendar,
    permissions,
    auth: authSlice,
    companies,
    vacancies,
    seoSlice,
    talents,
    admins,
    attributes: attributesSlice,
    FAQ: faqSlice,
    heroImageSlice: heroImageSlice,
    statistics,
  },

  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).prepend(listenerMiddleware.middleware),
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
