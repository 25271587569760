import { useState, MouseEvent, SetStateAction, Dispatch, useEffect, useCallback } from "react";

// ** Material
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Switch from "@mui/material/Switch";
import { FormControlLabel, MenuItem } from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";
import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";

// ** service
import { DictionaryListType, DictionaryTypeEnum, GetDictionaryRequestType, PostCreateUpdateDictionaryRequestParams } from "../../../../service";
import { getParentDictionary, postCreateUpdateDictionary } from "../../../../store/apps/attributesSlice";
import { useAppDispatch } from "../../../../store";

// ** components
import CountrySubItem from "./CountrySubItem";

// ** icons
import DragHandleIcon from "@mui/icons-material/DragHandle";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

// ** dnd
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";

// ** styles
import classes from "./countryItem.module.sass";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";

// ** types
type updateCityType = DictionaryListType & {
  modified?: boolean;
};

type postCityType = PostCreateUpdateDictionaryRequestParams & {
  modified?: boolean;
};

type propType = {
  data: parentType;
  open: string | null;
  setOpen: Dispatch<SetStateAction<string | null>>;
  section: DictionaryTypeEnum;
  refetchData(): void;
  setDeleteData: (data: GetDictionaryRequestType["data"]["dictionaryList"][0], dictionaryType?: number) => void;
};

type parentType = DictionaryListType & {
  childData?: updateCityType[];
};

const CountryItem = ({ data, open, setOpen, section, refetchData, setDeleteData }: propType) => {
  const dispatch = useAppDispatch();
  const [name, setName] = useState<string>(data.name);
  const [nameGE, setNameGE] = useState<string>(data.nameGE);
  const [cities, setCities] = useState<updateCityType[] | undefined>([]);
  const { pageName } = useParams();
  const dropDownOpen = open === data.id;
  const parentSection = section === DictionaryTypeEnum.City ? DictionaryTypeEnum.Country : DictionaryTypeEnum.Field;

  const { attributes, listeners, setNodeRef, transform, transition } = useSortable({ id: data.id || "" });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  useEffect(() => {
    initCityData();
  }, [data]);

  const initCityData = () => {
    console.log(data);
    setCities(data.childData);
  };

  const openDropDown = () => {
    if (open === data.id) setOpen("");
    else setOpen(data.id || "");
  };

  const handleSaveData = () => {
    const postCountry: PostCreateUpdateDictionaryRequestParams = {
      id: data.id === "new" ? null : data.id,
      name,
      nameGE,
      description: "",
      descriptionGE: "",
      dictionryType: parentSection,
      parentDictionary: null,
    };

    dispatch(postCreateUpdateDictionary(postCountry))
      .unwrap()
      .then((res) => {
        const postCity: postCityType[] | undefined = cities?.map((el) => ({
          id: el.id,
          name: el.name,
          nameGE: el.nameGE,
          description: el.description,
          descriptionGE: el.descriptionGE,
          dictionryType: section,
          orderIndex: 0,
          parentDictionary: res.data,
          modified: el.modified,
        }));
        postCity?.forEach((el) => el.modified && dispatch(postCreateUpdateDictionary(el)));
      })
      .then(() => {
        refetchData();
        toast.success("Data Updated");
      });
  };

  const addCity = () => {
    const alreadyAdded = cities?.find((el) => !el.id);
    if (alreadyAdded) return toast.error("Please add one Keyfact at once");
    const newCity: updateCityType = {
      id: null,
      name: "",
      nameGE: "",
      description: "",
      descriptionGE: "",
      childDictionaries: null,
      iconUrl: "",
      modified: true,
      parentDictionary: {
        id: data.id!,
        dictionaryName: "",
        name: data.name,
      },
    };
    if (cities) setCities([newCity, ...cities]);
  };

  const updateCity = (optName: string, optNameGE: string, idx: number) => {
    const modifiedOptions = cities?.map((el, index) => (index === idx ? { ...el, name: optName, nameGE: optNameGE, modified: true } : el));
    setCities(modifiedOptions);
  };

  const deleteCity = (idx: number) => {
    const newOptions = cities?.filter((el, index) => index !== idx);
    setCities(newOptions);
  };

  const handleDeleteParent = () => {
    if (!pageName) return;
    if (pageName.toLowerCase() === "field") {
      setDeleteData(data, DictionaryTypeEnum.Field);
    } else {
      setDeleteData(data, DictionaryTypeEnum.Country);
    }
  };

  const handleDeleteChild = (childData: updateCityType) => {
    if (!pageName) return;
    if (pageName.toLowerCase() === "field") {
      setDeleteData(childData, DictionaryTypeEnum.JobFunction);
    } else {
      setDeleteData(childData, DictionaryTypeEnum.City);
    }
  };

  return (
    <Box className={`${classes.CountryItem} ${dropDownOpen && classes.activeMenu}`} ref={setNodeRef} style={style}>
      <Box className={classes.header} onClick={openDropDown}>
        <DragHandleIcon {...listeners} {...attributes} />
        <Box>
          <Typography variant="h6">{name}</Typography>
          {!dropDownOpen ? <AddIcon /> : <RemoveIcon />}
        </Box>
      </Box>
      <Box className={`${classes.body} ${dropDownOpen && classes.activeMenu}`}>
        <TextField
          autoFocus
          fullWidth
          id="name"
          label="Parent Name"
          name="name"
          sx={{ mb: 0, width: "100%" }}
          onChange={(e) => {
            setName(e.target.value);
          }}
          value={name}
        />
        <TextField
          fullWidth
          id="nameGE"
          label="Parent Name (GE)"
          name="nameGE"
          sx={{ mb: 0, width: "100%" }}
          onChange={(e) => {
            setNameGE(e.target.value);
          }}
          value={nameGE}
        />

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button onClick={addCity}>Add Data</Button>
        </Box>

        {cities && cities.length > 0 && (
          <>
            <Divider />
            {cities.map((city, idx) => (
              <CountrySubItem
                name={city.name}
                nameGE={city.nameGE}
                key={city.id}
                deleteOption={() => deleteCity(idx)}
                updateOption={({ name, nameGE }) => updateCity(name, nameGE, idx)}
                setDeleteData={() => handleDeleteChild(city)}
              />
            ))}
          </>
        )}

        <Box sx={{ display: "flex", justifyContent: "center" }}>
          <Button onClick={handleSaveData}>Save Data</Button>
          <Button onClick={handleDeleteParent} color="error">
            Delete Data
          </Button>
        </Box>
      </Box>
    </Box>
  );
};

export default CountryItem;
