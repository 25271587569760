import React from 'react';
import { Editor } from 'react-draft-wysiwyg';
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import classes from './styles.module.sass'

interface Props {
    readonly editorState: any;
    readonly onEditorStateChange?: any
    readonly onChange?: any
}

const ContentDataText = ({ editorState, onEditorStateChange, onChange }: Props) => {
    return (
        <div>
            <Editor toolbar={{
                options: [
                    'inline',
                    'blockType',
                    'list',
                    'link',
                    'textAlign',
                    'colorPicker',
                    'emoji',
                    'remove',
                    'history',
                ],
                inline: {
                    options: ['bold', 'italic', 'underline', 'strikethrough'],
                },
            }}
                editorState={editorState}
                onEditorStateChange={onEditorStateChange}
                editorClassName={classes.editorClass}
                wrapperClassName={classes.wrapperClass}
                toolbarClassName={classes.toolbarClass}
                onChange={onChange}
            />
        </div>
    );
}

export default ContentDataText;