import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { DataGrid } from "@mui/x-data-grid";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton";
import Close from "mdi-material-ui/Close";

import classes from "./style.module.sass";
import { drawerProps } from "./types";

const TableDrawer = ({ data, open, onClose, setTalentId }: drawerProps) => {
  const generateRows = () => {
    let rows: any[] = [];
    if (!data) return [];
    data.forEach((datum: any) => {
      let object = {
        id: datum.talentId + Math.random(),
        talentId: datum.talentId,
        talentName: datum.talentName,
      };
      rows.push(object);
    });
    return rows;
  };

  const openApplicantEdit = (params: any) => {
    if (setTalentId) setTalentId(params.row.talentId);
  };

  return (
    <>
      <Dialog
        maxWidth="sm"
        fullWidth
        onClose={() => onClose(!open)}
        open={open}
      >
        <DialogTitle sx={{ mx: "auto", textAlign: "center" }}>
          <Typography variant="h4" component="span" sx={{ mb: 2 }}>
            Submitted Applicants
          </Typography>
          <IconButton
            size="small"
            onClick={() => onClose(!open)}
            sx={{
              position: "absolute",
              right: "1rem",
              top: "1rem",
            }}
          >
            <Close />
          </IconButton>
        </DialogTitle>
        <DialogContent>
          <Grid className={classes.drawerTableWrapper}>
            <DataGrid
              columns={[
                {
                  field: "talentName",
                  headerName: "Applicant",
                  minWidth: 150,
                  flex: 1,
                },
              ]}
              rows={generateRows()}
              sx={{ height: 600 }}
              onCellClick={openApplicantEdit}
              disableColumnSelector
              disableDensitySelector
              disableColumnMenu
              //   onCellClick={handleCellClick}
            />
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default TableDrawer;
