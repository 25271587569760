
import toast from "react-hot-toast";
import axiosInstance from "../../axios/axiosInstance";
import { ChildBenefitType, UpdateBenefitCategoryType } from "../../types/apps/attributeTypes";

import { DictionaryTypeEnum } from "../GlobalTypes"
import { PostCreateUpdateDictionaryRequestParams, PostCreateUpdateKeyFactParams, PostUpdateBenefitOrderRequestParams, PostUpdateDictionaryImageRequestParams, PostUpdateHeroImageRequestParams } from "./params";
import { GetDictionaryRequestType, NewBenefitRequestType } from "./types";

export const getDictionaryRequest = (section: DictionaryTypeEnum) => {
    return axiosInstance.get<GetDictionaryRequestType>(`api/BackOfficeDictionary/GetDictionary?dictionaryType=${section}`)
  }
  
export const postCreateUpdateDictionary = (params: PostCreateUpdateDictionaryRequestParams) => {
    return axiosInstance.post(`api/BackOfficeDictionary/CreateUpdateDictionary`, params)
  }
  
export const postUpdateDictionaryImageRequest = (params: PostUpdateDictionaryImageRequestParams) => {
  const formData = new FormData();
  if(params.body && params.body.ProfileImage)
    formData.append("ProfileImage", params?.body?.ProfileImage)
    return axiosInstance.post(`api/BackOfficeDictionary/UpdateDictionaryImage?dictionaryType=${params.body.dictionaryType}&itemId=${params.body.itemId}`, 
      formData
    )
}

export const postUpdateHeroImageRequest = (params: PostUpdateHeroImageRequestParams) => {
  const formData = new FormData();
    formData.append("file", params.HeroImage)
    return axiosInstance.post(`api/BackOfficeStaticData/UploadHeroImage`, 
      formData
    )
}

export const getAllBenefitsRequest = () => {
  return axiosInstance.get<NewBenefitRequestType>('api/BackOfficeBenefits/GetAll')
}

export const postUpdateBenefitCategoryRequest = (params: UpdateBenefitCategoryType) => {
  return axiosInstance.post('api/BackOfficeBenefits/CreateUpdateBenefitCategory', params)

}

export const postCreateUpdateBenefitRequest = (params: FormData) => {
  return axiosInstance.post('api/BackOfficeBenefits/CreateUpdateBenefit', params)
}

export const postUpdateBenefitOrderRequest = (params: PostUpdateBenefitOrderRequestParams) => {
  return axiosInstance.post('api/BackOfficeBenefits/UpdateBenefitOrder', params)
}

export const getKeyFactsRequest = () => {
  return axiosInstance.get('api/BackOfficeCompanyTalent/GetKeyFacts')
}
  
export const postUpdateOrderKeyFact = (params: string[]) => {
  return axiosInstance.post('api/BackOfficeCompanyTalent/OrderKeyFact', params)
}

export const postCreateUpdateKeyFact = (params: PostCreateUpdateKeyFactParams) => {
  const response = axiosInstance.post('api/BackOfficeCompanyTalent/CreateUpdateKeyFact', params)
  response.catch((err) => {
    const error = err.response.data
    console.log(error)
    for (const key in error) {
      if(Array.isArray(error[key])){
        return error[key].forEach((text: string) => {
          toast.error(text)
        })
      }
      return toast.error(error[key])
    }
  })
  return response

}

export const postUpdateDictionaryOrderRequest = (data: {dictionaryType: number, ids: string[]}) => {
  const response = axiosInstance.post('api/BackOfficeDictionary/updateDictionaryOrder', data)
  return response
}

export const postDeleteDictionaryRequest  = (data:{dictionaryType: number, itemId: string} ) => {
  const response = axiosInstance.post(`/api/BackOfficeDictionary/DeleteDictionary?dictionaryType=${data.dictionaryType}&itemId=${data.itemId}`)
  return response
}

export const postDeleteBenefitsRequest = (benefitId: string) => {
  const response = axiosInstance.post(`/api/backOfficeBenefits/DeleteBenefit?benefitId=${benefitId}`)
  return response
}

export const postDeleteBenefitCategoryRequest = (benefitCategoryId: string) => {
  const response = axiosInstance.post(`/api/backOfficeBenefits/DeleteBenefitCategory?benefitCategoryId=${benefitCategoryId}`)
  return response
}

export const postdeleteKeyFactRequest = (keyFactId: string) => {
  const response = axiosInstance.post(`/api/BackOfficeCompanyTalent/DeleteKeyFact?keyFactId=${keyFactId}`)
  return response
}