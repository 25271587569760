// ** React Imports
import { Ref, forwardRef, ReactElement, FC, useEffect, useState, useMemo } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Fade, { FadeProps } from "@mui/material/Fade";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import * as Yup from "yup";

// ** Icons Imports
import Close from "mdi-material-ui/Close";
import AccountOutline from "mdi-material-ui/AccountOutline";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
// import { getAdmins, postCreateNewAdmin } from '../../store/apps/adminsSlice'
import toast from "react-hot-toast";
import { useAppDispatch } from "../../../store";
import { getFAQList, updateFAQ } from "../../../store/apps/faqSlice";
import * as Api from "../../../service";
import { Checkbox, FormControlLabel, MenuItem, Select, SelectChangeEvent } from "@mui/material";

const Transition = forwardRef(function Transition(props: FadeProps & { children?: ReactElement<any, any> }, ref: Ref<unknown>) {
  return <Fade ref={ref} {...props} />;
});

interface AddQuestionFormInputs {
  readonly id?: string;
  readonly titleEN: string;
  readonly titleGE: string;
  readonly descriptionEN: string;
  readonly descriptionGE: string;
  readonly active: boolean;
}

const addQuestionFormSchema = Yup.object()
  .shape({
    titleEN: Yup.string().trim().min(3).required(),
    titleGE: Yup.string().trim().min(3).required(),
    descriptionEN: Yup.string().trim().min(3).required(),
    descriptionGE: Yup.string().trim().min(3).required(),
  })
  .required();

const DialogEditQuestion: FC<{
  question: Api.GetFAQListResponseType;
  onClose: () => void;
}> = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm<AddQuestionFormInputs>({
    resolver: yupResolver(addQuestionFormSchema),
  });

  const dispatch = useAppDispatch();
  const [forType, setForType] = useState<string>(props.question.forType ? `${props.question.forType}` : "0");

  useEffect(() => {
    reset({
      id: props.question.id,
      active: props.question.active,
      titleEN: props.question.titleEN,
      titleGE: props.question.titleGE,
      descriptionEN: props.question.descriptionEN,
      descriptionGE: props.question.descriptionGE,
    });
  }, []);

  const onSubmit: SubmitHandler<AddQuestionFormInputs> = (data) => {
    dispatch(updateFAQ({ ...data, forType: Number(forType) }))
      .unwrap()
      .then(() => {
        toast.success("FAQ updated successfully");
        reset();
        dispatch(getFAQList());
        props.onClose();
      })
      .catch((response) => {
        toast.error(response.error);
      });
  };

  return (
    <Card>
      <Dialog
        fullWidth
        open={true}
        maxWidth="md"
        scroll="body"
        onClose={() => {
          reset();
          props.onClose();
        }}
        TransitionComponent={Transition}
        onBackdropClick={() => {
          reset();
          props.onClose();
        }}
      >
        <form onSubmit={handleSubmit(onSubmit)} noValidate spellCheck="false" autoComplete="off">
          <DialogContent
            sx={{
              pb: 6,
              px: { xs: 8, sm: 15 },
              pt: { xs: 8, sm: 12.5 },
              position: "relative",
            }}
          >
            <IconButton
              size="small"
              onClick={() => {
                reset();
                props.onClose();
              }}
              sx={{ position: "absolute", right: "1rem", top: "1rem" }}
            >
              <Close />
            </IconButton>
            <Box sx={{ mb: 8, textAlign: "center" }}>
              <Typography variant="h5" sx={{ mb: 3, lineHeight: "2rem" }}>
                Edit Question
              </Typography>
            </Box>
            <Grid container spacing={6} mb={5}>
              <Grid item sm={12} xs={12}>
                <Select value={forType} onChange={(e) => setForType(e.target.value)}>
                  <MenuItem value={`${Api.FAQEnum.general}`}>General</MenuItem>
                  <MenuItem value={`${Api.FAQEnum.employers}`}>Employers</MenuItem>
                  <MenuItem value={`${Api.FAQEnum.talents}`}>Talents</MenuItem>
                </Select>
              </Grid>
            </Grid>
            <Grid container spacing={6}>
              <Grid item sm={6} xs={12}>
                <TextField fullWidth label="Question EN" error={!!errors["titleEN"]} {...register("titleEN")} />
                <br />
                <br />
                <TextField multiline label="Answer EN" rows={4} error={!!errors["descriptionEN"]} {...register("descriptionEN")} fullWidth />
                <FormControlLabel control={<Checkbox defaultChecked={props.question.active} {...register("active")} />} label="Active" />
              </Grid>
              <Grid item sm={6} xs={12}>
                <TextField fullWidth label="Question GE" error={!!errors["titleGE"]} {...register("titleGE")} />
                <br />
                <br />
                <TextField multiline label="Answer GE" rows={4} error={!!errors["descriptionGE"]} {...register("descriptionGE")} fullWidth />
              </Grid>
            </Grid>
          </DialogContent>
          <DialogActions sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: "center" }}>
            <Button type="submit" variant="contained" sx={{ mr: 2 }}>
              Update
            </Button>
            <Button
              variant="outlined"
              type="reset"
              color="secondary"
              onClick={() => {
                reset();
                props.onClose();
              }}
            >
              Discard
            </Button>
          </DialogActions>
        </form>
      </Dialog>
    </Card>
  );
};

export default DialogEditQuestion;
