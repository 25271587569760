import { Pagination, TablePagination } from "@mui/material";
import { gridPageCountSelector, gridPageSelector, gridPageSizeSelector, gridRowCountSelector, useGridApiContext, useGridSelector } from "@mui/x-data-grid";

// ** style imports
import classes from "./pagination.module.sass";

export const Paginate = () => {
  const apiRef = useGridApiContext();
  const page = useGridSelector(apiRef, gridPageSelector);
  const pageCount = useGridSelector(apiRef, gridPageCountSelector);
  const rowCount = useGridSelector(apiRef, gridRowCountSelector);
  const pageSize = useGridSelector(apiRef, gridPageSizeSelector);

  // @ts-ignore
  const handleChangePage = (event, newPage) => {
    apiRef.current.setPage(newPage);
  };

  // @ts-ignore
  const handleChangePageSize = (event) => {
    apiRef.current.setPageSize(parseInt(event.target.value, 10));
    apiRef.current.setPage(0);
  };

  // return <Pagination sx={(theme) => ({ padding: theme.spacing(1.5, 0) })} color="primary" count={pageCount} page={page + 1} onChange={(event, value) => apiRef.current.setPage(value - 1)} />;
  return (
    <TablePagination
      className="pagination-container"
      component="div"
      count={rowCount}
      page={page}
      rowsPerPage={pageSize}
      onRowsPerPageChange={handleChangePageSize}
      onPageChange={handleChangePage}
      rowsPerPageOptions={[10, 50, 100]}
      ActionsComponent={() => (
        <Pagination className={classes.pagination} color="primary" variant="outlined" shape="rounded" page={page + 1} count={pageCount} onChange={(event, page) => handleChangePage(event, page - 1)} />
      )}
    />
  );
};
