// ** React Imports
import { ChangeEvent, MouseEvent, ReactNode, useState } from "react";

// ** Next Imports
// import Link from 'next/link'
import { Link } from "react-router-dom";

// ** MUI components
import Box from "@mui/material/Box";
import MuiLink from "@mui/material/Link";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Checkbox from "@mui/material/Checkbox";
import TextField from "@mui/material/TextField";
import InputLabel from "@mui/material/InputLabel";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import CardContent from "@mui/material/CardContent";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import { styled, useTheme } from "@mui/material/styles";
import MuiCard, { CardProps } from "@mui/material/Card";
import InputAdornment from "@mui/material/InputAdornment";
import MuiFormControlLabel, {
  FormControlLabelProps,
} from "@mui/material/FormControlLabel";

// ** Icons Imports
import Google from "mdi-material-ui/Google";
import Github from "mdi-material-ui/Github";
import Twitter from "mdi-material-ui/Twitter";
import Facebook from "mdi-material-ui/Facebook";
import EyeOutline from "mdi-material-ui/EyeOutline";
import EyeOffOutline from "mdi-material-ui/EyeOffOutline";
import { SubmitHandler, useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
// ** Configs
import themeConfig from "../../configs/themeConfig";

// ** Layout Import
import BlankLayout from "../../components/@core/layouts/BlankLayout";

// ** Demo Imports
import FooterIllustrationsV1 from "../../utils/views/pages/auth/FooterIllustrationsV1";

import * as Yup from "yup";
import { useAppDispatch } from "../../store";
import { useNavigate } from "react-router-dom";
import { logIn, postSignIn } from "../../store/apps/authSlice";

// ** SVG Imports
import { ReactComponent as MainLogo } from "../../assets/SVG/Techwave.ge-Dark.svg";


interface LoginFormInputs {
  readonly email: string,
  readonly password: string,
  readonly rememberMe: boolean
}


const loginFormSchema = Yup.object().shape({
  email: Yup.string().email().required(),
  password: Yup.string().trim().min(3).required(),
}).required()

// ** Styled components
const Card = styled(MuiCard)<CardProps>(({ theme }) => ({
  [theme.breakpoints.up("sm")]: { width: 450 },
}));

const FormControlLabel = styled(MuiFormControlLabel)<FormControlLabelProps>(
  ({ theme }) => ({
    "& .MuiFormControlLabel-label": {
      fontSize: "0.875rem",
      color: theme.palette.text.secondary,
    },
  })
);

const LoginV1 = () => {
  // ** State
  const [showPassword, setShowPassword] = useState(false);



  const { register, handleSubmit, formState: { errors }, reset } = useForm<LoginFormInputs>({
    resolver: yupResolver(loginFormSchema)
  });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  // ** Hook
  // const theme = useTheme();



  const handleClickShowPassword = () => {
    setShowPassword(show => !show);
  };

  const onSubmit: SubmitHandler<LoginFormInputs> = data => {
    dispatch(postSignIn({ body: { ...data } }))
      .unwrap()
      .then((payload) => {
        dispatch(logIn(payload));
      })
      .then(() => navigate("/"));
  }

  return (
    <Box className="content-center">
      <Card sx={{ zIndex: 1 }}>
        <CardContent
          sx={{ p: (theme) => `${theme.spacing(10, 5, 5)} !important` }}
        >
          <Box
            sx={{
              mb: 8,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {/* <svg
              width={47}
              fill="none"
              height={26}
              viewBox="0 0 268 150"
              xmlns="http://www.w3.org/2000/svg"
            >
              <rect
                rx="25.1443"
                width="50.2886"
                height="143.953"
                fill={theme.palette.primary.main}
                transform="matrix(-0.865206 0.501417 0.498585 0.866841 195.571 0)"
              />
              <rect
                rx="25.1443"
                width="50.2886"
                height="143.953"
                fillOpacity="0.4"
                fill="url(#paint0_linear_7821_79167)"
                transform="matrix(-0.865206 0.501417 0.498585 0.866841 196.084 0)"
              />
              <rect
                rx="25.1443"
                width="50.2886"
                height="143.953"
                fill={theme.palette.primary.main}
                transform="matrix(0.865206 0.501417 -0.498585 0.866841 173.147 0)"
              />
              <rect
                rx="25.1443"
                width="50.2886"
                height="143.953"
                fill={theme.palette.primary.main}
                transform="matrix(-0.865206 0.501417 0.498585 0.866841 94.1973 0)"
              />
              <rect
                rx="25.1443"
                width="50.2886"
                height="143.953"
                fillOpacity="0.4"
                fill="url(#paint1_linear_7821_79167)"
                transform="matrix(-0.865206 0.501417 0.498585 0.866841 94.1973 0)"
              />
              <rect
                rx="25.1443"
                width="50.2886"
                height="143.953"
                fill={theme.palette.primary.main}
                transform="matrix(0.865206 0.501417 -0.498585 0.866841 71.7728 0)"
              />
              <defs>
                <linearGradient
                  y1="0"
                  x1="25.1443"
                  x2="25.1443"
                  y2="143.953"
                  id="paint0_linear_7821_79167"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop />
                  <stop offset="1" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                  y1="0"
                  x1="25.1443"
                  x2="25.1443"
                  y2="143.953"
                  id="paint1_linear_7821_79167"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop />
                  <stop offset="1" stopOpacity="0" />
                </linearGradient>
              </defs>
            </svg> */}
            <MainLogo style={{ height: "30px", width: "30px" }} />
            <Typography
              variant="h6"
              sx={{
                ml: 2,
                lineHeight: 1,
                fontWeight: 700,
                fontSize: "1.5rem !important",
              }}
            >
              {themeConfig.templateName}
            </Typography>
          </Box>
          <Box sx={{ mb: 6 }}>
            <Typography
              variant="h5"
              sx={{ mb: 1.5, fontWeight: 600, letterSpacing: "0.18px" }}
            >
              {`Welcome to ${themeConfig.templateName}! 👋🏻`}
            </Typography>
            <Typography variant="body2">
              Please sign-in to your account and start the adventure
            </Typography>
          </Box>
          <form
            onSubmit={handleSubmit(onSubmit)} 
            noValidate 
            spellCheck="false" 
          >
            <TextField
              autoFocus
              fullWidth
              label="Email"
              type="email"
              sx={{ mb: 4 }}
              error={!!errors['email']} 
              {...register("email")}
            />
            <FormControl fullWidth>
              <InputLabel htmlFor="auth-login-password" error={!!errors['password']} >Password</InputLabel>
              <OutlinedInput
                label="Password"
                error={!!errors['password']} 
                {...register("password")}
                type={showPassword ? "text" : "password"}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      edge="end"
                      onClick={handleClickShowPassword}
                      aria-label="toggle password visibility"
                    >
                      {showPassword ? <EyeOutline /> : <EyeOffOutline />}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <Box
              sx={{
                mb: 4,
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                justifyContent: "space-between",
              }}
            >
              <FormControlLabel
                label="Remember Me"
                control={<Checkbox {...register("rememberMe")} />}
                sx={{
                  "& .MuiFormControlLabel-label": { color: "text.primary" },
                }}
              />
              <Link to={"/reset-password"}>
                <Typography
                  style={{ cursor: "pointer" }}
                  component={MuiLink}
                  variant="body2"
                  sx={{ color: "primary.main" }}
                >
                  Forgot Password?
                </Typography>
              </Link>
            </Box>
            <Button
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              sx={{ mb: 7 }}
            >
              Login
            </Button>

            {/* <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexWrap: "wrap",
                justifyContent: "center",
              }}
            >
              <Typography sx={{ mr: 2, color: "text.secondary" }}>
                New on our platform?
              </Typography>
              <Typography>
                <Link passHref href='/pages/auth/register-v1'>
                  <Typography component={MuiLink} sx={{ color: 'primary.main' }}>
                    Create an account
                  </Typography>
                </Link>
              </Typography>
            </Box>
            <Divider
              sx={{ mt: 5, mb: 7.5, "& .MuiDivider-wrapper": { px: 4 } }}
            >
              or
            </Divider>
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Link href='/' passHref>
                <IconButton component='a' onClick={(e: MouseEvent<HTMLElement>) => e.preventDefault()}>
                  <Facebook sx={{ color: '#497ce2' }} />
                </IconButton>
              </Link>
              <Link href='/' passHref>
                <IconButton component='a' onClick={(e: MouseEvent<HTMLElement>) => e.preventDefault()}>
                  <Twitter sx={{ color: '#1da1f2' }} />
                </IconButton>
              </Link>
              <Link href='/' passHref>
                <IconButton component='a' onClick={(e: MouseEvent<HTMLElement>) => e.preventDefault()}>
                  <Github
                    sx={{ color: theme => (theme.palette.mode === 'light' ? '#272727' : theme.palette.grey[300]) }}
                  />
                </IconButton>
              </Link>
              <Link href='/' passHref>
                <IconButton component='a' onClick={(e: MouseEvent<HTMLElement>) => e.preventDefault()}>
                  <Google sx={{ color: '#db4437' }} />
                </IconButton>
              </Link>
            </Box> */}
          </form>
        </CardContent>
      </Card>
      <FooterIllustrationsV1 />
    </Box>
  );
};

LoginV1.getLayout = (page: ReactNode) => <BlankLayout>{page}</BlankLayout>;

export default LoginV1;
