import axios, { AxiosError } from "axios";
import * as Api from "../../../service";
import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";

export interface AuthState {
  isLoading: boolean;
  initialLogInTried: boolean;
  isLoggedIn: boolean;
  frontUrl: string
  auth: Api.PostSignInRequestType;
}

const initialState: AuthState = {
  isLoading: false,
  isLoggedIn: false,
  initialLogInTried: false,
  frontUrl: "",
  auth: {
    ...({} as Api.PostSignInRequestType),
    success: true,
  },

};

export const postSignIn = createAsyncThunk<
  Api.PostSignInRequestType,
  Api.PostSignInRequestParams
>("auth/postSignIn", async (params: Api.PostSignInRequestParams) => {
  const response = await Api.postSignInRequest(params);
  return response.data;
});

export const postSendPasswordRecoveryLink = createAsyncThunk<
  Api.PostSendPasswordRecoveryLinkRequestType,
  Api.PostSendPasswordRecoveryLinkRequestParams
>(
  "auth/postSendPasswordRecoveryLink",
  async (params: Api.PostSendPasswordRecoveryLinkRequestParams) => {
    const response = await Api.postSendPasswordRecoveryLinkRequest(params);
    return response.data;
  }
);

export const postRecoverAdminUserPassword = createAsyncThunk(
  "auth/postRecoverAdminUserPassword",
  async (params: Api.PostRecoverAdminUserPasswordRequestParams) => {
    const response = await Api.postRecoverAdminUserPasswordRequest(params);
    return response.data;
  }
);

export const getFrontUrl = createAsyncThunk<Api.getFrontUrlType>("backOffice/getFrontUrl",
async () => {
    const response = await Api.getFrontUrlRequest()
    return response.data
}
);

export const postRefreshToken = createAsyncThunk<Api.RefreshTokenType, Api.postRefreshTokenRequestParams>(
  'backOfficeAccount/postRefreshToken',
  async (params: Api.postRefreshTokenRequestParams, thunkApi) => {
    try {
      const response = await Api.postRefreshTokenRequest(params);
      return response.data;
    } catch (err) {
      const error = err;
      if (error) {
        return thunkApi.rejectWithValue((error as AxiosError).response?.data);
      }

      throw err;
    }
  }
);

export const postGenerateTokenForViewAsUser = createAsyncThunk<Api.generateTokenType, string>('BackOfficeUsers/GenerateTokenForViewAsUser', async (id: string) => {
  const response = await Api.postGenerateTokenForViewAsUserRequest(id)
  return response.data
})


export const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    // checkUserInfoStorage: (state) => {
    //   // if (localStorage.getItem('token') != null) {
    //   //   const tokenStorage = localStorage.getItem('token');

    //   //   state.auth.token = tokenStorage;

    //   //   axios.defaults.headers.common[
    //   //     "Authorization"
    //   //   ] = `bearer ${tokenStorage}`;
    //   // }

    //   // if (localStorage.getItem("userInfo") != null) {
    //   //   const userInfo = localStorage.getItem("userInfo");
    //   //   if (userInfo == null) return;
    //   //   const userInfoStorage: Api.PostSignInRequestType["userInfo"] =
    //   //     JSON.parse(userInfo);
    //   //   state.auth.userInfo = userInfoStorage;
    //   //   state.isLoggedIn = true;
    //   // }
    //   // state.initialLogInTried = true;
    // },
    logOut: (state) => {
      state.isLoggedIn = false;
      state.auth = {} as Api.PostSignInRequestType;
      state.auth.success = true;
      localStorage.removeItem("softAdminToken");
      localStorage.removeItem("userInfo");
    },
    logIn: (state, action: PayloadAction<Api.PostSignInRequestType>) => {
      if (action.payload.token == null) return;

      state.auth = action.payload;
      localStorage.setItem('softAdminToken', action.payload.token);
      localStorage.setItem("userInfo", JSON.stringify(action.payload.userInfo));
      state.isLoggedIn = true;
    },
    updateAuthSuccess: (state, action: PayloadAction<boolean>) => {
      state.auth.success = action.payload;
    },

  },
  extraReducers: (builder) => {
    builder
    .addCase(getFrontUrl.fulfilled, (state,action) => {
      state.frontUrl = action.payload.frontUrl
    })
      .addCase(postSignIn.fulfilled, (state) => {
        state.isLoading = false;
      })
      .addCase(postSignIn.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postSignIn.rejected, (state) => {
        toast.error("Email or password is not correct!")
        state.auth.success = false;
        state.isLoading = false;
      })
      .addCase(postSendPasswordRecoveryLink.fulfilled, (state) => {
        state.isLoading = false;
        toast.success("Email sent. Please follow instructions");
      })
      .addCase(postSendPasswordRecoveryLink.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(postSendPasswordRecoveryLink.rejected, (state) => {
        state.isLoading = false;
        toast.error("Something Went Wrong");
      })
      .addCase(postRecoverAdminUserPassword.fulfilled, () => {
        toast.success("Password updated successfully");
      })
      .addCase(postRecoverAdminUserPassword.rejected, () => {
        toast.error("Something went wrong");
      })
      .addCase(postGenerateTokenForViewAsUser.rejected, state => {
        state.isLoading = false
      })
      .addCase(postGenerateTokenForViewAsUser.pending, state => {
        state.isLoading = true
      })
      .addCase(postGenerateTokenForViewAsUser.fulfilled, state => {
        state.isLoading = false
      })
  },
});

export const { logIn, logOut, updateAuthSuccess } =
  authSlice.actions;
export default authSlice.reducer;
