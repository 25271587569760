// ** MUI Imports
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { useState } from "react";
import Typography from "@mui/material/Typography";
import { GetDictionaryRequestType } from "../../../../service";
import { useParams } from "react-router-dom";

type propType = {
  id?: string;
  name: string;
  nameGE: string;
  deleteOption(): void;
  updateOption({ name, nameGE }: { name: string; nameGE: string }): void;
  setDeleteData: () => void;
};

const CountrySubItem = ({ id, name, nameGE, deleteOption, updateOption, setDeleteData }: propType) => {
  const [localName, setLocalName] = useState<string>(name);
  const [localNameGE, setLocalNameGE] = useState<string>(nameGE);
  const { pageName } = useParams();
  const childPageName = () => {
    if (!pageName) return <></>;
    return pageName.toLowerCase() === "field" ? "Position" : "City";
  };
  return (
    <>
      <TextField
        autoFocus
        fullWidth
        id="Name"
        label="Child name"
        name="name"
        sx={{ mb: 0, width: "100%" }}
        onChange={(e) => {
          setLocalName(e.target.value);
        }}
        onBlur={() => updateOption({ name: localName, nameGE: localNameGE })}
        value={localName}
      />
      <TextField
        autoFocus
        fullWidth
        id="nameGE"
        label="Child name (GE)"
        name="nameGE"
        sx={{ mb: 0, width: "100%" }}
        onBlur={() => updateOption({ name: localName, nameGE: localNameGE })}
        onChange={(e) => {
          setLocalNameGE(e.target.value);
        }}
        value={localNameGE}
      />
      <Button color="error" onClick={setDeleteData}>
        Delete {childPageName()}
      </Button>

      <Divider />
    </>
  );
};

export default CountrySubItem;
