// ** React Imports
import { useEffect, useState, ChangeEvent } from "react";

// ** MUI Imports
import Card from "@mui/material/Card";
import CardHeader from "@mui/material/CardHeader";
import { DataGrid, GridColumns, GridSortModel } from "@mui/x-data-grid";

// ** Types Imports
import * as Api from "../../../../service";

// ** utils Import
import { format } from "date-fns";
import QuickSearchToolbar from "./QuickSearchToolbar";
import { RootState, useAppDispatch } from "../../../../store";
import { getAllDictionaries } from "../../../../store/apps/companiesSlice";
import { handleObjectForQueryString } from "../../../helperFunctions";
import { useSelector } from "react-redux";
import { ThunkAction } from "@reduxjs/toolkit";
import DialogRenewVacancy from "../../../../pages/vacancies/DialogRenewVacancy";
import { getSearchVacancy, putRenewAllExpiredVacancies, putRenewExpiredVacancies } from "../../../../store/apps/vacanciesSlice";
import { getSearchVacancyDataType, getSearchVacancyType, getVacancyDataType, postDeleteCompanyVacancies } from "../../../../service";
import { setSelectedItems } from "../../../../store/apps/vacanciesSlice";
import { Paginate } from "./Pagination";

type propTypes = {
  data: Api.GetSearchTalentsRequestType | getSearchVacancyDataType | Api.AdminType;
  title: string;
  updatePage?: (pageNum: number) => void;
  updatePageSize?: (pageSize: string) => void;
  sortTable: (objectArr: GridSortModel) => void;
  columns: GridColumns;
  toggleRenewModal?: () => void;
  toggleDeleteModal?: () => void;
  renewModal?: boolean;
  // @ts-ignore
  stateDispatcher: (arg: any) => ThunkAction;
  storedState: string;
  // @ts-ignore
  dictionaryStateDispatcher?();
  checkboxField: boolean;
  filterObject?: getSearchVacancyType;
  AddNewData?: React.FunctionComponent<{}>;
  deleteSelected?: boolean;
};

const TableServerSide = ({
  data,
  title,
  updatePage,
  updatePageSize,
  sortTable,
  columns,
  stateDispatcher,
  storedState,
  dictionaryStateDispatcher,
  toggleRenewModal,
  toggleDeleteModal,
  renewModal,
  checkboxField,
  filterObject,
  AddNewData,
  deleteSelected,
}: propTypes) => {
  const dispatch = useAppDispatch();

  // ** State
  const [searchValue, setSearchValue] = useState<string>("");
  const [vacanciesToRenew, setVacanciesToRenew] = useState([]);
  const [deadlineToRenew, setDeadlineTorenew] = useState<Date>();

  const { submittedFilteredObject, submittedPage, isLoading } = useSelector(
    // @ts-ignore
    (state: RootState) => state[storedState]
  );

  const { data: tableData, pageSize, totalRecords } = data;

  useEffect(() => {
    if (dictionaryStateDispatcher) {
      dispatch(dictionaryStateDispatcher());
    }
  }, []);

  const handleSearch = (value: string) => {
    setTimeout(() => {
      dispatch(stateDispatcher(handleObjectForQueryString({ ...submittedFilteredObject, SearchTerm: value }, submittedPage)));
    }, 500);
    setSearchValue(value);
  };

  const onRowsSelectionHandler = (ids: any) => {
    setVacanciesToRenew(ids);
    dispatch(setSelectedItems({ ids }));
  };

  const handleRenewExpiredVacancies = () => {
    const date = format(deadlineToRenew!, "yyyy-MM-dd");
    const obj = {
      deadline: date,
      vacancyIds: vacanciesToRenew,
    };
    dispatch(putRenewExpiredVacancies(obj)).then(() => {
      setVacanciesToRenew([]);
      dispatch(getSearchVacancy(submittedFilteredObject));
    });
    if (toggleRenewModal) toggleRenewModal();
  };
  // const checkboxColumn = (params: any) => console.log(params, 'params')

  const renewBtn =
    vacanciesToRenew.length > 0 &&
    vacanciesToRenew.every((id) => {
      for (let i = 0; i < tableData.length; i++) {
        if (tableData[i].id === id) {
          // @ts-ignore
          if (tableData[i].vacancyStatus === 2) return true;
        }
      }
      return false;
    });

  return (
    <>
      <Card sx={{ minHeight: "600px" }}>
        <CardHeader title={title} />
        <DataGrid
          autoHeight
          pagination
          rows={tableData}
          rowCount={totalRecords}
          columns={columns}
          loading={isLoading}
          page={submittedPage - 1}
          checkboxSelection={checkboxField}
          pageSize={pageSize}
          sortingMode="server"
          paginationMode="server"
          onSortModelChange={sortTable}
          rowsPerPageOptions={[7, 10, 25, 50]}
          onPageChange={(newPage) => updatePage && updatePage(newPage)}
          components={{ Toolbar: QuickSearchToolbar, Pagination: Paginate }}
          onPageSizeChange={(newPageSize) => updatePageSize && updatePageSize(`${newPageSize}`)}
          // isRowSelectable={(params) => params.row.vacancyStatus === 2 && params.row.company.isVerified}
          onSelectionModelChange={(ids) => onRowsSelectionHandler(ids)}
          componentsProps={{
            toolbar: {
              value: searchValue,
              handleRenewModal: toggleRenewModal,
              handleDeleteData: toggleDeleteModal,
              clearSearch: () => handleSearch(""),
              onChange: (event: ChangeEvent<HTMLInputElement>) => handleSearch(event.target.value),
              componentSection: storedState,
              AddNewData: AddNewData,
              exportFilter: { filterObject, selectedCheckboxes: vacanciesToRenew },
              renewBtn,
            },
          }}
        />
      </Card>
      {renewModal && (
        <DialogRenewVacancy
          onSubmit={handleRenewExpiredVacancies}
          vacancyId={vacanciesToRenew}
          onClose={toggleRenewModal!}
          additionalBtn
          setDeadlineTorenew={setDeadlineTorenew}
          setVacanciesToRenew={setVacanciesToRenew}
          submittedFilteredObject={submittedFilteredObject}
          vacanciesToRenew={vacanciesToRenew}
        />
      )}
    </>
  );
};

export default TableServerSide;
