// ** React Imports
import { Ref, useState, forwardRef, ReactElement, FC, Dispatch, useEffect } from "react";

// ** MUI Imports
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import Dialog from "@mui/material/Dialog";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CardContent from "@mui/material/CardContent";
import Fade, { FadeProps } from "@mui/material/Fade";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import * as Yup from "yup";

// ** Icons Imports
import Close from "mdi-material-ui/Close";
import AccountOutline from "mdi-material-ui/AccountOutline";
import { SubmitHandler, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { getAdmins, postCreateNewAdmin, postEditAdminUser } from "../../store/apps/adminsSlice";
import { useAppDispatch } from "../../store";
import toast from "react-hot-toast";

const Transition = forwardRef(function Transition(props: FadeProps & { children?: ReactElement<any, any> }, ref: Ref<unknown>) {
  return <Fade ref={ref} {...props} />;
});

interface addAdminFormInputs {
  readonly email: string;
  readonly name: string;
  readonly lastName: string;
  readonly password: string;
  readonly confirmPassword: string;
}

type dialogAddAdminProps = {
  onSuccess: () => void;
  editAdmin?: addAdminFormInputs & { id: string };
  show: boolean;
  setShow: Dispatch<React.SetStateAction<boolean>>;
  refetchData: () => void;
  setDataId: Dispatch<any>;
};

const addAdminFormSchema = Yup.object()
  .shape({
    email: Yup.string()
      .email()
      .when("$editAdmin", {
        is: (editAdmin: addAdminFormInputs & { id: string }) => !editAdmin,
        then: Yup.string().required("Email is required"),
        otherwise: Yup.string().notRequired(),
      }),
    name: Yup.string().required(),
    lastName: Yup.string().required(),
    password: Yup.string().min(4).required(),
    confirmPassword: Yup.string()
      .required()
      .oneOf([Yup.ref("password"), null], "Passwords must match"),
  })
  .required();

const DialogAddAdmin: FC<dialogAddAdminProps> = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm<addAdminFormInputs>({
    resolver: yupResolver(addAdminFormSchema),
    context: { editAdmin: props.editAdmin },
  });

  const dispatch = useAppDispatch();

  const onSubmit: SubmitHandler<addAdminFormInputs> = async (data) => {
    if (props.editAdmin) {
      const response = await dispatch(postEditAdminUser({ id: props.editAdmin.id, lastName: data.lastName, name: data.name, password: data.password, role: 1 }));
      // @ts-ignore
      if (response.payload.succeeded) toast.success("Admin successfully edited");
      // @ts-ignore
      else toast.error(`Error: ${response.payload.errors}`);
      props.refetchData();
      props.setShow(false);

      return;
    }
    dispatch(postCreateNewAdmin({ ...data, role: 1 }))
      .unwrap()
      .then(() => {
        toast.success("Admin Created Successfully");
        reset();
        props.onSuccess();
        props.setShow(false);
      })
      .catch((response) => {
        toast.error(response.error);
      });
  };

  useEffect(() => {
    if (props.editAdmin) {
      setValue("name", props.editAdmin.name);
      setValue("lastName", props.editAdmin.lastName);
      setValue("email", props.editAdmin.email);
    }
  }, [props.editAdmin, setValue]);

  const onClose = () => {
    props.setDataId(undefined);
    props.setShow(false);
    reset();
  };

  return (
    <Dialog fullWidth open={props.show} maxWidth="md" scroll="body" onClose={onClose} TransitionComponent={Transition} onBackdropClick={onClose}>
      <form onSubmit={handleSubmit(onSubmit)} noValidate spellCheck="false" autoComplete="off">
        <DialogContent
          sx={{
            pb: 6,
            px: { xs: 8, sm: 15 },
            pt: { xs: 8, sm: 12.5 },
            position: "relative",
          }}
        >
          <IconButton size="small" onClick={onClose} sx={{ position: "absolute", right: "1rem", top: "1rem" }}>
            <Close />
          </IconButton>
          <Box sx={{ mb: 8, textAlign: "center" }}>
            <Typography variant="h5" sx={{ mb: 3, lineHeight: "2rem" }}>
              {props.editAdmin?.name ? "Edit " : "Add New "}
              Admin
            </Typography>
            {/* <Typography variant='body2'>Updating user details will receive a privacy audit.</Typography> */}
          </Box>
          <Grid container spacing={6}>
            <Grid item sm={12} xs={12}>
              <TextField disabled={!!props.editAdmin?.email} fullWidth error={!!errors["email"]} className="is-snvalid" label="Email" {...register("email")} />
            </Grid>
            <Grid item sm={12} xs={12}>
              <TextField fullWidth error={!!errors["name"]} label="Name" {...register("name")} />
            </Grid>
            <Grid item sm={12} xs={12}>
              <TextField fullWidth error={!!errors["lastName"]} label="Last Name" {...register("lastName")} />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField type="password" fullWidth error={!!errors["password"]} label="Password" {...register("password")} />
            </Grid>
            <Grid item sm={6} xs={12}>
              <TextField type="password" fullWidth error={!!errors["confirmPassword"]} label="Confirm Password" {...register("confirmPassword")} />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ pb: { xs: 8, sm: 12.5 }, justifyContent: "center" }}>
          <Button
            type="submit"
            variant="contained"
            sx={{ mr: 2 }}
            // onClick={() => setShow(false)}
          >
            Submit
          </Button>
          <Button variant="outlined" type="reset" color="secondary" onClick={onClose}>
            Discard
          </Button>
        </DialogActions>
      </form>
    </Dialog>
  );
};

export default DialogAddAdmin;
