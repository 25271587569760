import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import toast from "react-hot-toast";
import * as Api from "../../../service";

const FAQDetailsInitialValues = {
  titleEN: "",
  titleGE: "",
  descriptionEN: "",
  descriptionGE: "",
  active: false,
} as Api.FAQDetailsType;

const initialState = {
  FAQList: [] as Api.GetFAQListResponseType[],
  isLoading: false,
  selectedFAQID: "",
  showModal: false,
  FAQDetails: FAQDetailsInitialValues,
};

export const getFAQList = createAsyncThunk<Api.GetFAQListResponseType[]>(
  "FAQ/getFAQList",
  async () => {
    const response = await Api.getFAQList();
    return response.data.data;
  }
);

export const getFAQDetails = createAsyncThunk<Api.FAQDetailsType, string>(
  "FAQ/getFAQDetails",
  async (id: string) => {
    const response = await Api.getFAQDetails(id);
    return response.data.data;
  }
);

export const updateFAQ = createAsyncThunk(
  "talents/updateFAQ",
  async (params: Api.FAQDetailsType) => {
    const response = await Api.updateFAQ({ body: params });
    return response.data;
  }
);

export const updateFAQListOrder = createAsyncThunk(
  "talents/updateFAQListOrder",
  async (params: Api.UpdateOrderFAQRequestType[]) => {
    const response = await Api.updateFAQListOrder(params);
    return response.data;
  }
);

export const FAQSlice = createSlice({
  name: "FAQ",
  initialState,
  reducers: {
    updateFAQList: (
      state,
      action: PayloadAction<Api.GetFAQListResponseType[]>
    ) => {
      state.FAQList = action.payload;
    },
    setShowModal: (state, action: PayloadAction<boolean>) => {
      state.showModal = action.payload;
      state.FAQDetails = FAQDetailsInitialValues;
    },
    setSelectedFAQID: (state, action: PayloadAction<string>) => {
      state.selectedFAQID = action.payload;
      state.showModal = action.payload ? true : false;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getFAQList.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFAQList.fulfilled, (state, action) => {
        state.FAQList = action.payload;
        state.isLoading = false;
      })
      .addCase(getFAQList.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(getFAQDetails.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getFAQDetails.fulfilled, (state, action) => {
        state.FAQDetails = action.payload;
        state.isLoading = false;
      })
      .addCase(getFAQDetails.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(updateFAQ.fulfilled, (state) => {
        state.showModal = false;
        state.selectedFAQID = "";
        state.FAQDetails = FAQDetailsInitialValues;
      })
      .addCase(updateFAQ.rejected, () => {
        toast.error("Something went wrong");
      })
      .addCase(updateFAQListOrder.fulfilled, (state) => {
        toast.success("FAQ order updated successfully");
      })
      .addCase(updateFAQListOrder.rejected, () => {
        toast.error("Something went wrong");
      });
  },
});

export const { updateFAQList, setSelectedFAQID, setShowModal } =
  FAQSlice.actions;
export default FAQSlice.reducer;
