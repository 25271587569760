import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { toast } from "react-hot-toast";
import * as Api from "../../../service";
import { GetSearchTalentsRequestParams } from "../../../service";
import { handleObjectForQueryString } from "../../../utils/helperFunctions";


interface TalentsState {
  readonly isLoading: boolean;
  readonly talents: Api.GetSearchTalentsRequestType;
  readonly dictionaries: Api.DictionaryType[];
  readonly updateNumber: number;
  readonly submittedPage: number;
  readonly submittedFilteredObject: GetSearchTalentsRequestParams;
}

const initialState = {
  submittedPage: 1,
  updateNumber: 0,
  submittedFilteredObject: {
    PageSize: 10
  }
} as TalentsState;

export const getSearchTalents = createAsyncThunk<
  Api.GetSearchTalentsRequestType, string
>(
  "talents/getSearchTalents",
  async (queryString: string) => {
    const response = await Api.getSearchTalentsRequest(queryString);
    return response.data;
  }
);

export const getTalent = createAsyncThunk<Api.GetTalentRequestType, string>(
  "talents/getTalent",
  async (id: string) => {
    const response = await Api.getTalentRequest(id);
    return response.data;
  }
);

export const postUpdateTalent = createAsyncThunk<
  Api.PostUpdateTalentsRequestType,
  Api.PostUpdateTalentsRequestParams
>(
  "talents/postUpdateTalent",
  async (params: Api.PostUpdateTalentsRequestParams) => {
    const response = await Api.postUpdateTalentRequest(params);
    return response.data;
  }
);

export const getAllDictionaries = createAsyncThunk<Api.DictionaryType[]>(
  "talents/getAllDictionaries",
  async () => {
    const response = await Api.getAllDictionariesRequest();
    return response.data;
  }
);

export const deleteCompanyOrTalent = createAsyncThunk<{userId: string}, string>(
  "talents/deleteCompanyOrTalent", async (userId: string) => {
    const response = await Api.postDeleteCompanyOrTalent(userId)
    return response.data
  }
)

export const talentsSlice = createSlice({
  name: "talents",
  initialState,
  reducers: {
    // setSubmittedPage: (
    //   state,
    //   action: PayloadAction<{
    //     pageNumber: number;
    //   }>
    // ) => {
    //   state.submittedPage = action.payload.pageNumber;
    // },
    setSubmittedFilterObject: (
      state,
      action: PayloadAction<GetSearchTalentsRequestParams>
    ) => {
      state.submittedFilteredObject = action.payload;
    },
    setSubmittedPage: (
      state,
      action: PayloadAction<{
        submittedPage: number;
      }>
    ) => {
      state.submittedPage = action.payload.submittedPage;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getSearchTalents.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getSearchTalents.fulfilled, (state, action) => {
        state.talents = action.payload;
        state.isLoading = false;
      })
      .addCase(getSearchTalents.rejected, (state) => {
        state.isLoading = false;
      })
      .addCase(postUpdateTalent.fulfilled, (state) => {
        toast.success("Talent updated successfully");
        state.updateNumber += 1;
      })
      .addCase(postUpdateTalent.rejected, () => {
        toast.error("Something went wrong");
      })
      .addCase(getAllDictionaries.fulfilled, (state, action) => {
        state.dictionaries = action.payload;
      });
  },
});

export const { setSubmittedPage, setSubmittedFilterObject } = talentsSlice.actions;

export default talentsSlice.reducer;
