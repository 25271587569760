import TableCollapsible from '../../utils/views/table/mui/TableCollapsible';
import classes from './styles.module.sass'
import { useSelector } from "react-redux";
import { useAppDispatch } from '../../store';
import { RootState } from '../../store';
import { SeoData } from '../../service';
import { useEffect, useState } from 'react';
import { getSeoDetails, } from '../../store/apps/seoSlice';

const Seo = () => {
  const { seoData } = useSelector((state: RootState) => state.seoSlice);
  const dispatch = useAppDispatch()
  const [pageData, setPageData] = useState<SeoData | null>(null);

  useEffect(() => {
    dispatch(getSeoDetails())
  }, [dispatch])

  useEffect(() => {
    if(seoData) {
      let obj = {
        id: seoData?.data.id,
        data: {
          ...seoData?.data
        }
      };
      delete obj.data.id;

      // @ts-ignore
      setPageData(obj);
    }
  }, [seoData]);

  return <div className={classes.component}>
    <TableCollapsible data={pageData as SeoData} />
  </div>
}

export default Seo;